import React, { Component, Fragment } from 'react'
import { DocType } from '../../utils/enum-divers'
import { Container, Divider, Button } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import { connect } from 'react-redux'
import { fetchFactures } from '../../redux/actions/facture-action'
import DataTables from '../shared/datatable/Datatable'
import Pagination from '../shared/datatable/Pagination'
import { history } from '../../utils/history'
import FactureSearchForm from './FactureSearchForm'
import { cellFormat, roundTwoDec } from '../shared/datatable/cell-format'
import { Channels } from '../../api/channels'
import { ipcRenderer } from '../../api/api'
import moment from 'moment'
import ExportPdf from '../shared/exportcsv/export-pdf'
import { toast } from 'react-toastify'
import EmailModal from '../shared/modal/EmailModal'
import { handleErrorResponse } from '../../utils/handle-error-response'
const _ = require('lodash');

class Facture extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cols: [
        {
          text: 'Numéro',
          key: 'numero',
        },
        {
          text: 'Date',
          key: 'date',
          format: cellFormat.DATE,
        },
        {
          text: 'Code client',
          key: 'recepteur.code',
        },
        {
          text: 'Client',
          key: 'recepteur.nom',
        },
        {
          text: 'Famille',
          key: 'famille',
        },
        {
          text: 'Date de paiement',
          key: 'dateDeReglement',
          format: cellFormat.DATE,
        },
        {
          text: 'Comptabilisée',
          key: 'isComptabilise',
        },
        {
          text: 'HT après remise',
          key: 'totalHTApresRemise',
          format: cellFormat.MONEY,
        },
        {
          text: 'Total TTC',
          key: 'totalTTC',
          format: cellFormat.MONEY,
        },
        {
            _text: 'Poids NET',
            text: 'Quantité',
          key: 'poidsNet',
          format: cellFormat.NUMBER,
        },
      ],
      type: this.props.match.params.type,
      showModalMail: false,
      defaultEmail: '',
    }
  }


  modalActionsMail() {
    return (
      <Fragment>
        <button className="ui button teal left labeled icon" type="submit">
          <i className="mail icon"></i>
          Envoyer
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalMail: false,
            })
          }}
        >
          Fermer
        </button>
      </Fragment>
    )
  }

  getComptableEmail = () => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_ALL, { params: { isGerant: true } })
      .then((response) => {
        const gerant = response.data[0];
        if(gerant){
          this.setState({
            defaultEmail: gerant.emailComptable,
          })
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  emailSubmit = (email, objet, message) => {
    ipcRenderer
      .invoke(Channels.EMAIL_SEND, {
        email,
        objet,
        message,
        typeDocument: 'Comptable',
      })
      .then((response) => {
        if (response.error) {
          handleErrorResponse(response.error)
        } else {
          this.setState({
            showModalMail: false,
          })
          toast.success(
            `L'export comptable a bien été envoyé`
          )
          // Attendre 3 secondes avant de recharger la page pour voir le message
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }


  componentDidMount() {
    const { queryFaAchat, queryFaVente } = this.props.factures

    const query =
      this.props.match.params.type === 'ACHAT' ? queryFaAchat : queryFaVente

    this.props.fetchFactures(query)
    this.getComptableEmail()
  }

  // Pour le passage de Fa vente à Fa achat et vice versa
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.type != prevProps.match.params.type) {
      const { queryFaAchat, queryFaVente } = this.props.factures

      // Récupération de la query
      const query =
        this.props.match.params.type === 'ACHAT' ? queryFaAchat : queryFaVente

      this.props.fetchFactures(query)
    }
  }
  async generateFileName() {
    let filename = 'Export_FA'

    const { queryFaAchat, queryFaVente } = this.props.factures

    // Récupération de la query
    const query =
      this.props.match.params.type === 'ACHAT' ? queryFaAchat : queryFaVente

    const { params } = query
    filename += `${params.type ? '_' + params.type : ''}`
    filename += `${params.numero ? '_' + params.numero : ''}`
    filename += `${
      params.fromDate ? '_' + moment(params.fromDate).format('DD/MM/YYYY') : ''
    }`
    filename += `${
      params.toDate ? '_' + moment(params.toDate).format('DD/MM/YYYY') : ''
    }`
    if (params.recepteurId) {
      const recepteur = await ipcRenderer.invoke(
        Channels.CONTACT_FIND_BY_ID,
        params.recepteurId
      )
      filename += `${recepteur.code ? '_' + recepteur.code : ''}`
    }
    filename += `${params.isComptabilise ? '_Comptabilisee' : ''}`
    filename += `${params.isEncaissee ? '_Encaissee' : ''}`
    filename += `${params.isPaye ? '_Payee' : ''}`
    filename += `${params.famille ? '_' + params.famille : ''}`
    return filename
  }

  getDataForExport = async () => {
    const { queryFaAchat, queryFaVente } = this.props.factures

    // Récupération de la query
    const query =
      this.props.match.params.type === 'ACHAT' ? queryFaAchat : queryFaVente

    const { data } = await ipcRenderer.invoke(
      Channels.FACTURE_FIND_ALL,
      _.omit(query, ['skip', 'take'])
    )

    const result = _.map(data, (fa) =>{
       return {
      numero: fa.numero,
      date:  moment(fa.date).format('DD/MM/YYYY'), 
      'recepteur.nom': `${fa.recepteur?.code} - ${fa.recepteur?.nom}`,
      famille: fa?.famille,
      dateDeReglement: fa?.dateDeReglement ? moment(fa?.dateDeReglement).format('DD/MM/YYYY') : '', 
      dateEncaissement: fa?.dateEncaissement ? moment(fa?.dateEncaissement).format('DD/MM/YYYY') : '', 
      isComptabilise: fa.isComptabilise ? 'oui' : 'non',
      colis: fa.colis,
      totalCotisation: fa.totalCotisation,
      totalHTApresRemise: fa.totalHTApresRemise,
      totalTTC: fa.totalTTC,
      poidsNet: fa.poidsNet,
    }})

    const totalPoidsNet = _.sumBy(data, (d) => d?.poidsNet)
    const totalTTC = _.sumBy(data, (d) => d?.totalTTC)
    const totalHT = _.sumBy(data, (d) => d?.totalHTApresRemise)

    result.push({
      numero: query.params.famille ? 'Total ' + query.params.famille : 'Total',
      date: '',
      'recepteur.nom': '',
      famille: '',
      dateDeReglement: '',
      dateEncaissement: '',
      isComptabilise: '',
      colis: '',
      totalCotisation: '',
      totalHTApresRemise: totalHT,
      totalTTC: totalTTC,
      poidsNet: totalPoidsNet,
    })

    return result
  }

  showModalEmail = () =>
  {
    this.setState({
      showModalMail: true,
    })
  }

  getHeaderActions = () => {
    return (
        <Fragment>
            <Button
            icon="mail"
            type="submit"
            content="Export Comptable"
            color="blue"
            onClick={() =>
              this.showModalEmail()
            }
          />
        <ExportPdf
          filenameGeneratorFunc={async () => await this.generateFileName()}
          csvData={this.getDataForExport}
          headers={[
            {
              text: 'Numéro',
              key: 'numero',
            },
            {
              text: 'Date',
              key: 'date',
              format: cellFormat.DATE,
            },
            {
              text: 'Famille',
              key: 'famille',
            },
            {
              text: 'Client',
              key: 'recepteur.nom',
            },
            {
              text: 'Date paiement',
              key: 'dateDeReglement',
              format: cellFormat.DATE,
            },
            {
              text: 'Date encaissement',
              key: 'dateEncaissement',
              format: cellFormat.DATE,
            },
            {
              text: 'Compta',
              key: 'isComptabilise',
            },
            {
              text: 'HT après remise',
              key: 'totalHTApresRemise',
              format: cellFormat.MONEY,
            },
            {
              text: 'Total TTC',
              key: 'totalTTC',
              format: cellFormat.MONEY,
            },
            {
                _text: 'Poids NET',
                text: 'Quantité',
              key: 'poidsNet',
              format: cellFormat.NUMBER,
            },
          ]}
        />
            <button
                className="ui button teal"
                onClick={() => {
                    history.push(`/factures/${this.props.match.params.type}/create`)
                }}
                type="button"
            >
                <i className="icon plus" />
                Ajouter
            </button>
      </Fragment>
    )
  }

  renderTotalLine = (factures) => {
    const totalPoidsNet = _.sumBy(factures, (d) => d?.poidsNet)
    const totalTTC = _.sumBy(factures, (d) => d?.totalTTC)
    const totalHT = _.sumBy(factures, (d) => d?.totalHTApresRemise)

    return (
      <tr className="total-row">
        <td colSpan={7}>Total</td>
        <td>{roundTwoDec(totalHT)}</td>
        <td>{roundTwoDec(totalTTC)}</td>
        <td>{roundTwoDec(totalPoidsNet)}</td>
      </tr>
    )
  }

  getActionRow = (row) => {
    const { type } = this.props.match.params
    if (!row.id) {
      return null
    }
    const  isEdit  = !row.isComptabilise && !row.isImprime
    return (
      <Fragment>
        <button
          className="ui button blue custom-btn"
          type="button"
          onClick={() => {
            history.push(`/factures/view/${row.id}`)
          }}
        >
          <i className="icon eye" />
          Consulter
        </button>
        {isEdit == true ?(
        <button
          className="ui button olive custom-btn"
          type="button"
          onClick={() => {
            history.push(`/factures/${type}/edit/${row.id}`)
          }}
        >
          <i className="icon pencil" />
          Editer
        </button>):''}
      </Fragment>
    )
  }
  onColumnClick = (col) => {
    const { queryFaAchat, queryFaVente } = this.props.factures
    let query =
      this.props.match.params.type === 'ACHAT' ? queryFaAchat : queryFaVente
    // Si on tri déjà par ce champs
    if (query.order[col]) {
      // Si le tri est déjà en ASC, on switch en DESC
      if (query.order[col] === 'ASC') {
        query.order[col] = 'DESC'
        // Sinon ASC
      } else {
        query.order[col] = 'ASC'
      }
    } else {
      query.order = { [col]: 'ASC' }
    }
    this.props.fetchFactures(query)
  }

  onPageClick = (pageInfo) => {
    const { queryFaAchat, queryFaVente } = this.props.factures

    let query =
      this.props.match.params.type === 'ACHAT' ? queryFaAchat : queryFaVente
    query.skip = (pageInfo.currentPage - 1) * query.take
    this.props.fetchFactures(query)
  }

  onSubmitSearch = (formValues) => {
    const { queryFaAchat, queryFaVente } = this.props.factures

    const query =
      this.props.match.params.type === 'ACHAT' ? queryFaAchat : queryFaVente
    query.params = formValues

    query.skip = 0
    query.take = 10
    this.props.fetchFactures(query)
  }

  onResetSearch = () => {
    const { queryFaAchat, queryFaVente } = this.props.factures

    const query =
      this.props.match.params.type === 'ACHAT' ? queryFaAchat : queryFaVente

    query.params = {
      type: this.props.match.params.type,
      fromDate: new Date(new Date().getFullYear(), 0, 1),
      toDate: new Date(new Date().getFullYear(), 11, 31),
    }

    this.props.fetchFactures(query)
  }

  render() {
    const { type } = this.props.match.params

    const { queryFaAchat, queryFaVente } = this.props.factures

    let query =
      this.props.match.params.type === 'ACHAT' ? queryFaAchat : queryFaVente

    return (
      <Container fluid>
        <div className="ui segment">
          <SubHeader
            /* title={`Liste des Factures - ${type === DocType.ACHAT ? 'Achats' : 'Ventes'} `} */
            title={`Liste des Factures`}
            actions={this.getHeaderActions()}
          />
          <Divider />

          <FactureSearchForm
            onSubmit={this.onSubmitSearch}
            initialValues={query.params}
            onResetSearch={this.onResetSearch}
            type={type}
          />
          <DataTables
            columns={this.state.cols}
            rows={this.props.factures.data}
            onColumnClick={this.onColumnClick}
            actionRow={this.getActionRow}
            query={query}
            // total={this.renderTotalLine(this.props.factures.data)}
          />
          <Pagination
            onPageClick={this.onPageClick}
            itemPerPage={query.take}
            pageNeighbours={1}
            totalItems={this.props.factures.count}
            />
            <Divider />
            <SubHeader
                title={''}
                actions={this.getHeaderActions()}
                repetitionEnbas={this.props.factures.count>10?true:false}
            />
              
        </div>
        <EmailModal
          title="Envoi export comptable"
          visible={this.state.showModalMail}
          actions={this.modalActionsMail()}
          typeDocument="Comptable"
          email={this.state.defaultEmail}
          onSubmit={this.emailSubmit}
        />
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  let dataInitial = state.factures.ids
  ? state.factures.ids.map((id) => {
      let fact = state.factures.byIds[id]
      fact.date = moment(fact.date).format('DD/MM/YYYY')
      fact.dateDeReglement = fact.dateDeReglement? moment(fact.dateDeReglement).format('DD/MM/YYYY'):''
      return fact
    })
  : []
  return {
    factures: {
      count: state.factures.count,
      data: dataInitial,
      queryFaAchat: state.factures.queryFaAchat,
      queryFaVente: state.factures.queryFaVente,
    },
  }
}

export default connect(mapStateToProps, { fetchFactures })(Facture)
