import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Card,
  Container,
  Divider,
  Feed,
  Grid,
  Segment,
} from 'semantic-ui-react'
import { VisionMode } from '../../utils/theme'
import TraiteRecap from './TraiteRecap'
import SubHeader from '../shared/subHeader/SubHeader'
import BonDeLivraisonRecap from './BonDeLivraisonRecap'
import FactureRecap from './FactureRecap'

class AccueilRecap extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    //console.log(this.props)
    return (
      <Container fluid>
        <Segment>
          <SubHeader title={"En un coup d'oeil"} />
          <Divider />
          <Grid centered>
            <Grid.Column
              width={
                this.props.theme.visionMode === VisionMode.AMELIORE ? 12 : 5
              }
            >
              <BonDeLivraisonRecap bonsDeLivraison={[]} />
            </Grid.Column>
            <Grid.Column
              width={
                this.props.theme.visionMode === VisionMode.AMELIORE ? 12 : 5
              }
            >
              <FactureRecap />
            </Grid.Column>
            {/*<Grid.Column*/}
            {/*  width={*/}
            {/*    this.props.theme.visionMode === VisionMode.AMELIORE ? 12 : 5*/}
            {/*  }*/}
            {/*>*/}
            {/*  <TraiteRecap />*/}
            {/*</Grid.Column>*/}
          </Grid>
        </Segment>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return { theme: state.theme }
}

export default connect(mapStateToProps, null)(AccueilRecap)
