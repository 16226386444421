import React from 'react'
import { Table } from 'semantic-ui-react'
import { Field } from 'react-final-form'
import { roundTwoDec } from '../../../shared/datatable/cell-format'

export class FactureFormFooter extends React.Component {
  constructor(props) {
    super(props)
    const {TVA_2_1_visible, TVA_5_5_visible, TVA_10_visible, TVA_20_visible} = this.props
    this.state = {
      totalTVA_2_1_visible: TVA_2_1_visible,
      totalTVA_5_5_visible: TVA_5_5_visible,
      totalTVA_10_visible: TVA_10_visible,
      totalTVA_20_visible: TVA_20_visible,
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("TEST",this.props)
    if (this.props !== prevProps) {
      const {TVA_2_1_visible, TVA_5_5_visible, TVA_10_visible, TVA_20_visible} = this.props
      this.setState({totalTVA_2_1_visible: TVA_2_1_visible,
        totalTVA_5_5_visible: TVA_5_5_visible, 
        totalTVA_10_visible: TVA_10_visible, 
        totalTVA_20_visible: TVA_20_visible})
    }
  }

  render() {
    const {TVA_2_1_visible, TVA_5_5_visible, TVA_10_visible, TVA_20_visible} = this.props
    return (
      <Table>
        <Table.Footer>
         {/*  <Table.Row>
            <Table.HeaderCell colSpan="8" rowSpan="1" />
             <Table.HeaderCell>Nombre de colis</Table.HeaderCell> 
            <Table.HeaderCell>
              <Field name={'colis'}>
                {({ input, meta }) => {
                  return input.value
                }}
              </Field>
            </Table.HeaderCell>
          </Table.Row> */}
          {/*<Table.Row>*/}
          {/*  <Table.HeaderCell colSpan="8" rowSpan="1" />*/}
          {/*  <Table.HeaderCell>Total Cotisation</Table.HeaderCell>*/}
          {/*  <Table.HeaderCell>*/}
          {/*    <Field name={'totalCotisation'}>*/}
          {/*      {({ input, meta }) => {*/}
          {/*        return roundTwoDec(input.value) + ' €'*/}
          {/*      }}*/}
          {/*    </Field>*/}
          {/*  </Table.HeaderCell>*/}
          {/*</Table.Row>*/}
          <Table.Row>
            <Table.HeaderCell colSpan="8" rowSpan="1" />
            <Table.HeaderCell>Total H.T. avant remise</Table.HeaderCell>
            <Table.HeaderCell>
              <Field name={'totalHTAvantRemise'}>
                {({ input, meta }) => {
                  return roundTwoDec(input.value) + ' €'
                }}
              </Field>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell colSpan="8" rowSpan="1" />
            <Table.HeaderCell>Montant remise</Table.HeaderCell>
            <Table.HeaderCell>
              <Field name={'montantRemise'}>
                {({ input, meta }) => {
                  return roundTwoDec(input.value) + ' €'
                }}
              </Field>
            </Table.HeaderCell>
          </Table.Row>

          <Table.Row>
            <Table.HeaderCell colSpan="8" rowSpan="1" />
            <Table.HeaderCell>Total H.T. après remise</Table.HeaderCell>
            <Table.HeaderCell>
              <Field name={'totalHTApresRemise'}>
                {({ input, meta }) => {
                  return roundTwoDec(input.value) + ' €'
                }}
              </Field>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row hidden={!TVA_2_1_visible}>
            <Table.HeaderCell colSpan="8" rowSpan="1" />
            <Table.HeaderCell>Montant TVA 2.1%</Table.HeaderCell>
            <Table.HeaderCell>
              <Field name={'totalTVA_2_1'}>
                {({ input, meta }) => {
                  return roundTwoDec(input.value) + ' €'
                }}
              </Field>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row hidden={!TVA_5_5_visible}>
            <Table.HeaderCell colSpan="8" rowSpan="1" />
            <Table.HeaderCell>Montant TVA 5.5%</Table.HeaderCell>
            <Table.HeaderCell>
              <Field name={'totalTVA_5_5'}>
                {({ input, meta }) => {
                  return roundTwoDec(input.value) + ' €'
                }}
              </Field>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row hidden={!TVA_10_visible}>
            <Table.HeaderCell colSpan="8" rowSpan="1" />
            <Table.HeaderCell>Montant TVA 10 %</Table.HeaderCell>
            <Table.HeaderCell>
              <Field name={'totalTVA_10'}>
                {({ input, meta }) => {
                  return roundTwoDec(input.value) + ' €'
                }}
              </Field>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row hidden={!TVA_20_visible}>
            <Table.HeaderCell colSpan="8" rowSpan="1" />
            <Table.HeaderCell>Montant TVA 20%</Table.HeaderCell>
            <Table.HeaderCell>
              <Field name={'totalTVA_20'}>
                {({ input, meta }) => {
                  return roundTwoDec(input.value) + ' €'
                }}
              </Field>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell colSpan="8" rowSpan="1" />
            <Table.HeaderCell>Montant TVA</Table.HeaderCell>
            <Table.HeaderCell>
              <Field name={'montantTVA'}>
                {({ input, meta }) => {
                  return roundTwoDec(input.value) + ' €'
                }}
              </Field>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell colSpan="8" rowSpan="1" />
            <Table.HeaderCell>Total TTC</Table.HeaderCell>
            <Table.HeaderCell>
              <Field name={'totalTTC'}>
                {({ input, meta }) => {
                  return roundTwoDec(input.value) + ' €'
                }}
              </Field>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell colSpan="8" rowSpan="1" />
            <Table.HeaderCell>Quantité</Table.HeaderCell>
            <Table.HeaderCell>
              <Field name={'poidsNet'}>
                {({ input, meta }) => {
                  return input.value/* + ' Kg'*/
                }}
              </Field>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    )
  }
}
