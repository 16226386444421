import { avoir, statistiques } from '../actions/types'
const _ = require('lodash');

export default (
  state = {
    achatsVentes: [],
    achatsVentesCount: 0,
    achatsVentesQuery: {
      skip: 0,
      take: 10,
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: {
        produitId: 'ASC',
      },
    },
    clients: [],
    clientsCount: 0,
    clientsQuery: {
      skip: 0,
      take: 10,
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: {
        produitId: 'ASC',
      },
    },
    parcelles: [],
    parcellesCount: 0,
    parcellesQuery: {
      skip: 0,
      take: 10,
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: {
        produitId: 'ASC',
      },
    },
    colis: {
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
    },
    fournisseurs: [],
    fournisseursCount: 0,
    fournisseursQuery: {
      skip: 0,
      take: 10,
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: {
        produitId: 'ASC',
      },
    },
    venteJournaliere: [],
    venteJournaliereCount: 0,
    venteJournaliereQuery: {
      params: {
        type: 'VENTE',
        fromDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          1,
          0,
          1
        ),
        toDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          24,
          59,
          59
        ),
      },
      order: {},
    },
  },
  action
) => {
  switch (action.type) {
    case statistiques.ACHAT_VENTE:
      const statistiquesState = {
        achatsVentes: action.payload.response.data,
        achatsVentesQuery: action.payload.query,
        achatsVentesCount: action.payload.response.count,
      }
      return {
        ...state,
        ...statistiquesState,
      }
    case statistiques.CLIENT:
      const statistiquesClientState = {
        clients: action.payload.response.data,
        clientsQuery: action.payload.query,
        clientsCount: action.payload.response.count,
      }
      return {
        ...state,
        ...statistiquesClientState,
      }
    case statistiques.PARCELLES:
      const statistiquesPacelleState = {
        parcelles: action.payload.response.data,
        parcellesQuery: action.payload.query,
        parcellesCount: action.payload.response.count,
      }
      return {
        ...state,
        ...statistiquesPacelleState,
      }
    case statistiques.FOURNISSEUR:
      const statistiquesFournisseurState = {
        fournisseurs: action.payload.response.data,
        fournisseursQuery: action.payload.query,
        fournisseursCount: action.payload.response.count,
      }
      return {
        ...state,
        ...statistiquesFournisseurState,
      }
    case statistiques.VENTE_JOURNALIERE:
      const statistiquesVenteJournaliere = {
        venteJournaliere: action.payload.response.data,
        venteJournaliereQuery: action.payload.query,
        venteJournaliereCount: action.payload.response.count,
      }
      return {
        ...state,
        ...statistiquesVenteJournaliere,
      }
    default:
      return state
  }
}
