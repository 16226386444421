import React, { Component } from 'react'
import { Field, useForm } from 'react-final-form'
import { Button, Form as SemanticForm, Grid } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'

class ProdouaneFormSearchForm extends Component {
  render() {
    return (
      <Grid>
        <Grid.Row verticalAlign={'bottom'}>
          <Grid.Column width={3}>
            <Field name="referencePeriod">
              {({ input, meta }) => (
                <SemanticForm.Field>
                  <label>Période</label>
                  <DatePicker
                    {...input.value}
                    selected={input.value ? input.value : null}
                    dateFormat="MM/yyyy"
                    locale="fr"
                    showMonthYearPicker
                    showFullMonthYearPicker
                  />
                </SemanticForm.Field>
              )}
            </Field>
          </Grid.Column>
          <Grid.Column width={6}>
            <Field name="searchBtn">
              {({ input, meta }) => {
                const form = useForm()
                return (
                  <Button
                    type="button"
                    onClick={() => {
                      const { referencePeriod } = form.getState().values
                      this.props.onSearchByPeriod(referencePeriod)
                    }}
                  >
                    <i className="icon search" />
                    {this.props.edit ? 'Mettre à jour' : 'Rechercher'}
                  </Button>
                )
              }}
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default ProdouaneFormSearchForm
