import {
  TITLE_PAGE,
  BTN_PAGE,
  SHOW_HIDE_MODAL_EDIT,
  SHOW_HIDE_MODAL_DELETE,
  SHOW_HIDE_MODAL_CANCEL,
  LOAD_FORM_VALUES,
  ACTIVATE_HOME_RECAP,
} from '../actions/autres'

import { autre } from '../actions/types'

export const EtatMode = {
  CLASSIQUE: 'CLASSIQUE',
  AMELIORE: 'AMELIORE',
}

export default (
  state = {
    titlePage: ' ',
    btnPage: [],
    formInitialValues: {},
    countries: [],
    recapReadyToLoad: false,
  },
  action
) => {
  switch (action.type) {
    case TITLE_PAGE:
      return {
        ...state,
        titlePage: action.payload,
      }
    case BTN_PAGE:
      return {
        ...state,
        btnPage: action.payload,
      }
    case SHOW_HIDE_MODAL_EDIT:
      return {
        ...state,
        modalEditActive: action.payload,
      }
    case SHOW_HIDE_MODAL_DELETE:
      return {
        ...state,
        modalDeleteActive: action.payload,
      }
    case SHOW_HIDE_MODAL_CANCEL:
      return {
        ...state,
        modalCancelActive: action.payload,
      }
    case LOAD_FORM_VALUES:
      return {
        ...state,
        formInitialValues: action.payload,
      }
    case autre.FETCH_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      }

    case ACTIVATE_HOME_RECAP:
      return {
        ...state,
        recapReadyToLoad: true,
      }

    default:
      return { ...state }
  }
}
