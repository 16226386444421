import React, { Component, Fragment } from 'react'
import { Field, useForm } from 'react-final-form'
import { Button, Grid, Form as SemanticForm } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import moment from 'moment'

class ProdouaneFormLigneHeader extends Component {
  onDelete = (fields, index) => {
    fields.remove(index)
  }

  componentDidMount = () => {
    this.props.updateTotalRemise(
      this.props.form,
      this.props?.bonDeLivraisons[this.props.index]?.id,
      this.props.index
    )
  }

  render() {
    const { fields, index, bonDeLivraisons } = this.props
    return (
      <Fragment>
        <Grid.Column width={5}>
          <Field name={`bonDeLivraisons[${index}].id`} index={index}>
            {({ input, meta }) => {
              const form = useForm()
              return (
                <SemanticForm.Field>
                  <SemanticForm.Dropdown
                    {...input}
                    search
                    fluid
                    label={'Bon de livraison'}
                    selection
                    onBlur={(e) => e.preventDefault()}
                    options={bonDeLivraisons?.map((bonLivraison) => {
                      return {
                        key: bonLivraison.id,
                        value: bonLivraison.id,
                        text: `${bonLivraison.numero} - ${bonLivraison.recepteur?.code}`,
                      }
                    })}
                    onChange={(event, data) => {
                      const blOption = bonDeLivraisons?.find((bl) => {
                        return bl.id === data.value
                      })

                      const blSelected = fields.value.find(
                        (bl) => bl.id === data.value
                      )
                      if (blOption && blSelected == null) {
                        input.onChange(data.value)
                        this.props.updateTotalRemise(
                          this.props.form,
                          data.value,
                          index
                        )
                        form.change(`bonDeLivraisons[${index}]`, blOption)
                      } else {
                        toast.info('Bon de livraison déjà utilisé')
                      }
                    }}
                    error={meta.error && meta.touched ? meta.error : null}
                  />
                </SemanticForm.Field>
              )
            }}
          </Field>
        </Grid.Column>

        <Grid.Column width={3} floated={'right'}>
          <Field name={`bonDeLivraisons[${index}].date`}>
            {({ input, meta }) => (
              <Grid>
                <Grid.Row>
                  <Grid.Column floated="left">
                    <p>
                      <strong>Date</strong>
                    </p>
                    {moment(input.value).format('DD/MM/YYYY')}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </Field>
        </Grid.Column>

        <Grid.Column width={1} floated={'right'}>
          <Button
            type={'button'}
            icon="remove"
            color={'red'}
            onClick={() => {
              this.onDelete(fields, index)
            }}
          />
        </Grid.Column>
      </Fragment>
    )
  }
}

export default ProdouaneFormLigneHeader
