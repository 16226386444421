export const cellFormat = {
  DATE: 'DATE',
  MULTILINE_STRING: 'MULTILINE_STRING',
  MONEY: 'MONEY',
  NUMBER: 'NUMBER',
}

export const roundTwoDec = (value) => {
  if (
    !isNaN(parseFloat(value)) &&
    !isNaN(value - 0) &&
    (!value.toString().startsWith('0') || value.toString().startsWith('0.'))
  ) {
    if (typeof value === 'string') {
      value = parseFloat(value)
    }
    value = value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  } else {
    value = 0.0
  }
  return value
}
