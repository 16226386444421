import React from 'react'
import { Card, Grid, Statistic, Table } from 'semantic-ui-react'
import { fetchFactures } from '../../redux/actions/facture-action'
import { connect } from 'react-redux'
import moment from 'moment'
import { NavLink } from 'react-router-dom'

class FactureRecap extends React.Component {
  componentDidMount() {
    let query = this.props.factures.query
    query = {
      ...query,
      params: {
        fromDate: moment().startOf('year').toDate(),
        toDate: moment().add(7, 'days').startOf('day').toDate(),
        // // !!!! Attention à tester
        waitingForPayment: true,
      },
      order: {
        date: 'ASC',
      },
    }
    this.props.fetchFactures(query)
  }

  renderTableRecap() {
    const { factures } = this.props
    return factures.data.map((facture, i) => {
      let dateString = ""
      if(facture.dateEcheance){
        let date = new Date(facture.dateEcheance)
        dateString = date.toLocaleDateString('fr-FR')
      }
      return (
        <Table.Row key={i}>
          <Table.Cell>{facture.numero}</Table.Cell>
          <Table.Cell>
            {dateString}
          </Table.Cell>
          <Table.Cell>{facture.totalTTC} €</Table.Cell>
        </Table.Row>
      )
    })
  }

  render() {
    const { factures } = this.props
    const nbEnAttente = factures.count

    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>Factures</Card.Header>
        </Card.Content>
        <Card.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Statistic size="tiny">
                  <Statistic.Label>ATTENTE</Statistic.Label>
                  <Statistic.Value>{nbEnAttente}</Statistic.Value>
                </Statistic>
              </Grid.Column>
              <Grid.Column width={12}>
                facture{nbEnAttente > 1 ? 's' : ''} en attente de paiement
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Table basic="very" celled>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell>N°</Table.HeaderCell>
                <Table.HeaderCell>Echéance</Table.HeaderCell>
                <Table.HeaderCell>Total TTC</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderTableRecap()}</Table.Body>
          </Table>
        </Card.Content>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    factures: {
      count: state.factures.count,
      data: state.factures.ids
        ? state.factures.ids.map((id) => {
            return state.factures.byIds[id]
          })
        : [],
      query: state.factures.query,
    },
  }
}

export default connect(mapStateToProps, { fetchFactures })(FactureRecap)
