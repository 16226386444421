import React from 'react'
import {
  Button,
  Container,
  Divider,
  Grid,
  Icon,
  Message,
  Segment,
  TextArea,
} from 'semantic-ui-react'
import arrayMutators from 'final-form-arrays'
import { Form as FinalForm, Field, useForm } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import SelectContact from './partials/header/SelectContact'
import DatePicker from 'react-datepicker'

import { fetchBonsDeLivraison } from '../../redux/actions/bon-de-livraison-action'
import { connect } from 'react-redux'
import ModeReglement from '../../api/enum/mode-reglement'
import tauxDeTVA from '../../api/enum/taux-tva'
import { composeValidators, isRequired } from '../../utils/validators'
import { SectionBonsDeLivraisons } from './partials/bl/SectionBonsDeLivraisons'
import { FactureFormFooter } from './partials/footer/FactureFormFooter'
import { updateGlobal } from './partials/common-form-update'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import moment from 'moment/moment'
import { SimpleConsoleLogger } from 'typeorm'
import { toast } from 'react-toastify'

class FactureForm extends React.Component {
  constructor(props) {
    super(props)
    const {initialValues} = this.props
    this.state = {
      view: this.props.view,
      buttonDisabled: true,
      emetteurId: null,
      recepteurId: null,
      mentions: [],
      dateValid: new Map(),
      totalTVA_2_1_visible: initialValues.totalTVA_2_1 && initialValues.totalTVA_2_1!=0,
      totalTVA_5_5_visible: initialValues.totalTVA_5_5 && initialValues.totalTVA_5_5!=0,
      totalTVA_10_visible: initialValues.totalTVA_10 && initialValues.totalTVA_10!=0,
      totalTVA_20_visible: initialValues.totalTVA_20 && initialValues.totalTVA_20!=0
    }
  }

  handleClose = (fieldId, id) => {
    if (id !== this.state.recepteurId) {
      //console.log('Changed')
    }
    switch (fieldId) {
      case 'emetteurId':
        this.setState({
          ...this.state,
          emetteurId: id,
          buttonDisabled: !this.state.recepteurId,
        })
        break
      case 'recepteurId':
        this.setState({
          ...this.state,
          recepteurId: id,
          buttonDisabled: !this.state.emetteurId,
        })
        break
    }
  }
  componentDidMount() {
    this.getMentionsInState()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      const initialValues = this.props.initialValues
      this.setState({totalTVA_2_1_visible: initialValues.totalTVA_2_1 != 0,
        totalTVA_5_5_visible: initialValues.totalTVA_5_5 != 0, 
        totalTVA_10_visible: initialValues.totalTVA_10 != 0, 
        totalTVA_20_visible: initialValues.totalTVA_20 != 0})
    }
  }

  getMentionsInState = () => {
    ipcRenderer.invoke(Channels.MENTION_FIND_ALL, {}).then(({ data }) => {
      this.setState({
        mentions: data,
      })
    })
  }

  onSubmit = (formValues) => {
    console.log('Facture submit form')
    if (!moment(formValues?.dateEcheance).isValid()) {
      toast.error(
        "La date d'échéance n'est pas valide. Celle-ci est obligatoire."
      )
      return
    }

    if (!moment(formValues?.date).isValid()) {
      toast.error('La date de création est obligatoire.')
      return
    }

    if (formValues.date) {
      if (typeof formValues.date === 'number') {
        formValues.date = moment(formValues.date, 'DD/MM/YYYY')
        .hour(0)
        .minutes(0)
        .second(0)
        .toDate()
      }
      
    }
    if (formValues.dateDeReglement) {
      if (typeof formValues.dateDeReglement === 'number') {
        formValues.dateDeReglement = moment(
          formValues.dateDeReglement,
          'DD/MM/YYYY'
        )
          .hour(0)
          .minutes(0)
          .second(0)
          .toDate()
      }
      
    } else {
      formValues.dateDeReglement = null
    }
    if (formValues.dateEncaissement) {
      if (typeof formValues.dateEncaissement === 'number') {
        formValues.dateEncaissement = moment(
          formValues.dateEncaissement,
          'DD/MM/YYYY'
        )
          .hour(0)
          .minutes(0)
          .second(0)
          .toDate()
      }
      
    } else {
      formValues.dateEncaissement = null
    }

    // console.log(moment(formValues?.dateEcheance).isValid())

    // console.log(typeof formValues.dateEcheance)
    // console.log(formValues.dateEcheance)

    if (typeof formValues.dateEcheance === 'number') {
      formValues.dateEcheance = moment(formValues.dateEcheance, 'DD/MM/YYYY')
      .hour(0)
      .minutes(0)
      .second(0)
      .toDate()
    }
    

    if (!formValues.totalCotisation) {
      formValues.totalCotisation = 0.0
    } else {
      formValues.totalCotisation = parseFloat(formValues.totalCotisation)
    }
    // if (!formValues.commentaire) {
    //   formValues.commentaire = null
    // }
    if (!formValues.tauxDeTVA) {
      formValues.tauxDeTVA = 0
    } else {
      formValues.tauxDeTVA = parseFloat(formValues.tauxDeTVA)
    }
    if (!formValues.montantTVA) {
      formValues.montantTVA = 0
    } else {
      formValues.montantTVA = parseFloat(formValues.montantTVA)
    }

    if (!formValues.totalTVA_2_1) {
      formValues.totalTVA_2_1 = 0
    } else {
      formValues.totalTVA_2_1 = parseFloat(formValues.totalTVA_2_1)
    }
    if (!formValues.totalTVA_5_5) {
      formValues.totalTVA_5_5 = 0
    } else {
      formValues.totalTVA_5_5 = parseFloat(formValues.totalTVA_5_5)
    }
    if (!formValues.totalTVA_10) {
      formValues.totalTVA_10 = 0
    } else {
      formValues.totalTVA_10 = parseFloat(formValues.totalTVA_10)
    }
    if (!formValues.totalTVA_20) {
      formValues.totalTVA_20 = 0
    } else {
      formValues.totalTVA_20 = parseFloat(formValues.totalTVA_20)
    }

    if (!formValues.montantRemise) {
      formValues.montantRemise = 0
    } else {
      formValues.montantRemise = parseFloat(formValues.montantRemise)
    }
    if (!formValues.remise) {
      formValues.remise = 0
    } else {
      formValues.remise = parseFloat(formValues.remise)
    }
    if (!formValues.totalHTAvantRemise) {
      formValues.totalHTAvantRemise = 0
    } else {
      formValues.totalHTAvantRemise = parseFloat(formValues.totalHTAvantRemise)
    }
    if (!formValues.totalHTApresRemise) {
      formValues.totalHTApresRemise = 0
    } else {
      formValues.totalHTApresRemise = parseFloat(formValues.totalHTApresRemise)
    }
    if (!formValues.totalTTC) {
      formValues.totalTTC = 0
    } else {
      formValues.totalTTC = parseFloat(formValues.totalTTC)
    }
    if (formValues.bonsDeLivraisons) {
      formValues.bonsDeLivraisons.forEach((item) => {
        item.bonDeLivraisonLignes.forEach((bll) => {
          bll.totalLigneHT = parseFloat(bll.totalLigneHT)
        })
      })
    } else {
      formValues.bonsDeLivraisons = []
    }
    this.props.onSubmit(formValues)
  }

  checkIfDateIsWithinCurrentYear = (date, field, message) => {
    // Zone
    const dateValid = this.state.dateValid

    // Si saisie d'une date hors année courante
    if (date.getFullYear() != new Date().getFullYear())
      dateValid.set(field, message)
    else dateValid.delete(field)

    this.setState({
      dateValid: dateValid,
    })
  }

  render() {
    const {
      type,
      initialValues,
      creation,
      formTitle,
      headerActions,
      consultation,
    } = this.props
    const tvaOptions = []
    const modeReglementOptions = []

    for (const mode in ModeReglement) {
      modeReglementOptions.push({
        key: ModeReglement[mode],
        value: ModeReglement[mode],
        text: ModeReglement[mode],
      })
    }

    for (const tva in tauxDeTVA) {
      if (!isNaN(Number(tva))) {
        tvaOptions.push({
          key: tauxDeTVA[tva],
          value: tva,
          text: `${tauxDeTVA[tva]} (${tva}%)`,
        })
      }
    }
    return (
      <Container fluid>
        <FinalForm
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          keepDirtyOnReinitialize={true}
          mutators={{
            ...arrayMutators,
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ handleSubmit, form, values }) => {
            return (
              <SemanticForm
                onSubmit={(e) => {
                  e.preventDefault()
                  handleSubmit()
                }}
                size="massive"
              >
                <Segment>
                  <SubHeader title={formTitle} actions={headerActions} />
                </Segment>
                <Segment>
                  <Container fluid>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <Message
                            hidden={this.state.dateValid.size <= 0}
                            icon="warning sign"
                            header="Certains éléments peuvent être incorrects"
                            list={[...this.state.dateValid.values()]}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column floated="left" width={7}>
                          <SelectContact
                            type={type}
                            label={'Emetteur'}
                            fieldId={'emetteurId'}
                            fieldString={'emetteurAsString'}
                            mainForm={form}
                            handleClose={this.handleClose}
                            view={this.props.view}
                          />
                        </Grid.Column>

                        <Grid.Column floated="right" width={7}>
                          <SelectContact
                            type={type}
                            label={'Client'}
                            fieldId={'recepteurId'}
                            fieldString={'recepteurAsString'}
                            mainForm={form}
                            handleClose={this.handleClose}
                            view={this.props.view}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid>
                      {/* <Grid.Row>
                        <Grid.Column verticalAlign="middle" width={3}>
                          <Field name="isComptabilise" type="checkbox">
                            {({ input, meta }) => (
                              <SemanticForm.Checkbox
                                {...input}
                                toggle
                                readOnly={
                                  (initialValues.isComptabilise &&
                                    initialValues.dateDeReglement &&
                                    initialValues.dateEncaissement) ||
                                  this.props.consultation
                                }
                                label="Comptabilisée"
                                onChange={(event, { checked }) => {
                                  return input.onChange(checked)
                                }}
                              />
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row> */}
                      <Grid.Column width={4}>
                        <Field name={`type`}>
                          {({ input }) => <input {...input} type="hidden" />}
                        </Field>
                        <Field name="numero">
                          {({ input, meta }) => (
                            <SemanticForm.Input
                              {...input}
                              label="Numéro"
                              type="text"
                              placeholder="Généré automatiquement"
                              readOnly={true}
                              error={
                                meta.error && meta.touched ? meta.error : null
                              }
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Field name="date" validate={isRequired}>
                          {({ input, meta }) => (
                            <SemanticForm.Field>
                              <label>Date de création</label>
                              <DatePicker
                                {...input.value}
                                locale="fr"
                                showYearDropdown
                                selected={
                                  (input.value && new Date(input.value)) || null
                                }
                                onSelect={(date) => {
                                  if (date) {
                                    this.checkIfDateIsWithinCurrentYear(
                                      date,
                                      input.name,
                                      'Date de la facture'
                                    )
                                  }

                                  let dateEcheance = new Date(date)
                                  dateEcheance.setDate(date.getDate() + 30)

                                  form.change(`dateEcheance`, dateEcheance)

                                  input.onChange(date)
                                }}
                                onChange={(date) => {
                                  if (date) {
                                    this.checkIfDateIsWithinCurrentYear(
                                      date,
                                      input.name,
                                      'Date de la facture'
                                    )
                                  }
                                  input.onChange(date)
                                }}
                                dateFormat="dd/MM/yyyy"
                                readOnly={this.props.view}
                              />
                            </SemanticForm.Field>
                          )}
                        </Field>
                      </Grid.Column>
                      {/* <Grid.Column width={4}>
                        <Field name="tauxDeTVA" validate={isRequired}>
                          {({ input, meta }) => {
                            const form = useForm()
                            return (
                              <SemanticForm.Select
                                {...input}
                                fluid
                                label="Taux TVA"
                                disabled={this.props.view}
                                onChange={async (value, data) => {
                                  await updateGlobal(form)
                                  return input.onChange(data.value)
                                }}
                                onClose={async (event, data) => {
                                  await updateGlobal(form)
                                }}
                                options={tvaOptions}
                                error={
                                  meta.error && meta.touched ? meta.error : null
                                }
                              />
                            )
                          }}
                        </Field>
                      </Grid.Column> 

                      <Grid.Column width={4}>
                        <Field name="remise">
                          {({ input, meta }) => {
                            const form = useForm()
                            return (
                              <SemanticForm.Input
                                {...input}
                                label="Remise"
                                type="Number"
                                placeholder="ex: 20"
                                readOnly={this.props.view}
                                onChange={async (event, data) => {
                                  input.onChange(data.value)
                                  await updateGlobal(form)
                                }}
                                error={
                                  meta.error && meta.touched ? meta.error : null
                                }
                              />
                            )
                          }}
                        </Field>
                        </Grid.Column>*/}
                      <Grid.Row>
                        <Grid.Column width={4}>
                          {
                            <Field name="modeReglement" >
                              {({ input, meta }) => (
                                <SemanticForm.Select
                                  {...input}
                                  fluid
                                  label="Mode de réglement"
                                  onChange={(value, data) => {
                                    return input.onChange(data.value)
                                  }}
                                  disabled={this.props.view}
                                  options={modeReglementOptions}
                                  error={
                                    meta.error && meta.touched
                                      ? meta.error
                                      : null
                                  }
                                />
                              )}
                            </Field>
                          }
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Field name="dateDeReglement">
                            {({ input, meta }) => {
                              const form = useForm()
                              return (
                                <SemanticForm.Field>
                                  <label>Date de réglement</label>
                                  <DatePicker
                                    {...input.value}
                                    locale="fr"
                                    isClearable={!consultation}
                                    showYearDropdown
                                    selected={
                                      (input.value && new Date(input.value)) ||
                                      null
                                    }
                                    onSelect={(date) => {
                                      if (date) {
                                        this.checkIfDateIsWithinCurrentYear(
                                          date,
                                          input.name,
                                          'Date de reglement'
                                        )
                                      }
                                      input.onChange(date)
                                    }}
                                    onChange={(date) => {
                                      if (date) {
                                        this.checkIfDateIsWithinCurrentYear(
                                          date,
                                          input.name,
                                          'Date de reglement'
                                        )
                                      }
                                      input.onChange(date)
                                      this.setState({
                                        ...this.state,
                                        view: false,
                                      })
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    readOnly={consultation}
                                  />
                                </SemanticForm.Field>
                              )
                            }}
                          </Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Field name="dateEncaissement">
                            {({ input, meta }) => {
                              const form = useForm()
                              const { dateDeReglement } = form.getState().values
                              return (
                                <SemanticForm.Field>
                                  <label>Date d'encaissement</label>
                                  <DatePicker
                                    {...input.value}
                                    locale="fr"
                                    isClearable={!consultation}
                                    showYearDropdown
                                    selected={
                                      (input.value && new Date(input.value)) ||
                                      null
                                    }
                                    onSelect={(date) => {
                                      if (date) {
                                        this.checkIfDateIsWithinCurrentYear(
                                          date,
                                          input.name,
                                          'Date encaissement facture'
                                        )
                                      }
                                    }}
                                    onChange={(date) => {
                                      if (date) {
                                        this.checkIfDateIsWithinCurrentYear(
                                          date,
                                          input.name,
                                          'Date encaissement facture'
                                        )
                                      }
                                      if (dateDeReglement) {
                                        input.onChange(date)
                                        this.setState({
                                          ...this.state,
                                          view: false,
                                        })
                                      } else {
                                        alert(
                                          'La date de règlement doit être renseignée'
                                        )
                                      }
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    readOnly={consultation}
                                  />
                                </SemanticForm.Field>
                              )
                            }}
                          </Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Field name="dateEcheance">
                            {({ input, meta }) => {
                              const form = useForm()
                              const { dateDeReglement } = form.getState().values
                              return (
                                <SemanticForm.Field>
                                  <label>Date d'échéance</label>
                                  <DatePicker
                                    {...input.value}
                                    locale="fr"
                                    isClearable={!consultation}
                                    showYearDropdown
                                    onSelect={(date) => {
                                      if (date) {
                                        this.checkIfDateIsWithinCurrentYear(
                                          date,
                                          input.name,
                                          "Date d'échéance"
                                        )
                                      }
                                      input.onChange(date)
                                    }}
                                    onChange={(date) => {
                                      if (date) {
                                        this.checkIfDateIsWithinCurrentYear(
                                          date,
                                          input.name,
                                          "Date d'échéance"
                                        )
                                      }
                                      input.onChange(date)
                                    }}
                                    selected={
                                      (input.value && new Date(input.value)) ||
                                      null
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    readOnly={consultation}
                                  />
                                </SemanticForm.Field>
                              )
                            }}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      {/* <Grid.Row>
                        <Grid.Column width={8}>
                          <Field name="commentaire">
                            {({ input, meta }) => (
                              <SemanticForm.Field>
                                <label>Commentaire</label>
                                <TextArea {...input} readOnly={this.props.view} rows={8} />
                              </SemanticForm.Field>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row> */}

                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Field name="mentions">
                            {({ input, meta }) => (
                              <SemanticForm.Field>
                                <label>Mentions</label>
                                <TextArea
                                  {...input}
                                  readOnly={this.props.view}
                                  rows={8}
                                />
                              </SemanticForm.Field>
                            )}
                          </Field>
                        </Grid.Column>

                        <div
                          className={
                            this.props.view ? 'd-none' : 'six wide column'
                          }
                        >
                          <Grid.Column width={6}>
                            <Field name="mention">
                              {({ input, meta }) => (
                                <SemanticForm.Select
                                  {...input}
                                  fluid
                                  label="Insérer une mention"
                                  placeholder="Sélectionner une mention et cliquez sur Ajouter"
                                  onChange={(value, data) => {
                                    return input.onChange(data.value)
                                  }}
                                  selection
                                  options={this.state?.mentions?.map(
                                    (mention) => {
                                      return {
                                        key: mention.id,
                                        value: mention.mention,
                                        text: `${
                                          mention.descriptionCourte
                                        } - ${mention.mention?.substring(
                                          0,
                                          40
                                        )}${
                                          mention.mention?.length > 40
                                            ? '..'
                                            : ''
                                        }`,
                                      }
                                    }
                                  )}
                                  error={
                                    meta.error && meta.touched
                                      ? meta.error
                                      : null
                                  }
                                />
                              )}
                            </Field>
                            <Field name="input">
                              {({ input, meta }) => {
                                const form = useForm()
                                return (
                                  <Button
                                    {...input}
                                    icon="add"
                                    type="button"
                                    size="mini"
                                    content="Ajouter"
                                    color="teal"
                                    onClick={() => {
                                      const { mention, mentions } =
                                        form.getState().values
                                      let mentionsUpdate = ''
                                      if (mention) {
                                        if (mentions) {
                                          mentionsUpdate = `${mentions} \n${mention}`
                                        } else {
                                          mentionsUpdate = mention
                                        }
                                        form.change(`mentions`, mentionsUpdate)
                                      }
                                    }}
                                  />
                                )
                              }}
                            </Field>
                          </Grid.Column>
                        </div>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Divider horizontal>
                            <h4>
                              <Icon name="bar chart" />
                              Bons de livraisons
                            </h4>
                          </Divider>
                          <SectionBonsDeLivraisons
                            type={type}
                            creation={creation}
                            values={values}
                            view={this.props.view}
                             onUpdateTVA={(totalTVA_2_1_visible, totalTVA_5_5_visible, totalTVA_10_visible, totalTVA_20_visible)=>{
                              this.setState({totalTVA_2_1_visible: totalTVA_2_1_visible,
                                totalTVA_5_5_visible: totalTVA_5_5_visible, 
                                totalTVA_10_visible: totalTVA_10_visible, 
                                totalTVA_20_visible: totalTVA_20_visible})}} 
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Divider horizontal>
                            <h4>
                              <Icon name="bar chart" />
                              Récapitulatif
                            </h4>
                          </Divider>
                          <FactureFormFooter 
                          TVA_2_1_visible= {this.state.totalTVA_2_1_visible}
                          TVA_5_5_visible= {this.state.totalTVA_5_5_visible}
                          TVA_10_visible= {this.state.totalTVA_10_visible}
                          TVA_20_visible= {this.state.totalTVA_20_visible}/>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Container>
                    </Segment>
                    <Segment>
                        <SubHeader title='' actions={headerActions} />
                    </Segment>
              </SemanticForm>
            )
          }}
        />
      </Container>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    bonsDeLivraison: {
      count: state.bonsDeLivraison.count,
      data: state.bonsDeLivraison.ids
        ? state.bonsDeLivraison.ids.map((id) => {
            return state.bonsDeLivraison.byIds[id]
          })
        : [],
      query: state.bonsDeLivraison.query,
    },
  }
}
export default connect(mapStateToProps, { fetchBonsDeLivraison })(FactureForm)
