import { solde } from '../actions/types'

export default (
  state = {
    data: null,
    count: 0,
    query: {
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
    },
  },
  action
) => {
  switch (action.type) {
    case solde.FETCH_SOLDE:
      return {
        ...state,
        data: action.payload.response,
        query: action.payload.query,
      }
    default:
      return state
  }
}
