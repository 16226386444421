export const theme = {
  SET_VISION_MODE: 'SET_VISION_MODE',
  SET_COLOR_THEME: 'SET_COLOR_THEME',
}

/**
 * Store le mode de vision choisi
 * @param {classique ou ameliore} mode
 */
export const actionVisionMode = (mode) => {
  console.log(document.getElementById('themecss').href)
  document.getElementById('themecss').href = `styles/Theme/mode.${mode}.css`

  return { type: theme.SET_VISION_MODE, payload: mode }
}

/**
 * Store le thème de couleur choisi
 * @param {classique ou dark} colorTheme
 */
export const setColorTheme = (colorTheme) => {
  return { type: theme.SET_COLOR_THEME, payload: colorTheme }
}
