import React, { Component } from 'react'
import { Form as SemanticForm } from 'semantic-ui-react'
import { Field, Form as FinalForm } from 'react-final-form'
import DatePicker from 'react-datepicker'

class VenteJournaliereSearchForm extends Component {
  render() {
    return (
      <FinalForm
        onSubmit={this.props.onSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit, form }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <SemanticForm.Group>
                  <Field name="fromDate">
                    {({ input, meta }) => (
                      <SemanticForm.Field>
                        <label>Sélectionnez une date</label>
                        <DatePicker
                          {...input.value}
                          locale="fr"
                          selected={input.value ? input.value : null}
                          dateFormat="dd/MM/yyyy"
                          showYearDropdown
                          onChange={(date) => {
                            input.onChange(date)
                            form.submit() // submit du formulaire
                          }}
                        />
                      </SemanticForm.Field>
                    )}
                  </Field>
                </SemanticForm.Group>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default VenteJournaliereSearchForm
