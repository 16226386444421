import React from 'react'
import { Button, Form as SemanticForm, Table } from 'semantic-ui-react'
import { Field, useForm } from 'react-final-form'
import { updateGlobal } from '../common-form-update'
import { connect } from 'react-redux'
const _ = require('lodash');

export class BonDeLivraisonItemTableRow extends React.Component {
  constructor(props) {
    super(props)

  }
  updatePoidsNet = (form) => {
    const {
      poidsBrut = 0,
      nbColis = 0,
      tare = 0,
    } = _.get(form.getState().values, `${this.props.name}`)

    let poidsNet = poidsBrut - nbColis * tare
    poidsNet = poidsNet < 0 ? 0 : poidsNet

    form.change(`${this.props.name}.poidsNet`, poidsNet.toFixed(2))
  }
  updateTotalLigneHT = (form) => {
    const { poidsNet = 0, prixUnitaireHT = 0, montantRemiseIndicative= 0 } = _.get(
      form.getState().values,
      `${this.props.name}`
    )
    let totalLigneHT = poidsNet * prixUnitaireHT - poidsNet * prixUnitaireHT * montantRemiseIndicative / 100
    totalLigneHT = totalLigneHT < 0 ? 0 : totalLigneHT 
    form.change(
      `${this.props.name}.totalLigneHT`,
      Number.isInteger(totalLigneHT) ? totalLigneHT : totalLigneHT.toFixed(2)
    )
  }
  updateTotalLigneTTC = (form) => {
      const { poidsNet = 0, prixUnitaireHT = 0, montantRemiseIndicative= 0, tauxDeTVA = 0 } = _.get(
        form.getState().values,
        `${this.props.name}`
      )
    let totalLigneHT = poidsNet * prixUnitaireHT - poidsNet * prixUnitaireHT * montantRemiseIndicative / 100
    let totalLigneTTC = totalLigneHT + totalLigneHT * tauxDeTVA /100
    totalLigneTTC = totalLigneTTC < 0 ? 0 : totalLigneTTC
    form.change(
      `${this.props.name}.totalLigneTTC`,
      Number.isInteger(totalLigneTTC) ? totalLigneTTC : totalLigneTTC.toFixed(2)
    )
  }

  updateGlobal = (form) => {
     //updateTotalCotisation(form)
     //updateTotalHTAvantRemise(form)
     //updateMontantRemise(form)
     //updateTotalHTApresRemise(form)
     //updateMontantTVA(form)
     //updateTotalTTC(form)
     console.log("updateGlobal")
     updateGlobal(form) 
     const allBLLines = _.flatMap(
      _.get(form.getState().values, 'bonsDeLivraisons'),
      (item) => _(item.bonDeLivraisonLignes).value()
    )
     let totalTVA_2_1 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 2.1) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })

    let totalTVA_5_5 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 5.5) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })

    let totalTVA_10 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 10) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })

    let totalTVA_20 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 20) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })
  
     // mise a jour des lignes visible en fonction de la valeur
     this.props.onUpdateTVA(totalTVA_2_1!= 0, totalTVA_5_5 != 0, totalTVA_10 != 0, totalTVA_20 != 0) 
  }

  render() {
    const { view } = this.props
    return (
      <Table.Row key={this.props.index}>
        {/* <Table.Cell>
          <Field name={`${this.props.name}.nbColis`}>
            {({ input, meta }) => {
              const form = useForm()
              return (
                <SemanticForm.Input
                  {...input}
                  readOnly={view}
                  onChange={(event, data) => {
                    input.onChange(data.value)
                    this.updatePoidsNet(form)
                    this.updateTotalLigneHT(form)
                    this.updateGlobal(form)
                  }}
                />
              )
            }}
          </Field>
        </Table.Cell> */}
        <Table.Cell>
          <Field name={`${this.props.name}.codeProduit`}>
          {({ input }) => {
              return <span {...input}>{input.value}</span>
            }}
          {/* {({ input, meta }) => {
              const form = useForm()
              return (
                <SemanticForm.Input
                  {...input}
                  readOnly={view}
                  onChange={(event, data) => {
                    input.onChange(data.value)
                    this.updatePoidsNet(form)
                    this.updateTotalLigneHT(form)
                    this.updateGlobal(form)
                  }}
                />
              )
            }} */}
           {/*  {({ input }) => {
              return <span {...input}>{input.value}</span>
            }} */}
          </Field>
        </Table.Cell>
        <Table.Cell colSpan="6">
          <Field name={`${this.props.name}.libelleProduit`}>
            {({ input, meta }) => {
              return (
                <SemanticForm.Input
                  {...input}
                  type="text"
                  
                  readOnly={view}
                  onWheel={(e) => {
                    e.target.blur(); // Retire le focus lorsque l'utilisateur utilise la molette
                    /* e.preventDefault();  */// Empêche l'événement par défaut
                  }}
                  error={meta.error && meta.touched ? meta.error : null}
                />
              )
            }}
          </Field>
        </Table.Cell>
        {/*<Table.Cell>*/}
        {/*  <Field*/}
        {/*    name={`${this.props.name}.categorie`}*/}
        {/*    type={'text'}*/}
        {/*    component="input"*/}
        {/*    readOnly={view}*/}
        {/*  />*/}
        {/*</Table.Cell>*/}
        <Table.Cell>
          <Field name={`${this.props.name}.poidsBrut`}>
            {({ input }) => {
              const form = useForm()
              return (
                <SemanticForm.Input
                  {...input}
                  readOnly={view}
                  type="number"
                  onChange={(event, data) => {
                    input.onChange(data.value)
                    this.updatePoidsNet(form)
                    this.updateTotalLigneHT(form)
                    this.updateTotalLigneTTC(form)
                    this.updateGlobal(form)
                  }}
                />
              )
            }}
          </Field>
        </Table.Cell>
        {/*<Table.Cell>*/}
        {/*  <Field name={`${this.props.name}.tare`}>*/}
        {/*    {({ input }) => {*/}
        {/*      const form = useForm()*/}
        {/*      return (*/}
        {/*        <SemanticForm.Input*/}
        {/*          {...input}*/}
        {/*          readOnly={view}*/}
        {/*          type="number"*/}
        {/*          onChange={(event, data) => {*/}
        {/*            input.onChange(data.value)*/}
        {/*            this.updatePoidsNet(form)*/}
        {/*            this.updateTotalLigneHT(form)*/}
        {/*            this.updateGlobal(form)*/}
        {/*          }}*/}
        {/*        />*/}
        {/*      )*/}
        {/*    }}*/}
        {/*  </Field>*/}
        {/*</Table.Cell>*/}
        {/*<Table.Cell>*/}
        {/*  <Field name={`${this.props.name}.poidsNet`}>*/}
        {/*    {({ input }) => {*/}
        {/*      return <span {...input}>{input.value}</span>*/}
        {/*    }}*/}
        {/*  </Field>*/}
        {/*</Table.Cell>*/}
        <Table.Cell>
          <Field name={`${this.props.name}.prixUnitaireHT`}>
            {({ input }) => {
              const form = useForm()
              return (
                <SemanticForm.Input
                  {...input}
                  readOnly={view}
                  type="number"
                  onChange={(event, data) => {
                    input.onChange(data.value)
                    this.updateTotalLigneHT(form)
                    this.updateTotalLigneTTC(form)
                    this.updateGlobal(form)
                  }}
                />
              )
            }}
          </Field>
        </Table.Cell>
        <Table.Cell>
          <Field name={`${this.props.name}.montantRemiseIndicative`}>
            {({ input }) => {
              const form = useForm()
              return (
                <SemanticForm.Input
                  {...input}
                  readOnly={view}
                  type="number"
                  onChange={(event, data) => {
                    input.onChange(data.value)
                    this.updateTotalLigneHT(form)
                    this.updateTotalLigneTTC(form)
                    this.updateGlobal(form)
                  }}
                />
              )
            }}
          </Field>
        </Table.Cell>
        <Table.Cell>
          <Field name={`${this.props.name}.totalLigneHT`}>
            {({ input }) => {
              return <SemanticForm.Input readOnly={true} {...input} />
            }}
          </Field>
        </Table.Cell>
        <Table.Cell>
          <Field name={`${this.props.name}.tauxDeTVA`}>
            {({ input }) => {
              return <SemanticForm.Input readOnly={true} {...input} />
            }}
          </Field>
        </Table.Cell>
        <Table.Cell>
          <Field name={`${this.props.name}.totalLigneTTC`}>
            {({ input }) => {
              return <SemanticForm.Input readOnly={true} {...input} />
            }}
          </Field>
        </Table.Cell>
        {this.props.avoir ? (
          <Table.Cell>
            <button
              type="button"
              className="ui button red"
              disabled={view}
              onClick={() => {this.props.removeLine()}}
            >
              <i className="trash icon"></i>
            </button>
          </Table.Cell>
        ) : (
          ''
        )}
      </Table.Row>
    )
  }
}
export default connect(null, null)(BonDeLivraisonItemTableRow)
