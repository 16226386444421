import React, { Component, Fragment } from 'react'
import { history } from '../../utils/history'
import { connect } from 'react-redux'
import TraiteForm from './TraiteForm'
import { Button } from 'semantic-ui-react'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { fetchTraite } from '../../redux/actions/traite-action'
import CustomModal from '../shared/modal/CustomModal'
import { handleErrorResponse } from '../../utils/handle-error-response'
import { toast } from 'react-toastify'
const _ = require('lodash');

class TraiteEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModalDelete: false,
      showModalCancel: false,
    }
  }

  componentDidMount() {
    this.props.fetchTraite(this.props.match.params.id)
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button icon="save" type="submit" content="Enregistrer" color="teal" />
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: true,
            })
          }}
        >
          Annuler
        </button>
      </Fragment>
    )
  }

  modalActionsCancel() {
    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
            history.push('/traites')
          }}
        >
          <i className="checkmark icon"></i>
          Oui
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
          }}
        >
          Non
        </button>
      </Fragment>
    )
  }

  onSubmit = (formValues) => {
    formValues.montant = formValues.montant
      ? parseFloat(formValues.montant)
      : null
    formValues.isPayee = formValues.isPayee
      ? formValues.isPayee
      : formValues.datePaiement != null

    formValues.numeroFacture = formValues.numeroFacture
      ? formValues.numeroFacture
      : null

    if (formValues.isPayee && formValues.datePaiement == null) {
      toast.error('Veuillez renseigner la date de payement')
      return
    }
    ipcRenderer
      .invoke(Channels.TRAITE_UPDATE, {
        id: this.props.match.params.id,
        traite: formValues,
      })
      .then((response) => {
        if (response.hasError) {
          handleErrorResponse(response.errors)
        } else {
          history.push('/traites')
          toast.success('La traite a bien été modifiée')
        }
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  render() {
    return (
      <Fragment>
        <TraiteForm
          onSubmit={this.onSubmit}
          headerActions={this.getHeaderActions()}
          formTitle={`Editer la traite :  ${this.props.traite?.numeroTraite}`}
          initialValues={this.props.traite ? this.props.traite : null}
          edit={true}
        />
        <CustomModal
          title="Annuler"
          content="Quitter et annuler les modifications en cours ?"
          visible={this.state.showModalCancel}
          actions={this.modalActionsCancel()}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { traite: state.traites.traite, theme: state.theme }
}

export default connect(mapStateToProps, { fetchTraite })(TraiteEdit)
