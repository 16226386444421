import React from 'react'
import { composeValidators, isRequired } from '../../../../utils/validators'
import { Button, Form as SemanticForm, Label } from 'semantic-ui-react'
import { Field } from 'react-final-form'
import ModalSelectContact from './ModalSelectContact'
import { DocType } from '../../../../utils/enum-divers'
import { ipcRenderer } from '../../../../api/api'
import { Channels } from '../../../../api/channels'
import { updateGlobal } from '../common-form-update'

class SelectContact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contactId: null,
      open: false,
      contact: null,
      contactButtonAction: 'Ajouter',
    }
  }

  renderButtonContact = () => {
    const { open } = this.state
    const view = this.props.view
    const button = !view ? (
      <Button
        content={
          this.state.contactId
            ? `Modifier ${this.props.label}`
            : `Ajouter ${this.props.label}`
        }
        size="mini"
        disabled={open || view}
        positive
        onClick={this.handleOpen}
        className="labeled icon"
        icon="add"
      />
    ) : (
      this.props.label
    )
    return button
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.contactId !== this.state.contactId &&
      !!this.state.contactId
    ) {
      this.loadData(this.state.contactId)
    }
  }

  handleClose() {
    this.setState({ open: false })
  }

  handleOpen = () => this.setState({ open: true })

  loadData = (id) => {
    ipcRenderer.invoke(Channels.CONTACT_FIND_BY_ID, id).then((data) => {
      const adresseAsString = `${data.nom} \n${
        data.complement ? data.complement : ''
      }\n${data.adresse}\n${data.codePostal} ${data.ville}\n${
        data.pays.libelleFR
      }`
      this.props.mainForm.change(this.props.fieldString, adresseAsString)
      this.props.mainForm.change(this.props.fieldId, id)
      if (this.props.fieldId === 'recepteurId') {
        this.props.mainForm.change('remise', data.remiseParDefaut)

        // Si le contact sélectionné dans la facture possède des mentions
        this.props.mainForm.change(
          'mentions',
          data.mentions
            ? data.mentions?.map((m) => `${m?.mention}\n`)?.join('') // évite les virgules car map en ajoute lors de la conversion en string
            : ''
        )

        updateGlobal(this.props.mainForm).then(() => console.log('updated'))
      }

      this.setState({
        contact: adresseAsString,
      })
    })
  }

  render() {
    const { open } = this.state
    const { type, fieldId, fieldString, view } = this.props
    return (
      <div>
        <Field name={fieldId}>
          {({ input, meta }) => (
            <input
              {...input}
              type="hidden"
              onChange={(e) => {
                this.setState({ contactId: e.target.value })
                input.onChange(e)
              }}
            />
          )}
        </Field>
        <Field name={fieldString} validate={composeValidators(isRequired)}>
          {({ input, meta }) => (
            <SemanticForm.TextArea
              {...input}
              rows={7}
              readOnly={view}
              label={
                this.props.label === 'Client'
                  ? this.renderButtonContact()
                  : ''
              }
              className="resize-none"
              //value={this.state.contact ? this.state.contact : ''}
              error={meta.error && meta.touched ? meta.error : null}
            />
          )}
        </Field>
        <ModalSelectContact
          open={open}
          isClient={type === DocType.VENTE}
          isFournisseur={type === DocType.ACHAT}
          onOpen={() => console.log('Main open')}
          onClose={(id) => {
            if (
              this.props.fieldId === 'recepteurId' &&
              id !== this.props.mainForm.getState().values['recepteurId']
            ) {
              this.props.mainForm.change('bonsDeLivraisons', [])
            }
            this.props.mainForm.change(fieldId, id)
            this.setState({ open: false, contactId: id })
            this.props.handleClose(fieldId, id)
          }}
          onCancel={() => this.setState({ open: false })}
        />
      </div>
    )
  }
}

export default SelectContact
