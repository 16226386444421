import React, { Component } from 'react'
import { ProdouaneForm } from './ProdouaneForm'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { history } from '../../utils/history'

class ProdouaneFormCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onFormSubmit = (formValues) => {
    ipcRenderer
      .invoke(Channels.PRODOUANE_CREATE, formValues)
      .then((response) => {
        history.push(`/prodouane`)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  render() {
    return (
      <ProdouaneForm
        onFormSubmit={this.onFormSubmit}
        title={"Création d'un export Prodouane"}
        initialValues={{
          edit: false,
        }}
      />
    )
  }
}

export default ProdouaneFormCreate
