import React from 'react'

class SubHeader extends React.Component {
    renderActions = () => {
        if (this.props.repetitionEnbas == null || this.props.repetitionEnbas == true) {
            if (!this.props.actions) {
                return null
            }
            return this.props.actions
        }
        else
            return null
  }
  render() {
    return (
      <div className="en-tete">
        <h3>{this.props.title}</h3>
        <div className="form-btn">{this.renderActions()}</div>
      </div>
    )
  }
}

export default SubHeader
