import React, { Component, Fragment } from 'react'
import { history } from '../../utils/history'
import ContactForm from './ContactForm'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { Button } from 'semantic-ui-react'

class ContactCreate extends Component {
  constructor(props) {
    super(props)
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button icon="save" type="submit" content="Enregistrer" color="teal" />
        <Button
          icon="reply"
          type="submit"
          content="Retour à la liste"
          color="black"
          onClick={() => {
            history.push('/settings/contacts')
          }}
        />
      </Fragment>
    )
  }
  
  onSubmit = async (formValues) => {
    let societeId = JSON.parse(localStorage.getItem('societeId')||'{}')
    formValues.societeId = societeId
    return ipcRenderer.invoke(Channels.CONTACT_CREATE, formValues)
    // .then((response) => {
    //   if (response.hasError) {
    //     handleErrorResponse(response.errors)
    //   } else {
    //     return response
    //   }
    // })
    // .catch((error) => {
    //   console.error(error)
    // })
  }

  render() {
    const initialValues = {
      isGerant: this.props?.isGerant ?? false, 
      isTVA: this.props?.isTVA ?? false
    }
    return (
      <ContactForm
        onSubmit={this.onSubmit}
        headerActions={this.getHeaderActions()}
        formTitle={ this.props?.isGerant ? 'Ma société' : 'Créer un contact'}
        initialValues={initialValues}
      />
    )
  }
}

export default ContactCreate
