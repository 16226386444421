import React from 'react'
import { useHistory } from 'react-router-dom';
import MenuList from './MenuList'
import MenuItem from './MenuItem'
import './Menu.css'
import logo from '../../../OCCIFACT-logo.jpg'


const menu = require('./SideMenuConfig.json')

// Déploi / masque les sous-rubriques du menu
const unfold = (event) => {
  const menuItem = event.target.closest('div.menuItem')

  if (menuItem) {
    menuItem.classList.toggle('isActive')
  }
  event.stopPropagation()
}

const renderedMenu = (menu, level = 0) => {
  return menu.map((item, id = 0) => {
    item.classCSS = undefined
    const sousMenu =
      item.items && item.items.length > 0 ? (
        <MenuList className={`sousMenu sousMenu-${level}`}>
          {renderedMenu(item.items, level++, id)}
        </MenuList>
          ) : null
        return (
            <MenuItem
                name={item.name}
                icon={item.icon}
                href={item.href ? item.href : '#'}
                className={item.classCSS ? item.classCSS : ''}
                id={item.id ? item.id : ''}
                //Config
                key={id++} // Un tableau doit avoir un clé pour React...
                event={unfold} //Gestion du isActive
                exact={item.exact}
            >
                {sousMenu}
            </MenuItem>
        )
  })
}

const SideMenu = () => {
  const history = useHistory(); // Utiliser le hook useHistory

  const handleLogoClick = () => {
    history.push('/'); // Redirige vers la page d'accueil
  };

    return (
      <MenuList id="sideMenu">
      <span
        id="closebtn"
        onClick={() => {
          document.getElementById('sideMenu').classList.remove('visible')
          document.getElementById('closebtn').classList.remove('visible')
        }}
      >
        &times;
      </span>

        {/* Ajout du logo en haut du menu */}
        <div className="logo-container-sideMenu" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
          <img src={logo}  alt="logo-OCCIFACT" className="logo-sideMenu" />
        </div>

      {renderedMenu(menu)}
    </MenuList>
  )
}

export default SideMenu
