import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { fetchFacturesTransporteur } from '../../redux/actions/facture-transporteur-action'
import { history } from '../../utils/history'
import SubHeader from '../shared/subHeader/SubHeader'
import DataTables from '../shared/datatable/Datatable'
import Pagination from '../shared/datatable/Pagination'
import FactureTransporteurSearchForm from './FactureTransporteurSearchForm'
import { cellFormat } from '../shared/datatable/cell-format'

class FactureTransporteur extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props)
    this.state = {
      cols: [
        {
          text: 'Numéro de facture',
          key: 'numero',
        },
        {
          text: 'Société de transport',
          key: 'societeTransport',
        },
        {
          text: 'Date',
          key: 'date',
          format: cellFormat.DATE,
        },
      ],
    }
  }

  componentDidMount() {
    this.props.fetchFacturesTransporteur(this.props.facturesTransporteur.query)
  }

  /**
   * The actions to show in the right column
   * @param {*} row
   * @returns
   */
  getActionRow = (row) => {
    if (!row.id) {
      return null
    }
    return (
      <Fragment>
        <button
          className="ui button olive"
          type="button"
          onClick={() => {
            //console.log(row.id)
            history.push(`/factures-transporteur/edit/${row.id}`)
          }}
        >
          <i className="icon pencil" />
          Editer
        </button>
      </Fragment>
    )
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <button
          className="ui button teal"
          onClick={() => {
            history.push('/factures-transporteur/create')
          }}
          type="button"
        >
          <i className="icon plus" />
          Ajouter
        </button>
      </Fragment>
    )
  }

  /**
   * Action on page click
   * @param {*} pageInfo
   */
  onPageClick = (pageInfo) => {
    let query = this.props.facturesTransporteur.query
    query.skip = (pageInfo.currentPage - 1) * query.take
    this.props.fetchFacturesTransporteur(query)
  }

  onSubmitSearch = (formValues) => {
    const query = this.props.facturesTransporteur.query
    query.params = formValues
    query.skip = 0
    query.take = 10

    //console.log(formValues)

    this.props.fetchFacturesTransporteur(query)
  }
  onResetSearch = () => {
    const query = {
      skip: 0,
      take: 10,
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: { date: 'ASC' },
    }
    this.props.fetchFacturesTransporteur(query)
  }
  /**
   * Action on column click
   * @param {*} col
   */
  onColumnClick = (col) => {
    let { query } = this.props.facturesTransporteur
    //console.log(query)
    // Si on tri déjà par ce champs
    if (query.order[col]) {
      // Si le tri est déjà en ASC, on switch en DESC
      if (query.order[col] === 'ASC') {
        query.order[col] = 'DESC'
        // Sinon ASC
      } else {
        query.order[col] = 'ASC'
      }
    } else {
      query.order = { [col]: 'ASC' }
    }
    this.props.fetchFacturesTransporteur(query)
  }

  render() {
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title={'Liste des factures transporteur'}
            actions={this.getHeaderActions()}
          />
          <div className="ui divider" />
          <div className="dt-top-container">
            <div className="content">
              <FactureTransporteurSearchForm
                onSubmit={this.onSubmitSearch}
                initialValues={this.props.facturesTransporteur.query.params}
                onResetSearch={this.onResetSearch}
              />
            </div>
          </div>
          <div className="content">
            <DataTables
              columns={this.state.cols}
              rows={this.props.facturesTransporteur.data}
              onColumnClick={this.onColumnClick}
              actionRow={this.getActionRow}
              query={this.props.facturesTransporteur.query}
            />
            <Pagination
              onPageClick={this.onPageClick}
              itemPerPage={this.props.facturesTransporteur.query.take}
              pageNeighbours={1}
              totalItems={this.props.facturesTransporteur.count}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    facturesTransporteur: {
      count: state.facturesTransporteur.count,
      data: state.facturesTransporteur.ids
        ? state.facturesTransporteur.ids.map((id) => {
            return state.facturesTransporteur.byIds[id]
          })
        : [],
      query: state.facturesTransporteur.query,
    },
  }
}

export default connect(mapStateToProps, { fetchFacturesTransporteur })(
  FactureTransporteur
)
