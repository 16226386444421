import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { societe } from './types'

export const fetchSociete = (id) => {
  return async (dispatch) => {
    try {
      const payload = await ipcRenderer.invoke(Channels.SOCIETE_FIND_BY_ID, id)
      console.log("payload")
      console.log(payload)
      if (payload.hasError) {
        throw new Error(payload.error?.toString())
      } else {
        dispatch({
          type: societe.FETCH_SOCIETE,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération d'une société")
    }
  }
}

export const fetchSocietes = (query) => {
  return async (dispatch) => {
    try {
      const response = await ipcRenderer.invoke(
        Channels.SOCIETE_FIND_ALL,
        query
      )
      const payload = { response, query }
      if (response.hasError) {
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: societe.FETCH_SOCIETES,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des sociétés")
    }
  }
}
