import React, { Component } from 'react'
import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import SideMenu from '../shared/menu/SideMenu'
import Routing from '../shared/routing/Routing'
import { VisionMode } from '../../utils/theme'
import Header from '../shared/header/Header'
import Footer from '../shared/footer/Footer'
import Notification from '../shared/notification/Notification'
import Home from '../home/Home'
import './App.css'
import './Font.css'
import "../../../node_modules/react-datepicker/dist/react-datepicker.css"
import "../../../node_modules/react-toastify/dist/ReactToastify.css"

// Router englobe App (cf app.js)
function App () {
    const [loginIn, setloginIn] = useState(false);
    const [email, setEmail] = useState('')
    const [societeID, setsocieteID] = useState(-1);

    const Log = (LogginIn, societeID)=>
      {
        setloginIn(LogginIn)
        setsocieteID(societeID)
      }
    // Delete spinner
    const spinner = document.getElementById('spinner-intro')

    if (spinner && !spinner.hasAttribute('hidden')) {
      spinner.setAttribute('hidden', 'true')
    }
    // End delete spinner
    // Recuperation des informations d'authentification

    useEffect(() => {
      setloginIn(JSON.parse(localStorage.getItem('loggedIn')))
      setsocieteID(JSON.parse(localStorage.getItem('societeId'))) 

    // Fetch the user email and token from local storage
    const user = JSON.parse(localStorage.getItem('user'))

    // If the token/email does not exist, mark the user as logged out
    if (!user || !user.token) {
      setloginIn(false)
      return
    }

    const API  = (process.env.NODE_ENV === 'development') ? (process.env.REACT_APP_API_DEV || 'http://localhost:3001/') : (process.env.REACT_APP_API_PROD || 'http://localhost:3001/')
    console.log(API)
    // If the token exists, verify it with the auth server to see if it is valid
    fetch( API + 'verify', {
      method: 'POST',
      headers: {
        'jwt-token': user.token,
      },
    })
      .then((r) => r.json())
      .then((r) => {
        setloginIn('success' === r.message)
        setEmail(user.email || '')
      })


      }, [])

    return (
      <div id="main-app">
        {loginIn ? 
        <div id="main"
        /* className={
            this.props.theme.visionMode === VisionMode.AMELIORE
            ? 'ameliore'
            :   'classique'
        } */>
         <SideMenu />
          <div id="main-content">
            <Header log= {Log}/>
            <div id="content">
              <Routing />
            </div>
            <Footer />
          </div>
        </div>
        : <Home log= {Log}/>
        }
        <Notification />
      </div>
    )
  }

  const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  }
}

export default connect(mapStateToProps)(App)
