import React, { Component, Fragment } from 'react'
import SubHeader from '../shared/subHeader/SubHeader'
import { history } from '../../utils/history'
import { connect } from 'react-redux'
import DataTables from '../shared/datatable/Datatable'
import Pagination from '../shared/datatable/Pagination'
import ParcelleSearchForm from './ParcelleSearchForm'
import { fetchParcelles } from '../../redux/actions/parcelle-action'
import { Channels } from '../../api/channels'
import ExportPdf from '../shared/exportcsv/export-pdf'
import { ExportCsv } from '../shared/exportcsv/export-csv'
import { ipcRenderer } from '../../api/api'
const _ = require('lodash');

class Parcelle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cols: [
        {
          text: 'Code',
          key: 'code',
        },
        {
          text: 'Libellé',
          key: 'libelle',
        },
      ],
    }
  }

  componentDidMount() {
    this.props.fetchParcelles(this.props.parcelles.query)
  }
  /**
   * The actions to show in the right column
   * @param {*} row
   * @returns
   */
  getActionRow = (row) => {
    if (!row.id) {
      return null
    }
    return (
      <Fragment>
        <button
          className="ui button blue"
          type="button"
          onClick={() => {
            history.push(`/settings/parcelles/view/${row.id}`)
          }}
        >
          <i className="icon eye" />
          Consulter
        </button>
        <button
          className="ui button olive"
          type="button"
          onClick={() => {
            history.push(`/settings/parcelles/edit/${row.id}`)
          }}
        >
          <i className="icon pencil" />
          Editer
        </button>
      </Fragment>
    )
  }

  getDataForExport = async () => {
    const { query } = this.props.parcelles
    const { data } = await ipcRenderer.invoke(
      Channels.PARCELLE_FIND_ALL,
      _.omit(query, ['skip', 'take'])
    )

    return data
  }

  async generateFileName() {
    const { query } = this.props.parcelles
    const { params } = query
    let filename = 'Parcelles'
    filename += `${params.code ? '_CODE_' + params.code : ''}`
    return filename
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <button
          className="ui button teal"
          onClick={() => {
            history.push('/settings/parcelles/create')
          }}
          type="button"
        >
          <i className="icon plus" />
          Ajouter
        </button>
        <ExportCsv
          query={this.props.parcelles.query}
          channel={Channels.PARCELLE_FIND_ALL}
          fileName = 'Parcelles.csv'
          keys={['code', 'libelle']}
        />
        <ExportPdf
          filenameGeneratorFunc={async () => await this.generateFileName()}
          csvData={this.getDataForExport}
          headers={this.state.cols}
        />
      </Fragment>
    )
  }
  /**
   * Action on page click
   * @param {*} pageInfo
   */
  onPageClick = (pageInfo) => {
    let query = this.props.parcelles.query
    query.skip = (pageInfo.currentPage - 1) * query.take
    this.props.fetchParcelles(query)
  }

  onSubmitSearch = (formValues) => {
    const query = this.props.parcelles.query
    query.params = formValues
    query.skip = 0
    query.take = 10
    this.props.fetchParcelles(query)
  }
  onResetSearch = () => {
    const query = this.props.parcelles.query
    query.params = {}
    this.props.fetchParcelles(query)
  }
  /**
   * Action on column click
   * @param {*} col
   */
  onColumnClick = (col) => {
    let { query } = this.props.parcelles
    // Si on tri déjà par ce champs
    if (query.order[col]) {
      // Si le tri est déjà en ASC, on switch en DESC
      if (query.order[col] === 'ASC') {
        query.order[col] = 'DESC'
        // Sinon ASC
      } else {
        query.order[col] = 'ASC'
      }
    } else {
      query.order = { [col]: 'ASC' }
    }
    this.props.fetchParcelles(query)
  }

  render() {
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title={'Liste des parcelles'}
            actions={this.getHeaderActions()}
          />
          <div className="ui divider" />
          <div className="dt-top-container">
            <div className="content">
              <ParcelleSearchForm
                onSubmit={this.onSubmitSearch}
                initialValues={this.props.parcelles.query.params}
                onResetSearch={this.onResetSearch}
              />
            </div>
          </div>
          <div className="content">
            <DataTables
              columns={this.state.cols}
              rows={this.props.parcelles.data}
              onColumnClick={this.onColumnClick}
              actionRow={this.getActionRow}
              query={this.props.parcelles.query}
            />
            <Pagination
              onPageClick={this.onPageClick}
              itemPerPage={this.props.parcelles.query.take}
              pageNeighbours={1}
              totalItems={this.props.parcelles.count}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    parcelles: {
      count: state.parcelles.count,
      data: state.parcelles.ids
        ? state.parcelles.ids.map((id) => {
            return state.parcelles.byIds[id]
          })
        : [],
      query: state.parcelles.query,
    },
  }
}

export default connect(mapStateToProps, { fetchParcelles })(Parcelle)
