import React, { Fragment } from 'react'
import BonDeLivraisonForm from './BonDeLivraisonForm'
import { DocType } from '../../utils/enum-divers'
import { Button, Container, Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { history } from '../../utils/history'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { fetchBonDeLivraison } from '../../redux/actions/bon-de-livraison-action'
import { Field } from 'react-final-form'
import CustomModal from '../shared/modal/CustomModal'
import EmailModal from '../shared/modal/EmailModal'
import { toast } from 'react-toastify'
import { handleErrorResponse } from '../../utils/handle-error-response'
const _ = require('lodash');

class BonDeLivraisonView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitBtn: null,
      showModalPrint: false,
      showModalMail: false,
      defaultEmail: '',
    }
  }

  modalActionsMail() {
    return (
      <Fragment>
        <button className="ui button teal left labeled icon" type="submit">
          <i className="mail icon"></i>
          Envoyer
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalMail: false,
            })
          }}
        >
          Fermer
        </button>
      </Fragment>
    )
  }

  emailSubmit = (email, objet, message) => {
    ipcRenderer
      .invoke(Channels.EMAIL_SEND, {
        email,
        objet,
        message,
        typeDocument: 'Bl',
        idDocument: this.props.bonDeLivraison.id,
        numero: this.props.bonDeLivraison.numero,
      })
      .then((response) => {
        if (response.error) {
          handleErrorResponse(response.error)
        } else {
          history.push(`/bons-de-livraison/${this.props.bonDeLivraison.type}`)
          toast.success(
            `Le bon de livraison ${this.props.bonDeLivraison.numero} a bien été créé et l'email a bien été envoyé`
          )
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  getRecepteurEmail = (id) => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_BY_ID, id)
      .then((response) => {
        this.setState({
          defaultEmail: response.email,
        })
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button
          icon="print"
          type="button"
          content="Imprimer"
          color="teal"
          onClick={() => {
            this.setState({
              showModalPrint: true,
            })
          }}
        />
        <Button
          icon="mail"
          type="button"
          content="Email"
          color="blue"
          onClick={() =>
            this.setState({
              showModalMail: true,
            })
          }
        />
        <Button
          icon="reply"
          type="button"
          content="Retour à la liste"
          color="black"
          onClick={() => {
            history.push(`/bons-de-livraison/${this.props.bonDeLivraison.type}`)
          }}
        />
      </Fragment>
    )
  }

  modalActionsCancel() {
    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalPrint: false,
            });
            const { id } = this.props.match.params;
            ipcRenderer
            .invoke(Channels.BON_DE_LIVRAISON_PRINT, {
              data: id,
              options: { printParcelles: true },
            })
            .then((response) => {
              console.log('API Response:', response);
              const pdfPath = response.filePath;
              if (pdfPath) {
                window.open(pdfPath);
                toast.success(`Le bon de livraison a bien été créé`);
              } else {
                toast.error("Le chemin du PDF est manquant ou invalide.");
              }
            })
            .catch((error) => {
              console.error(error);
              toast.error('Erreur lors de la création du bon de livraison.');
            });
          }}
        >
          <i className="checkmark icon" />
          Impression PDF
        </button>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalPrint: false,
            });
            console.log("Action annulée");
          }}
        >
          Non
        </button>
      </Fragment>
    );
  }
  

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.fetchBonDeLivraison(id)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.bonDeLivraison !== prevProps.bonDeLivraison) {
      this.getRecepteurEmail(this.props.bonDeLivraison?.recepteurId)
    }
  }

  render() {
    
    const { bonDeLivraison } = this.props
    const initialValues = bonDeLivraison
    console.log('Render view')
    console.log(initialValues)


    return initialValues ? (
      <Fragment>
        <BonDeLivraisonForm
          /* formTitle={`Consulter un bon de livraison - ${initialValues.type === DocType.ACHAT ? 'Achats' : 'Ventes'} `} */
          formTitle={`Consulter un bon de livraison`}
          type={
            initialValues.type === DocType.ACHAT ? DocType.ACHAT : DocType.VENTE
          }
          view={true}
          headerActions={this.getHeaderActions()}
          initialValues={initialValues}
          onSubmit={() => {
            //console.log('No submit : View page')
          }}
        ></BonDeLivraisonForm>
        <EmailModal
          title="Envoi du bon de livraison par mail"
          visible={this.state.showModalMail}
          actions={this.modalActionsMail()}
          id={initialValues.id}
          numero={initialValues.numero}
          email={this.state.defaultEmail}
          typeDocument="Bl"
          onSubmit={this.emailSubmit}
        />
        <CustomModal
          title="Imprimer"
          content="Imprimer le Bon de livraison"
          visible={this.state.showModalPrint}
          actions={this.modalActionsCancel()}
        />
      </Fragment>
    ) : (
      <div>Chargement</div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    bonDeLivraison: state.bonsDeLivraison.bonDeLivraison,
    theme: state.theme,
  }
}

export default connect(mapStateToProps, { fetchBonDeLivraison })(
  BonDeLivraisonView
)
