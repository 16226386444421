import { avoir, factureTransporteur } from '../actions/types'
const _ = require('lodash');

export default (
  state = {
    factureTransporteur: null,
    byIds: {},
    count: 0,
    ids: [],
    query: {
      skip: 0,
      take: 10,
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: { date: 'ASC' },
    },
  },
  action
) => {
  switch (action.type) {
    case factureTransporteur.FETCH_FACTURE_TRANSPORTEUR:
      return { ...state, factureTransporteur: action.payload }
    case factureTransporteur.FETCH_FACTURES_TRANSPORTEUR:
      const fatState = {
        byIds: _.mapKeys(action.payload.response.data, 'id'),
        count: action.payload.response.count,
        ids: _.map(action.payload.response.data, 'id'),
        query: action.payload.query,
      }
      return {
        ...state,
        ...fatState,
      }
    default:
      return state
  }
}
