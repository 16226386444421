import React, { Component, Fragment } from 'react'
import { Field } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import {
  composeValidators,
  minLength,
  isRequired,
} from '../../utils/validators'

class ContactFormGeneralContact extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Fragment>
        <SemanticForm.Group widths="equal">
          <Field name="email">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                label="Email"
                type="text"
                placeholder="adresse@email.fr"
                autoComplete="off"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          <Field name="telFixe">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                label="Tel. Fixe"
                type="text"
                placeholder="01 23 45 67 89"
                autoComplete="off"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          <Field name="telPortable">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                label="Tel. Portable"
                type="text"
                placeholder="01 23 45 67 89"
                autoComplete="off"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          <Field name="fax">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                label="Fax"
                type="text"
                placeholder="01 23 45 67 89"
                autoComplete="off"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
        </SemanticForm.Group>
      </Fragment>
    )
  }
}

export default ContactFormGeneralContact
