import React, { Component } from 'react'
import { Field } from 'react-final-form'
import { Grid, Form as SemanticForm } from 'semantic-ui-react'
import { roundTwoDec } from '../shared/datatable/cell-format'

class ProdouaneFormLigneFooter extends Component {
  render() {
    return (
      <Grid.Column width={11} floated="right">
        <Grid>
          <Grid.Row>
            <Grid.Column width={3} floated="left">
              <p>
                <strong>Total HT</strong>
              </p>
              <Field
                name={`bonDeLivraisons[${this.props.index}].totalPrixHT`}
                index={this.props.index}
              >
                {({ input, meta }) => {
                  return roundTwoDec(input.value) + ' €'
                }}
              </Field>
            </Grid.Column>

            <Grid.Column width={4} floated="left">
              <p>
                <strong>Remise indicative</strong>
              </p>
              <Field
                name={`bonDeLivraisons[${this.props.index}].montantRemiseIndicative`}
                index={this.props.index}
              >
                {({ input, meta }) => {
                  return roundTwoDec(input.value) + ' €'
                }}
              </Field>
            </Grid.Column>

            <Grid.Column width={4} floated="left">
              <p>
                <strong>Total avec remise</strong>
              </p>
              <Field
                name={`bonDeLivraisons[${this.props.index}].totalSansRemise`}
                index={this.props.index}
              >
                {({ input, meta }) => {
                  return roundTwoDec(input.value) + ' €'
                }}
              </Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    )
  }
}

export default ProdouaneFormLigneFooter
