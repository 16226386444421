import React, { Component, Fragment } from 'react'
import { history } from '../../utils/history'
import { connect } from 'react-redux'
import VarieteForm from './VarieteForm'
import { Button } from 'semantic-ui-react'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import CustomModal from '../shared/modal/CustomModal'
import { fetchVariete } from '../../redux/actions/variete-action'
import { handleErrorResponse } from '../../utils/handle-error-response'
import { toast } from 'react-toastify'
const _ = require('lodash');

class VarieteEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModalDelete: false,
      showModalCancel: false,
    }
  }

  componentDidMount() {
    this.props.fetchVariete(this.props.match.params.id)
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button icon="save" type="submit" content="Enregistrer" color="teal" />
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: true,
            })
          }}
        >
          Annuler
        </button>
      </Fragment>
    )
  }

  modalActionsCancel() {
    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
            history.push('/settings/varietes')
          }}
        >
          <i className="checkmark icon" />
          Oui
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
          }}
        >
          Non
        </button>
      </Fragment>
    )
  }

  onSubmit = (formValues) => {
    //console.log(formValues)
    ipcRenderer
      .invoke(Channels.VARIETE_UPDATE, {
        id: this.props.match.params.id,
        variete: formValues,
      })
      .then((response) => {
        if (response.hasError) {
          //console.log(response)
          handleErrorResponse(response.errors)
        } else {
          history.push('/settings/varietes')
          toast.success('La catégorie a bien été modifiée')
        }
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  render() {
    return (
      <Fragment>
        <VarieteForm
          onSubmit={this.onSubmit}
          headerActions={this.getHeaderActions()}
          formTitle={`Editer la catégorie ${this.props.variete?.libelle}`}
          initialValues={_.omit(this.props.variete, ['produits'])}
          view={true}
        />
        <CustomModal
          title="Annuler"
          content="Quitter et annuler les modifications en cours ?"
          visible={this.state.showModalCancel}
          actions={this.modalActionsCancel()}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { variete: state.varietes.variete, theme: state.theme }
}

export default connect(mapStateToProps, { fetchVariete })(VarieteEdit)
