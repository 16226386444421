import { toast } from 'react-toastify'

export const handleErrorResponse = (errors) => {
  if (Array.isArray(errors)) {
    for (const e of errors) {
      toast.error(getFormattedErrorString(e))
    }
  } else {
    toast.error('Une erreur est survenue, veuillez réessayer ultérieurement. \nSi le problème persiste, veuillez contacter le support.')
  }
}

export const getFormattedErrorString = (err) => {
  if (
    err.message &&
    typeof err.message === 'string' &&
    err.message.includes('UNIQUE')
  ) {
    return `Le ${err.message
      .split(' ')
      .splice(-1)} doit être unique au sein de la base`
  } else {
    return err.message
  }
}
