import React from 'react'
import { Header, Icon } from 'semantic-ui-react'
import './HeaderSeparator.css'

class HeaderSeparator extends React.Component {
  render() {
    return (
      <div className="header-separator">
        <div className="header-separator-content">
          {this.props.iconName && (
            <Icon
              name={this.props.iconName}
              fitted
              size={this.props.iconSize || 'large'}
            />
          )}

          <Header as={this.props.headerAs || 'h4'}>
            {this.props.children}
          </Header>
        </div>
      </div>
    )
  }
}

export default HeaderSeparator
