import React from 'react'
import * as FileSaver from 'file-saver'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
const _ = require('lodash');

export default ({
  csvData,
  headers,
  filenameGeneratorFunc,
  showHeader = true,
  customTotalVenteJournaliere = false,
  orientation = 'landscape',
}) => {
  const filePdfExtension = '.pdf'

  const buildMultilineTitle = (title) => {
    const arrayTitle = title.split(' ')
    let current_line = ''
    let finalTitle = ''
    let nbLine = 1
    for (const word of arrayTitle) {
      const lineLength = current_line.length + word.length
      if (lineLength > 70) {
        finalTitle += '\n'
        nbLine++
        current_line = ''
      } else {
        current_line += ` ${word}`
      }
      finalTitle += ` ${word}`
    }
    return {
      nbLine,
      finalTitle,
    }
  }
  const exportToPDF = async (
    csvData,
    headers,
    filenameGeneratorFunc,
    customTotalVenteJournaliere,
    orientation
  ) => {
    // Récupération des headers, lignes de données pour le fichier PDF
    let fileName = 'Export'
    if (filenameGeneratorFunc && typeof filenameGeneratorFunc === 'function') {
      fileName = await filenameGeneratorFunc()
    }

    const fileNameWithSpace = fileName.replaceAll('_', ' ')
    const title = buildMultilineTitle(fileNameWithSpace)
    // Les colonnes sont le clés des propriétés de l'objet csvFormated
    // JsPDF prend un tableau de tableaux pour les headers
    headers = _.map(headers, (n) => {
      return { dataKey: n.key, header: n.text }
    })

    // On récupère les valeurs de chaque proprieté (qui correspond à une colonne)
    const lignes = formatLines(await csvData())

    // console.log(lignes)
    // console.log(headers)
    const unit = 'pt'
    const size = 'A4' //  A1, A2, A3 or A4

    const marginLeft = 40

    // Création du document PDF
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(14)

    // Définition du contenu du fichier et stylings
    let content = {
      startY: title.nbLine > 1 ? 50 + title.nbLine * 10 : 50,
      theme: 'grid',
      columns: headers,
      margin: { left: 10, right: 10 },
      body: lignes,
    }

    doc.text(title.finalTitle, marginLeft, 40)
    doc.autoTable(content)
    // On crée un objet blob pour pouvoir l'enregistrer avec fileSaver
    var blob = doc.output('blob')
    FileSaver.saveAs(blob, fileName + filePdfExtension)
  }

  const formatLines = (lines) => {
    for (let i = 0; i < lines?.length; i++) {
      const keys = Object.keys(lines[i])

      //console.log(keys)

      keys.map((key) => {
        if (
          !isNaN(parseFloat(lines[i][key])) &&
          !isNaN(lines[i][key] - 0) &&
          (!lines[i][key].toString().startsWith('0') ||
            lines[i][key].toString().startsWith('0.'))
        ) {
          if (typeof lines[i][key] === 'string') {
            lines[i][key] = parseFloat(lines[i][key])
          }
          if (!Number.isInteger(lines[i][key])) {
            lines[i][key] = lines[i][key]
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
          }
        } else {
          if (typeof lines[i][key] === 'boolean') {
            lines[i][key] = lines[i][key] == false ? 'Non' : 'Oui'
          }

          if (
            typeof lines[i][key] === 'object' &&
            moment(lines[i][key]).isValid()
          ) {
            //console.log(lines[i][key])
            //console.log(lines[i][key])

            lines[i][key] = moment(lines[i][key]).local().format('DD/MM/YYYY')
          }

          if (lines[i][key] == null && typeof lines[i][key] !== 'object')
            lines[i][key] = 0 // évite les cases vides
        }
      })
    }

    return lines
  }

  return (
    <button
      className="ui negative button export-btn"
      type="button"
      onClick={async (e) =>
        await exportToPDF(
          csvData,
          headers,
          filenameGeneratorFunc,
          customTotalVenteJournaliere,
          orientation
        )
      }
    >
      PDF
    </button>
  )
}
