import React, { Component, Fragment } from 'react'
import { DocType } from '../../utils/enum-divers'
import { Container, Divider } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import { connect } from 'react-redux'
import { fetchBonsDeLivraison } from '../../redux/actions/bon-de-livraison-action'
import DataTables from '../shared/datatable/Datatable'
import Pagination from '../shared/datatable/Pagination'
import { history } from '../../utils/history'
import { cellFormat } from '../shared/datatable/cell-format'
import BonDeLivraisonSearchForm from './BonDeLivraisonSearchForm'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import ExportPdf from '../shared/exportcsv/export-pdf'
import moment from 'moment'
const _ = require('lodash');

class BonDeLivraison extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cols: [
        {
          text: 'Numéro',
          key: 'numero',
        },
        {
          text: 'Date',
          key: 'date',
          format: cellFormat.DATE,
        },
        {
          text: 'Type',
          key: 'type',
        },
        {
          text: 'Facturé ?',
          key: 'isFactureDone',
        },
        {
          text: 'N° Facture',
          key: 'facture.numero',
        },
        {
          text: 'Date facture',
          key: 'facture.date',
          format: cellFormat.DATE,
        },
        {
          text: 'Code client',
          key: 'recepteur.code',
        },
        {
          text: 'Client',
          key: 'recepteur.nom',
        },
        {
          text: 'Montant HT',
          key: 'totalPrixHT',
          format: cellFormat.MONEY,
        },
      ],
      type: this.props.match.params.type,
    }
  }

  componentDidMount() {
    const { queryBLAchat, queryBLVente } = this.props.bonsDeLivraison

    const query =
      this.props.match.params.type === 'ACHAT' ? queryBLAchat : queryBLVente

    this.props.fetchBonsDeLivraison(query)
  }

  // Pour le passage de BL vente à BL achat et vice versa
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Si le type a changé
    if (this.props.match.params.type != prevProps.match.params.type) {
      const { queryBLAchat, queryBLVente } = this.props.bonsDeLivraison

      // Récupération de la query
      const query =
        this.props.match.params.type === 'ACHAT' ? queryBLAchat : queryBLVente

      this.props.fetchBonsDeLivraison(query)
    }
  }
  async generateFileName() {
    let filename = 'Export_BL'
    // Build filenamewith query
    const { queryBLAchat, queryBLVente } = this.props.bonsDeLivraison
    const { params } =
      this.props.match.params.type === 'ACHAT' ? queryBLAchat : queryBLVente
    filename += `${params.type ? '_' + params.type : ''}`
    filename += `${params.numero ? '_' + params.numero : ''}`
    filename += `${params.numeroFacture ? '_' + params.numeroFacture : ''}`
    filename += `${
      params.fromDate ? '_' + moment(params.fromDate).format('DD/MM/YYYY') : ''
    }`
    filename += `${
      params.toDate ? '_' + moment(params.toDate).format('DD/MM/YYYY') : ''
    }`
    if (params.recepteurId) {
      const recepteur = await ipcRenderer.invoke(
        Channels.CONTACT_FIND_BY_ID,
        params.recepteurId
      )
      filename += `${recepteur.code ? '_' + recepteur.code : ''}`
    }
    filename += `${params.priceless ? '_Sans_Prix' : ''}`
    filename += `${params.isFactureDone ? '_Avec_Facture' : ''}`
    return filename
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <ExportPdf
          filenameGeneratorFunc={async () => await this.generateFileName()}
          csvData={this.getDataForExport}
          headers={this.state.cols}
        />
        <button
          className="ui button teal"
          onClick={() => {
            history.push(
              `/bons-de-livraison/${this.props.match.params.type}/create`
            )
          }}
          type="button"
        >
          <i className="icon plus" />
          Ajouter
        </button>
      </Fragment>
    )
  }

  getActionRow = (row) => {
    const { type } = this.props.match.params
    if (!row.id) {
      return null
    }
    const  isEdit  = !row.isFactureDone
    return (
      <Fragment>
        <button
          className="ui button blue"
          type="button"
          onClick={() => {
            history.push(`/bons-de-livraison/view/${row.id}`)
          }}
        >
          <i className="icon eye" />
          Consulter
        </button>
        {isEdit==true?(
        <button
          className="ui button olive"
          type="button"
          onClick={() => {
            history.push(`/bons-de-livraison/${type}/edit/${row.id}`)
          }}
        >
          <i className="icon pencil" />
          Editer
        </button>
        ):''}
      </Fragment>
    )
  }

  onPageClick = (pageInfo) => {
    const { queryBLAchat, queryBLVente } = this.props.bonsDeLivraison

    let query =
      this.props.match.params.type === 'ACHAT' ? queryBLAchat : queryBLVente
    query.skip = (pageInfo.currentPage - 1) * query.take
    this.props.fetchBonsDeLivraison(query)
  }

  onSubmitSearch = (formValues) => {
    // Si priceless est à false, on ne prend pas en compte le critère
    /* if (!formValues?.priceless) */ delete formValues.priceless

    const { queryBLAchat, queryBLVente } = this.props.bonsDeLivraison

    const query =
      this.props.match.params.type === 'ACHAT' ? queryBLAchat : queryBLVente
    query.params = formValues
    query.skip = 0
    query.take = 10
    this.props.fetchBonsDeLivraison(query)
  }

  onResetSearch = () => {
    const { queryBLAchat, queryBLVente } = this.props.bonsDeLivraison

    const query =
      this.props.match.params.type === 'ACHAT' ? queryBLAchat : queryBLVente

    query.params = {
      type: this.props.match.params.type,
      isFactureDone: false,
      fromDate: new Date(new Date().getFullYear(), 0, 1),
      toDate: new Date(new Date().getFullYear(), 11, 31),
    }

    this.props.fetchBonsDeLivraison(query)
  }

  onColumnClick = (col) => {
    const { queryBLAchat, queryBLVente } = this.props.bonsDeLivraison

    let query =
      this.props.match.params.type === 'ACHAT' ? queryBLAchat : queryBLVente

    // Si on tri déjà par ce champs
    if (query.order[col]) {
      // Si le tri est déjà en ASC, on switch en DESC
      if (query.order[col] === 'ASC') {
        query.order[col] = 'DESC'
        // Sinon ASC
      } else {
        query.order[col] = 'ASC'
      }
    } else {
      query.order = { [col]: 'ASC' }
    }
    this.props.fetchBonsDeLivraison(query)
  }

  getDataForExport = async () => {
    const { type } = this.props.match.params
    const { queryBLAchat, queryBLVente } = this.props.bonsDeLivraison

    const { data } = await ipcRenderer.invoke(
      Channels.BON_DE_LIVRAISON_FIND_ALL,
      _.omit(type === 'ACHAT' ? queryBLAchat : queryBLVente, ['skip', 'take'])
    )

    return _.map(data, (bl) => ({
      numero: bl.numero,
      type: bl.type,
      date: moment(bl.date).format('DD/MM/YYYY'),
      'recepteur.code': bl.recepteur?.code,
      'recepteur.nom': bl.recepteur?.nom,
      isFactureDone: bl?.isFactureDone == 0 ? false : true,
      'facture.numero': bl?.facture?.numero,
      'facture.date': bl?.facture
        ? moment(bl?.facture?.date).format('DD/MM/YYYY')
        : '',
      totalPrixHT: bl.totalPrixHT,
    }))
  }

  render() {
    const { type } = this.props.match.params
    const { queryBLAchat, queryBLVente } = this.props.bonsDeLivraison

    let query =
      this.props.match.params.type === 'ACHAT' ? queryBLAchat : queryBLVente

    return (
      <Container fluid>
        <div className="ui segment">
                <SubHeader
                   /*  title={`Liste des bons de livraison - ${type === DocType.ACHAT ? 'Achats' : 'Ventes'} `} */
                    title={`Liste des bons de livraison`}
                    actions={this.getHeaderActions()}
          />
          <Divider />

          <BonDeLivraisonSearchForm
            onSubmit={this.onSubmitSearch}
            initialValues={query.params}
            onResetSearch={this.onResetSearch}
            type={type}
          />
          <DataTables
            columns={this.state.cols}
            rows={this.props.bonsDeLivraison.data}
            onColumnClick={this.onColumnClick}
            actionRow={this.getActionRow}
            query={query}
          />
          <Pagination
            onPageClick={this.onPageClick}
            itemPerPage={query.take}
            pageNeighbours={1}
            totalItems={this.props.bonsDeLivraison.count}
          />
          <Divider />
        <SubHeader
            title={''}
            actions={this.getHeaderActions()}
            repetitionEnbas={this.props.bonsDeLivraison.count > 10 ? true : false}
          />
        </div>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  let dataInitial = state.bonsDeLivraison.ids
  ? state.bonsDeLivraison.ids.map((id) => {
      return state.bonsDeLivraison.byIds[id]
    })
  : []

  return {
    bonsDeLivraison: {
      count: state.bonsDeLivraison.count,
      data: state.bonsDeLivraison.ids
        ? state.bonsDeLivraison.ids.map((id) => {
            let BL = state.bonsDeLivraison.byIds[id]
            BL.date = moment(BL.date).format('DD/MM/YYYY')
            if(BL.facture)
              BL.facture.date = BL.facture?moment(BL.facture.date).format('DD/MM/YYYY'):''
            return BL
          })
        : [],
      queryBLAchat: state.bonsDeLivraison.queryBLAchat,
      queryBLVente: state.bonsDeLivraison.queryBLVente,
    },
  }
}

export default connect(mapStateToProps, { fetchBonsDeLivraison })(
  BonDeLivraison
)
