import React, { Component } from 'react'
import './Menu.css'

class MenuList extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div
        className={`menu ${this.props.className ? this.props.className : ''}`}
        id={`${this.props.id ? this.props.id : ''}`}
      >
        {this.props.children}
      </div>
    )
  }
}

export default MenuList
