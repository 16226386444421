import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { history } from '../../../utils/history'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'; 
import { ipcRenderer } from '../../../api/api'
import { Channels } from '../../../api/channels'

const Menu = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const Logout = () => {
    props.logout()
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const onButtonParamClick = () => {
    console.log("Payload")
    setIsOpen(false);
    ipcRenderer.invoke(Channels.CONTACT_GERANT, {}).then(result => {
      console.log("Payload")
      console.log(result)
      if (result.hasError) {
        console.log("Passage dans contact create")
        history.push(`/settings/societes/create`)
      } else {
        history.push(`/settings/societes/-1`)
      }
    })
  }

  const onButtonPWDClick = () => {
    setIsOpen(false);
    history.push(`/settings/pwd`)
  }

  const onButtonLogOutClick = () => {
    setIsOpen(false);
    Logout()
  }

  return (
    <div>
      <button onClick={toggleMenu} className="dropdown-button">
      <FontAwesomeIcon icon={faUser} /> 
      </button>
      {isOpen && (
        <div className={`dropdown-content ${isOpen ? "show" : ""}`}>
            <div  className={'menuContainer'} >
                <input
                    className={'inputMenu'}
                    type="link"
                    onClick={onButtonParamClick}
                    value={'Paramètres sociétés'}
                    readOnly={true}
                />
            </div>
            <div className={'menuContainer'}>
                <input
                    className={'inputMenu'}
                    type="link"
                    onClick={onButtonPWDClick}
                    value={'Modifier mon mot de passe'}
                    readOnly={true}
                />
            </div>
            <div className={'menuContainer'}>
                <input
                    className={'inputMenu'}
                    type="link"
                    onClick={onButtonLogOutClick}
                    value={'Déconnexion'}
                    readOnly={true}
                />
            </div>
        </div>
      )} 
    </div>
  );
};

export default Menu;
