import React, { Component } from 'react'
const _ = require('lodash');

class Pagination extends Component {
  constructor(props) {
    super(props)

    // Get props
    const { totalItems = null, itemPerPage = 30 } = props

    // Init default value
    if (typeof itemPerPage === 'number' && itemPerPage <= 100) {
      this.itemPerPage = itemPerPage
    } else {
      this.itemPerPage = 30
    }
    if (typeof totalItems === 'number') {
      this.totalItems = totalItems
    } else {
      this.totalItems = 0
    }

    this.totalPages = this.totalItems / this.itemPerPage
    // State current page
    this.state = { currentPage: 1 }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log('Update pagination')
    // console.log(this.props)
    if (prevProps.count !== this.props.count) {
      this.setState({ currentPage: 1 })
    }
  }

  getAllPageNumber = () => {
    const totalPages = this.totalPages
    const currentPage = this.state.currentPage
    // Test
    const pageNeighbours = this.props.pageNeighbours
    const totalNumbers = this.props.pageNeighbours * 2 + 3
    const totalBlocks = totalNumbers + 2
    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours)
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)
      let pages = _.range(startPage, endPage + 1)

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2
      const hasRightSpill = totalPages - endPage > 1
      const spillOffset = totalNumbers - (pages.length + 1)

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = _.range(startPage - spillOffset, startPage - 1)
          pages = ['LEFT_PAGE', ...extraPages, ...pages]
          break
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = _.range(endPage + 1, endPage + spillOffset)
          pages = [...pages, ...extraPages, 'RIGHT_PAGE']
          break
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = ['LEFT_PAGE', ...pages, 'RIGHT_PAGE']
          break
        }
      }

      return [1, ...pages, totalPages]
    }
    // end Test
    return _.range(1, totalPages + 1)
  }
  goToPage = (page) => {
    const { onPageClick = (f) => f } = this.props
    const currentPage = Math.max(0, Math.min(page, this.totalPages))
    const paginationData = {
      currentPage,
      totalPages: this.totalPages,
      itemPerPage: this.props.itemPerPage,
      totalItems: this.props.totalItems,
    }

    this.setState({ currentPage }, () => onPageClick(paginationData))
  }
  handleClick = (page) => (evt) => {
    evt.preventDefault()
    this.goToPage(page)
  }
  handleMoveLeft = (event) => {
    event.preventDefault()
    this.goToPage(this.state.currentPage - this.props.pageNeighbours * 2 - 1)
  }

  handleMoveRight = (event) => {
    event.preventDefault()
    this.goToPage(this.state.currentPage + this.props.pageNeighbours * 2 + 1)
  }

  render = () => {
    this.totalPages = Math.ceil(this.props.totalItems / this.props.itemPerPage)
    if (!this.props.totalItems || this.totalPages === 1) {
      return null
    }
    const pagesButtonToShow = this.getAllPageNumber()
    return (
      <div className="ui pagination menu">
        {pagesButtonToShow.map((page, index) => {
          if (page === 'LEFT_PAGE')
            return (
              <a key={index} className="item " onClick={this.handleMoveLeft}>
                {'<<'}
              </a>
            )

          if (page === 'RIGHT_PAGE')
            return (
              <a key={index} className="item" onClick={this.handleMoveRight}>
                {'>>'}
              </a>
            )
          return (
            <a
              key={index}
              className={`item${
                this.state.currentPage === page ? ' active' : ''
              }`}
              onClick={this.handleClick(page)}
            >
              {page}
            </a>
          )
        })}
      </div>
    )
  }
}

export default Pagination
