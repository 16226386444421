import React, { Component, Fragment } from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import {
  composeValidators,
  minLength,
  length,
  isRequired,
  mustBeNumber,
} from '../../utils/validators'
import ChampsCalcul from '../../api/enum/champs-calcul'

class FamilleForm extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <FinalForm
        onSubmit={this.props.onSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <div className="ui segment">
                  <SubHeader
                    title={this.props.formTitle}
                    actions={this.props.headerActions}
                  />
                  <div className="ui divider" />
                  <SemanticForm.Group widths="equal">
                    <Field
                      name="code"
                      validate={composeValidators(isRequired, minLength(3))}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Code"
                          type="text"
                          readOnly={this.props.view}
                          placeholder="ex: Ac12"
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                    {/* <Field
                      name="nomenclature"
                      validate={composeValidators(isRequired, minLength(3))}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Nomenclature"
                          type="text"
                          placeholder="ex: ABCDE"
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field> */}
                    <Field
                      name="libelle"
                      validate={composeValidators(minLength(3))}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Libellé"
                          type="text"
                          placeholder="ex: ABCDE"
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                  </SemanticForm.Group>
                  {/*Cotisation*/}
                  {/* <SemanticForm.Group widths="equal">
                    <Field name="champsCalcul">
                      {({ input, meta }) => (
                        <SemanticForm.Select
                          {...input}
                          fluid
                          label="Champs de calcul de cotisation"
                          onChange={(value, data) => {
                            return input.onChange(data.value)
                          }}
                          options={[
                            {
                              key: ChampsCalcul.POIDS_NET,
                              value: ChampsCalcul.POIDS_NET,
                                  _text: 'Poids Net',
                                  text: 'Quantité',
                            },
                            {
                              key: ChampsCalcul.TOTAL_PRIX_HT,
                              value: ChampsCalcul.TOTAL_PRIX_HT,
                              text: 'Total prix HT',
                              },
                              {
                                  key: ChampsCalcul.AUCUN,
                                  value: ChampsCalcul.AUCUN,
                                  text: 'Aucun',
                              },
                          ]}
                          placeholder="Sélectionnez une option"
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                    <Field
                      name="diviseur"
                      validate={composeValidators(mustBeNumber)}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Diviseur pour le calcul des cotisation"
                          type="number"
                          placeholder="1"
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                    <Field
                      name="montant cotisation"
                      validate={composeValidators(mustBeNumber)}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Montant"
                          type="number"
                          placeholder="1"
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field> 
                  </SemanticForm.Group>*/}
                </div>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default FamilleForm
