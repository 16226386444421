import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { avoir } from './types'

export const fetchAvoir = (id) => {
  console.log('fetch action')

  return async (dispatch) => {
    try {
      const payload = await ipcRenderer.invoke(Channels.AVOIR_FIND_BY_ID, id)
      if (payload.hasError) {
        throw new Error(payload.error?.toString())
      } else {
        dispatch({
          type: avoir.FETCH_AVOIR,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération d'un avoir")
    }
  }
}

export const fetchAvoirs = (query) => {
  return async (dispatch) => {
    try {
      const response = await ipcRenderer.invoke(Channels.AVOIR_FIND_ALL, query)
      const payload = { response, query }
      if (response.hasError) {
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: avoir.FETCH_AVOIRS,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des avoirs")
    }
  }
}
