import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import CustomRoute from './CustomRoute'
import Accueil from '../../accueil/Accueil'
import BonDeLivraison from '../../bon-de-livraison/BonDeLivraison'
import Facture from '../../facture/Facture'
import Produit from '../../produit/Produit'
import ProduitCreate from '../../produit/ProduitCreate'
import Famille from '../../famille/Famille'
import FamilleCreate from '../../famille/FamilleCreate'
import FamilleView from '../../famille/FamilleView'
import FamilleEdit from '../../famille/FamilleEdit'
import Variete from '../../variete/Variete'
import VarieteCreate from '../../variete/VarieteCreate'
import VarieteView from '../../variete/VarieteView'
import VarieteEdit from '../../variete/VarieteEdit'
import ProduitEdit from '../../produit/ProduitEdit'
import ProduitView from '../../produit/ProduitView'
import Emballage from '../../emballage/Emballage'
import EmballageCreate from '../../emballage/EmballageCreate'
import EmballageEdit from '../../emballage/EmballageEdit'
import EmballageView from '../../emballage/EmballageView'
import Parcelle from '../../parcelle/Parcelle'
import ParcelleCreate from '../../parcelle/ParcelleCreate'
import ParcelleEdit from '../../parcelle/ParcelleEdit'
import ParcelleView from '../../parcelle/ParcelleView'
import Mention from '../../mention/Mention'
import MentionCreate from '../../mention/MentionCreate'
import MentionEdit from '../../mention/MentionEdit'
import MentionView from '../../mention/MentionView'
import Contact from '../../contact/Contact'
import ContactCreate from '../../contact/ContactCreate'
import ContactEdit from '../../contact/ContactEdit'
import ContactView from '../../contact/ContactView'
import Traite from '../../traite/Traite'
import TraiteCreate from '../../traite/TraiteCreate'
import TraiteEdit from '../../traite/TraiteEdit'
import TraiteView from '../../traite/TraiteView'
import BonDeLivraisonCreate from '../../bon-de-livraison/BonDeLivraisonCreate'
import BonDeLivraisonEdit from '../../bon-de-livraison/BonDeLivraisonEdit'
import factureCreate from '../../facture/FactureCreate'
import factureEdit from '../../facture/FactureEdit'
import factureView from '../../facture/FactureView'
import BonDeLivraisonView from '../../bon-de-livraison/BonDeLivraisonView'
import Avoir from '../../avoir/Avoir'
import AvoirCreate from '../../avoir/AvoirCreate'
import AvoirEdit from '../../avoir/AvoirEdit'
import AvoirView from '../../avoir/AvoirView'
import StatistiquesAchatsVentes from '../../statistique/AchatsVentes/StatistiquesAchatsVentes'
import StatistiquesClients from '../../statistique/Clients/StatistiquesClients'
import StatistiquesParcelles from '../../statistique/Parcelles/StatistiquesParcelles'
import StatistiquesFournisseurs from '../../statistique/Fournisseurs/StatistiquesFournisseurs'
import StatistiqueVenteJournaliere from '../../statistique/VenteJournaliere/StatistiqueVenteJournaliere'
import FactureTransporteur from '../../facture-transporteur/FactureTransporteur'
import FactureTransporteurCreate from '../../facture-transporteur/FactureTransporteurCreate'
import FactureTransporteurEdit from '../../facture-transporteur/FactureTransporteurEdit'
import Solde from '../../solde/Solde'
import ProdouaneEdit from '../../Prodouane/ProdouaneEdit'
import ProdouaneFormCreate from '../../Prodouane/ProdouaneFormCreate'
import Prodouane from '../../Prodouane/Prodouane'
import Colis from '../../statistique/colis/Colis'
import Societe from '../../societes/Societe'
import SocieteForm from '../../societes/SocieteForm'
import SocieteEdit from '../../societes/SocieteEdit'
import UserPwd from '../../userPwd/UserPwd.js'
import CompteComptable from '../../compte-comptable/CompteComptable.js'
import CompteComptableCreate from '../../compte-comptable/CompteComptableCreate.js'
import CompteComptableView from '../../compte-comptable/CompteComptableView.js'
import CompteComptableEdit from '../../compte-comptable/CompteComptableEdit.js'

class Routing extends Component {
  render() {
    return (
      <Switch>
        <CustomRoute path="/" exact component={Accueil} title="Accueil" />
        <CustomRoute
          path="/statistiques/achats-ventes"
          component={StatistiquesAchatsVentes}
          title="Statistiques"
          exact
        />
        <CustomRoute
          path="/statistiques/clients"
          component={StatistiquesClients}
          title="Statistiques"
          exact
        />
        <CustomRoute
          path="/statistiques/fournisseurs"
          component={StatistiquesFournisseurs}
          title="Statistiques"
          exact
        />
        <CustomRoute
          path="/statistiques/ventesJournalieres"
          component={StatistiqueVenteJournaliere}
          title="Statistiques"
          exact
        />
        <CustomRoute
          path="/statistiques/colis"
          component={Colis}
          title="Colis"
          exact
        />
        <CustomRoute
          path="/statistiques/parcelles"
          component={StatistiquesParcelles}
          title="Parcelles"
          exact
        />
        <CustomRoute
          path="/bons-de-livraison/:type"
          component={BonDeLivraison}
          title="Bons de livraison"
          exact
        />
        <CustomRoute
          path="/bons-de-livraison/:type/create"
          component={BonDeLivraisonCreate}
          title="Bons de livraison"
        />
        <CustomRoute
          path="/bons-de-livraison/:type/edit/:id"
          component={BonDeLivraisonEdit}
          title="Bons de livraison"
        />
        <CustomRoute
          path="/bons-de-livraison/view/:id"
          component={BonDeLivraisonView}
          title="Bons de livraison"
        />
        <CustomRoute
          path="/factures/:type"
          component={Facture}
          title="Factures"
          exact
        />
        <CustomRoute
          path="/factures/:type/create"
          component={factureCreate}
          title="Factures"
        />
        <CustomRoute
          path="/factures/:type/edit/:id"
          component={factureEdit}
          title="Factures"
        />
        <CustomRoute
          path="/factures/view/:id"
          component={factureView}
          title="Factures"
        />
        {/* SOLDE*/}
        <CustomRoute path="/soldes" component={Solde} title="Soldes" />
        {/*Paramétrage*/}
        {/*Mention*/}
        <CustomRoute
          path="/settings/contacts"
          component={Contact}
          title="Gestion des clients"
          exact
        />
        <CustomRoute
          path="/settings/societes/create"
          render={() => <ContactCreate isGerant={true} isTVA={true}/>}
          title="Création de la société"
        />
        <CustomRoute
          path="/settings/contacts/create"
          render={() => <ContactCreate isGerant={false} isTVA={false}/>}
          title="Gestion des clients"
        />
        <CustomRoute
          path="/settings/contacts/edit/:id"
          component={ContactEdit}
          title="Gestion des clients"
        />
        <CustomRoute
          path="/settings/contacts/view/:id"
          component={ContactView}
          title="Gestion des clients"
        />
        {/*Famille*/}
        <CustomRoute
          path="/settings/familles"
          component={Famille}
          title="Gestion des familles"
          exact
        />
        <CustomRoute
          path="/settings/familles/create"
          component={FamilleCreate}
          title="Gestion des familles"
        />
        <CustomRoute
          path="/settings/familles/view/:id"
          component={FamilleView}
          title="Gestion des familles"
        />
        <CustomRoute
          path="/settings/familles/edit/:id"
          component={FamilleEdit}
          title="Gestion des familles"
        />
        {/*catégorie*/}
        <CustomRoute
          path="/settings/varietes"
          component={Variete}
          title="Gestion des catégorie"
          exact
        />
        <CustomRoute
          path="/settings/varietes/create"
          component={VarieteCreate}
          title="Gestion des catégorie"
        />
        <CustomRoute
          path="/settings/varietes/view/:id"
          component={VarieteView}
          title="Gestion des catégorie"
        />
        <CustomRoute
          path="/settings/varietes/edit/:id"
          component={VarieteEdit}
          title="Gestion des catégorie"
        />
        {/*Comptes comptables*/}
        <CustomRoute
          path="/settings/comptes_comptables"
          component={CompteComptable}
          title="Gestion des comptes comptables"
          exact
        />
        <CustomRoute
          path="/settings/comptes_comptables/create"
          component={CompteComptableCreate}
          title="Gestion des comptes comptables"
        />
        <CustomRoute
          path="/settings/comptes_comptables/view/:id"
          component={CompteComptableView}
          title="Gestion des comptes comptables"
        />
        <CustomRoute
          path="/settings/comptes_comptables/edit/:id"
          component={CompteComptableEdit}
          title="Gestion des comptes comptables"
        />
        {/*Produit*/}
        <CustomRoute
          path="/settings/produits"
          component={Produit}
          title="Gestion des produits"
          exact
        />
        <CustomRoute
          path="/settings/produits/create"
          component={ProduitCreate}
          title="Gestion des produits"
        />
        <CustomRoute
          path="/settings/produits/edit/:id"
          component={ProduitEdit}
          title="Gestion des produits"
        />
        <CustomRoute
          path="/settings/produits/view/:id"
          component={ProduitView}
          title="Gestion des produits"
        />

        {/*Société*/}
        <CustomRoute
          path="/settings/societes/:id"
          component={ContactEdit}
          title="Paramétrage de la société"
        />



        <CustomRoute
          path="/settings/societes/view/:id"
          component={Societe}
          title="Gestion des produits"
        />

        {/*Emballage*/}
        <CustomRoute
          path="/settings/emballages"
          component={Emballage}
          title="Gestion des emballages"
          exact
        />
        <CustomRoute
          path="/settings/emballages/create"
          component={EmballageCreate}
          title="Gestion des emballages"
        />
        <CustomRoute
          path="/settings/emballages/edit/:id"
          component={EmballageEdit}
          title="Gestion des emballages"
        />
        <CustomRoute
          path="/settings/emballages/view/:id"
          component={EmballageView}
          title="Gestion des emballages"
        />
        {/*Parcelles*/}
        <CustomRoute
          path="/settings/parcelles"
          component={Parcelle}
          title="Gestion des parcelles"
          exact
        />
        <CustomRoute
          path="/settings/parcelles/create"
          component={ParcelleCreate}
          title="Gestion des parcelles"
        />
        <CustomRoute
          path="/settings/parcelles/edit/:id"
          component={ParcelleEdit}
          title="Gestion des parcelles"
        />
        <CustomRoute
          path="/settings/parcelles/view/:id"
          component={ParcelleView}
          title="Gestion des parcelles"
        />
        {/*Mention*/}
        <CustomRoute
          path="/settings/mentions"
          component={Mention}
          title="Gestion des mentions"
          exact
        />
        <CustomRoute
          path="/settings/mentions/create"
          component={MentionCreate}
          title="Gestion des mentions"
        />
        <CustomRoute
          path="/settings/mentions/edit/:id"
          component={MentionEdit}
          title="Gestion des mentions"
        />
        <CustomRoute
          path="/settings/mentions/view/:id"
          component={MentionView}
          title="Gestion des mentions"
        />
        {/* Factures transporteur*/}
        <CustomRoute
          path="/factures-transporteur"
          component={FactureTransporteur}
          title="Factures transporteur"
          exact
        />
        <CustomRoute
          path="/factures-transporteur/create"
          component={FactureTransporteurCreate}
          title="Factures transporteur"
          exact
        />
        <CustomRoute
          path="/factures-transporteur/edit/:id"
          component={FactureTransporteurEdit}
          title="Factures transporteur"
          exact
        />
        {/*Traite*/}
        <CustomRoute
          path="/traites"
          component={Traite}
          title="Gestion des traites"
          exact
        />
        <CustomRoute
          path="/traites/create"
          component={TraiteCreate}
          title="Gestion des traites"
        />
        <CustomRoute
          path="/traites/edit/:id"
          component={TraiteEdit}
          title="Gestion des traites"
        />
        <CustomRoute
          path="/traites/view/:id"
          component={TraiteView}
          title="Gestion des traites"
        />
        {/*Avoir*/}
        <CustomRoute
          path="/avoirs"
          component={Avoir}
          title="Gestion des avoirs"
          exact
        />
        <CustomRoute
          path="/avoirs/create"
          component={AvoirCreate}
          title="Gestion des avoirs"
        />
        <CustomRoute
          path="/avoirs/edit/:id"
          component={AvoirEdit}
          title="Gestion des avoirs"
        />
        <CustomRoute
          path="/avoirs/view/:id"
          component={AvoirView}
          title="Gestion des avoirs"
        />
        {/* Prodouane */}
        <CustomRoute
          path="/prodouane"
          component={Prodouane}
          title="Prodouane"
          exact
        />
        <CustomRoute
          path="/prodouane/create"
          component={ProdouaneFormCreate}
          title="Création d'un document prodouane"
          exact
        />
        <CustomRoute
          path="/prodouane/edit/:id"
          component={ProdouaneEdit}
          title="Edition du document prodouane"
        />

        {/*  <CustomRoute
          path="/bons-de-livraison/:type"
          component={BonDeLivraison}
          title="Bons de livraison"
          exact
        />
        <CustomRoute
          path="/bons-de-livraison/:type/create"
          component={BonDeLivraisonCreate}
          title="Bons de livraison"
        />
        <CustomRoute
          path="/bons-de-livraison/:type/edit/:id"
          component={BonDeLivraisonEdit}
          title="Bons de livraison"
        />
        <CustomRoute
          path="/bons-de-livraison/view/:id"
          component={BonDeLivraisonView}
          title="Bons de livraison"
        /> */}
        <CustomRoute
          path="/settings/pwd/"
          component={UserPwd}
          title="Modification du mot de passe"
        />
      </Switch>
    )
  }
}

export default Routing
