import React, { Component, Fragment } from 'react'
import { cellFormat, roundTwoDec } from './cell-format'
import moment from 'moment'

class TableCell extends Component {
  constructor(props) {
    super(props)
  }

  dynamicRender = () => {
    const { format } = this.props
    let { content } = this.props

    if (content instanceof Date) {
      if (format && format === cellFormat.DATE) {
        return moment(content).format('DD/MM/YYYY')
      } else {
        return content.toLocaleString('fr-FR')
      }
    }
    if (format === cellFormat.MONEY || format === cellFormat.NUMBER) {
      content = roundTwoDec(content)
    }
    if (format === cellFormat.MONEY) {
      content = content + ' €'
      return content
    }

    switch (typeof content) {
      case 'boolean':
        return content ? (
          <i className="check icon color-success" />
        ) : (
          <i className="close icon color-error" />
        )
      case 'string':
        return <div className="new-line">{content}</div>
      default:
        return content
    }
  }

  render() {
    return <td>{this.dynamicRender()}</td>
  }
}

export default TableCell
