import { ipcRenderer } from '../../api/api'
import React, { Component, Fragment } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'semantic-ui-react'
import { Channels } from '../../api/channels'
import { history } from '../../utils/history'
import CustomModal from '../shared/modal/CustomModal'
import { ProdouaneForm } from './ProdouaneForm'
import { BL_STATUS } from './prodouane-form-update'
import { handleErrorResponse } from '../../utils/handle-error-response'

class ProdouaneEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      prodouaneItem: null,
    }
  }

  componentDidMount = () => {
    ipcRenderer
      .invoke(Channels.PRODOUANE_FIND_BY_ID, this.props.match.params.id)
      .then((data) => {
        data.bonDeLivraisons = data.bonDeLivraisons?.map((item) => {
          item.totalSansRemise = item.totalPrixHT - item.montantRemiseIndicative
          return item
        })
        this.setState({
          prodouaneItem: data,
        })
      })
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button icon="save" type="submit" content="Enregistrer" color="teal" />
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: true,
            })
          }}
        >
          Annuler
        </button>
      </Fragment>
    )
  }

  modalActionsCancel() {
    const { type } = this.props?.match?.params
    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
            history.push(history.push(`/bons-de-livraison/${type}`))
          }}
        >
          <i className="checkmark icon" />
          Oui
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
          }}
        >
          Non
        </button>
      </Fragment>
    )
  }

  onFormSubmit = (formValues) => {
    /*console.log(formValues.referencePeriod)
    const arrayDate = formValues.referencePeriod.split('/')
    formValues.referencePeriod = new Date(arrayDate[1], arrayDate[1] - 1, 1, 10)*/
    ipcRenderer
      .invoke(Channels.PRODOUANE_UPDATE, {
        id: this.props.match.params.id,
        prodouane: formValues,
      })
      .then((response) => {
        //console.log(response)
        if (response.hasError) {
          handleErrorResponse(response.errors)
        } else {
          history.push('/prodouane')
          toast.success("L'export prodouane à bien été mis à jour")
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  render() {
    return this.state.prodouaneItem ? (
      <Fragment>
        <ProdouaneForm
          onFormSubmit={this.onFormSubmit}
          title={"Edition d'un export prodouane"}
          initialValues={{
            edit: true,
            ...this.state.prodouaneItem,
          }}
        />

        <CustomModal
          title="Annuler"
          content="Quitter et annuler les modifications en cours ?"
          visible={this.state.showModalCancel}
          actions={this.modalActionsCancel()}
        />
      </Fragment>
    ) : (
      <div>Chargement</div>
    )
  }
}

export default ProdouaneEdit
