import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Button } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import { Table } from 'semantic-ui-react'
import { VisionMode } from '../../utils/theme'
import { history } from '../../utils/history'
import { fetchEmballage } from '../../redux/actions/emballage-action'

class EmballageView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchEmballage(this.props.match.params.id)
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button
          icon="reply"
          type="submit"
          content="Retour à la liste"
          color="black"
          onClick={() => {
            history.push('/settings/emballages')
          }}
        />
      </Fragment>
    )
  }

  render() {
    const { emballage } = this.props
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title={`Emballage ${emballage?.libelle}`}
            actions={this.getHeaderActions()}
          />
          <div className="ui divider"></div>
          <div className="dt-top-container">
            <div className="content">
              <Table
                definition
                className={`${
                  this.props.theme.visionMode === VisionMode.AMELIORE
                    ? 'inverted'
                    : ''
                }`}
              >
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Code</Table.Cell>
                    <Table.Cell>{emballage?.code}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Libellé</Table.Cell>
                    <Table.Cell>{emballage?.libelle}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return { emballage: state.emballages.emballage, theme: state.theme }
}

export default connect(mapStateToProps, { fetchEmballage })(EmballageView)
