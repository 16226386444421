import React, { Component, Fragment } from 'react'
import SubHeader from '../shared/subHeader/SubHeader'
import { history } from '../../utils/history'
import { connect } from 'react-redux'
import DataTables from '../shared/datatable/Datatable'
import Pagination from '../shared/datatable/Pagination'
import TraiteSearchForm from './TraiteSearchForm'
import { fetchTraites } from '../../redux/actions/traite-action'
import { cellFormat, roundTwoDec } from '../shared/datatable/cell-format'
import { Channels } from '../../api/channels'
import ExportPdf from '../shared/exportcsv/export-pdf'
import moment from 'moment'
import { ipcRenderer } from '../../api/api'
const _ = require('lodash');

class Traite extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cols: [
        {
          text: 'Numéro traite',
          key: 'numeroTraite',
        },
        {
          text: 'Numéro Facture',
          key: 'numeroFacture',
        },
        // {
        //   text: 'Intitulé Facture',
        //   key: 'intituleFacture',
        // },
        {
          text: 'Catégorie',
          key: 'categorie',
        },
        {
          text: "Date d'échéance",
          key: 'dateEcheance',
          format: cellFormat.DATE,
        },
        {
          text: 'Payée',
          key: 'isPayee',
        },
        {
          text: 'Mode de règlement',
          key: 'modeReglement',
        },
        {
          text: 'Date de paiement',
          key: 'datePaiement',
          format: cellFormat.DATE,
        },
        {
          text: 'Montant',
          key: 'montant',
          format: cellFormat.MONEY,
        },
      ],
    }
  }
  componentDidMount() {
    this.props.traites.query.params.toDate = new Date(
      new Date().getFullYear(),
      11,
      31
    )
    this.props.fetchTraites(this.props.traites.query)
  }
  /**
   * The actions to show in the right column
   * @param {*} row
   * @returns
   */
  getActionRow = (row) => {
    if (!row.id) {
      return null
    }
    return (
      <Fragment>
        <button
          className="ui button blue"
          type="button"
          onClick={() => {
            history.push(`/traites/view/${row.id}`)
          }}
        >
          <i className="icon eye" />
          Consulter
        </button>
        <button
          className="ui button olive"
          type="button"
          onClick={() => {
            history.push(`/traites/edit/${row.id}`)
          }}
        >
          <i className="icon pencil" />
          Editer
        </button>
      </Fragment>
    )
  }

  async generateFileName() {
    const { query } = this.props.traites
    const { params } = query
    let filename = 'Export_Traites'
    filename += `${
      query.params.fromDate
        ? '_du_' + moment(query.params.fromDate).format('DD/MM/YYYY')
        : ''
    }`
    filename += `${
      params.toDate ? '_au_' + moment(params.toDate).format('DD/MM/YYYY') : ''
    }`
    return filename
  }

  getDataForExport = async () => {
    const { query } = this.props.traites
    const { data } = await ipcRenderer.invoke(
      Channels.TRAITE_FIND_ALL,
      _.omit(query, ['skip', 'take'])
    )
    // Ajout d'une ligne pour le total (auto-bindé sur les keys des colonnes)
    data.push({
      datePaiement: 'Total',
      montant: data
        ? roundTwoDec(
            _.sumBy(data, (ligne) =>
              isNaN(parseFloat(ligne?.montant)) // l'espace des milliers posait problème pour la conversion en float
                ? 0
                : parseFloat(ligne?.montant)
            )
          )
        : '0.0',
    })

    return data
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <ExportPdf
          filenameGeneratorFunc={async () => await this.generateFileName()}
          csvData={this.getDataForExport}
          headers={this.state.cols}
          customTotalTraites={true}
        />
        <button
          className="ui button teal"
          onClick={() => {
            history.push('/traites/create')
          }}
          type="button"
        >
          <i className="icon plus" />
          Ajouter
        </button>
      </Fragment>
    )
  }

  /**
   * Action on column click
   * @param {*} col
   */
  onColumnClick = (col) => {
    let query = this.props.traites.query
    // Si on tri déjà par ce champs
    if (query.order[col]) {
      // Si le tri est déjà en ASC, on switch en DESC
      if (query.order[col] === 'ASC') {
        query.order[col] = 'DESC'
        // Sinon ASC
      } else {
        query.order[col] = 'ASC'
      }
    } else {
      query.order = { [col]: 'ASC' }
    }
    this.props.fetchTraites(query)
  }

  /**
   * Action on page click
   * @param {*} pageInfo
   */
  onPageClick = (pageInfo) => {
    let query = this.props.traites.query
    query.skip = (pageInfo.currentPage - 1) * query.take
    this.props.fetchTraites(query)
  }

  onSubmitSearch = (formValues) => {
    //console.log(formValues)

    const query = this.props.traites.query
    query.params = formValues
    query.skip = 0
    query.take = 10
    this.props.fetchTraites(query)
  }
  onResetSearch = () => {
    const query = {
      skip: 0,
      take: 10,
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: { dateEcheance: 'ASC' },
    }

    this.props.fetchTraites(query)
  }

  renderTotalLine = (traites) => {
    return (
      <tr className="total-row">
        <td colSpan={8}>Total</td>
        <td colSpan={2}>
          {traites
            ? roundTwoDec(_.sumBy(traites, (traite) => traite?.montant))
            : 0.0}{' '}
          €
        </td>
      </tr>
    )
  }

  render() {
    const { query, data, count } = this.props.traites

    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title={'Liste des traites'}
            actions={this.getHeaderActions()}
          />
          <div className="ui divider" />

          <TraiteSearchForm
            onSubmit={this.onSubmitSearch}
            initialValues={query.params}
            onResetSearch={this.onResetSearch}
          />

          <div className="content">
            <DataTables
              columns={this.state.cols}
              rows={data}
              onColumnClick={this.onColumnClick}
              actionRow={this.getActionRow}
              query={query}
              total={this.renderTotalLine(data)}
            />
            <Pagination
              onPageClick={this.onPageClick}
              itemPerPage={query.take}
              pageNeighbours={1}
              totalItems={count}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    traites: {
      count: state.traites.count,
      data: state.traites.ids
        ? state.traites.ids.map((id) => {
            return state.traites.byIds[id]
          })
        : [],
      query: state.traites.query,
    },
  }
}

export default connect(mapStateToProps, { fetchTraites })(Traite)
