import React, { Component } from 'react'
import { Button, Form as SemanticForm } from 'semantic-ui-react'
import { Field, Form as FinalForm, useForm } from 'react-final-form'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'

class ParcelleSearchForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contacts: [],
    }
  }
  componentDidMount() {
    this.getContactsInState()
  }
  getContactsInState = () => {
    ipcRenderer.invoke(Channels.CONTACT_FIND_ALL, {params: { isGerant: false,}}).then(({ data }) => {
      this.setState({
        contacts: data,
      })
    })
  }

  render() {
    return (
      <FinalForm
        onSubmit={this.props.onSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit, form }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <SemanticForm.Group>
                  <Field name="code">
                    {({ input, meta }) => (
                      <SemanticForm.Input
                        {...input}
                        label="Code"
                        type="text"
                        placeholder="Filtrer par code"
                        autoComplete="off" 
                      />
                    )}
                  </Field>
                  <Field name="contactId">
                    {({ input, meta }) => {
                      const form = useForm()
                      return (
                        <SemanticForm.Field>
                          <label>Contact</label>
                          <SemanticForm.Dropdown
                            placeholder="-- Tous les contacts --"
                            {...input}
                            search
                            fluid
                            selection
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.contacts?.map((contact) => {
                              return {
                                key: contact.id,
                                value: contact.id,
                                text: contact.code + ' - ' + contact.nom,
                              }
                            })}
                            onChange={(event, data) => {
                              const contactOption = this.state.contacts.find(
                                (item) => {
                                  return item.id === data.value
                                }
                              )
                              if (contactOption) {
                                form.change(`contactId`, contactOption.id)
                              }
                              input.onChange(data.value)
                            }}
                            //onSearchChange={this.handleSearchChange}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )
                    }}
                  </Field>
                  <div className="inline field searchFormBtns">
                    <Button type="submit">
                      <i className="icon search"></i>Rechercher
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        // Reset the form
                        form.reset()
                        this.props.onResetSearch()
                      }}
                    >
                      <i className="icon eraser"></i>Effacer
                    </Button>
                  </div>
                </SemanticForm.Group>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default ParcelleSearchForm
