import React, { Component, Fragment } from 'react'
import { Field } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import {
  composeValidators,
  minLength,
  isRequired,
  maxLength,
} from '../../utils/validators'
import Civilite from '../../api/enum/civilite'

class ContactFormContactInfos extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { name, index } = this.props
    return (
      <div className="ui segment">
        <div className="en-tete">
          <h3>Information n° {index + 1}</h3>
          <button
            className="ui button negative icon right floated"
            index={index}
            onClick={this.props.onDelete}
            //disabled={submitting} // désactive le bt durant le submit
            type="button"
          >
            <i className="trash alternate icon" index={index} />
          </button>
        </div>
        <div className="ui content">
          <div className="ui divider" />
          <Field name={`${name}.id`} type="hidden">
            {({ input, meta }) => (
              <SemanticForm.Input {...input} type="hidden" />
            )}
          </Field>
          <SemanticForm.Group widths="equal">
            <Field
              name={`${name}.intitule`}
              validate={composeValidators(
                isRequired,
                minLength(3),
                maxLength(255)
              )}
            >
              {({ input, meta }) => (
                <SemanticForm.Input
                  {...input}
                  label="Intitule"
                  type="text"
                  placeholder="Intitulé du contact info"
                  autoComplete="off"
                  error={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </SemanticForm.Group>
          <SemanticForm.Group widths="equal">
            <Field name={`${name}.civilite`}>
              {({ input, meta }) => (
                <SemanticForm.Select
                  {...input}
                  fluid
                  label="Civilité"
                  onChange={(value, data) => {
                    return input.onChange(data.value)
                  }}
                  options={[
                    {
                      key: Civilite.MADAME,
                      value: Civilite.MADAME,
                      text: Civilite.MADAME,
                    },
                    {
                      key: Civilite.MONSIEUR,
                      value: Civilite.MONSIEUR,
                      text: Civilite.MONSIEUR,
                    },
                  ]}
                  placeholder="Sélectionnez une option"
                  error={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
            <Field
              name={`${name}.nom`}
              validate={composeValidators(
                isRequired,
                minLength(3),
                maxLength(255)
              )}
            >
              {({ input, meta }) => (
                <SemanticForm.Input
                  {...input}
                  label="Nom"
                  type="text"
                  placeholder="Nom"
                  autoComplete="off"
                  error={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
            <Field
              name={`${name}.prenom`}
              validate={composeValidators(
                isRequired,
                minLength(3),
                maxLength(255)
              )}
            >
              {({ input, meta }) => (
                <SemanticForm.Input
                  {...input}
                  label="Prénom"
                  type="text"
                  placeholder="Prénom"
                  autoComplete="off"
                  error={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </SemanticForm.Group>
          <SemanticForm.Group widths="equal">
            <Field
              name={`${name}.profession`}
              validate={composeValidators(minLength(3), maxLength(255))}
            >
              {({ input, meta }) => (
                <SemanticForm.Input
                  {...input}
                  label="Profession"
                  type="text"
                  placeholder="Profession"
                  error={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
            <Field
              name={`${name}.email`}
              validate={composeValidators(minLength(3), maxLength(255))}
            >
              {({ input, meta }) => (
                <SemanticForm.Input
                  {...input}
                  label="Email"
                  type="text"
                  placeholder="adresse@email.fr"
                  autoComplete="off"
                  error={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </SemanticForm.Group>
          <SemanticForm.Group widths="equal">
            <Field
              name={`${name}.telFixe`}
              validate={composeValidators(minLength(3), maxLength(255))}
            >
              {({ input, meta }) => (
                <SemanticForm.Input
                  {...input}
                  label="Tel. Fixe"
                  type="text"
                  placeholder="01 23 45 67 89"
                  autoComplete="off"
                  error={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
            <Field
              name={`${name}.telPortable`}
              validate={composeValidators(minLength(3), maxLength(255))}
            >
              {({ input, meta }) => (
                <SemanticForm.Input
                  {...input}
                  label="Tel. Portable"
                  type="text"
                  placeholder="01 23 45 67 89"
                  autoComplete="off"
                  error={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
            <Field
              name={`${name}.fax`}
              validate={composeValidators(minLength(3), maxLength(255))}
            >
              {({ input, meta }) => (
                <SemanticForm.Input
                  {...input}
                  label="Fax"
                  type="text"
                  placeholder="01 23 45 67 89"
                  autoComplete="off"
                  error={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </SemanticForm.Group>
        </div>
      </div>
    )
  }
}

export default ContactFormContactInfos
