import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { famille } from './types'

export const fetchFamille = (id) => {
  return async (dispatch) => {
    try {
      const payload = await ipcRenderer.invoke(Channels.FAMILLE_FIND_BY_ID, id)
      //console.log(payload)
      if (payload.hasError) {
        throw new Error(payload.error?.toString())
      } else {
        dispatch({
          type: famille.FETCH_FAMILLE,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération d'un famille")
    }
  }
}

export const fetchFamilles = (query) => {
  return async (dispatch) => {
    try {
      const response = await ipcRenderer.invoke(
        Channels.FAMILLE_FIND_ALL,
        query
      )
      const payload = { response, query }
      if (response.hasError) {
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: famille.FETCH_FAMILLES,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des familles")
    }
  }
}
