import React, { Component, Fragment } from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { Form as SemanticForm, Label, Icon } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import {
  composeValidators,
  minLength,
  isRequired,
  maxLength,
} from '../../utils/validators'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import ContactFormAdministratif from './ContactFormAdministratif'
import ContactFormGeneralContact from './ContactFormGeneralContact'
import ContactFormContactInfos from './ContactFormContactInfos'
import HeaderSeparator from '../shared/header/HeaderSeparator'
import { toast } from 'react-toastify'
import { handleErrorResponse } from '../../utils/handle-error-response'
import { history } from '../../utils/history'
import './ContactForm.css'
import ContactFormComptabilite from './ContactFormComptabilite.js'
class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pays: [],
      contactsSelected: [],
      logo: false,
      logoFile: null,
    }

    ipcRenderer.invoke(Channels.PAYS_FIND_ALL, { order: { libelleFR: 'ASC' }}).then(({ data }) => {
      console.log(data)
      this.setState({
        pays: data,
      })
  })
}

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (prevProps !== this.props && !!this.props) {
      /* const { initialValues } = this.props
      if (initialValues && initialValues.pays) {
        this.setState({
          pays: [initialValues.pays],
          logo: initialValues.isGerant,
        })
      } */
        if (this.props?.initialValues?.pays) {
          const { pays } = this.props.initialValues.pays
          const { logo } = this.props.initialValues.isGerant
          if(this.props.initialValues.isGerant)
            this.setState({logo : this.props.initialValues.isGerant})
          ipcRenderer.invoke(Channels.PAYS_FIND_ALL, { order: { libelleFR: 'ASC' }}).then(({ data }) => {
            this.setState(
              {
                paysSelected: data.map((c) => c.id),
                pays: data,
              }
            )
          })
        }
    }
  }

  handleSearchChangePays = (e) => {
    if (e.target.value) {
      const query = {
        params: {
          libelleFR: e.target.value,
        },
      }
      ipcRenderer.invoke(Channels.PAYS_FIND_ALL, query).then(({ data }) => {
        console.log(data)
        this.setState({
          pays: data,
        })
      })
    }
    //console.log(e)
  }

  getBase64(file, cb) {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  renderContactInfosBloc = ({ fields, meta }) => {
    return (
      <Fragment>
        {fields.map((name, index) => (
          <ContactFormContactInfos
            key={index}
            name={name}
            index={index}
            meta={meta}
            onDelete={() => fields.remove(index)}
          />
        ))}
        <button
          className="ui labeled icon button"
          type="button"
          onClick={() => fields.push({})}
        >
          <i className="plus icon" />
          Ajouter une information sur le contact (facultatif)
        </button>
      </Fragment>
    )
  }

  handleSubmit = async (formValues) => {
    if (formValues.paysId == null) {
      toast.error("Le pays n'a pas été renseigné")
      return
    }

    if (!formValues.isFournisseur) {
      formValues.isFournisseur = false
    }
    if (!formValues.isOPM_AGM) {
      formValues.isOPM_AGM = false
    }
    if (!formValues.isClient) {
      formValues.isClient = true
    }
    if (!formValues.isGerant) {
      formValues.isGerant = false
    }
    else
    {
      formValues.isClient = !formValues.isGerant
    }
    if (!formValues.isTVA) {
      formValues.isTVA = false
    }
    if (!formValues.isCEE) {
      formValues.isCEE = false
    }
    if (!formValues.numeroSIRET) {
      formValues.numeroSIRET = null
    }
    if (!formValues.email) {
      formValues.email = null
    }
    if (!formValues.telFixe) {
      formValues.telFixe = null
    }
    if (!formValues.telPortable) {
      formValues.telPortable = null
    }
    if (!formValues.fax) {
      formValues.fax = null
    }
    if (!formValues.complement) {
      formValues.complement = null
    }
    formValues.remiseParDefaut = formValues.remiseParDefaut
      ? parseFloat(formValues.remiseParDefaut)
      : 0
    if (!formValues.mentionId) {
      formValues.mentionId = null
    }
    let contact = await this.props.onSubmit(formValues)

    if (contact?.hasError ?? false) {
      handleErrorResponse(contact.errors)
      return
    }

    if (this.state.logoFile) {
      const formData = new FormData()
      // Update the formData object
      formData.append('file', this.state.logoFile)
      let base64 = ''
      this.getBase64(this.state.logoFile, (result) => {
        base64 = result
        ipcRenderer
          .invoke(Channels.LOGO_UPLOAD, {
            id: contact.id,
            logo: base64,
          })
          .then((response) => {
            if (response?.hasError) {
              handleErrorResponse(response.errors)
            } else {
              if (formValues.isGerant) {
                history.push('/')
                toast.success('Le paramètre société a bien été enregistré')
              } else {
                history.push('/settings/contacts')
                toast.success('Le contact a bien été enregistré')
              }
              
            }
          })
          .catch((error) => {
            console.error(error)
          })
      })
    } else {
      if (formValues.isGerant) {
        history.push('/')
        toast.success('Le paramètre société a bien été enregistré')
      } else {
        history.push('/settings/contacts')
        toast.success('Le contact a bien été enregistré')
      }
    }
    
  }

  render() {
    return (
      <FinalForm
        onSubmit={this.handleSubmit}
        initialValues={this.props.initialValues}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <div className="ui segment">
                  <SubHeader
                    title={this.props.formTitle}
                    actions={this.props.headerActions}
                  />

                  <div className="ui divider"></div>

                  <div className="bloc-contact">
                    <HeaderSeparator iconName="info">
                      Spécifications
                    </HeaderSeparator>
                    <SemanticForm.Group className="inline">
                      {/* <Field name="isGerant" type="checkbox">
                        {({ input, meta }) => (
                          <SemanticForm.Checkbox
                            {...input}
                            toggle
                            label="Gérant"
                            onChange={(event, { checked }) => {
                              this.setState({ ...this.state, logo: checked })
                              return input.onChange(checked)
                            }}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field> */}
                     {/*  <Field name="isFournisseur" type="checkbox">
                        {({ input, meta }) => (
                          <SemanticForm.Checkbox
                            {...input}
                            toggle
                            label="Fournisseur"
                            onChange={(event, { checked }) => {
                              return input.onChange(checked)
                            }}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field> */}
                      {/* <Field name="isClient" type="checkbox">
                        {({ input, meta }) => (
                          <SemanticForm.Checkbox
                            {...input}
                            toggle
                            label="Client"
                            onChange={(event, { checked }) => {
                              return input.onChange(checked)
                            }}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )} 
                      </Field>
                      {/*<Field name="isOPM_AGM" type="checkbox">*/}
                      {/*  {({ input, meta }) => (*/}
                      {/*    <SemanticForm.Checkbox*/}
                      {/*      {...input}*/}
                      {/*      toggle*/}
                      {/*      label="OPM/AGM"*/}
                      {/*      onChange={(event, { checked }) => {*/}
                      {/*        return input.onChange(checked)*/}
                      {/*      }}*/}
                      {/*      error={*/}
                      {/*        meta.error && meta.touched ? meta.error : null*/}
                      {/*      }*/}
                      {/*    />*/}
                      {/*  )}*/}
                      {/*</Field>*/}
                      <Field name="isActive" type="checkbox">
                        {({ input, meta }) => (
                          <SemanticForm.Checkbox
                            {...input}
                            toggle
                            label="Actif"
                            className={this.state.isGerant? '' : 'hidden'}
                            onChange={(event, { checked }) => {
                              return input.onChange(checked)
                            }}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field>
                    </SemanticForm.Group>

                    <SemanticForm.Group widths="equal">
                      <Field
                        name="code"
                        validate={composeValidators(
                          isRequired,
                          minLength(3),
                          maxLength(20)
                        )}
                      >
                        {({ input, meta }) => (
                          <SemanticForm.Input
                            {...input}
                            label="Code"
                            type="text"
                            placeholder="Code contact"
                            autoComplete="off"
                            readOnly={this.props.view}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field>
                      <Field
                        name="nom"
                        validate={composeValidators(isRequired, minLength(3))}
                      >
                        {({ input, meta }) => (
                          <SemanticForm.Input
                            {...input}
                            label="Nom"
                            type="text"
                            placeholder="Nom contact"
                            autoComplete="off"
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field>
                    </SemanticForm.Group>
                    <Label
                      width="3"
                      as="label"
                      className={this.state.logo ? '' : 'hidden'}
                      style={{ cursor: 'pointer' }}
                      htmlFor="file"
                      size="medium"
                    >
                      <Icon name="file" />
                      Logo facture
                    </Label>
                    <input
                      id="file"
                      name="logo"
                      hidden
                      type="file"
                      onChange={(event) => {
                        this.setState({
                          ...this.state,
                          logoFile: event.target.files[0],
                        })
                      }}
                    />
                  </div>
                  <div className="bloc-contact">
                    <HeaderSeparator iconName="building">
                      Adresse
                    </HeaderSeparator>
                    <SemanticForm.Group widths="equal">
                      <Field
                        name="adresse"
                        validate={composeValidators(
                          // isRequired,
                          minLength(3),
                          maxLength(255)
                        )}
                      >
                        {({ input, meta }) => (
                          <SemanticForm.Input
                            {...input}
                            label="Adresse"
                            type="text"
                            placeholder="1, rue de l'adresse"
                            autoComplete="off"
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field>
                      <Field
                        name="complement"
                        validate={composeValidators(maxLength(255))}
                      >
                        {({ input, meta }) => (
                          <SemanticForm.Input
                            {...input}
                            label="Complément"
                            type="text"
                            placeholder="Résidence des Compléments"
                            autoComplete="off"
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field>
                    </SemanticForm.Group>
                    <SemanticForm.Group>
                      <Field
                        name="codePostal"
                        validate={composeValidators(
                          isRequired,
                          minLength(3),
                          maxLength(255)
                        )}
                      >
                        {({ input, meta }) => (
                          <SemanticForm.Input
                            width={4}
                            {...input}
                            label="Code postal"
                            type="text"
                            placeholder="12345"
                            autoComplete="off"
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field>
                      <Field
                        name="ville"
                        validate={composeValidators(
                          isRequired,
                          minLength(3),
                          maxLength(255)
                        )}
                      >
                        {({ input, meta }) => (
                          <SemanticForm.Input
                            width={12}
                            {...input}
                            label="Ville"
                            type="text"
                            placeholder="Nom-de-ville"
                            autoComplete="off"
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field>
                    </SemanticForm.Group>
                    <SemanticForm.Group>
                      <Field
                        name="departement"
                        validate={composeValidators(
                          minLength(1),
                          maxLength(255)
                        )}
                      >
                        {({ input, meta }) => (
                          <SemanticForm.Input
                            width={6}
                            {...input}
                            label="Département"
                            type="text"
                            placeholder="Nom-de-département"
                            autoComplete="off" 
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field>
                      <Field name="paysId">
                        {({ input, meta }) => (
                          <SemanticForm.Dropdown
                            {...input}
                            placeholder="Sélectionnez un pays"
                            search
                            width={12}
                            fluid
                            selection
                            value={this.state.paysSelected}
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.pays.map((pays) => {
                              return {
                                key: pays.id,
                                value: pays.id,
                                text: pays.libelleFR,
                              }
                            })}
                            label="Pays"
                            onChange={(event, data) => {
                              this.setState({
                                paysSelected: data.value,
                              })
                              return input.onChange(data.value)
                            }}
                            //onSearchChange={this.handleSearchChangePays}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field>
                    </SemanticForm.Group>
                  </div>
                  <div className="bloc-contact">
                    <HeaderSeparator iconName="phone">
                      Coordonnées
                    </HeaderSeparator>
                    <ContactFormGeneralContact />
                  </div>
                  <div className="bloc-contact">
                    <HeaderSeparator iconName="book">
                      Administratif
                    </HeaderSeparator>
                    <ContactFormAdministratif isGerant={this.props?.initialValues?.isGerant ?? false } isTVA={this.props?.initialValues?.isTVA ?? false} />
                  </div>
                  <div className={this.props?.initialValues?.isGerant ? 'bloc-contact' : 'bloc-contact hidden'}>
                    <HeaderSeparator iconName="gavel">
                      Comptabilité
                    </HeaderSeparator>
                    <ContactFormComptabilite isGerant={this.props?.initialValues?.isGerant ?? false } isTVA={this.props?.initialValues?.isTVA ?? false} />
                  </div>
                  <FieldArray
                    name="contactInfos"
                    component={this.renderContactInfosBloc}
                  />
                </div>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default ContactForm
