import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ipcRenderer } from '../../../api/api'
import { Channels } from '../../../api/channels'
import SubHeader from '../../shared/subHeader/SubHeader'
import { Divider } from 'semantic-ui-react'
import ColisSearchForm from './ColisSearchForm'
import ColisResult from './ColisResult'
const _ = require('lodash');

class Colis extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cols: [
        {
          text: 'Numéro',
          key: 'numero',
        },
      ],
      colisStats: null,
    }
  }

  componentDidMount() {
    const { query } = this.props.statistiques
    ipcRenderer
      .invoke(Channels.BON_DE_LIVRAISON_FIND_ALL, query)
      .then((data) => {
        const colisStats = _(data.data)
          .map((bl) => {
            return _(bl.bonDeLivraisonLignes)
              .map((bll) => {
                return {
                  type: bl.type,
                  recepteurId: bl.recepteurId,
                  recepteurAsString: bl.recepteurAsString,
                  ...bll,
                }
              })
              .value()
          })
          .flatten()
          .groupBy('type')
          .mapValues((values) =>
            _.chain(values)
              .groupBy('emballage.code')
              .mapValues((item) => {
                return _.sumBy(item, 'nbColis')
              })
              .value()
          )
          .value()
        this.setState({
          colisStats,
        })
      })
  }

  onResetSearch = () => {
    const query = {
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
    }

    ipcRenderer
      .invoke(Channels.BON_DE_LIVRAISON_FIND_ALL, query)
      .then((data) => {
        const colisStats = _(data.data)
          .map((bl) => {
            return _(bl.bonDeLivraisonLignes)
              .map((bll) => {
                return {
                  type: bl.type,
                  recepteurId: bl.recepteurId,
                  recepteurAsString: bl.recepteurAsString,
                  ...bll,
                }
              })
              .value()
          })
          .flatten()
          .groupBy('type')
          .mapValues((values) =>
            _.chain(values)
              .groupBy('emballage.code')
              .mapValues((item) => {
                return _.sumBy(item, 'nbColis')
              })
              .value()
          )
          .value()
        this.setState({
          colisStats,
        })
      })
  }
  onSearch = (formValues) => {
    const query = {
      params: formValues,
    }
    ipcRenderer
      .invoke(Channels.BON_DE_LIVRAISON_FIND_ALL, query)
      .then((data) => {
        const colisStats = _(data.data)
          .map((bl) => {
            return _(bl.bonDeLivraisonLignes)
              .map((bll) => {
                return {
                  type: bl.type,
                  recepteurId: bl.recepteurId,
                  recepteurAsString: bl.recepteurAsString,
                  ...bll,
                }
              })
              .value()
          })
          .flatten()
          .groupBy('type')
          .mapValues((values) =>
            _.chain(values)
              .groupBy('emballage.code')
              .mapValues((item) => {
                return _.sumBy(item, 'nbColis')
              })
              .value()
          )
          .value()
        this.setState({
          colisStats,
        })
      })
  }

  render() {
    const { statistiques } = this.props
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader title="Statistiques Colis" />
          <Divider />
          <ColisSearchForm
            onSearch={this.onSearch}
            onResetSearch={this.onResetSearch}
            initialValues={statistiques?.query?.params}
          />

          <ColisResult
            theme={this.props.theme.visionMode}
            result={this.state.colisStats}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    contacts: {
      count: state.contacts.count,
      data: state.contacts.ids
        ? state.contacts.ids.map((id) => {
            return state.contacts.byIds[id]
          })
        : [],
      query: state.contacts.query,
    },
    statistiques: {
      query: state.statistiques.colis,
    },
    theme: state.theme,
  }
}

export default connect(mapStateToProps, {})(Colis)
