import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { parcelle } from './types'

export const fetchParcelle = (id) => {
  return async (dispatch) => {
    try {
      const payload = await ipcRenderer.invoke(Channels.PARCELLE_FIND_BY_ID, id)
      if (payload.hasError) {
        throw new Error(payload.error?.toString())
      } else {
        dispatch({
          type: parcelle.FETCH_PARCELLE,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération d'une parcelle")
    }
  }
}

export const fetchParcelles = (query) => {
  return async (dispatch) => {
    try {
      const response = await ipcRenderer.invoke(
        Channels.PARCELLE_FIND_ALL,
        query
      )
      const payload = { response, query }
      if (response.hasError) {
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: parcelle.FETCH_PARCELLES,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des parcelles")
    }
  }
}
