import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import { FieldArray } from 'react-final-form-arrays'
import BonDeLivraisonFormLigne from './BonDeLivraisonFormLigne'
import BonDeLivraisonFormTableFooter from './BonDeLivraisonFormTableFooter'
import { useForm } from 'react-final-form'
import { VisionMode } from '../../../../utils/theme'
import { updateMontantRemiseIndicative } from '../common-form-update'
import { ipcRenderer } from '../../../../api/api'
import { Channels } from '../../../../api/channels'
const _ = require('lodash');

class BonDeLivraisonFormTable extends React.Component {
  constructor(props) {
    super(props)
    const {TVA_2_1_visible, TVA_5_5_visible, TVA_10_visible, TVA_20_visible} = this.props
    this.state = {
      emballages: [],
      parcelles: [],
      totalTVA_2_1_visible: TVA_2_1_visible,
      totalTVA_5_5_visible: TVA_5_5_visible,
      totalTVA_10_visible: TVA_10_visible,
      totalTVA_20_visible: TVA_20_visible,
    }
  }

  componentDidMount() {
    ipcRenderer.invoke(Channels.EMBALLAGE_FIND_ALL, {}).then(({ data }) => {
      this.setState({
        emballages: data,
      })
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      const {TVA_2_1_visible, TVA_5_5_visible, TVA_10_visible, TVA_20_visible} = this.props
      this.setState({totalTVA_2_1_visible: TVA_2_1_visible,
        totalTVA_5_5_visible: TVA_5_5_visible, 
        totalTVA_10_visible: TVA_10_visible, 
        totalTVA_20_visible: TVA_20_visible})
    }
  }


  /**
   *
   * @param form
   */
  updateTotalRemise = (form) => {
     const allBLLines = _.flatMap(
      _.get(form.getState().values, 'bonsDeLivraisons'),
      (item) => _(item.bonDeLivraisonLignes).value()
    ) 

    const montantRemise =
      _.sumBy(allBLLines, (o) => (parseFloat(o.poidsNet)*parseFloat(o.prixUnitaireHT))*parseFloat(o.montantRemiseIndicative)/100)
  
    form.change(
      `totalMontantRemiseIndicative`,
      Number.isInteger(montantRemise) ? montantRemise : montantRemise.toFixed(2)
    )
  }

  updateTotalHT = (form) => {
    const bons = form.getState().values.bonDeLivraisonLignes
    let totalPrixHT = _.sumBy(bons, ({ totalLigneHT }) => {
      if (!isNaN(totalLigneHT)) {
        return parseFloat(parseFloat(totalLigneHT).toFixed(2))
      } else {
        return 0
      }
    })
    form.change(`totalPrixHT`, totalPrixHT.toFixed(2))
  }

  updateTotalMontantTVA= (form) => {
    const bons = form.getState().values.bonDeLivraisonLignes

    let totalHT = _.sumBy(bons, ({ totalLigneHT }) => {
      if (!isNaN(totalLigneHT)) {
        return parseFloat(parseFloat(totalLigneHT).toFixed(2))
      } else {
        return 0
      }
    })

    
    let totalTVA_2_1 = _.sumBy(bons, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 2.1) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })

    let totalTVA_5_5 = _.sumBy(bons, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 5.5) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })

    let totalTVA_10 = _.sumBy(bons, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 10) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })

    let totalTVA_20 = _.sumBy(bons, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 20) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })
    
    let totalTTC = _.sumBy(bons, ({ totalLigneTTC }) => {
      if (!isNaN(totalLigneTTC)) {
        return parseFloat(parseFloat(totalLigneTTC).toFixed(2))
      } else {
        return 0
      }
    })

    this.setState({totalTVA_2_1_visible: totalTVA_2_1 != 0,
      totalTVA_5_5_visible: totalTVA_5_5!=0, 
      totalTVA_10_visible: totalTVA_10!=0, 
      totalTVA_20_visible: totalTVA_20!=0})

    form.change(`totalTVA`, (totalTTC - totalHT).toFixed(2))
    form.change(`totalTVA_2_1`, (totalTVA_2_1).toFixed(2))
    form.change(`totalTVA_5_5`, (totalTVA_5_5).toFixed(2))
    form.change(`totalTVA_10`, (totalTVA_10).toFixed(2))
    form.change(`totalTVA_20`, (totalTVA_20).toFixed(2))
  }

  updateTotalTTC = (form) => {
    const bons = form.getState().values.bonDeLivraisonLignes
    let totalLigneTTC = _.sumBy(bons, ({ totalLigneTTC }) => {
      if (!isNaN(totalLigneTTC)) {
        return parseFloat(parseFloat(totalLigneTTC).toFixed(2))
      } else {
        return 0
      }
    })
    form.change(`totalTTC`, totalLigneTTC.toFixed(2))
  }

  render() {
    const { view, theme } = this.props

    return (
      //aurore: mémo : ici prise en compte de la largeur de toutes les colonnes
      <Table celled compact inverted={theme.visionMode === VisionMode.AMELIORE}>
        <Table.Header>
          <Table.Row>
           {/*  <Table.HeaderCell width={1}>Colis</Table.HeaderCell> */}
            <Table.HeaderCell width={2}>Code</Table.HeaderCell>
            <Table.HeaderCell width={5} colSpan="6">Désignation</Table.HeaderCell>
            {/*<Table.HeaderCell width={1}>CAT</Table.HeaderCell>*/}
            <Table.HeaderCell width={1}>Quantité</Table.HeaderCell>
            {/*<Table.HeaderCell width={1}>Tare unit</Table.HeaderCell>*/}
            {/*<Table.HeaderCell width={1}>Pds net</Table.HeaderCell>*/}
            <Table.HeaderCell width={1}>Prix unitaire</Table.HeaderCell>
            <Table.HeaderCell width={1}>Taux Remise{/* (ind)*/}</Table.HeaderCell>
            <Table.HeaderCell width={2}>Total H.T.</Table.HeaderCell>
            <Table.HeaderCell width={1}>Taux TVA</Table.HeaderCell>
            {/*<Table.HeaderCell width={1}>Parcelle</Table.HeaderCell>*/}
            {/*<Table.HeaderCell width={1}>Emb</Table.HeaderCell>*/}
            <Table.HeaderCell width={2}>Total T.T.C</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <FieldArray name="bonDeLivraisonLignes">
          {({ fields, meta }) => (
            <Fragment>
              <Table.Body>
                {fields.map((name, index) => {
                  const form = useForm()
                  return (
                    <BonDeLivraisonFormLigne
                      key={index}
                      name={name}
                      index={index}
                      meta={meta}
                      view={view}
                      emballages={this.state.emballages}
                      onDelete={() => {
                        fields.remove(index)
                        this.updateTotalHT(form)
                        updateMontantRemiseIndicative(form)
                        this.updateTotalMontantTVA(form)
                        this.updateTotalTTC(form)
                      }}
                      onUpdateTVA={(totalTVA_2_1_visible, totalTVA_5_5_visible, totalTVA_10_visible, totalTVA_20_visible)=>{
                        this.setState({totalTVA_2_1_visible: totalTVA_2_1_visible,
                          totalTVA_5_5_visible: totalTVA_5_5_visible, 
                          totalTVA_10_visible: totalTVA_10_visible, 
                          totalTVA_20_visible: totalTVA_20_visible})
                      }}
                    />
                  )
                })}
              </Table.Body>
              <BonDeLivraisonFormTableFooter
                fields={fields}
                view={view}
                emballages={this.state.emballages}
                totalTVA_2_1_visible = {this.state.totalTVA_2_1_visible}
                totalTVA_5_5_visible = {this.state.totalTVA_5_5_visible}
                totalTVA_10_visible = {this.state.totalTVA_10_visible}
                totalTVA_20_visible = {this.state.totalTVA_20_visible}
              />
            </Fragment>
          )}
        </FieldArray>
      </Table>
    )
  }
}

export default connect(
  ({ theme }) => ({ theme }),
  null
)(BonDeLivraisonFormTable)
