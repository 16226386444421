import React, { Fragment } from 'react'
import BonDeLivraisonForm from './BonDeLivraisonForm'
import { history } from '../../utils/history'
import { DocType } from '../../utils/enum-divers'
import { Button } from 'semantic-ui-react'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { handleErrorResponse } from '../../utils/handle-error-response'
import moment from 'moment'
import CustomModal from '../shared/modal/CustomModal'
import EmailModal from '../shared/modal/EmailModal'
const _ = require('lodash');

class BonDeLivraisonCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultEmetteur: null,
      submitBtn: null,
      showModalPrint: false,
      id: 0,
      showModalMail: false,
      numBl: '',
      defaultEmail: '',
    }
  }
  componentDidMount() {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_ALL, {
        params: { isGerant: true },
        order: { nom: 'ASC' },
        take: 1,
      })
      .then(({ data }) => {
        if (data?.length > 0) {
          const [contact] = data
          const adresseAsString = `${contact.nom} \n${
            contact.complement ? contact.complement : ''
          }\n${contact.adresse}\n${contact.codePostal} ${contact.ville}\n${
            contact.pays.libelleFR
          }`
          this.setState({
            defaultEmetteur: {
              id: contact.id,
              asString: adresseAsString,
            },
          })
        }
      })
  }

  modalActionsMail() {
    const { type } = this.props.match.params

    return (
      <Fragment>
        <button className="ui button teal left labeled icon" type="submit">
          <i className="mail icon"></i>
          Envoyer
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalMail: false,
            })
            history.push(`/bons-de-livraison/${type}`)
            toast.success(
              `Le bon de livraison ${this.state.numBl} a bien été créé`
            )
          }}
        >
          Fermer
        </button>
      </Fragment>
    )
  }

  emailSubmit = (email, objet, message) => {
    const { type } = this.props?.match?.params

    ipcRenderer
      .invoke(Channels.EMAIL_SEND, {
        email,
        objet,
        message,
        typeDocument: 'Bl',
        idDocument: this.state.id,
        numero: this.state.numBl,
      })
      .then((response) => {
        if (response.error) {
          handleErrorResponse(response.error)
        } else {
          history.push(`/bons-de-livraison/${type}`)
          toast.success(
            `Le bon de livraison ${this.state.numBl} a bien été créé et l'email a bien été envoyé`
          )
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  getRecepteurEmail = (id) => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_BY_ID, id)
      .then((response) => {
        this.setState({
          defaultEmail: response.email,
        })
      })
      .catch((error) => {
        toast.error(error)
      })
  }
  //RAF : Fonction "imprimer" : à ce jour, le pdf s'enregistre dans documents Storage et ne s'ouvre pas directement.
  modalActionsPrint(id) {
    const { type } = this.props.match.params

    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalPrint: false,
              })
          ipcRenderer
            .invoke(Channels.BON_DE_LIVRAISON_PRINT, {
              data: id,
              options: { printParcelles: true },
              })
              .then((response) => {
                console.log('API Response:', response);
                const pdfPath = response.filePath;
                if (pdfPath) {
                  window.open(pdfPath);  
                  toast.success(`Le bon de livraison a bien été créé`);
                } else {
                  toast.error('Le chemin du PDF est manquant ou invalide.');
                }
              })
            .catch((error) => {
              console.error(error)
              toast.error('Erreur lors de la création du bon de livraison.');
            })
          }}
        >
          <i className="checkmark icon" />
          Impression PDF
        </button>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalPrint: false,
            })
            console.log('Annulation de l\'impression du BL avec id ' + id) 
          }
          }
        >
          Non
        </button>
      </Fragment>
    )
  }

  getHeaderActions = () => {
    const { type } = this.props?.match?.params
    return (
      <Fragment>
        <Button
          icon="save"
          type="submit"
          content="Enregistrer"
          color="teal"
          onClick={() => {
            this.setState({ ...this.state, submitBtn: 'enregistrer' })
          }}
        />
        <Button
          icon="print"
          type="submit"
          content="Imprimer"
          color="green"
          onClick={() => {
            this.setState({ ...this.state, submitBtn: 'imprimer' })
          }}
        />
        <Button
          icon="mail"
          type="submit"
          content="Email"
          color="blue"
          onClick={() =>
            this.setState({
              submitBtn: 'email',
            })
          }
        />
        <Button
          icon="file alternate outline"
          type="submit"
          content="Facturer"
          color="red"
          onClick={() => {
            this.setState({ ...this.state, submitBtn: 'facturer' })
          }}
        />
        <Button
          icon="reply"
          type="button"
          content="Retour à la liste"
          color="black"
          onClick={() => history.push(`/bons-de-livraison/${type}`)}
        />
      </Fragment>
    )
  }
  onFormSubmit = (formValues) => {
    const { type } = this.props.match.params
    if (!formValues.date) {
      toast.error('Veuillez renseigner une date correcte')
      return
    }
    ipcRenderer
      .invoke(Channels.BON_DE_LIVRAISON_CREATE, formValues)
      .then((response) => {
        if (response.hasError) {
          handleErrorResponse(response.errors)
        } else {
          if (this.state.submitBtn == 'facturer'){
            //calcul de la quantité totale
            const lignes = formValues.bonDeLivraisonLignes
            let quantite = _.sumBy(lignes, ({ poidsNet }) => {
              if (!isNaN(poidsNet)) {
                return parseFloat(parseFloat(poidsNet).toFixed(2))
              } else {
                return 0
              }
            })
            // Construction de la facture à partir du BL
            let facture = { isComptabilise:false,
                            isImprime: false,
                            date: response.date, // RAF today
                            dateEcheance : moment(response.date).add(30, 'days'),
                            emetteurId: response.emetteurId,
                            emetteurAsString:response.emetteurAsString,
                            recepteurId: response.recepteurId,
                            recepteurAsString : response.recepteurAsString,
                            type: response.type,
                            mentions: response.mentions,
                            bonsDeLivraisons: [{
                              id: response.id,
                              numero: response.numero,
                              bonDeLivraisonLignes: response.bonDeLivraisonLignes
                            }],
                            totalHTAvantRemise: response.totalPrixHT + response.totalMontantRemiseIndicative,
                            montantRemise: response.totalMontantRemiseIndicative,
                            totalHTApresRemise: response.totalPrixHT,
                            totalTVA_2_1: response.totalTVA_2_1,
                            totalTVA_5_5: response.totalTVA_5_5,
                            totalTVA_10: response.totalTVA_10,
                            totalTVA_20: response.totalTVA_20,
                            montantTVA: response.totalTVA,
                            totalTTC: response.totalTTC,
                            poidsNet: quantite,
                            dateDeReglement: null,
                            dateEncaissement: null,
                            totalCotisation: 0,
                            tauxDeTVA: 0,
                            remise: 0,
                          }
            ipcRenderer
            .invoke(Channels.FACTURE_CREATE, facture)
            .then((response) => {
              if (response.hasError) {
                handleErrorResponse(response.errors)
              } else {
                  history.push(`/bons-de-livraison/${type}`)
                  toast.success(`La facture ${response.numero} a bien été créée`)
              }
            })
          }
          else if (this.state.submitBtn == 'imprimer') {
            this.setState({ id: response.id }, () => {
              this.setState({ showModalPrint: true })
            })
          } else if (this.state.submitBtn == 'email') {
            this.getRecepteurEmail(response.recepteurId)
            this.setState(
              {
                id: response.id,
                numBl: response.numero,
              },
              () =>
                this.setState({
                  showModalMail: true,
                })
            )
          } else {
            history.push(`/bons-de-livraison/${type}`)
            toast.success(
              `Le bon de livraison ${response.numero} a bien été créé`
            )
          }
        }
      })
      .catch((error) => {
        console.log(error)
        handleErrorResponse(error)
      })
  }

  render() {
    const { type } = this.props.match.params
    const { defaultEmetteur, defaultEmail, id, numBl } = this.state
    let actualDate = new Date()
    const initialValue = {
      type: type === DocType.ACHAT ? DocType.ACHAT : DocType.VENTE,
      isFactureDone: false,
      montantRemiseIndicative: 0,
      date: moment(actualDate, 'DD-MM-YYYY'),
    }
    if (defaultEmetteur) {
      initialValue.emetteurId = defaultEmetteur.id
      initialValue.emetteurAsString = defaultEmetteur.asString
    }
      return (
      <Fragment>
        <BonDeLivraisonForm
          /* formTitle={`Créer un bon de livraison - ${type === DocType.ACHAT ? 'Achats' : 'Ventes'} `} */
          formTitle={`Créer un bon de livraison`}
          type={type === DocType.ACHAT ? DocType.ACHAT : DocType.VENTE}
          headerActions={this.getHeaderActions()}
          onSubmit={this.onFormSubmit}
          initialValues={initialValue}
        />
        <EmailModal
          title="Envoi du bon de livraison par mail"
          visible={this.state.showModalMail}
          actions={this.modalActionsMail()}
          id={id}
          numero={numBl}
          email={defaultEmail}
          typeDocument="Bl"
          onSubmit={this.emailSubmit}
        />
        <CustomModal
          title="Imprimer"
          content="Imprimer le Bon de livraison"
          visible={this.state.showModalPrint}
          actions={this.modalActionsPrint(this.state.id)}
        />
      </Fragment>
    )
  }
}

export default BonDeLivraisonCreate
