import { bonDeLivraison, famille } from '../actions/types'
const _ = require('lodash');

export default (
  state = {
    bonDeLivraison: null,
    byIds: {},
    count: 0,
    ids: [],
    query: {
      skip: 0,
      take: 10,
      params: {},
      order: { id: 'ASC' },
    },
    queryBLAchat: {
      skip: 0,
      take: 10,
      params: {
        isFactureDone: false,
        type: 'ACHAT',
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: { id: 'ASC' },
      type: 'ACHAT',
    },
    queryBLVente: {
      skip: 0,
      take: 10,
      params: {
        isFactureDone: false,
        type: 'VENTE',
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: { id: 'ASC' },
    },
  },
  action
) => {
  switch (action.type) {
    case bonDeLivraison.FETCH_BON_DE_LIVRAISON:
      return { ...state, bonDeLivraison: action.payload }
    case bonDeLivraison.FETCH_BONS_DE_LIVRAISON:
      const blState = {
        byIds: _.mapKeys(action.payload.response.data, 'id'),
        count: action.payload.response.count,
        ids: _.map(action.payload.response.data, 'id'),
        query: action.payload.query,
        queryBLAchat:
          action.payload.query.type?.params?.type === 'ACHAT'
            ? action.payload.query
            : state.queryBLAchat,
        queryBLVente:
          action.payload.query?.params?.type === 'VENTE'
            ? action.payload.query
            : state.queryBLVente,
      }
      return {
        ...state,
        ...blState,
      }
    default:
      return state
  }
}
