import React, { Component, Fragment } from 'react'
import { history } from '../../utils/history'
import { connect } from 'react-redux'
import FamilleForm from './FamilleForm'
import { Button } from 'semantic-ui-react'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { handleErrorResponse } from '../../utils/handle-error-response'
import { toast } from 'react-toastify'

class FamilleCreate extends Component {
  constructor(props) {
    super(props)
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button icon="save" type="submit" content="Enregistrer" color="teal" />
        <Button
          icon="reply"
          type="submit"
          content="Retour à la liste"
          color="black"
          onClick={() => {
            history.push('/settings/familles')
          }}
        />
      </Fragment>
    )
  }

  onSubmit = (formValues) => {
    /* formValues.montant = formValues.montant
      ? parseFloat(formValues.montant)
      : null
    formValues.diviseur = formValues.diviseur
      ? parseFloat(formValues.diviseur)
      : null
    formValues.champsCalcul = formValues.champsCalcul
      ? formValues.champsCalcul
      : null */
    ipcRenderer
      .invoke(Channels.FAMILLE_CREATE, formValues)
      .then((response) => {
        if (response.hasError) {
          handleErrorResponse(response.errors)
        } else {
          history.push('/settings/familles')
          toast.success('La famille a bien été créée')
        }
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  render() {
    return (
      <FamilleForm
        onSubmit={this.onSubmit}
        headerActions={this.getHeaderActions()}
        formTitle={'Créer une famille'}
      />
    )
  }
}

export default connect(null, null)(FamilleCreate)
