import React from 'react'
import { composeValidators, isRequired } from '../../../../utils/validators'
import { Field, useForm } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import { ipcRenderer } from '../../../../api/api'
import { Channels } from '../../../../api/channels'
import { updateGlobal } from '../common-form-update'
const _ = require('lodash');

export class SelectBonDeLivraison extends React.Component {
  componentDidMount = async () => {
    if (this.props.avoir) {
      // Fetch all BLs
      const bl = await this.getBonDeLivraison(this.data.value) // CJ ajout de this
      // Add Famille to Facture
      const familles = _.flatMap(
        bl.bonDeLivraisonLignes,
        (item) => item.produit.variete.famille
      )

      // Populate la dropdown
      this.populateBonDeLivraison(this.props.fields.value[this.props.index], bl)
      //CJ
     /*  await updateGlobal(mainForm)
      this.props.onChangeBLInvoice(mainForm) */
    }
  }
  /**
   *
   * @param currentForm
   * @returns {*}
   */
  getAvailableBLs = (currentForm) => {
    let bonsSelected = currentForm.getState()?.values?.bonsDeLivraisons
    let bonsAll = this.props?.availableBLs

    // On conserve que les non facturés
    bonsAll = bonsAll?.filter(
      (bon) => !bon.isFactureDone || bon.factureId == this.props.idFacture
    )

    return bonsAll?.reduce((results, item) => {
      // Désactive les bons de livraison ayant déjà été sélectionnés
      const bonsDisabled = bonsSelected?.some((bl) => bl.id === item.id)

      results.push({
        key: item.numero,
        text: item.numero,
        value: item.id,
        disabled: bonsDisabled,
      })
      return results
    }, [])
  }
  /**
   *
   * @param id
   * @returns {Promise<any>}
   */
  getBonDeLivraison = async (id) => {
    return await ipcRenderer.invoke(Channels.BON_DE_LIVRAISON_FIND_BY_ID, id)
  }
  /**
   *
   * @param fieldValue
   * @param bl
   */
  populateBonDeLivraison = (fieldValue, bl) => {
    fieldValue.numero = bl.numero
    fieldValue.bonDeLivraisonLignes = bl.bonDeLivraisonLignes
  }
  render() {
    return (
      <Field name={this.props.name} validate={composeValidators(isRequired)}>
        {({ input, meta }) => {
          const mainForm = useForm()
          return (
            <SemanticForm.Select
              {...input}
              selectOnBlur={false}
              label="Bon de livraison"
              onChange={async (event, data) => {
                // Fetch all BLs
                const bl = await this.getBonDeLivraison(data.value)
                // Add Famille to Facture
                const familles = _.flatMap(
                  bl.bonDeLivraisonLignes,
                  (item) => item.produit.variete.famille
                )

                // Populate la dropdown
                this.populateBonDeLivraison(
                  this.props.fields.value[this.props.index],
                  bl
                )
                await updateGlobal(mainForm)
                const allBLLines = _.flatMap(
                  _.get(mainForm.getState().values, 'bonsDeLivraisons'),
                  (item) => _(item.bonDeLivraisonLignes).value()
                )
                 let totalTVA_2_1 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
                  if (!isNaN(totalLigneHT) && tauxDeTVA == 2.1) {
                    return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
                  } else {
                    return 0
                  }
                })
            
                let totalTVA_5_5 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
                  if (!isNaN(totalLigneHT) && tauxDeTVA == 5.5) {
                    return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
                  } else {
                    return 0
                  }
                })
            
                let totalTVA_10 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
                  if (!isNaN(totalLigneHT) && tauxDeTVA == 10) {
                    return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
                  } else {
                    return 0
                  }
                })
            
                let totalTVA_20 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
                  if (!isNaN(totalLigneHT) && tauxDeTVA == 20) {
                    return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
                  } else {
                    return 0
                  }
                })
              
                 // mise a jour des lignes visible en fonction de la valeur
                 this.props.onUpdateTVA(totalTVA_2_1!= 0, totalTVA_5_5 != 0, totalTVA_10 != 0, totalTVA_20 != 0) 



                this.props.onChangeBLInvoice(mainForm)

                return input.onChange(data.value)
              }}
              options={this.getAvailableBLs(mainForm)}
              error={meta.error && meta.touched ? meta.error : null}
            />
          )
        }}
      </Field>
    )
  }
}
