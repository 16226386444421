import React, { Component, Fragment } from 'react'
import { Field, Form as FinalForm, useForm } from 'react-final-form'
import {
  Button,
  Container,
  Form as SemanticForm,
  Segment,
} from 'semantic-ui-react'
import { onSearchBLWithFilter } from '../../redux/actions/prodouane-action'
import { connect } from 'react-redux'
import arrayMutators from 'final-form-arrays'
import SubHeader from '../shared/subHeader/SubHeader'
import { history } from '../../utils/history'
import ProdouaneFormLigne from './ProdouaneFormLigne'
import ProdouaneFormSearchForm from './ProdouaneFormSearchForm'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'

export class ProdouaneForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      view: false,
      referencePeriod: null,
      blFiltered: [],
      query: {
        params: {
          type: 'VENTE',
          emetteurCEE: true,
          hasNoProdouaneID: true,
        },
      },
    }
  }

  componentDidMount(prevProps, prevState, snapshot) {
    if (
      this.props.initialValues?.referencePeriod &&
      this.props.initialValues.edit
    ) {
      this.loadBlForReferencePeriod(
        this.props.initialValues.referencePeriod
      ).then(({ data }) => {
        data = data.map((item) => {
          item.totalSansRemise = item.totalPrixHT - item.montantRemiseIndicative
          return item
        })
        this.props.initialValues.bonDeLivraisons =
          this.props.initialValues.bonDeLivraisons.map((item) => {
            item.totalSansRemise =
              item.totalPrixHT - item.montantRemiseIndicative
            return item
          })
        this.setState({
          blFiltered: [...data, ...this.props.initialValues.bonDeLivraisons],
        })
      })
    }
  }

  headerActions = () => {
    return (
      <Fragment>
        <Button icon="save" type="submit" content="Enregistrer" color="teal" />
        <Button
          icon="reply"
          type="button"
          content="Retour à la liste"
          color="black"
          onClick={() => {
            history.push(`/prodouane`)
          }}
        />
      </Fragment>
    )
  }

  loadBlForReferencePeriod = (referencePeriod, edit = false) => {
    const fromDate = new Date(
      referencePeriod.getFullYear(),
      referencePeriod.getMonth(),
      1
    )
    const toDate = new Date(
      referencePeriod.getFullYear(),
      referencePeriod.getMonth() + 1,
      0,
      23,
      59,
      59
    )
    const hasNoProdouaneID = !edit
    const params = {
      ...this.state.query.params,
      fromDate,
      toDate,
      hasNoProdouaneID,
    }

    return ipcRenderer.invoke(Channels.BON_DE_LIVRAISON_FIND_ALL, {
      params,
    })
  }

  onSubmit = (formValues) => {
    this.props.onFormSubmit(formValues)
  }
  onSearchByPeriod = (form, referencePeriod) => {
    const { edit } = this.props.initialValues
    this.loadBlForReferencePeriod(referencePeriod, edit).then(({ data }) => {
      data = data?.map((item) => {
        item.totalSansRemise = item.totalPrixHT - item.montantRemiseIndicative
        return item
      })
      form.change(`bonDeLivraisons`, data)
      this.setState({ blFiltered: data })
    })
  }

  render() {
    return (
      <Container fluid>
        <FinalForm
          onSubmit={this.onSubmit}
          initialValues={this.props.initialValues}
          mutators={{
            ...arrayMutators,
          }}
          render={({ handleSubmit, form, values }) => {
            return (
              <SemanticForm onSubmit={handleSubmit} size="massive">
                <Segment>
                  <SubHeader
                    title={this.props.title}
                    actions={this.headerActions()}
                  />

                  <ProdouaneFormSearchForm
                    onSearchByPeriod={(referencePeriod) =>
                      this.onSearchByPeriod(form, referencePeriod)
                    }
                    edit={this.props.initialValues?.edit}
                  />

                  <div className="ui divider" />

                  {<ProdouaneFormLigne blFiltered={this.state.blFiltered} />}
                </Segment>
              </SemanticForm>
            )
          }}
        />
      </Container>
    )
  }
}
