import React from 'react'
import { Route } from 'react-router-dom'
import {
  actionTitlePage,
  actionBtnPage,
  actionShowHideModalEdit,
  actionShowHideModalDelete,
} from '../../../redux/actions/autres'
import { connect } from 'react-redux'

class CustomRoute extends Route {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // Save dans le store du titre de la page
    this.props.actionTitlePage(this.props.title)

    // Visibilité des modal à false dans le store
    this.props.actionShowHideModalEdit(false)
    this.props.actionShowHideModalDelete(false)

    // Save dans le store la liste des btns de la page concernée
    this.props.actionBtnPage(this.props.btns)
  }

  componentDidUpdate(prevProps) {
    // Si le titre a changé (nécessaire pour éviter de checker en perma et causer une erreur de maxDeep)
    if (this.props.title !== prevProps.title) {
      // On le met à jour dans le store
      this.props.actionTitlePage(this.props.title)
    }

    // Si l'array des boutons a changée
    if (this.props.btns !== prevProps.btns) {
      // On le met à jour dans le store
      this.props.actionBtnPage(this.props.btns)
    }
  }

  render() {
    // Title de l'appli corrélé au store
    document.title = this.props.autres.titlePage

    const { ...state } = this.props

    // Affichage dans le content du composant cible
    return <Route {...state} />
  }
}

const mapStateToProps = (state) => {
  return {
    autres: state.autres,
  }
}

export default connect(mapStateToProps, {
  actionTitlePage,
  actionBtnPage,
  actionShowHideModalEdit,
  actionShowHideModalDelete,
})(CustomRoute)
