import { avoir } from '../actions/types'
const _ = require('lodash');

export default (
  state = {
    avoir: null,
    byIds: {},
    count: 0,
    ids: [],
    query: {
      skip: 0,
      take: 10,
      params: {},
      order: {},
    },
  },
  action
) => {
  switch (action.type) {
    case avoir.FETCH_AVOIR:
      return { ...state, avoir: action.payload }
    case avoir.FETCH_AVOIRS:
      const avoirsState = {
        byIds: _.mapKeys(action.payload.response.data, 'id'),
        count: action.payload.response.count,
        ids: _.map(action.payload.response.data, 'id'),
        query: action.payload.query,
      }
      return {
        ...state,
        ...avoirsState,
      }
    default:
      return state
  }
}
