import React, { Component, Fragment } from 'react'
import { history } from '../../utils/history'
import { connect } from 'react-redux'
import AvoirForm from './AvoirForm'
import { Button } from 'semantic-ui-react'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { fetchAvoir } from '../../redux/actions/avoir-action'
import CustomModal from '../shared/modal/CustomModal'
import { toast } from 'react-toastify'
import { handleErrorResponse } from '../../utils/handle-error-response'
import EmailModal from '../shared/modal/EmailModal'
import moment from 'moment/moment'
const _ = require('lodash');

class AvoirEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModalDelete: false,
      showModalCancel: false,
      btnSubmit: null,
      showModalMail: false,
      defaultEmail: '',
    }
  }

  componentDidMount() {
    this.props.fetchAvoir(this.props.match.params.id)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.avoir !== prevProps.avoir) {
      this.getRecepteurEmail(this.props.avoir?.facture.recepteurId)
    }
  }

  modalActionsMail() {
    return (
      <Fragment>
        <button className="ui button teal left labeled icon" type="submit">
          <i className="mail icon"></i>
          Envoyer
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalMail: false,
            })
            history.push(`/avoirs`)
            toast.success(
              `L'avoir ${this.state.numAvoir} a bien été créé mais pas de email envoyé`
            )
          }}
        >
          Fermer
        </button>
      </Fragment>
    )
  }

  emailSubmit = (email, objet, message) => {
    ipcRenderer
      .invoke(Channels.EMAIL_SEND, {
        email,
        objet,
        message,
        typeDocument: 'Avoir',
        idDocument: this.state.avoirId,
        numero: this.state.numAvoir,
      })
      .then((response) => {
        if (response.error) {
          handleErrorResponse(response.error)
        } else {
          history.push(`/avoirs`)
          toast.success(
            `L'avoir ${this.state.numAvoir} a bien été créé et l'email a bien été envoyé`
          )
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <button
          type="button"
          className="ui button red left labeled icon"
          disabled={this.props.avoir?.isComptabilise}
          onClick={() => {
            this.setState({
              showModalDelete: true,
            })
          }}
        >
          <i className="trash icon"></i>
          Supprimer
        </button>
        <Button
          icon="save"
          type="submit"
          content="Enregistrer"
          onClick={() => {
            this.setState({
              btnSubmit: 'enregistrer',
            })
          }}
          color="teal"
        />
        <Button
          icon="print"
          type="submit"
          content="Imprimer"
          color="green"
          onClick={() => {
            this.setState({
              btnSubmit: 'imprimer',
            })
          }}
        />
        <Button
          icon="mail"
          type="submit"
          content="Email"
          color="blue"
          onClick={() =>
            this.setState({
              btnSubmit: 'email',
            })
          }
        />
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: true,
            })
          }}
        >
          Annuler
        </button>
      </Fragment>
    )
  }

  modalActionsCancel() {
    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
            history.push('/avoirs')
          }}
        >
          <i className="checkmark icon"></i>
          Oui
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
          }}
        >
          Non
        </button>
      </Fragment>
    )
  }

  modalActionsDelete() {
    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalDelete: false,
            })
            ipcRenderer
              .invoke(Channels.AVOIR_DELETE, this.props.match.params.id)
              .then((response) => {
                if (response.hasError) {
                  handleErrorResponse(response.errors)
                } else {
                  history.push('/avoirs')
                  toast.success(`L'avoir a bien été suprrimé`)
                }
              })
          }}
        >
          <i className="trash icon"></i>
          Oui
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalDelete: false,
            })
          }}
        >
          Non
        </button>
      </Fragment>
    )
  }

  onSubmit = (formValues) => {
    if (!formValues.avoirLignes){
      toast.error(
        "Aucune ligne dans l'avoir, impossible d'enregistrer les modifications"
       
      )
      return
    }
    formValues.quantiteRetournee = 0

    formValues.avoirLignes.map((ligne) => {
      ligne.montantRemiseIndicative =
        (ligne.montantRemiseIndicative != null) ? ligne.montantRemiseIndicative : 0
      formValues.quantiteRetournee += parseFloat(ligne.poidsNet)
    })

    formValues.tauxDeTVA = null

    formValues.montant =
    formValues.montant != null
      ? parseFloat(
          formValues.montant
        )
      : null

  formValues.montantTVA =
    (formValues.montantTVA != null)
      ? parseFloat(formValues.montantTVA)
      : null

   formValues.totalTTC =
   formValues.totalTTC != null
      ? parseFloat(formValues.totalTTC)
      : null 

      console.log("formValues")
      console.log(formValues)
      
    delete formValues.totalHTApresRemise
    delete formValues.totalHTAvantRemise
    delete formValues.montantRemise

    ipcRenderer
      .invoke(Channels.AVOIR_UPDATE, {
        id: this.props.match.params.id,
        avoir: formValues,
      })
      .then((response) => {
        if (response.hasError) {
          handleErrorResponse(response.errors)
        } else {
          if (this.state.btnSubmit == 'imprimer') {
            ipcRenderer
              .invoke(Channels.AVOIR_PRINT, response.id)
              .then((response) => {
                const pdfPath = response.filePath;
               if (pdfPath) {
                 window.open(pdfPath);
                 history.push(`/avoirs`)
                 toast.success(`L'avoir est prêt à être imprimé`);
               } else {
                 toast.error("Le chemin du PDF est manquant ou invalide.");
               }
             })
               .catch((error) => {
                 console.error(error)
                 toast.error("Erreur lors de l'impression de l'avoir.");
               })            
          } else if (this.state.btnSubmit == 'email') {
            this.getRecepteurEmail(this.props.avoir.facture.recepteurId)
            this.setState(
              {
                avoirId: response.id,
                numAvoir: response.numero,
              },
              () =>
                this.setState({
                  showModalMail: true,
                })
            )
          } else {
            history.push('/avoirs')
          }
        }
      })
  }

  getRecepteurEmail = (id) => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_BY_ID, id)
      .then((response) => {
        this.setState({
          defaultEmail: response.email,
        })
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  render() {
    const { avoir } = this.props
    const { avoirId, numAvoir, defaultEmail } = this.state

    return avoir ?(
      <Fragment>
        <AvoirForm
          onSubmit={this.onSubmit}
          headerActions={this.getHeaderActions()}
          formTitle={`Editer l'avoir : ${this.props.avoir?.numero}`}
          initialValues={avoir}
          view={false}
          isEdit={true}
        />
        <EmailModal
          title="Envoi de l'avoir par mail"
          visible={this.state.showModalMail}
          actions={this.modalActionsMail()}
          id={avoirId}
          numero={numAvoir}
          email={defaultEmail}
          typeDocument="Avoir"
          onSubmit={this.emailSubmit}
        />

        <CustomModal
          title="Annuler"
          content="Quitter et annuler les modifications en cours ?"
          visible={this.state.showModalCancel}
          actions={this.modalActionsCancel()}
        />
        <CustomModal
          title="Supprimer"
          content="Supprimer l'avoir ?"
          visible={this.state.showModalDelete}
          actions={this.modalActionsDelete()}
        />
      </Fragment>
    ) : (
      <div>Chargement</div>
    )
  }
}

const mapStateToProps = (state) => {
  return { avoir: state.avoirs.avoir, theme: state.theme }
}

export default connect(mapStateToProps, { fetchAvoir })(AvoirEdit)
