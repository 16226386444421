import React from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import DatePicker from 'react-datepicker'
import {
  composeValidators,
  isRequired,
  maxLength,
  minLength,
} from '../../utils/validators'
import { Channels } from '../../api/channels'
import { ipcRenderer } from '../../api/api'
import { DocType } from '../../utils/enum-divers'
const _ = require('lodash');

class FactureTransporteurForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bonsDeLivraison: [],
      bonsDeLivraisonSelected: [],
    }
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (prevProps !== this.props && this.props) {
      if (this.props?.initialValues?.bonsDeLivraison) {
        const { bonsDeLivraison } = this.props.initialValues
        ipcRenderer
          .invoke(Channels.BON_DE_LIVRAISON_FIND_ALL, {
            params: {
              type: DocType.VENTE,
              //hasNoFATransporteur: true, // Filtre plus bas car pas d'initials values sinon, les sélected seraient comparées à rien
              fromDate: new Date(new Date().getFullYear(), 0, 1),
              toDate: new Date(new Date().getFullYear(), 11, 31),
            },
          })
          .then(({ data }) => {
            this.setState({
              bonsDeLivraison: data,
              bonsDeLivraisonSelected: bonsDeLivraison?.map((b) => b.id) ?? [], // return array of ids selected
            })
          })
      }
    }
  }

  handleSearchChangeBons = (e) => {
    if (e.target.value && e.target.value.length > 0) {
      const query = {
        params: {
          libelle: e.target.value,
          type: DocType.VENTE,
          //hasNoFATransporteur: true, // Filtre plus bas car pas d'initials values sinon, les sélected seraient comparées à rien
          fromDate: new Date(new Date().getFullYear(), 0, 1),
          toDate: new Date(new Date().getFullYear(), 11, 31),
        },
      }

      ipcRenderer
        .invoke(Channels.BON_DE_LIVRAISON_FIND_ALL, query)
        .then(({ data }) => {
          const { bonsDeLivraison, bonsDeLivraisonSelected } = this.state
          this.setState({
            bonsDeLivraison: [
              ...bonsDeLivraison.filter(
                (v) => bonsDeLivraisonSelected.indexOf(v.id) >= 0
              ),
              ...data.filter((v) => bonsDeLivraisonSelected.indexOf(v.id) < 0),
            ],
          })
        })
    }
  }

  render() {
    return (
      <FinalForm
        onSubmit={this.props.onSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui segment">
                <SubHeader
                  title={this.props.formTitle}
                  actions={this.props.headerActions}
                />
                <div className="ui divider" />
                <Field name={`type`}>
                  {({ input }) => <input {...input} type="hidden" />}
                </Field>

                <SemanticForm.Group widths="equal">
                  <Field
                    name="numero"
                    validate={composeValidators(
                      isRequired,
                      minLength(2),
                      maxLength(255)
                    )}
                  >
                    {({ input, meta }) => (
                      <SemanticForm.Input
                        {...input}
                        type="text"
                        label="Numéro"
                        placeholder="Numéro"
                        
                        error={meta.error && meta.touched ? meta.error : null}
                      />
                    )}
                  </Field>
                  <Field
                    name="societeTransport"
                    validate={composeValidators(minLength(2), maxLength(255))}
                  >
                    {({ input, meta }) => (
                      <SemanticForm.Input
                        {...input}
                        type="text"
                        placeholder="Société Transport"
                        label="Société Transport"
                        error={meta.error && meta.touched ? meta.error : null}
                      />
                    )}
                  </Field>

                  <Field name="date">
                    {({ input, meta }) => (
                      <SemanticForm.Field>
                        <label>Date </label>
                        <DatePicker
                          {...input.value}
                          locale="fr"
                          selected={input.value ? input.value : null}
                          dateFormat="dd/MM/yyyy"
                          showYearDropdown
                          value={new Date()}
                          onSelect={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(
                              date.setHours(
                                (-1 * date.getTimezoneOffset()) / 60
                              )
                            )
                            input.onChange(dateFmt)
                          }}
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      </SemanticForm.Field>
                    )}
                  </Field>
                </SemanticForm.Group>

                <Field name="bonsDeLivraison" validate={isRequired}>
                  {({ input, meta }) => (
                    <SemanticForm.Field>
                      <SemanticForm.Dropdown
                        {...input}
                        placeholder="Sélectionnez des bons"
                        required={true}
                        search
                        multiple
                        fluid
                        selection
                        value={this.state.bonsDeLivraisonSelected}
                        onBlur={(e) => e.preventDefault()}
                        // Dans la dropdown, on veut les BLs non liés à d'autres transporteurs, et les selected en edit
                        options={this.state.bonsDeLivraison
                          ?.filter(
                            (bon) =>
                              bon.factureTransporteurId == null ||
                              this.state.bonsDeLivraisonSelected.find(
                                (x) => x === bon.id
                              ) > 0
                          )
                          .map((bon) => {
                            return {
                              key: bon.id,
                              value: bon.id,
                              text: bon.numero + ' - ' + bon.recepteurAsString,
                            }
                          })}
                        label="Bons de livraison"
                        onChange={(event, data) => {
                          this.setState({
                            bonsDeLivraisonSelected: data.value,
                          })
                          return input.onChange(data.value)
                        }}
                        onSearchChange={this.handleSearchChangeBons}
                        error={meta.error && meta.touched ? meta.error : null}
                      />
                    </SemanticForm.Field>
                  )}
                </Field>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default FactureTransporteurForm
