import React, { Component, Fragment } from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import { ipcRenderer } from '../../api/api'
import {
  composeValidators,
  minLength,
  isRequired,
} from '../../utils/validators'
import { Channels } from '../../api/channels'
import './CompteComptableForm.css'

class CompteComptableForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      produit: [],
    }
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
  }

  handleSearchChange = (e) => {
    if (e.target.value && e.target.value.length > 1) {
      const query = {
        params: {
          libelle: e.target.value,
        },
      }
      ipcRenderer.invoke(Channels.PRODUIT_FIND_ALL, query).then(({ data }) => {
        this.setState({
          produit: data,
        })
      })
    }
  }

  render() {
    return (
      <FinalForm
        onSubmit={this.props.onSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <div className="ui segment">
                  <SubHeader
                    title={this.props.formTitle}
                    actions={this.props.headerActions}
                  />
                  <div className="ui divider"></div>
                  <SemanticForm.Group widths="equal">
                    <Field
                      name="numero"
                      validate={composeValidators(isRequired, minLength(1))}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Numéro"
                          readOnly={this.props.view}
                          type="text"
                          placeholder="ex: Ac12"
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                    <Field
                      name="intitule"
                      validate={composeValidators(isRequired, minLength(1))}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Intitulé"
                          type="text"
                          placeholder="ex: Ac12"
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                  </SemanticForm.Group>
                </div>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default CompteComptableForm
