import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Card, Feed, Grid, Label, Statistic, Table } from 'semantic-ui-react'
import { fetchBonsDeLivraison } from '../../redux/actions/bon-de-livraison-action'
class BonDeLivraisonRecap extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let query = this.props.bonsDeLivraison.query
    query = {
      ...query,
      params: {
        fromDate: moment().startOf('year').toDate(),
        toDate: moment().add(7, 'days').startOf('day').toDate(),
          isFactureDone: 0,
          type: 'VENTE',
      },
      order: {
        date: 'ASC',
      },
      }
    this.props.fetchBonsDeLivraison(query)
  }

  renderTableRecap() {
    const { bonsDeLivraison } = this.props
    const lignes = bonsDeLivraison.data.map((bl, i) => {
      return (
        <Table.Row key={i}>
          <Table.Cell>{bl.numero}</Table.Cell>
          <Table.Cell>{bl.type}</Table.Cell>
          <Table.Cell>{bl.totalPrixHT} € </Table.Cell>
        </Table.Row>
      )
    })
    return lignes
  }

  render() {
    const { bonsDeLivraison } = this.props
    //console.log(bonsDeLivraison)
    const nbEnAttente = bonsDeLivraison.count
    const ligneBL = []
    for (var i = 0; i < nbEnAttente; i++) {
      ligneBL.push(`<Grid.Row divided>
                      <Grid.Column width={4}>${bonsDeLivraison?.data?.[i]?.numero}</Grid.Column>
                      <Grid.Column width={12}>${bonsDeLivraison?.data?.[i]?.n}</Grid.Column>
                    </Grid.Row>`)
    }
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>Bons de Livraison</Card.Header>
        </Card.Content>
        <Card.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Statistic size="tiny">
                  <Statistic.Label>ATTENTE</Statistic.Label>
                  <Statistic.Value>{nbEnAttente}</Statistic.Value>
                </Statistic>
              </Grid.Column>
              <Grid.Column width={12}>
                bon{nbEnAttente > 1 ? 's' : ''} de livraison en attente de
                facturation
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Table basic="very" celled>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell>N°</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Prix</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderTableRecap()}</Table.Body>
          </Table>
        </Card.Content>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    bonsDeLivraison: {
      count: state.bonsDeLivraison.count,
      data: state.bonsDeLivraison.ids
        ? state.bonsDeLivraison.ids.map((id) => {
            return state.bonsDeLivraison.byIds[id]
          })
        : [],
      query: state.bonsDeLivraison.query,
    },
  }
}

export default connect(mapStateToProps, { fetchBonsDeLivraison })(
  BonDeLivraisonRecap
)
