import { emballage, famille, produit } from '../actions/types'
const _ = require('lodash');

export default (
  state = {
    emballage: null,
    byIds: {},
    count: 0,
    ids: [],
    query: {
      skip: 0,
      take: 10,
      params: {},
      order: { id: 'ASC' },
    },
  },
  action
) => {
  switch (action.type) {
    case emballage.FETCH_EMBALLAGE:
      return { ...state, emballage: action.payload }
    case emballage.FETCH_EMBALLAGES:
      const emballagesState = {
        byIds: _.mapKeys(action.payload.response.data, 'id'),
        count: action.payload.response.count,
        ids: _.map(action.payload.response.data, 'id'),
        query: action.payload.query,
      }
      return {
        ...state,
        ...emballagesState,
      }
    default:
      return state
  }
}
