import React, { Component, Fragment } from 'react'
import SubHeader from '../shared/subHeader/SubHeader'
import { history } from '../../utils/history'
import ProduitForm from './ProduitForm'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { fetchProduit } from '../../redux/actions/produit-action'
import CustomModal from '../shared/modal/CustomModal'
import { handleErrorResponse } from '../../utils/handle-error-response'
import { toast } from 'react-toastify'
const _ = require('lodash');

class ProduitEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModalDelete: false,
      showModalCancel: false,
    }
  }

  componentDidMount() {
    this.props.fetchProduit(this.props.match.params.id)
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button icon="save" type="submit" content="Enregistrer" color="teal" />
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: true,
            })
          }}
        >
          Annuler
        </button>
      </Fragment>
    )
  }
  modalActionsCancel() {
    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
            history.push('/settings/produits')
          }}
        >
          <i className="checkmark icon"></i>
          Oui
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
          }}
        >
          Non
        </button>
      </Fragment>
    )
  }

  onSubmit = (formValues) => {
    ipcRenderer
      .invoke(Channels.PRODUIT_UPDATE, {
        id: this.props.match.params.id,
        produit: formValues,
      })
      .then((response) => {
        if (response.hasError) {
          handleErrorResponse(response.errors)
        } else {
          history.push('/settings/produits')
          toast.success('Le produit a bien été modifié')
        }
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  render() {
    const { produit } = this.props
    const initialValues = _.omit(produit, ['BonDeLivraisonLignes', 'Avoirs'])
    return (
      <Fragment>
        <ProduitForm
          onSubmit={this.onSubmit}
          headerActions={this.getHeaderActions()}
          view={true}
          formTitle={`Editer le produit ${
            produit ? produit.libelle : 'inconnu'
          }`}
          initialValues={initialValues}
        />
        <CustomModal
          title="Annuler"
          content="Quitter et annuler les modifications en cours ?"
          visible={this.state.showModalCancel}
          actions={this.modalActionsCancel()}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { produit: state.produits.produit, theme: state.theme }
}

export default connect(mapStateToProps, { fetchProduit })(ProduitEdit)
