import React from 'react'
import { ipcRenderer } from '../../../api/api'
const _ = require('lodash');


export class ExportCsv extends React.Component {
  constructor(props) {
    super(props)
  }

  downloadCSV = async (data, filename) => {
    let csvContent = this.toCSV(data);
    csvContent = this.props.keys.join(';') + '\r\n' + csvContent;
    const element = document.createElement('a');
    const file = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    element.setAttribute('download', filename);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  getDataToConvert = async (channel, query) => {
    return await ipcRenderer.invoke(channel, _.omit(query, ['skip', 'take']))
  }

  cellFormat = (cellContent) => {
    let value = null
    if (cellContent === null || cellContent === undefined) {
      value = ''
    } else {
      switch (typeof cellContent) {
        case 'string':
          value = cellContent.replace(/\n/g, ' ').replace(/\s+/g, ' ').trim()
          break
        case 'number':
          value = cellContent.toFixed(2)
          break
        case 'object':
          value = this.objectFormater(cellContent)
          break
        case 'boolean':
          value = this.booleanFormater(cellContent)
          break
        default:
          value = cellContent
          break
      }
    }
    return value
  }

  booleanFormater = (boolean) => {
    return boolean ? 'Oui' : 'Non'
  }

  objectFormater = (object) => {
    if (object && object.getMonth) {
      return `${object.getDate()}/${object.getMonth()}/${object.getFullYear()}`
    }
    return Object.entries(object)
      .map(([k, v]) => `${k}: ${this.cellFormat(v)}`)
      .join(', ')
  }

  toCSV = (data) => {
    // Les stats Achats/ventes ont un sous-objet {raws, total}. On accéde à raws.
    if (this.props.statsAchatsVentes) data = data?.raws

    // Les stats Clients/fournisseurs ont un sous-array d'objets [{produits, contact}, {...}]
    if (this.props.statsClientsFournisseurs) {
      data = _.chain(data)
        // Map pour return un tableau de tableaux "produits"
        .map((value, key) =>
          value.produits
            // Ajout manuellement de la moyenne au sein de chaque produit
            .map((element) => {
              return {
                ...element,
                average: (element?.montantHT / element?.poidsNet)?.toFixed(2),
              }
            })
        )
        .flatten() // flat pour combiner les tableaux de produits en 1 seul
        .value() // nécessaire avec le _chain lodash
    }
    //console.log(data)

    let csvContent = ''
    data?.forEach((item) => {
      const keys = this.props.keys
      const values = []
      for (var k of keys) {
        values.push(this.cellFormat(_.get(item, k)))
      }

      csvContent += values.join(';') + '\r\n'
    })
    return csvContent
  }

  render() {
    return (
      <button
        className="ui button blue"
        onClick={async () => {
          const { query, channel, fileName } = this.props
          const { data } = await this.getDataToConvert(channel, query)
          this.downloadCSV(data, fileName)
        }}
        type="button"
      >
        {/* <i className="icon folder" /> */}
        CSV
      </button>
    )
  }
}
