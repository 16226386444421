import { ipcRenderer } from '../../../api/api'
import { Channels } from '../../../api/channels'
const _ = require('lodash');

const updateTotalCotisation = async (form) => {
  let totalCotisation = 0
  const { recepteurId, type } = form.getState().values
  if (recepteurId) {
    const recepteur = await ipcRenderer.invoke(
      Channels.CONTACT_FIND_BY_ID,
      recepteurId
    )
    if (recepteur && !recepteur.isOPM_AGM && type === 'ACHAT') {
      // Get all bll id
      const ids = _.flatMap(
        _.get(form.getState().values, 'bonsDeLivraisons'),
        (item) => _(item.bonDeLivraisonLignes).value()
      ).map((bll) => bll.id)

      const { data } = await ipcRenderer.invoke(
        Channels.BON_DE_LIVRAISON_LIGNE_FIND_ALL,
        { params: { ids } }
      )

      data.forEach((item) => {
        totalCotisation += calculateBLLigneCotisation(item)
      })
    }
  }

  form.change(`totalCotisation`, totalCotisation.toFixed(2))
}

const calculateBLLigneCotisation = (ligne) => {
  const { champsCalcul, diviseur, montant } = ligne.produit?.variete?.famille
  if (champsCalcul && diviseur && montant && ligne[champsCalcul]) {
    return (ligne[champsCalcul] / diviseur) * montant
  } else {
    return 0.0
  }
}

const updateTotalHTAvantRemise = (form) => {
  let allBLLines = null
  if(_.get(form.getState().values, 'bonsDeLivraisons')){
    allBLLines = _.flatMap(
      _.get(form.getState().values, 'bonsDeLivraisons'),
      (item) => _(item.bonDeLivraisonLignes).value()
  )}
  else{
    allBLLines = _.get(form.getState().values, 'avoirLignes')
  }
  const totalLigneHT =
    _.sumBy(allBLLines, (o) => parseFloat(o.poidsNet)* parseFloat(o.prixUnitaireHT))
  form.change(
    `totalHTAvantRemise`,
    Number.isInteger(totalLigneHT) ? totalLigneHT : totalLigneHT.toFixed(2)
  )
}

const updateMontantRemise = (form) => {
  let allBLLines = null
  if(_.get(form.getState().values, 'bonsDeLivraisons')){
    allBLLines = _.flatMap(
      _.get(form.getState().values, 'bonsDeLivraisons'),
      (item) => _(item.bonDeLivraisonLignes).value()
  )}
  else{
    allBLLines = _.get(form.getState().values, 'avoirLignes')
  }
    const montantRemise =
      _.sumBy(allBLLines, (o) => (parseFloat(o.poidsNet)*parseFloat(o.prixUnitaireHT)*parseFloat(o.montantRemiseIndicative)/100))

  form.change(
    `montantRemise`,
    Number.isInteger(montantRemise) ? montantRemise : montantRemise.toFixed(2)
  )
}

const updateTotalHTApresRemise = (form) => {
  console.log("updateTotalHTApresRemise")
  console.log(form.getState().values)
  let allBLLines = null
  if(_.get(form.getState().values, 'bonsDeLivraisons')){
    allBLLines = _.flatMap(
      _.get(form.getState().values, 'bonsDeLivraisons'),
      (item) => _(item.bonDeLivraisonLignes).value()
  )}
  else{
    allBLLines = _.get(form.getState().values, 'avoirLignes')
  }
  console.log(allBLLines)
  const totalHTApresRemise =
    _.sumBy(allBLLines, (o) => parseFloat(o.totalLigneHT))

  form.change(
    `totalHTApresRemise`,
    Number.isInteger(totalHTApresRemise)
      ? totalHTApresRemise
      : totalHTApresRemise.toFixed(2)
  )

  form.change(
    `montant`,
    Number.isInteger(totalHTApresRemise)
      ? totalHTApresRemise
      : totalHTApresRemise.toFixed(2)
  )
  console.log(totalHTApresRemise)
}

const updateMontantTVA = (form) => {
  let allBLLines = null
  if(_.get(form.getState().values, 'bonsDeLivraisons')){
    allBLLines = _.flatMap(
      _.get(form.getState().values, 'bonsDeLivraisons'),
      (item) => _(item.bonDeLivraisonLignes).value()
  )}
  else{
    allBLLines = _.get(form.getState().values, 'avoirLignes')
  }
  const montantTVA =
    _.sumBy(allBLLines, (o) => parseFloat(o.totalLigneHT)*o.tauxDeTVA/100)

    let totalTVA_2_1 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 2.1) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })

    let totalTVA_5_5 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 5.5) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })

    let totalTVA_10 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 10) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })

    let totalTVA_20 = _.sumBy(allBLLines, ({ totalLigneHT, tauxDeTVA }) => {
      if (!isNaN(totalLigneHT) && tauxDeTVA == 20) {
        return parseFloat(parseFloat(totalLigneHT*tauxDeTVA/100).toFixed(2))
      } else {
        return 0
      }
    })

  form.change(
    `montantTVA`,
    Number.isInteger(montantTVA) ? montantTVA : montantTVA.toFixed(2)
  )
  form.change(
    `totalTVA_2_1`,
    Number.isInteger(totalTVA_2_1) ? totalTVA_2_1 : totalTVA_2_1.toFixed(2)
  )
  form.change(
    `totalTVA_5_5`,
    Number.isInteger(totalTVA_5_5) ? totalTVA_5_5 : totalTVA_5_5.toFixed(2)
  )
  form.change(
    `totalTVA_10`,
    Number.isInteger(totalTVA_10) ? totalTVA_10 : totalTVA_10.toFixed(2)
  )
  form.change(
    `totalTVA_20`,
    Number.isInteger(totalTVA_20) ? totalTVA_20 : totalTVA_20.toFixed(2)
  )

  


}

const updateTotalTTC = (form) => {
  const { totalHTApresRemise, montantTVA = '0' } = form.getState().values
  const totalTTC = parseFloat(totalHTApresRemise) + parseFloat(montantTVA)

  form.change(
    `totalTTC`,
    Number.isInteger(totalTTC) ? totalTTC : totalTTC.toFixed(2)
  )
}

const updateTotalPoidsNet = (form) => {
  let allBLLines = null
  if(_.get(form.getState().values, 'bonsDeLivraisons')){
    allBLLines = _.flatMap(
      _.get(form.getState().values, 'bonsDeLivraisons'),
      (item) => _(item.bonDeLivraisonLignes).value()
  )}
  else{
    allBLLines = _.get(form.getState().values, 'avoirLignes')
  }
  const totalPoidsNet = _.sumBy(allBLLines, (o) => parseFloat(o.poidsNet))
  const totalColis = _.sumBy(allBLLines, (o) => parseFloat(o.nbColis))
  form.change(
    `poidsNet`,
    Number.isInteger(totalPoidsNet) ? totalPoidsNet : totalPoidsNet.toFixed(2)
  )
  form.change(
    `quantiteRetournee`,
    Number.isInteger(totalPoidsNet) ? totalPoidsNet : totalPoidsNet.toFixed(2)
  )
  
  form.change(`colis`, totalColis)
}

const updateGlobal = async (form) => {
  await updateTotalCotisation(form)
  updateTotalPoidsNet(form)
  updateTotalHTAvantRemise(form)
  updateMontantRemise(form)
  updateTotalHTApresRemise(form)
  updateMontantTVA(form)
  //updateTotalMontantTVA(form)
  updateTotalTTC(form)
}

export {
  updateTotalCotisation,
  updateTotalHTAvantRemise,
  updateMontantRemise,
  updateTotalHTApresRemise,
  updateMontantTVA,
  updateTotalTTC,
  updateTotalPoidsNet,
  updateGlobal,
}
