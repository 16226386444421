import React, { Fragment } from 'react'
import { DocType } from '../../utils/enum-divers'
import { Button, Container, Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { history } from '../../utils/history'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { fetchFacture } from '../../redux/actions/facture-action'
import { Field } from 'react-final-form'
import FactureForm from './FactureForm'
import EmailModal from '../shared/modal/EmailModal'
import { toast } from 'react-toastify'
import { handleErrorResponse } from '../../utils/handle-error-response'
const _ = require('lodash');

class FactureView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModalMail: false,
      defaultEmail: '',
    }
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button
          icon="print"
          type="button"
          content="Imprimer"
          color="teal"
          onClick={() => {
            const { id } = this.props.match.params
            ipcRenderer
              .invoke(Channels.FACTURE_PRINT, id)
              .then((response) => {
                const pdfPath = response.filePath;
                if (pdfPath) {
                  window.open(pdfPath); 
                  history.push(`/factures/${this.props.facture.type}`);
                  toast.success(`La facture ${response.numero} a bien été générée.`);
                } else {
                  toast.error("Le chemin du PDF est manquant ou invalide.");
                }
              })
              .catch((error) => {
                console.error(error)
                toast.error("Erreur lors de l'impression de la facture.");
              })
          }}
        />
        <Button
          icon="mail"
          type="submit"
          content="Email"
          color="blue"
          onClick={() => {
            this.setState({
              showModalMail: true,
            })
          }}
        />
        <Button
          icon="reply"
          type="button"
          content="Retour à la liste"
          color="black"
          onClick={() => {
            history.push(`/factures/${this.props.facture.type}`)
          }}
        />
      </Fragment>
    )
  }

  getRecepteurEmail = (id) => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_BY_ID, id)
      .then((response) => {
        this.setState({
          defaultEmail: response.email,
        })
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  emailSubmit = (email, objet, message) => {
    ipcRenderer
      .invoke(Channels.EMAIL_SEND, {
        email,
        objet,
        message,
        typeDocument: 'Facture',
        idDocument: this.props.facture.id,
        numero: this.props.facture.numero,
      })
      .then((response) => {
        if (response.error) {
          handleErrorResponse(response.error)
        } else {
          history.push(`/factures/${this.props.facture.type}`)
          toast.success(
            `La facture ${this.props.facture.numero} a bien été envoyée par mail`
          )
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }
  modalActionsMail() {
    return (
      <Fragment>
        <button className="ui button teal left labeled icon" type="submit">
          <i className="mail icon"></i>
          Envoyer
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalMail: false,
            })
          }}
        >
          Fermer
        </button>
      </Fragment>
    )
  }
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.fetchFacture(id)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.facture !== prevProps.facture) {
      this.getRecepteurEmail(this.props.facture?.recepteurId)
    }
  }

  render() {
    const { facture } = this.props
    const initialValues = facture
    return initialValues ? (
      <>
        <FactureForm
          /* formTitle={`Consultation d'une facture ${initialValues.type === DocType.ACHAT ? 'achats' : 'ventes'} `} */
          formTitle={`Consultation d'une facture`}
          type={
            initialValues.type === DocType.ACHAT ? DocType.ACHAT : DocType.VENTE
          }
          view={true}
          consultation={true}
          headerActions={this.getHeaderActions()}
          initialValues={initialValues}
          onSubmit={() => {
            //console.log('No submit : View page')
          }}
        />
        <EmailModal
          title="Envoi facture par mail"
          visible={this.state.showModalMail}
          actions={this.modalActionsMail()}
          id={initialValues?.id}
          numero={initialValues?.numero}
          email={this.state.defaultEmail}
          typeDocument="Facture"
          onSubmit={this.emailSubmit}
        />
      </>
    ) : (
      <div>Chargement</div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    facture: state.factures.facture,
    theme: state.theme,
  }
}

export default connect(mapStateToProps, { fetchFacture })(FactureView)
