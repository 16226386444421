import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { mention } from './types'

export const fetchMention = (id) => {
  return async (dispatch) => {
    try {
      const payload = await ipcRenderer.invoke(Channels.MENTION_FIND_BY_ID, id)
      if (payload.hasError) {
        throw new Error(payload.error?.toString())
      } else {
        dispatch({
          type: mention.FETCH_MENTION,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération d'une mention")
    }
  }
}

export const fetchMentions = (query) => {
  return async (dispatch) => {
    try {
       const response = await ipcRenderer.invoke(
        Channels.MENTION_FIND_ALL,
        query
      ) 
        const payload = { response, query }
        if (response.hasError) {
          throw new Error(response.error?.toString())
        } else {
          dispatch({
            type: mention.FETCH_MENTIONS,
            payload: payload,
          })
        }
    }catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des mentions")
    }
  }
}
