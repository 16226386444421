import React, { Component, Fragment } from 'react'
import SubHeader from '../shared/subHeader/SubHeader'
import { history } from '../../utils/history'
import { connect } from 'react-redux'
import { fetchProduits } from '../../redux/actions/produit-action'
import DataTables from '../shared/datatable/Datatable'
import Pagination from '../shared/datatable/Pagination'
import ProduitSearchForm from './ProduitSearchForm'
import ExportPdf from '../shared/exportcsv/export-pdf'
import { ExportCsv } from '../shared/exportcsv/export-csv'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
const _ = require('lodash');

class Produit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cols: [
        {
          text: 'Code',
          key: 'code',
        },
        {
          text: 'Libellé',
          key: 'libelle',
        },
        {
          text: 'Prix HT',
          key: 'prixHT',
        },
        {
          text: 'TVA',
          key: 'tauxDeTVA',
        },
        {
          text: 'Actif ?',
          key: 'isActive',
        },
      ],
    }
  }

  componentDidMount() {
    this.props.fetchProduits(this.props.produits.query)
  }
  /**
   * The actions to show in the right column
   * @param {*} row
   * @returns
   */
  getActionRow = (row) => {
    if (!row.id) {
      return null
    }
    return (
      <Fragment>
        <button
          className="ui button blue"
          type="button"
          onClick={() => {
            history.push(`/settings/produits/view/${row.id}`)
          }}
        >
          <i className="icon eye" />
          Consulter
        </button>
        <button
          className="ui button olive"
          type="button"
          onClick={() => {
            history.push(`/settings/produits/edit/${row.id}`)
          }}
        >
          <i className="icon pencil" />
          Editer
        </button>
      </Fragment>
    )
  }

  getDataForExport = async () => {
    const { query } = this.props.produits
    const { data } = await ipcRenderer.invoke(
      Channels.PRODUIT_FIND_ALL,
      _.omit(query, ['skip', 'take'])
    )

    return data
  }

  async generateFileName() {
    const { query } = this.props.produits
    const { params } = query
    //console.log(params)
    let filename = 'Produits'
    filename += `${params.code ? '_CODE_' + params.code : ''}`
    filename += `${
      params.codeVariete ? '_NOMENCLATURE_' + params.codeVariete : ''
    }`
    filename += `${params.codeFamille ? '_FAM_' + params.codeFamille : ''}`
    return filename
  }
  getHeaderActions = () => {
    return (
      <Fragment>
        <button
          className="ui button teal"
          onClick={() => {
            history.push('/settings/produits/create')
          }}
          type="button"
        >
          <i className="icon plus" />
          Ajouter
        </button>
        <ExportCsv
          query={this.props.produits.query}
          channel={Channels.PRODUIT_FIND_ALL}
          fileName = 'Produits.csv'
          keys={['code', 'libelle', 'prixHT', /* 'stock', */ 'isActive']}
        />
        <ExportPdf
          filenameGeneratorFunc={async () => await this.generateFileName()}
          csvData={this.getDataForExport}
          headers={this.state.cols}
        />
      </Fragment>
    )
  }
  /**
   * Action on page click
   * @param {*} pageInfo
   */
  onPageClick = (pageInfo) => {
    let query = this.props.produits.query
    query.skip = (pageInfo.currentPage - 1) * query.take
    this.props.fetchProduits(query)
  }

  onSubmitSearch = (formValues) => {
    const query = this.props.produits.query
    query.params = formValues
    query.skip = 0
    query.take = 10
    this.props.fetchProduits(query)
  }
  onResetSearch = () => {
    const query = this.props.produits.query
    query.params = {}
    this.props.fetchProduits(query)
  }
  /**
   * Action on column click
   * @param {*} col
   */
  onColumnClick = (col) => {
    let { query } = this.props.produits
    // Si on tri déjà par ce champs
    if (query.order[col]) {
      // Si le tri est déjà en ASC, on switch en DESC
      if (query.order[col] === 'ASC') {
        query.order[col] = 'DESC'
        // Sinon ASC
      } else {
        query.order[col] = 'ASC'
      }
    } else {
      query.order = { [col]: 'ASC' }
    }
    this.props.fetchProduits(query)
  }

  render() {
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title={'Liste des produits'}
            actions={this.getHeaderActions()}
          />

          <div className="ui divider"></div>
          <div className="dt-top-container">
            <div className="content">
              <ProduitSearchForm
                onSubmit={this.onSubmitSearch}
                initialValues={this.props.produits.query.params}
                onResetSearch={this.onResetSearch}
              />
            </div>
          </div>
          <div className="content">
            <DataTables
              columns={this.state.cols}
              rows={this.props.produits.data}
              onColumnClick={this.onColumnClick}
              actionRow={this.getActionRow}
              query={this.props.produits.query}
            />
            <Pagination
              onPageClick={this.onPageClick}
              itemPerPage={this.props.produits.query.take}
              pageNeighbours={1}
              totalItems={this.props.produits.count}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    produits: {
      count: state.produits.count,
      data: state.produits.ids
        ? state.produits.ids.map((id) => {
            return state.produits.byIds[id]
          })
        : [],
      query: state.produits.query,
    },
  }
}

export default connect(mapStateToProps, { fetchProduits })(Produit)
