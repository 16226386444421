import React, { Component, Fragment } from 'react'
import { Button, Form as SemanticForm } from 'semantic-ui-react'
import { Field, Form as FinalForm } from 'react-final-form'
const _ = require('lodash');

class ContactSearchForm extends Component {
  constructor(props) {
    super(props)
  }

  onSubmit = (formValues) => {
    const valuesFormatted = _.omit(formValues, [
      'isClient',
      'isFournisseur',
      'isGerant',
      'isActive',
    ])

    if (formValues.isClient) {
      valuesFormatted.isClient = formValues.isClient
    }
    if (formValues.isFournisseur) {
      valuesFormatted.isFournisseur = formValues.isFournisseur
    }
    if (formValues.isGerant) {
      valuesFormatted.isGerant = formValues.isGerant
    }
    if (formValues.isActive) {
      valuesFormatted.isActive = formValues.isActive
    }
    this.props.onSubmit(valuesFormatted)
  }

  render() {
    return (
      <FinalForm
        onSubmit={this.onSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit, form }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <SemanticForm.Group className="inline">
                  <Field name="code">
                    {({ input, meta }) => (
                      <SemanticForm.Input
                        {...input}
                        label="Code"
                        type="text"
                        placeholder="Filtrer par code"
                        autoComplete="off" 
                      />
                    )}
                  </Field>
                    <Field name="isActive" type="checkbox">
                    {({ input, meta }) => (
                      <SemanticForm.Checkbox
                        {...input}
                        toggle
                        label="Actifs seulement"
                        onChange={(event, { checked }) => {
                          return input.onChange(checked)
                        }}
                        error={meta.error && meta.touched ? meta.error : null}
                      />
                    )}
                    </Field>
                 {/*  <Field name="isClient" type="checkbox">
                    {({ input, meta }) => (
                      <SemanticForm.Checkbox
                        {...input}
                        toggle
                        label="Client"
                        onChange={(event, { checked }) => {
                          return input.onChange(checked)
                        }}
                        error={meta.error && meta.touched ? meta.error : null}
                      />
                    )}
                  </Field> */}
                {/*   <Field name="isFournisseur" type="checkbox">
                    {({ input, meta }) => (
                      <SemanticForm.Checkbox
                        {...input}
                        toggle
                        label="Fournisseur"
                        onChange={(event, { checked }) => {
                          return input.onChange(checked)
                        }}
                        error={meta.error && meta.touched ? meta.error : null}
                      />
                    )}
                  </Field> */}
                  <Button type="submit">
                    <i className="icon search" />
                    Rechercher
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      // Reset the form
                      form.reset()
                      this.props.onResetSearch()
                    }}
                  >
                    <i className="icon eraser" />
                    Effacer
                  </Button>
                </SemanticForm.Group>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default ContactSearchForm
