import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { emballage, produit } from './types'

export const fetchEmballage = (id) => {
  return async (dispatch) => {
    try {
      const payload = await ipcRenderer.invoke(
        Channels.EMBALLAGE_FIND_BY_ID,
        id
      )
      if (payload.hasError) {
        throw new Error(payload.error?.toString())
      } else {
        dispatch({
          type: emballage.FETCH_EMBALLAGE,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération d'un emballage")
    }
  }
}

export const fetchEmballages = (query) => {
  return async (dispatch) => {
    try {
      const response = await ipcRenderer.invoke(
        Channels.EMBALLAGE_FIND_ALL,
        query
      )
      const payload = { response, query }
      if (response.hasError) {
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: emballage.FETCH_EMBALLAGES,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des emballages")
    }
  }
}
