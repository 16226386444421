import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Button, Label } from 'semantic-ui-react'
import { fetchFamille } from '../../redux/actions/famille-action'
import SubHeader from '../shared/subHeader/SubHeader'
import { Table } from 'semantic-ui-react'
import { VisionMode } from '../../utils/theme'
import { history } from '../../utils/history'
const _ = require('lodash');

class FamilleView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchFamille(this.props.match.params.id)
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button
          icon="reply"
          type="submit"
          content="Retour"
          color="black"
          onClick={() => {
            history.push('/settings/familles')
          }}
        />
      </Fragment>
    )
  }

 /*  renderCotisation = (cotisation) => {
    return (
      <Fragment>
        <Table.Row>
          <Table.Cell>Code</Table.Cell>
          <Table.Cell>{cotisation.code}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Libellé</Table.Cell>
          <Table.Cell>{cotisation.libelle}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Cotisation champs calcul</Table.Cell>
          <Table.Cell>{cotisation.champsCalcul}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Cotisation diviseur</Table.Cell>
          <Table.Cell>{cotisation.diviseur}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Cotisation montant</Table.Cell>
          <Table.Cell>{cotisation.montant}</Table.Cell>
        </Table.Row>
      </Fragment>
    )
  } */

  render() {
    const { famille } = this.props
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title={`Famille ${this.props.famille?.libelle}`}
            actions={this.getHeaderActions()}
          />
          <div className="ui divider"></div>
          <div className="dt-top-container">
            <div className="content">
              <Table
                definition
                className={`${
                  this.props.theme.visionMode === VisionMode.AMELIORE
                    ? 'inverted'
                    : ''
                }`}
              >
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Code</Table.Cell>
                    <Table.Cell>{famille?.code}</Table.Cell>
                  </Table.Row>
                 {/*  <Table.Row>
                    <Table.Cell>Nomenclature</Table.Cell>
                    <Table.Cell>{famille?.nomenclature}</Table.Cell>
                  </Table.Row> */}
                  <Table.Row>
                    <Table.Cell>Libellé</Table.Cell>
                    <Table.Cell>{famille?.libelle}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Catégories liées</Table.Cell>
                    <Table.Cell>
                      {famille?.varietes
                        ? _.map(famille.varietes, (variete, i) => {
                            return (
                              <Label
                                size="medium"
                                as="a"
                                color="black"
                                key={i}
                                className="label-famille"
                              >
                                {variete.code} - {variete.libelle}
                              </Label>
                            )
                          })
                        : 'Aucune catégorie liée'}
                    </Table.Cell>
                  </Table.Row>
                  {/* <Table.Row>
                    <Table.Cell>Cotisation champs calcul</Table.Cell>
                    <Table.Cell>
                      {famille?.champsCalcul
                        ? famille?.champsCalcul
                        : 'Aucun champs calcul'}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Cotisation diviseur</Table.Cell>
                    <Table.Cell>
                      {famille?.diviseur ? famille?.diviseur : 'Aucun diviseur'}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Cotisation montant</Table.Cell>
                    <Table.Cell>
                      {famille?.montant
                        ? famille?.montant + ' €'
                        : 'Aucun diviseur'}
                    </Table.Cell>
                  </Table.Row> */}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return { famille: state.familles.famille, theme: state.theme }
}

export default connect(mapStateToProps, { fetchFamille })(FamilleView)
