import React from 'react'
import { Card } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { fetchTraites } from '../../redux/actions/traite-action'
import TraiteRecapFeed from './partials/TraiteRecapFeed'
import moment from 'moment'
class TraiteRecap extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    let query = this.props.traites.query
    query = {
      ...query,
      params: {
        fromDate: moment().startOf('year').toDate(),
        toDate: moment().endOf('year').toDate(),
        isPayee: false,
      },
      order: {
        dateEcheance: 'ASC',
      },
    }
    this.props.fetchTraites(query)
  }

  onMoreClick = () => {
    //this.props.traites.query
  }

  render() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>Traites à venir</Card.Header>
        </Card.Content>
        <Card.Content>
          {
            <TraiteRecapFeed
              traites={this.props.traites?.data?.filter((tr) => !tr.isPayee)}
              onMoreClick={this.onMoreClick}
            />
          }
        </Card.Content>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    traites: {
      count: state.traites.count,
      data: state.traites.ids
        ? state.traites.ids.map((id) => {
            return state.traites.byIds[id]
          })
        : [],
      query: state.traites.query,
    },
  }
}

export default connect(mapStateToProps, { fetchTraites })(TraiteRecap)
