import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Button, Label } from 'semantic-ui-react'
import { fetchFamille } from '../../redux/actions/famille-action'
import SubHeader from '../shared/subHeader/SubHeader'
import { Table } from 'semantic-ui-react'
import { VisionMode } from '../../utils/theme'
import { history } from '../../utils/history'
import { fetchVariete } from '../../redux/actions/variete-action'
const _ = require('lodash');

class VarieteView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchVariete(this.props.match.params.id)
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button
          icon="reply"
          type="submit"
          content="Retour"
          color="black"
          onClick={() => {
            history.push('/settings/varietes')
          }}
        />
      </Fragment>
    )
  }

  render() {
    const { variete } = this.props
    //console.log(variete)
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title={`Catégorie ${this.props.variete?.libelle}`}
            actions={this.getHeaderActions()}
          />
          <div className="ui divider"></div>
          <div className="dt-top-container">
            <div className="content">
              <Table
                definition
                className={`${
                  this.props.theme.visionMode === VisionMode.AMELIORE
                    ? 'inverted'
                    : ''
                }`}
              >
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Code</Table.Cell>
                    <Table.Cell>{variete?.code}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Libellé</Table.Cell>
                    <Table.Cell>{variete?.libelle}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Famille</Table.Cell>
                    <Table.Cell>
                      {variete?.famille.code} - {variete?.famille.libelle}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Produits liés</Table.Cell>
                    <Table.Cell>
                      {variete?.produits && variete.produits.length > 0
                        ? _.map(variete.produits, (produit, i) => {
                            return (
                              <Label size="medium" as="a" color="black" key={i}>
                                {produit.code} - {produit.libelle}
                              </Label>
                            )
                          })
                        : 'Aucun produit lié'}
                    </Table.Cell>
                  </Table.Row>
                  {/*<Table.Row>*/}
                  {/*  <Table.Cell>Parcelles liées</Table.Cell>*/}
                  {/*  <Table.Cell>*/}
                  {/*    {variete?.parcelles && variete.parcelles.length > 0*/}
                  {/*      ? _.map(variete.parcelles, (parcelle) => {*/}
                  {/*          return (*/}
                  {/*            <Label size="medium" as="a" color="black">*/}
                  {/*              {parcelle.code} - {parcelle.libelle}*/}
                  {/*            </Label>*/}
                  {/*          )*/}
                  {/*        })*/}
                  {/*      : 'Aucune parcelle liée'}*/}
                  {/*  </Table.Cell>*/}
                  {/*</Table.Row>*/}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return { variete: state.varietes.variete, theme: state.theme }
}

export default connect(mapStateToProps, { fetchVariete })(VarieteView)
