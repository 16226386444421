import React, { Fragment } from 'react'
import FactureForm from './FactureForm'
import { history } from '../../utils/history'
import { DocType } from '../../utils/enum-divers'
import { Button } from 'semantic-ui-react'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { fetchFacture } from '../../redux/actions/facture-action'
import { connect } from 'react-redux'
import { handleErrorResponse } from '../../utils/handle-error-response'
import { toast } from 'react-toastify'
import EmailModal from '../shared/modal/EmailModal'

class FactureEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      btnSubmit: null,
      showModalMail: false,
      factureId: 0,
      numFacture: '',
      defaultEmail: '',
    }
  }
  modalActionsMail() {
    return (
      <Fragment>
        <button className="ui button teal left labeled icon" type="submit">
          <i className="mail icon"></i>
          Envoyer
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalMail: false,
            })
          }}
        >
          Fermer
        </button>
      </Fragment>
    )
  }

  getRecepteurEmail = (id) => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_BY_ID, id)
      .then((response) => {
        this.setState({
          defaultEmail: response.email,
        })
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  emailSubmit = (email, objet, message) => {
    ipcRenderer
      .invoke(Channels.EMAIL_SEND, {
        email,
        objet,
        message,
        typeDocument: 'Facture',
        idDocument: this.state.factureId,
        numero: this.state.numFacture,
      })
      .then((response) => {
        if (response.error) {
          handleErrorResponse(response.error)
        } else {
          history.push(`/factures/${this.props.facture.type}`)
          toast.success(
            `La facture ${this.state.numFacture} a bien été enregistrée et l'email a bien été envoyé`
          )
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }
  getHeaderActions = () => {
    const view = !!this.props.facture?.dateEncaissement
    return (
      <Fragment>
        <Button
          icon="save"
          type="submit"
          disabled={
            !this.props.facture ||
            this.props.facture.isComptabilise
          }
          content="Enregistrer"
          color="teal"
          onClick={() => {
            this.setState({
              btnSubmit: 'enregistrer',
            })
          }}
        />
        <Button
          icon="print"
          type="submit"
          content="Imprimer"
          color="green"
          onClick={() => {
            this.setState({
              btnSubmit: 'imprimer',
            })
          }}
        />
        <Button
          icon="mail"
          type="submit"
          content="Email"
          color="blue"
          onClick={() =>
            this.setState({
              btnSubmit: 'email',
            })
          }
        />
        <Button
          icon="reply"
          type="button"
          content="Retour à la liste"
          color="black"
          onClick={() => {
            history.push(`/factures/${this.props.facture.type}`)
          }}
        />
      </Fragment>
    )
  }

  onFormSubmit = (formValues) => {
    const { type } = this.props.match.params

    if (formValues)
      //console.log(formValues)
      ipcRenderer
        .invoke(Channels.FACTURE_UPDATE, {
          id: this.props.match.params.id,
          facture: formValues,
        })
        .then((response) => {
          //console.log(response)
          if (response.hasError) {
            handleErrorResponse(response.errors)
          } else {
            if (this.state.btnSubmit == 'imprimer') {
              ipcRenderer
                .invoke(Channels.FACTURE_PRINT, response.id)
                .then((response) => {
                 const pdfPath = response.filePath;
                if (pdfPath) {
                  window.open(pdfPath);
                  history.push(`/factures/${type}`)
                  toast.success(`La facture ${response.numero} a bien été générée`);
                } else {
                  toast.error("Le chemin du PDF est manquant ou invalide.");
                }
              })
                .catch((error) => {
                  //console.error(error)
                  toast.error("Erreur lors de l'impression de la facture.");
                })
            } else if (this.state.btnSubmit == 'email') {
              this.getRecepteurEmail(response.recepteurId)
              this.setState(
                {
                  factureId: response.id,
                  numFacture: response.numero,
                },
                () =>
                  this.setState({
                    showModalMail: true,
                  })
              )
            } else {
              history.push(`/factures/${type}`)
              toast.success(
                `La facture ${response.numero} a bien été mise à jour`
              )
            }
          }
        })
        .catch((error) => {
          console.log(error)
          handleErrorResponse(error)
        })
  }

  componentDidMount() {
    const { id } = this.props.match.params

    this.props.fetchFacture(id)
  }

  render() {
    const { facture } = this.props
    const initialValues = facture
    const { type } = this.props.match.params
    // const view = !!facture?.dateEncaissement
    const { factureId, numFacture, defaultEmail } = this.state

    const view =
      facture?.dateDeReglement != null ||
      facture?.dateEncaissement != null ||
      facture?.isComptabilise || 
      facture?.isImprime
    // facture?.dateEcheance
    return initialValues ? (
      <>
        <FactureForm
          formTitle={`Edition d'une facture`}
          headerActions={this.getHeaderActions()}
          type={type === DocType.ACHAT ? DocType.ACHAT : DocType.VENTE}
          onSubmit={this.onFormSubmit}
          initialValues={initialValues}
          view={view}
          consultation={false}
        />
        <EmailModal
          title="Envoi facture par mail"
          visible={this.state.showModalMail}
          actions={this.modalActionsMail()}
          id={factureId}
          numero={numFacture}
          email={defaultEmail}
          typeDocument="Facture"
          onSubmit={this.emailSubmit}
        />
      </>
    ) : (
      <div>Chargement</div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    facture: state.factures.facture,
    theme: state.theme,
  }
}

export default connect(mapStateToProps, { fetchFacture })(FactureEdit)
