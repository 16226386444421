import React, { Component, Fragment } from 'react'
import { history } from '../../utils/history'
import { connect } from 'react-redux'
import TraiteForm from './TraiteForm'
import { Button } from 'semantic-ui-react'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { handleErrorResponse } from '../../utils/handle-error-response'
import { toast } from 'react-toastify'
import moment from 'moment'

class TraiteCreate extends Component {
  constructor(props) {
    super(props)
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button icon="save" type="submit" content="Enregistrer" color="teal" />
        <Button
          icon="reply"
          type="button"
          content="Retour à la liste"
          color="black"
          onClick={() => {
            history.push('/traites')
          }}
        />
      </Fragment>
    )
  }

  onSubmit = (formValues) => {
    if (
      formValues.dateEcheance == null ||
      !moment(formValues.dateEcheance).isValid()
    ) {
      toast.error("Veuillez renseigner la date d'échéance")
      return
    }

    if (formValues.isPayee && formValues.datePaiement == null) {
      toast.error('Veuillez renseigner la date de payement')
      return
    }

    formValues.isPayee = formValues.isPayee
      ? formValues.isPayee
      : formValues.datePaiement != null
    formValues.montant = formValues.montant
      ? parseFloat(formValues.montant)
      : null
    ipcRenderer
      .invoke(Channels.TRAITE_CREATE, formValues)
      .then((response) => {
        if (response.hasError) {
          //console.log(response)
          handleErrorResponse(response.errors)
        } else {
          history.push('/traites')
          toast.success('La traite a bien été créée')
        }
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  render() {
    return (
      <TraiteForm
        onSubmit={this.onSubmit}
        headerActions={this.getHeaderActions()}
        formTitle={'Créer une traite'}
      />
    )
  }
}

export default connect(null, null)(TraiteCreate)
