import React, { Component } from 'react'
import SubHeader from '../../shared/subHeader/SubHeader'
import { connect } from 'react-redux'
import { fetchStatsParcelles } from '../../../redux/actions/statistiques-action'
import ParcellesSearchForm from './ParcellesSearchForm'
import DataTables from '../../shared/datatable/Datatable'
import { cellFormat, roundTwoDec } from '../../shared/datatable/cell-format'
import { Channels } from '../../../api/channels'
import { ExportCsv } from '../../shared/exportcsv/export-csv'
import { Divider } from 'semantic-ui-react'
import ExportPdf from '../../shared/exportcsv/export-pdf'
import { ipcRenderer } from '../../../api/api'
import moment from 'moment'
import './StatistiquesParcelles.css'
const _ = require('lodash');

class StatistiquesParcelles extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cols: [
        {
          text: 'Code produit',
          key: 'codeProduit',
        },
        {
          text: 'Poids Net (Kg)',
          key: 'poidsNet',
          format: cellFormat.NUMBER,
        },
      ],
    }
  }

  componentDidMount() {
    this.props.fetchStatsParcelles(this.props.statistiques.query)
  }
  /**
   * Action on page click
   * @param {*} pageInfo
   */
  onPageClick = (pageInfo) => {
    let query = this.props.statistiques.query
    query.skip = (pageInfo.currentPage - 1) * query.take
    this.props.fetchStatsParcelles(this.props.statistiques.query)
  }

  onColumnClick = (col) => {
    let { query } = this.props.statistiques
    // Si on tri déjà par ce champs
    if (query.order[col]) {
      // Si le tri est déjà en ASC, on switch en DESC
      if (query.order[col] === 'ASC') {
        query.order[col] = 'DESC'
        // Sinon ASC
      } else {
        query.order[col] = 'ASC'
      }
    } else {
      query.order = { [col]: 'ASC' }
    }
    this.props.fetchStatsParcelles(query)
  }

  renderTotalLine = (statistiques) => {
    const totalPoidsNet = _.sumBy(statistiques.produits, (d) => d?.poidsNet)

    return (
      <tr className="total-row">
        <td>Total</td>
        <td>{roundTwoDec(totalPoidsNet)}</td>
      </tr>
    )
  }

  renderParcelles = (parcelles) => {
    return parcelles.map((parcelle) => {
      return (
        <div style={{ marginLeft: '0.8rem' }} key={parcelle.parcelleId}>
          <h5> Parcelle : {parcelle.numParcelle}</h5>
          {parcelle.varietes.map((variete) => {
            return (
              <div
                style={{ marginLeft: '0.8rem', marginBottom: '1rem' }}
                key={variete.varieteId}
              >
                <h5>Varieté : {variete.numVariete}</h5>
                <DataTables
                  columns={this.state.cols}
                  rows={variete.produits}
                  onColumnClick={this.onColumnClick}
                  query={this.props.statistiques.query}
                  total={this.renderTotalLine(variete)}
                />
              </div>
            )
          })}
        </div>
      )
    })
  }

  renderResult = () => {
    const { statistiques } = this.props
    console.log(statistiques)
    if (statistiques?.data.length > 0) {
      return statistiques?.data.map((item) => {
        return (
          <div key={item.contactId}>
            <h4>Fournisseur : {item.contactNom}</h4>
            {/* {this.renderProducts(item.parcelles)} */}
            {this.renderParcelles(item.parcelles)}
          </div>
        )
      })
    }
    return 'Aucun enregistrement ne correspond à votre recherche'
  }

  /* Specifique au searchForm */
  onSubmitSearch = (formValues) => {
    const query = this.props.statistiques.query
    query.params = formValues
    this.props.fetchStatsParcelles(query)
  }

  onResetSearch = () => {
    const query = this.props.statistiques.query
    query.params = {
      fromDate: new Date(new Date().getFullYear(), 0, 1),
      toDate: new Date(new Date().getFullYear(), 11, 31),
    }
    this.props.fetchStatsParcelles(query)
  }
  getDataForExport = async () => {
    const { query } = this.props.statistiques
    const { data } = await ipcRenderer.invoke(
      Channels.STATS_PARCELLE,
      _.omit(query, ['skip', 'take'])
    )
    const productLines = []
    for (const item of data) {
      const { contactNom } = item
      for (const parcelle of item.parcelles) {
        const { numParcelle } = parcelle
        for (const variete of parcelle.varietes) {
          const { numVariete } = variete
          for (const produit of variete.produits) {
            produit.poidsNet = parseFloat(produit?.poidsNet.toFixed(2))
            productLines.push({
              contactNom,
              numParcelle,
              numVariete,
              ...produit,
            })
          }

          const totalPdsNet = _.sumBy(variete.produits, (d) => d?.poidsNet)
          productLines.push({
            contactNom: `Total ${contactNom}`,
            numParcelle,
            numVariete,
            poidsNet: totalPdsNet.toFixed(2),
          })
        }
      }
    }
    //console.log(data)
    return productLines
  }

  async generateFileName() {
    const { query } = this.props.statistiques
    const { params } = query
    let filename = 'Statistiques_Parcelles_'
    filename += `${
      query.params.fromDate
        ? '_du_' + moment(query.params.fromDate).format('DD/MM/YYYY')
        : ''
    }`
    filename += `${
      params.toDate ? '_au_' + moment(params.toDate).format('DD/MM/YYYY') : ''
    }`
    filename += `${params.numParcelle ? '_P_' + params.numParcelle : ''}`
    filename += `${params.codeVariete ? '_CV_' + params.codeVariete : ''}`
    filename += `${params.codeProduit ? '_CP_' + params.codeProduit : ''}`
    return filename
  }

  getHeaderActions = () => {
    return (
      <ExportPdf
        csvData={this.getDataForExport}
        filenameGeneratorFunc={async () => await this.generateFileName()}
        headers={[
          {
            text: 'Nom contact',
            key: 'contactNom',
          },
          {
            text: 'Numéro Parcelle',
            key: 'numParcelle',
          },
          {
            text: 'Varieté',
            key: 'numVariete',
          },
          ...this.state.cols,
        ]}
        orientation="portrait"
      />
    )
  }

  render() {
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title="Statistiques parcelles"
            actions={this.getHeaderActions()}
          />
          <Divider />
          <ParcellesSearchForm
            onSubmit={this.onSubmitSearch}
            initialValues={this.props.statistiques?.query?.params}
            onResetSearch={this.onResetSearch}
          />
          <div className="ui content">{this.renderResult()}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    statistiques: {
      count: state.statistiques.parcellesCount,
      data: state.statistiques.parcelles,
      query: state.statistiques.parcellesQuery,
    },
  }
}

export default connect(mapStateToProps, { fetchStatsParcelles })(
  StatistiquesParcelles
)
