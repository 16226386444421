import React, { Component } from 'react'
import SubHeader from '../../shared/subHeader/SubHeader'
import { connect } from 'react-redux'
import { fetchStatsClients } from '../../../redux/actions/statistiques-action'
import ClientSearchForm from './ClientSearchForm'
import DataTables from '../../shared/datatable/Datatable'
import { cellFormat, roundTwoDec } from '../../shared/datatable/cell-format'
import { Channels } from '../../../api/channels'
import { ExportCsv } from '../../shared/exportcsv/export-csv'
import { Divider } from 'semantic-ui-react'
import ExportPdf from '../../shared/exportcsv/export-pdf'
import { ipcRenderer } from '../../../api/api'
import moment from 'moment'
import './StatistiquesClients.css'
const _ = require('lodash');

class StatistiquesClients extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cols: [
        {
          text: 'Code produit',
          key: 'codeProduit',
        },
        {
          text: 'Libellé produit',
          key: 'libelleProduit',
        },
        {
          _text: 'Poids Net',
            _key: 'poidsNet',
            text: 'Quantité',
           key: 'poidsNet',
          format: cellFormat.NUMBER,
        },
        {
            _text: 'Moyenne (prix au kg)',
           text: 'Prix moyen',
          key: 'average',
          format: cellFormat.MONEY,
        },
        {
          text: 'Montant HT',
          key: 'montantHT',
          format: cellFormat.MONEY,
        },
      ],
    }
  }

  componentDidMount() {
    this.props.fetchStatsClients(this.props.statistiques.query)
  }
  /**
   * Action on page click
   * @param {*} pageInfo
   */
  onPageClick = (pageInfo) => {
    let query = this.props.statistiques.query
    query.skip = (pageInfo.currentPage - 1) * query.take
    this.props.fetchStatsClients(this.props.statistiques.query)
  }

  onColumnClick = (col) => {
    let { query } = this.props.statistiques
    // Si on tri déjà par ce champs
    if (query.order[col]) {
      // Si le tri est déjà en ASC, on switch en DESC
      if (query.order[col] === 'ASC') {
        query.order[col] = 'DESC'
        // Sinon ASC
      } else {
        query.order[col] = 'ASC'
      }
    } else {
      query.order = { [col]: 'ASC' }
    }
    this.props.fetchStatsClients(query)
  }

  renderTotalLine = (statistiques) => {
    const totalPoidsNet = _.sumBy(statistiques.produits, (d) => d?.poidsNet)
    const totalMontantHT = _.sumBy(statistiques.produits, (d) => d?.montantHT)

    return (
      <tr className="total-row">
        <td colSpan={2}>Total</td>
        <td>{roundTwoDec(totalPoidsNet) /*+ ' Kg'*/}</td>
        <td>{roundTwoDec(totalMontantHT / totalPoidsNet) + ' €'}</td>
        <td>{roundTwoDec(totalMontantHT) + ' €'}</td>
      </tr>
    )
  }

  renderResult = () => {
    const { statistiques } = this.props
    if (statistiques?.data.length > 0) {
      return statistiques?.data.map((item) => {
        // Ajout manuellement de la moyenne (pour éviter de faire côté back)
        item.produits = item?.produits.map((element) => {
          return {
            ...element,
            average: (element?.montantHT / element?.poidsNet)?.toFixed(2),
          }
        })

        return (
          <div key={item.contact.contactId}>
                <h4 style={{ marginTop: 20 }}>
              {/*{item.contact.contactCode} - */}{item.contact.contactNom}
            </h4>
            {/* {this.renderProducts(item.produits)} */}
            <DataTables
              columns={this.state.cols}
              rows={item.produits}
              onColumnClick={this.onColumnClick}
              actionRow={this.getActionRow}
              query={this.props.statistiques.query}
              total={this.renderTotalLine(item)}
                />
            </div> 
        )
      })
    }
    return 'Aucun enregistrement ne correspond à votre recherche'
  }

  /* Specifique au searchForm */
  onSubmitSearch = (formValues) => {
    const query = this.props.statistiques.query
    query.params = formValues
    this.props.fetchStatsClients(query)
  }

  onResetSearch = () => {
    const query = this.props.statistiques.query
    query.params = {
      fromDate: new Date(new Date().getFullYear(), 0, 1),
      toDate: new Date(new Date().getFullYear(), 11, 31),
    }
    this.props.fetchStatsClients(query)
  }
  getDataForExport = async () => {
    const { query } = this.props.statistiques
    const { data } = await ipcRenderer.invoke(
      Channels.STATS_CLIENT,
      _.omit(query, ['skip', 'take'])
    )
    const productLines = []
    for (const item of data) {
        const { contactNom } = item.contact
      for (const produit of item.produits) {
        produit.montantHT = parseFloat(produit?.montantHT.toFixed(2))
        produit.poidsNet = parseFloat(produit?.poidsNet.toFixed(2))
        produit.average = (produit?.montantHT / produit?.poidsNet)?.toFixed(2)
          productLines.push({ contactNom, ...produit })
      }
      const totalPdsNet = _.sumBy(item.produits, (d) => d?.poidsNet)
      const totalMontantHT = _.sumBy(item.produits, (d) => d?.montantHT)
      productLines.push({
          contactNom: `Total ${contactNom}`,
        poidsNet: totalPdsNet.toFixed(2),
        montantHT: totalMontantHT.toFixed(2),
        average: (totalMontantHT / totalPdsNet).toFixed(2),
      })
    }
    //console.log(data)
    return productLines
  }

  async generateFileName() {
    const { query } = this.props.statistiques
    const { params } = query
    let filename = 'Statistiques_Clients_'
    filename += `${
      query.params.fromDate
        ? '_du_' + moment(query.params.fromDate).format('DD/MM/YYYY')
        : ''
    }`
    filename += `${
      params.toDate ? '_au_' + moment(params.toDate).format('DD/MM/YYYY') : ''
    }`
    filename += `${params.codeFamille ? '_CF_' + params.codeFamille : ''}`
    filename += `${params.codeVariete ? '_CV_' + params.codeVariete : ''}`
    filename += `${params.codeProduit ? '_CP_' + params.codeProduit : ''}`
    return filename
  }

  getHeaderActions = () => {
    return (
      <ExportPdf
        csvData={this.getDataForExport}
        filenameGeneratorFunc={async () => await this.generateFileName()}
        headers={[
          {
                text: 'Client',
                key: 'contactNom',
          },
          ...this.state.cols,
        ]}
        orientation="portrait"
      />
    )
  }

  render() {
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title="Statistiques clients"
            actions={this.getHeaderActions()}
          />
          <Divider />
          <ClientSearchForm
            onSubmit={this.onSubmitSearch}
            initialValues={this.props.statistiques?.query?.params}
            onResetSearch={this.onResetSearch}
          />
          <div className="ui content">{this.renderResult()}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    statistiques: {
      count: state.statistiques.clientsCount,
      data: state.statistiques.clients,
      query: state.statistiques.clientsQuery,
    },
  }
}

export default connect(mapStateToProps, { fetchStatsClients })(
  StatistiquesClients
)
