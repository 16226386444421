import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Button, Label } from 'semantic-ui-react'
import { fetchTraite } from '../../redux/actions/traite-action'
import SubHeader from '../shared/subHeader/SubHeader'
import { Table } from 'semantic-ui-react'
import { VisionMode } from '../../utils/theme'
import { history } from '../../utils/history'
import { NavLink } from 'react-router-dom'
const _ = require('lodash');

class TraiteView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchTraite(this.props.match.params.id)
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button
          icon="reply"
          type="submit"
          content="Retour"
          color="teal"
          onClick={() => {
            history.push('/traites')
          }}
        />
      </Fragment>
    )
  }

  render() {
    const { traite } = this.props
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title={`Traite ${this.props.traite?.numeroTraite}`}
            actions={this.getHeaderActions()}
          />
          <div className="ui divider"></div>
          <div className="dt-top-container">
            <div className="content">
              <Table
                definition
                className={`${
                  this.props.theme.visionMode === VisionMode.AMELIORE
                    ? 'inverted'
                    : ''
                }`}
              >
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Numéro Traite</Table.Cell>
                    <Table.Cell>{traite?.numeroTraite}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Numéro Facture</Table.Cell>
                    <Table.Cell>{traite?.numeroFacture}</Table.Cell>
                  </Table.Row>
                  {/* <Table.Row>
                    <Table.Cell>Intitulé Facture</Table.Cell>
                    <Table.Cell>{traite?.intituleFacture}</Table.Cell>
                  </Table.Row> */}
                  {/*<Table.Row>*/}
                  {/*  <Table.Cell>Catégorie</Table.Cell>*/}
                  {/*  <Table.Cell>{traite?.categorie}</Table.Cell>*/}
                  {/*</Table.Row>*/}
                  <Table.Row>
                    <Table.Cell>Société</Table.Cell>
                    <Table.Cell>
                      {traite?.contact?.code} - {traite?.contact?.nom}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Mode de règlement</Table.Cell>
                    <Table.Cell>{traite?.modeReglement}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Numéro de chèque</Table.Cell>
                    <Table.Cell>{traite?.numeroCheque}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Montant</Table.Cell>
                    <Table.Cell>{traite?.montant}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Date d'échéance de paiement</Table.Cell>
                    <Table.Cell>
                      <p>{traite?.dateEcheance?.toLocaleDateString('fr-FR')}</p>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Payée</Table.Cell>
                    <Table.Cell>{traite?.isPayee ? 'Oui' : 'Non'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Date de paiement</Table.Cell>
                    <Table.Cell>
                      <p>{traite?.datePaiement?.toLocaleDateString('fr-FR')}</p>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Commentaire</Table.Cell>
                    <Table.Cell>
                      <p>{traite?.commentaire}</p>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return { traite: state.traites.traite, theme: state.theme }
}

export default connect(mapStateToProps, { fetchTraite })(TraiteView)
