import React, { useState } from 'react';
import { Container } from 'semantic-ui-react';
import './Home.css';
import Login from '../login/Login';
import logo from '../../OCCIFACT-logo.jpg'

const Home = (props) => {

  const [ClickLogin, setClickLogin] = useState(false);

  const Log = (LogginIn, societeID)=>
    {
      localStorage.setItem('loggedIn', JSON.stringify(LogginIn)) 
      localStorage.setItem('societeId', JSON.stringify( societeID ))
      setClickLogin(false)
      props.log(true, societeID)
      
    }
  
  const onButtonClick = () => {
    setClickLogin(true)
  }

    return (
     /* <div>
        {!ClickLogin ?
      <Container fluid>
       <div className={'titleContainer'}>
        <div>Bienvenue !</div>
      </div>
      <div>This is the home page.</div>
      <div className={'buttonContainer'}>
        <input
          className={'inputButton'}
          type="button"
          onClick={onButtonClick}
          value={'Log in'}
        />
      </div>
      </Container>
      :
      <Login log= {Log}/>
      }
      </div>
    )*/

      <div>
      {!ClickLogin ? (
        <Container fluid>
          <div className={'homepage-container'}>
            <div className="logo-container">
            <img src={logo} alt="logo" className="homepage-logo" />
            </div>
            <h1>Solutions & Impulsions technologiques</h1>
            <p>Vous accompagner dans votre transformation numérique</p>
            <p>Créer, accéder, envoyer vos factures et bons de livraison en un seul endroit</p>
            <div className={'homepage-buttonContainer'}>
              <input
                className={'homepage-inputButton'}
                type="button"
                onClick={onButtonClick}
                value={'Log in'}
              />
            </div>
          </div>
            </Container>
           ) : (
            <Login log={Log} />
          )}
        
      <footer className="homepage-footer">
      </footer>
      </div>
  );
}
  
export default Home