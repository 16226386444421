import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import './Menu.css'

class MenuItem extends Component {
  constructor(props) {
    super(props)
    this.handleDisplaySousRub = this.handleDisplaySousRub.bind(this)
  }

  handleDisplaySousRub(event) {
    event.stopPropagation()
    if (this.props.children) this.props.event(event)
    else {
      this.props.history.push(this.props.href)
      document.getElementById('sideMenu').classList.remove('visible')
      document.getElementById('closebtn').classList.remove('visible')
    }
  }

  render() {
    return (
      <div onClick={this.handleDisplaySousRub} className="menuItem">
        <NavLink
          to={this.props.href}
          className={this.props.className ? this.props.className : ''}
          id={this.props.id ? this.props.id : ''}
          activeClassName={!this.props.children ? 'linkActive' : ''}
          exact={this.props.exact}
        >
          {this.props.icon ? <i className={`${this.props.icon} icon`}></i> : ''}{' '}
          {this.props.name}
        </NavLink>
        {this.props.children ? <span className="chevron" /> : ''}
        {this.props.children}
      </div>
    )
  }
}

export default withRouter(MenuItem)
