import React, { Component } from 'react'
import { Field, useForm } from 'react-final-form'
import { Grid, Table } from 'semantic-ui-react'
import { FieldArray } from 'react-final-form-arrays'
import { roundTwoDec } from '../shared/datatable/cell-format'

class ProdouaneFormLigneBody extends Component {
  render() {
    const { index } = this.props
    return (
      <Grid.Column>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Code Produit</Table.HeaderCell>
              <Table.HeaderCell>Categorie</Table.HeaderCell>
              {/* <Table.HeaderCell>Nombre de colis</Table.HeaderCell> */}
              <Table.HeaderCell>Prix unitaire HT</Table.HeaderCell>
              <Table.HeaderCell>Prix total HT</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <FieldArray name={`bonDeLivraisons[${index}].bonDeLivraisonLignes`}>
              {({ fields, meta }) => {
                const form = useForm()
                return fields.map((name, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <Field name={`${name}.codeProduit`}>
                          {({ input, meta }) => {
                            return input.value
                          }}
                        </Field>
                      </Table.Cell>
                      <Table.Cell>
                        <Field name={`${name}.categorie`}>
                          {({ input, meta }) => {
                            return input.value
                          }}
                        </Field>
                      </Table.Cell>

                     {/*  <Table.Cell>
                        <Field name={`${name}.nbColis`}>
                          {({ input, meta }) => {
                            return input.value
                          }}
                        </Field>
                      </Table.Cell> */}

                      <Table.Cell>
                        <Field name={`${name}.prixUnitaireHT`}>
                          {({ input, meta }) => {
                            return roundTwoDec(input.value) + ' €'
                          }}
                        </Field>
                      </Table.Cell>

                      <Table.Cell>
                        <Field name={`${name}.totalLigneHT`}>
                          {({ input, meta }) => {
                            return roundTwoDec(input.value) + ' €'
                          }}
                        </Field>
                      </Table.Cell>
                    </Table.Row>
                  )
                })
              }}
            </FieldArray>
          </Table.Body>
        </Table>
      </Grid.Column>
    )
  }
}

export default ProdouaneFormLigneBody
