import { theme } from '../actions/theme'
import { VisionMode, ColorTheme } from '../../utils/theme'

const ThemeReducer = (
  state = {
    visionMode: VisionMode.CLASSIQUE, // zoomed by default
    colorTheme: ColorTheme.CLASSIQUE,
  },
  action
) => {
  switch (action.type) {
    case theme.SET_VISION_MODE:
      return {
        ...state,
        visionMode: action.payload,
      }
    case theme.SET_COLOR_THEME:
      return {
        ...state,
        colorTheme: action.payload,
      }
    default:
      return { ...state }
  }
}

export default ThemeReducer
