import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { prodouane } from './types'

export const fetchProdouanes = (query) => {
  return async (dispatch) => {
    try {
      //console.log(query)
      const response = await ipcRenderer.invoke(
        Channels.PRODOUANE_FIND_ALL,
        query
      )
      if (response.hasError) {
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: prodouane.FETCH_PRODOUANES,
          payload: { response, query },
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des prodouane")
    }
  }
}
