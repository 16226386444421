import React, { Component, Fragment } from 'react'
import { history } from '../../utils/history'
import { connect } from 'react-redux'
import FamilleForm from './FamilleForm'
import { Button } from 'semantic-ui-react'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { fetchFamille } from '../../redux/actions/famille-action'
import CustomModal from '../shared/modal/CustomModal'
import { handleErrorResponse } from '../../utils/handle-error-response'
import { toast } from 'react-toastify'
const _ = require('lodash');

class FamilleEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModalDelete: false,
      showModalCancel: false,
    }
  }

  componentDidMount() {
    this.props.fetchFamille(this.props.match.params.id)
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button icon="save" type="submit" content="Enregistrer" color="teal" />
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: true,
            })
          }}
        >
          Annuler
        </button>
      </Fragment>
    )
  }

  modalActionsCancel() {
    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
            history.push('/settings/familles')
          }}
        >
          <i className="checkmark icon"></i>
          Oui
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
          }}
        >
          Non
        </button>
      </Fragment>
    )
  }

  onSubmit = (formValues) => {
   /*  formValues.montant = formValues.montant
      ? parseFloat(formValues.montant)
      : null
    formValues.diviseur = formValues.diviseur
      ? parseFloat(formValues.diviseur)
      : null
    formValues.champsCalcul = formValues.champsCalcul
      ? formValues.champsCalcul
      : null */
    //console.log(formValues)
    ipcRenderer
      .invoke(Channels.FAMILLE_UPDATE, {
        id: this.props.match.params.id,
        famille: formValues,
      })
      .then((response) => {
        if (response.hasError) {
          handleErrorResponse(response.errors)
        } else {
          history.push('/settings/familles')
          toast.success('La famille a bien été modifiée')
        }
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  render() {
    return (
      <Fragment>
        <FamilleForm
          onSubmit={this.onSubmit}
          headerActions={this.getHeaderActions()}
          formTitle={`Editer la famille ${this.props.famille?.libelle}`}
          initialValues={_.omit(this.props.famille, ['varietes', 'cotisation'])}
          view={true}
        />
        <CustomModal
          title="Annuler"
          content="Quitter et annuler les modifications en cours ?"
          visible={this.state.showModalCancel}
          actions={this.modalActionsCancel()}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { famille: state.familles.famille, theme: state.theme }
}

export default connect(mapStateToProps, { fetchFamille })(FamilleEdit)
