import React, { Component, Fragment } from 'react'
import { history } from '../../utils/history'
import SocieteForm from './SocieteForm'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import CustomModal from '../shared/modal/CustomModal'
import { fetchSociete } from '../../redux/actions/societe-action'
const _ = require('lodash');

class SocieteEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModalDelete: false,
      showModalCancel: false,
    }
  }

  componentDidMount() {
    this.props.fetchSociete(1)
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button icon="save" type="submit" content="Enregistrer" color="teal" />
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: true,
            })
          }}
        >
          Annuler
        </button>
      </Fragment>
    )
  }
  modalActionsCancel() {
    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
            history.push('/settings/societes')
          }}
        >
          <i className="checkmark icon" />
          Oui
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
          }}
        >
          Non
        </button>
      </Fragment>
    )
  }

  onSubmit = (formValues) => {
    return ipcRenderer
      .invoke(Channels.SOCIETE_UPDATE, {
        id: this.props.match.params.id,
        societe: formValues,
      })
      .catch((error) => {
        console.error(error)
      })
  }

  render() {
    const { societe } = this.props
    const initialValues = _.omit(societe, ['BonDeLivraisons'])
    return (
      <Fragment>
        <SocieteForm
          onSubmit={this.onSubmit}
          headerActions={this.getHeaderActions()}
          formTitle={`Editer ${societe ? societe.nom : 'inconnu'}`}
          initialValues={initialValues}
          view={false}
        />
        <CustomModal
          title="Annuler"
          content="Quitter et annuler les modifications en cours ?"
          visible={this.state.showModalCancel}
          actions={this.modalActionsCancel()}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { societe: state.societes.societe, theme: state.theme }
}

export default connect(mapStateToProps, { fetchSociete })(SocieteEdit)
