import React, { Component } from 'react'
import { Field, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { Button, Grid, Icon } from 'semantic-ui-react'
import ProdouaneFormLigneBody from './ProdouaneFormLigneBody'
import ProdouaneFormLigneFooter from './ProdouaneFormLigneFooter'
import ProdouaneFormLigneHeader from './ProdouaneFormLigneHeader'

class ProdouaneFormLigne extends Component {
  updateTotalRemise = (form, blId, index) => {
    /*const { bonDeLivraisons } = this.props.initialValues
    const bl = bonDeLivraisons?.find((bl) => bl.id == blId)
    const montantRemise = bl?.totalPrixHT - bl?.montantRemiseIndicative

    form.change(
      `TotalRemise[${index}]`,
      Number.isInteger(montantRemise) ? montantRemise : montantRemise.toFixed(2)
    )*/
  }

  render() {
    const { blFiltered } = this.props
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <FieldArray name="bonDeLivraisons">
              {({ fields, meta }) => {
                const form = useForm()
                return (
                  <div>
                    {fields.map((name, index) => {
                      return (
                        <Grid key={index}>
                          <Grid.Row>
                            <ProdouaneFormLigneHeader
                              bonDeLivraisons={blFiltered}
                              fields={fields}
                              index={index}
                              form={form}
                              updateTotalRemise={this.updateTotalRemise}
                            />
                          </Grid.Row>

                          <Grid.Row>
                            <ProdouaneFormLigneBody index={index} />
                          </Grid.Row>

                          <Grid.Row>
                            {
                              <ProdouaneFormLigneFooter
                                bonDeLivraisons={blFiltered}
                                fields={fields}
                                index={index}
                                form={form}
                                updateTotalRemise={this.updateTotalRemise}
                              />
                            }
                          </Grid.Row>
                          <div className="ui divider" />
                        </Grid>
                      )
                    })}

                    <Button
                      floated="left"
                      type="button"
                      icon
                      labelPosition="left"
                      primary
                      size="small"
                      onClick={() => fields.push({})}
                    >
                      <Icon name="user" /> Ajouter une ligne
                    </Button>
                  </div>
                )
              }}
            </FieldArray>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default ProdouaneFormLigne
