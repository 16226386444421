import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { facture } from './types'

export const fetchFacture = (id) => {
  return async (dispatch) => {
    try {
      const payload = await ipcRenderer.invoke(Channels.FACTURE_FIND_BY_ID, id)
      //console.log(payload)
      if (payload.hasError) {
        throw new Error(payload.error?.toString())
      } else {
        dispatch({
          type: facture.FETCH_FACTURE,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération d'une facture")
    }
  }
}

export const fetchFactures = (query) => {
  return async (dispatch) => {
    try {
      const response = await ipcRenderer.invoke(
        Channels.FACTURE_FIND_ALL,
        query
      )
      const payload = { response, query }
      if (response.hasError) {
            console.log(response)
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: facture.FETCH_FACTURES,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des factures")
    }
  }
}
