import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './components/App/App'
import reportWebVitals from './reportWebVitals'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import reduxThunk from 'redux-thunk'
import { history } from './utils/history'
import allReducers from './redux/reducers'

console.log(process.env.NODE_ENV )

 //const rootSelector = document.currentScript.getAttribute('data-container')
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
//Store redux
 const store = createStore(
  allReducers,
  composeEnhancers(applyMiddleware(reduxThunk))
)  

//const root = ReactDOM.createRoot(document.querySelector(rootSelector));
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<Provider store={store}>
  <Router history={history}>
    <App />
  </Router>
</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
