import { prodouane } from '../actions/types'
const _ = require('lodash');

export default (
  state = {
    prodouane: null,
    count: 0,
    byIds: {},
    ids: [],
    query: {
      skip: 0,
      take: 10,
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: { id: 'ASC' },
    },
  },
  action
) => {
  switch (action.type) {
    case prodouane.FETCH_PRODOUANE:
      return {
        ...state,
        prodouane: action.payload.data,
      }
    case prodouane.FETCH_PRODOUANES:
      const prodouanesState = {
        byIds: _.mapKeys(action.payload.response.data, 'id'),
        count: action.payload.response.count,
        ids: _.map(action.payload.response.data, 'id'),
        query: action.payload.query,
      }
      return {
        ...state,
        ...prodouanesState,
      }

    default:
      return state
  }
}
