import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { Field } from 'react-final-form'
import { Header, Table } from 'semantic-ui-react'
import { BonDeLivraisonItemTableRow } from './BonDeLivraisonItemTableRow'

export class BonDeLivraisonItem extends React.Component {
  render() {
    const { view, avoir } = this.props
    return (
      <div>
        <h3>
          Bon de livraison N°{' '}
          <Field name={`${this.props.parentName}.numero`}>
            {({ input, meta }) => <span {...input}>{input.value}</span>}
          </Field>
        </h3>
        <br />
        <Field name={`${this.props.parentName}.commentaire`}>
          {({ input, meta }) => <span {...input}>{input.value}</span>}
        </Field>
        <Table celled compact>
          <Table.Header>
            <Table.Row>
              {/* <Table.HeaderCell>Colis</Table.HeaderCell> */}
              <Table.HeaderCell width={2}>Code</Table.HeaderCell>
              <Table.HeaderCell width={5} colSpan="6">Désignation</Table.HeaderCell>
             {/* <Table.HeaderCell>CAT</Table.HeaderCell>}*/}
              <Table.HeaderCell width={1}>Quantité</Table.HeaderCell>
              {/*<Table.HeaderCell>Tare</Table.HeaderCell>*/}
              {/*<Table.HeaderCell>Pds Net</Table.HeaderCell>*/}
              <Table.HeaderCell width={1}>Prix Unitaire</Table.HeaderCell>
              <Table.HeaderCell width={1}>Taux Remise</Table.HeaderCell>
              <Table.HeaderCell width={2}>Total HT</Table.HeaderCell>
              <Table.HeaderCell width={1}>Taux TVA</Table.HeaderCell>
              <Table.HeaderCell width={2}>Total TTC</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <FieldArray name={`${this.props.parentName}.bonDeLivraisonLignes`}>
            {({ fields }) => {
              return (
                <Table.Body>
                  {fields.map((name, index) => {
                    return (
                      <BonDeLivraisonItemTableRow
                        name={name}
                        parentName={this.props.parentName}
                        index={index}
                        key={index}
                        view={view}
                        avoir = {avoir}
                        removeLine={() => {
                          fields.remove(index) 
                       }}
                        onUpdateTVA={this.props.onUpdateTVA}
                      />
                    )
                  })}
                </Table.Body>
              )
            }}
          </FieldArray>
        </Table>
      </div>
    )
  }
}
