import React, { Component, Fragment } from 'react'
import { history } from '../../utils/history'
import { connect } from 'react-redux'
import VarieteForm from './VarieteForm'
import { Button } from 'semantic-ui-react'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { handleErrorResponse } from '../../utils/handle-error-response'
import { toast } from 'react-toastify'

class VarieteCreate extends Component {
  constructor(props) {
    super(props)
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button icon="save" type="submit" content="Enregistrer" color="teal" />
        <Button
          icon="reply"
          type="submit"
          content="Retour à la liste"
          color="black"
          onClick={() => {
            history.push('/settings/varietes')
          }}
        />
      </Fragment>
    )
  }

  onSubmit = (formValues) => {
    ipcRenderer
      .invoke(Channels.VARIETE_CREATE, formValues)
      .then((response) => {
        if (response.hasError) {
          handleErrorResponse(response.errors)
        } else {
          history.push('/settings/varietes')
          toast.success('La catégorie a bien été créée')
        }
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  render() {
    return (
      <VarieteForm
        onSubmit={this.onSubmit}
        headerActions={this.getHeaderActions()}
        formTitle={'Créer une catégorie'}
        initialValues={{ isBio: false }}
      />
    )
  }
}

export default connect(null, null)(VarieteCreate)
