import { traite } from '../actions/types'
const _ = require('lodash');

export default (
  state = {
    traite: null,
    byIds: {},
    count: 0,
    ids: [],
    query: {
      skip: 0,
      take: 10,
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: { dateEcheance: 'ASC' },
    },
  },
  action
) => {
  switch (action.type) {
    case traite.FETCH_TRAITE:
      return { ...state, traite: action.payload }
    case traite.FETCH_TRAITES:
      const traitesState = {
        byIds: _.mapKeys(action.payload.response.data, 'id'),
        count: action.payload.response.count,
        ids: _.map(action.payload.response.data, 'id'),
        query: action.payload.query,
      }
      return {
        ...state,
        ...traitesState,
      }
    default:
      return state
  }
}
