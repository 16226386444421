import React, { Component } from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import {
  composeValidators,
  minLength,
  isRequired,
  maxLength,
} from '../../utils/validators'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'

class MentionForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contacts: [],
      contactsSelected: [],
    }
    
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_ALL, { params: { isGerant: false,}, order: { nom: 'ASC' } })
      .then(({ data }) => {
        console.log(data)
        this.setState({
          contacts: data,
        })
      })
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    console.log("componentDidUpdate")
    if (prevProps !== this.props && this.props) {
      if (this.props?.initialValues?.contacts) {
        const { contacts } = this.props.initialValues

        ipcRenderer.invoke(Channels.CONTACT_FIND_ALL, {isGerant: false,}).then(({ data }) => {
          this.setState(
            {
              contactsSelected: contacts.map((c) => c.id),
              contacts: data,
            }
            //console.log(this.state.contactsSelected)
          )
        })
      }
    }
  }

  onSubmit = (formValues) => {
    formValues.contacts = formValues.contacts?.map((item) => {
      if (typeof item === 'number') {
        return { id: item }
      } else {
        return item
      }
    })
    if (formValues.contacts?.length !== 0) this.props.onSubmit(formValues)
    else toast.error('Veuillez associer 1 ou plusieurs contacts')
  }

  render() {
    return (
      <FinalForm
        onSubmit={this.onSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit }) => {
          return (
            <SemanticForm onSubmit={handleSubmit} size="massive">
              <div className="ui content">
                <div className="ui segment">
                  <SubHeader
                    title={this.props.formTitle}
                    actions={this.props.headerActions}
                  />
                  <div className="ui divider"></div>

                  <SemanticForm.Group widths="equal">
                    <Field
                      name="descriptionCourte"
                      validate={composeValidators(
                        isRequired,
                        minLength(3),
                        maxLength(30)
                      )}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Description courte"
                          type="text"
                          placeholder="ex: Ac12"
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                    <Field
                      name="mention"
                      validate={composeValidators(isRequired, minLength(3))}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Mention"
                          type="text"
                          placeholder="ex: ABCDE"
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                  </SemanticForm.Group>
                  <div className="two fields">
                    <Field
                      name="contacts"
                      //validate={composeValidators(isRequired)}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Field>
                          <SemanticForm.Dropdown
                            {...input}
                            placeholder="Sélectionnez des contacts"
                            search
                            multiple
                            fluid
                            selection
                            value={this.state.contactsSelected}
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.contacts?.map((contact) => {
                              return {
                                key: contact.id,
                                value: contact.id,
                                text: contact?.code + ' - ' + contact?.nom,
                              }
                            })}
                            label="Contacts à associer à cette mention"
                            onChange={(event, data) => {
                              this.setState({
                                contactsSelected: data.value,
                              })
                              return input.onChange(data.value)
                            }}
                            //onSearchChange={this.handleSearchChangeMentions}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default MentionForm
