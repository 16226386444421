import React, { Fragment } from 'react'
import BonDeLivraisonForm from './BonDeLivraisonForm'
import { DocType } from '../../utils/enum-divers'
import { Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { history } from '../../utils/history'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { fetchBonDeLivraison } from '../../redux/actions/bon-de-livraison-action'
import CustomModal from '../shared/modal/CustomModal'
import { toast } from 'react-toastify'
import { handleErrorResponse } from '../../utils/handle-error-response'
import EmailModal from '../shared/modal/EmailModal'
import moment from 'moment'

const _ = require('lodash');

class BonDeLivraisonEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModalCancel: false,
      submitBtn: null,
      showModalPrint: false,
      id: 0,
      showModalMail: false,
      numBl: '',
      defaultEmail: '',
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.fetchBonDeLivraison(id)
  }

  modalActionsMail() {
    const { type } = this.props.match.params

    return (
      <Fragment>
        <button className="ui button teal left labeled icon" type="submit">
          <i className="mail icon"></i>
          Envoyer
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalMail: false,
            })
          }}
        >
          Fermer
        </button>
      </Fragment>
    )
  }

  emailSubmit = (email, objet, message) => {
    const { type } = this.props?.match?.params

    ipcRenderer
      .invoke(Channels.EMAIL_SEND, {
        email,
        objet,
        message,
        typeDocument: 'Bl',
        idDocument: this.state.id,
        numero: this.state.numBl,
      })
      .then((response) => {
        if (response.error) {
          handleErrorResponse(response.error)
        } else {
          history.push(`/bons-de-livraison/${type}`)
          toast.success(
            `Le bon de livraison ${this.state.numBl} a bien été créé et l'email a bien été envoyé`
          )
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  getRecepteurEmail = (id) => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_BY_ID, id)
      .then((response) => {
        this.setState({
          defaultEmail: response.email,
        })
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button
          icon="save"
          type="submit"
          content="Enregistrer"
          color="teal"
          disabled={
            !this.props.bonDeLivraison ||
            this.props.bonDeLivraison.isFactureDone
          }
          onClick={() => {
            this.setState({ ...this.state, submitBtn: 'enregistrer' })
          }}
        />
        <Button
          icon="print"
          type="submit"
          content="Imprimer"
          color="green"
          onClick={() => {
            this.setState({ ...this.state, submitBtn: 'imprimer' })
          }}
        />
        <Button
          icon="mail"
          type="submit"
          content="Email"
          color="blue"
          onClick={() =>
            this.setState({
              submitBtn: 'email',
            })
          }
        />
        <Button
          icon="file alternate outline"
          type="submit"
          content="Facturer"
          color="red"
          disabled={
            !this.props.bonDeLivraison ||
            this.props.bonDeLivraison.isFactureDone
          }
          onClick={() => {
            this.setState({ ...this.state, submitBtn: 'facturer' })
          }}
        />
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: true,
            })
          }}
        >
          Annuler
        </button>
      </Fragment>
    )
  }

  modalActionsCancel() {
    const { type } = this.props?.match?.params
    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
            history.push(history.push(`/bons-de-livraison/${type}`))
          }}
        >
          <i className="checkmark icon"></i>
          Oui
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalCancel: false,
            })
          }}
        >
          Non
        </button>
      </Fragment>
    )
  }

  modalActionsPrint(id) {
    const { type } = this.props.match.params

    return (
      <Fragment>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalPrint: false,
            })
            ipcRenderer
            .invoke(Channels.BON_DE_LIVRAISON_PRINT, {
              data: id,
              options: { printParcelles: true },
            })
            .then((response) => {
              //console.log('API Response:', response);
              const pdfPath = response.filePath;
              if (pdfPath) {
                window.open(pdfPath);
                toast.success(`Le bon de livraison a bien été créé`);
              } else {
                toast.error("Le chemin du PDF est manquant ou invalide.");
              }
            })
            .catch((error) => {
              console.error(error);
              toast.error('Erreur lors de la création du bon de livraison.');
            });
          }}
        >
          <i className="checkmark icon" />
          Impression PDF
        </button>
        <button
          className="ui button teal left labeled icon"
          type="button"
          onClick={() => {
            this.setState({
              showModalPrint: false,
              })
              console.log('printing bl with id ' + id)
            }
          }  
        >
          Non
        </button>
      </Fragment>
    )
  }

 onFormSubmit = async (formValues) => {
    console.log(formValues);
    const { type } = this.props.match.params;

    try {
        const response = await ipcRenderer.invoke(Channels.BON_DE_LIVRAISON_UPDATE, {
            id: this.props.match.params.id,
            bonDeLivraison: formValues,
        });

        if (response.hasError) {
            handleErrorResponse(response.errors);
            return;
        }

        this.setState({ id: response.id });
        switch (this.state.submitBtn) {
            case 'imprimer':
                this.setState({ showModalPrint: true });
                break;
            case 'email':
                this.getRecepteurEmail(response.recepteurId);
                this.setState({ numBl: response.numero, showModalMail: true });
                break;

            case 'facturer': {
              console.log("formValues")
                console.log(formValues)
                  //calcul de la quantité totale
                   const lignes = formValues.bonDeLivraisonLignes
                  let quantite = _.sumBy(lignes, ({ poidsNet }) => {
                    if (!isNaN(poidsNet)) {
                      return parseFloat(parseFloat(poidsNet).toFixed(2))
                    } else {
                      return 0
                    }
                  }) 
                  // Construction de la facture à partir du BL
                  let facture = { isComptabilise:false,
                                  isImprime: false,
                                  date: response.date, // RAF today
                                  dateEcheance : moment(response.date).add(30, 'days'),
                                  emetteurId: response.emetteurId,
                                  emetteurAsString:response.emetteurAsString,
                                  recepteurId: response.recepteurId,
                                  recepteurAsString : response.recepteurAsString,
                                  type: response.type,
                                  mentions: response.mentions,
                                  bonsDeLivraisons: [{
                                    id: response.id,
                                    numero: response.numero,
                                    bonDeLivraisonLignes: response.bonDeLivraisonLignes
                                  }],
                                  totalHTAvantRemise: response.totalPrixHT + response.totalMontantRemiseIndicative,
                                  montantRemise: response.totalMontantRemiseIndicative,
                                  totalHTApresRemise: response.totalPrixHT,
                                  totalTVA_2_1: response.totalTVA_2_1,
                                  totalTVA_5_5: response.totalTVA_5_5,
                                  totalTVA_10: response.totalTVA_10,
                                  totalTVA_20: response.totalTVA_20,
                                  montantTVA: response.totalTVA,
                                  totalTTC: response.totalTTC,
                                  poidsNet: quantite,
                                  dateDeReglement: null,
                                  dateEncaissement: null,
                                  totalCotisation: 0,
                                  tauxDeTVA: 0,
                                  remise: 0,
                                }
                  ipcRenderer
                  .invoke(Channels.FACTURE_CREATE, facture)
                  .then((response) => {
                    if (response.hasError) {
                      handleErrorResponse(response.errors)
                    } else {
                        history.push(`/bons-de-livraison/${type}`)
                        toast.success(`La facture ${response.numero} a bien été créée`)
                    }
                  })
                }
                break;
            default:
                history.push(`/bons-de-livraison/${type}`);
                toast.success(`Le bon de livraison ${response.numero} a bien été mis à jour`);
                break;
        }
    } catch (error) {
        console.log(error);
        handleErrorResponse(error);
    }
};

  render() {
    const { bonDeLivraison , TVA_2_1_visible, TVA_5_5_visible, TVA_10_visible, TVA_20_visible} = this.props
    //console.log(bonDeLivraison)
    const initialValues = bonDeLivraison
    const { defaultEmail, id, numBl } = this.state

    return initialValues ? (
      <Fragment>
        <BonDeLivraisonForm
          /* formTitle={`Editer un bon de livraison - ${initialValues.type === DocType.ACHAT ? 'Achats' : 'Ventes'} `} */
          formTitle={`Editer un bon de livraison`}
          type={
            initialValues.type === DocType.ACHAT ? DocType.ACHAT : DocType.VENTE
          }
          headerActions={this.getHeaderActions()}
          initialValues={initialValues}
          onSubmit={this.onFormSubmit}
          view={
            !this.props.bonDeLivraison ||
            this.props.bonDeLivraison.isFactureDone
          }
        />
        <EmailModal
          title="Envoi du bon de livraison par mail"
          visible={this.state.showModalMail}
          actions={this.modalActionsMail()}
          id={id}
          numero={numBl}
          email={defaultEmail}
          typeDocument="Bl"
          onSubmit={this.emailSubmit}
        />
        <CustomModal
          title="Annuler"
          content="Quitter et annuler les modifications en cours ?"
          visible={this.state.showModalCancel}
          actions={this.modalActionsCancel()}
        />
        <CustomModal
          title="Imprimer"
          content="Imprimer le Bon de livraison"
          visible={this.state.showModalPrint}
          actions={this.modalActionsPrint(this.state.id)}
        />
      </Fragment>
    ) : (
      <div>Chargement</div>
    )
  }
}

const mapStateToProps = (state) => {
  console.log ("state",state)
  return {
    bonDeLivraison: state.bonsDeLivraison.bonDeLivraison,
    theme: state.theme,
  }
}

export default connect(mapStateToProps, { fetchBonDeLivraison })(
  BonDeLivraisonEdit
)
