import React, { Component, Fragment } from 'react'
import { Field, useForm } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import {
  composeValidators,
  minLength,
  isRequired,
  maxLength,
  length,
  mustBeNumber,
} from '../../utils/validators'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'

class ContactFormAdministratif extends Component {
  constructor(props) {
    super(props)
    this.state = {isProf : false}
  }

  componentDidMount() {
    console.log("Props Contact form admin")
    console.log(this.props)
    this.setState({ ...this.state, isGerant: this.props?.isGerant }) 
    this.setState({ ...this.state, isProf: this.props?.isTVA })
    this.getMentionsInState()
  }
  
  getMentionsInState = () => {
    ipcRenderer.invoke(Channels.MENTION_FIND_ALL, {}).then(({ data }) => {
      this.setState({
        mentions: data,
      })
    })
  }

  render() {
    return (
      <Fragment>
        <SemanticForm.Group className="inline">
          <Field name="isTVA" type="checkbox">
            {({ input, meta }) => (
              <SemanticForm.Checkbox
                {...input}
                toggle
                label="Professionnel (soumis à la TVA) ?"
                onChange={(event, { checked }) => {
                  this.setState({ ...this.state, isProf: checked })
                  return input.onChange(checked)
                }}
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          {/* <Field name="isCEE" type="checkbox">
            {({ input, meta }) => (
              <SemanticForm.Checkbox
                {...input}
                className={this.state.isProf? '' : 'hidden'}
                toggle
                label="Communauté Européenne"
                onChange={(event, { checked }) => {
                  return input.onChange(checked)
                }}
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field> */}
        </SemanticForm.Group>
        <SemanticForm.Group widths="equal">
          <Field name="numeroTVA">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                className={this.state.isProf? '' : 'hidden'}
                label="Numéro de TVA"
                type="text"
                placeholder="12312312312"
                autoComplete="off"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          <Field name="numeroSIRET" validate={composeValidators(length(14))}>
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                className={this.state.isProf? '' : 'hidden'}
                label="Numéro SIRET"
                type="text"
                placeholder="12312312312312"
                autoComplete="off"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          <Field name="remiseParDefaut">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                className={this.props.isGerant? 'hidden' : ''}
                label="Remise par défaut (%)"
                type="number"
                placeholder="1"
                autoComplete="off"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
          <Field name="emailComptable">
            {({ input, meta }) => (
              <SemanticForm.Input
                {...input}
                className={this.props.isGerant ? '' : 'hidden'}
                label="Email du comptable"
                type="text"
                placeholder="adresse@email.fr"
                autoComplete="off"
                error={meta.error && meta.touched ? meta.error : null}
              />
            )}
          </Field>
        </SemanticForm.Group>
        <SemanticForm.Group>
          <Field name="mentionId">
            {({ input, meta }) => {
              const form = useForm()
              return (
                <SemanticForm.Dropdown
                  placeholder="Tous"
                  {...input}
                  className={this.props.isGerant? 'hidden' : ''}
                  clearable={true}
                  label={'Mention'}
                  fluid
                  selection
                  onBlur={(e) => e.preventDefault()}
                  options={this.state.mentions?.map((m) => {
                    return {
                      key: m.id,
                      value: m.id,
                      text: m.descriptionCourte,
                    }
                  })}
                  onChange={(event, data) => {
                    const mentionOption = this.state.mentions.find((item) => {
                      return item.id === data.value
                    })
                    if (mentionOption) {
                      form.change(`mentionId`, mentionOption.id)
                    }
                    input.onChange(data.value)
                  }}
                  //onSearchChange={this.handleSearchChange}
                  error={meta.error && meta.touched ? meta.error : null}
                />
              )
            }}
          </Field>
        </SemanticForm.Group>
      </Fragment>
    )
  }
}

export default ContactFormAdministratif
