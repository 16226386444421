import React, { Component, Fragment } from 'react'
import SubHeader from '../shared/subHeader/SubHeader'
import { history } from '../../utils/history'
import { connect } from 'react-redux'
import DataTables from '../shared/datatable/Datatable'
import FamilleSearchForm from './CompteComptableSearchForm'
import Pagination from '../shared/datatable/Pagination'
import { fetchComptesComptables } from '../../redux/actions/compte-comptable-action'
import { Channels } from '../../api/channels'
import { ExportCsv } from '../shared/exportcsv/export-csv'
import ExportPdf from '../shared/exportcsv/export-pdf'
import { ipcRenderer } from '../../api/api'
const _ = require('lodash');

class CompteComptable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cols: [
        {
          text: 'Numéro',
          key: 'numero',
        },
        {
          text: 'Intitulé',
          key: 'intitule',
        },
      ],
    }
  }
  componentDidMount() {
    this.props.fetchComptesComptables(this.props.comptesComptables.query)
  }
  /**
   * The actions to show in the right column
   * @param {*} row
   * @returns
   */
  getActionRow = (row) => {
    if (!row.id) {
      return null
    }
    return (
      <Fragment>
        <button
          className="ui button blue"
          type="button"
          onClick={() => {
            history.push(`/settings/comptes_comptables/view/${row.id}`)
          }}
        >
          <i className="icon eye" />
          Consulter
        </button>
        <button
          className="ui button olive"
          type="button"
          onClick={() => {
            history.push(`/settings/comptes_comptables/edit/${row.id}`)
          }}
        >
          <i className="icon pencil" />
          Editer
        </button>
      </Fragment>
    )
  }
  getDataForExport = async () => {
    const { query } = this.props.comptesComptables
    const { data } = await ipcRenderer.invoke(
      Channels.COMPTE_COMPTABLE_FIND_ALL,
      _.omit(query, ['skip', 'take'])
    )

    return data
  }

  async generateFileName() {
    const { query } = this.props.comptesComptables
    const { params } = query
    let filename = 'ComptesComptables'
    filename += `${params.numero ? '_NUMERO_' + params.numero : ''}`
    // filename += `${params.intitule ? '_INTITULE_' + params.intitule : ''}`
    return filename
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <button
          className="ui button teal"
          onClick={() => {
            history.push('/settings/comptes_comptables/create')
          }}
          type="button"
        >
          <i className="icon plus" />
          Ajouter
        </button>
        <ExportCsv
          query={this.props.comptesComptables.query}
          channel={Channels.COMPTE_COMPTABLE_FIND_ALL}
          fileName = 'ComptesComptables.csv'
          keys={['code', 'libelle', 'isBio']}
        />
        <ExportPdf
          filenameGeneratorFunc={async () => await this.generateFileName()}
          csvData={this.getDataForExport}
          headers={this.state.cols}
        />
      </Fragment>
    )
  }

  /**
   * Action on column click
   * @param {*} col
   */
  onColumnClick = (col) => {
    let { query } = this.props.comptesComptables
    // Si on tri déjà par ce champs
    if (query.order[col]) {
      // Si le tri est déjà en ASC, on switch en DESC
      if (query.order[col] === 'ASC') {
        query.order[col] = 'DESC'
        // Sinon ASC
      } else {
        query.order[col] = 'ASC'
      }
    } else {
      query.order = { [col]: 'ASC' }
    }
    this.props.fetchComptesComptables(query)
  }

  /**
   * Action on page click
   * @param {*} pageInfo
   */
  onPageClick = (pageInfo) => {
    let query = this.props.comptesComptables.query
    query.skip = (pageInfo.currentPage - 1) * query.take
    this.props.fetchComptesComptables(query)
  }

  onSubmitSearch = (formValues) => {
    const query = this.props.comptesComptables.query
    query.params = formValues
    query.skip = 0
    query.take = 10
    this.props.fetchComptesComptables(query)
  }
  onResetSearch = () => {
    const query = this.props.comptesComptables.query
    query.params = {}
    this.props.fetchComptesComptables(query)
  }

  render() {
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title={'Liste des comptes comptables'}
            actions={this.getHeaderActions()}
          />
          <div className="ui divider"></div>
          <div className="dt-top-container">
            <div className="content">
              <FamilleSearchForm
                onSubmit={this.onSubmitSearch}
                initialValues={this.props.comptesComptables.query.params}
                onResetSearch={this.onResetSearch}
              />
            </div>
          </div>
          <div className="content">
            <DataTables
              columns={this.state.cols}
              rows={this.props.comptesComptables.data}
              onColumnClick={this.onColumnClick}
              actionRow={this.getActionRow}
              query={this.props.comptesComptables.query}
            />
            <Pagination
              onPageClick={this.onPageClick}
              itemPerPage={this.props.comptesComptables.query.take}
              pageNeighbours={1}
              totalItems={this.props.comptesComptables.count}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    comptesComptables: {
      count: state.comptesComptables.count,
      data: state.comptesComptables.ids
        ? state.comptesComptables.ids.map((id) => {
            return state.comptesComptables.byIds[id]
          })
        : [],
      query: state.comptesComptables.query,
    },
  }
}

export default connect(mapStateToProps, { fetchComptesComptables })(CompteComptable)
