import React, { Component } from 'react'
import SubHeader from '../../shared/subHeader/SubHeader'
import DataTables from '../../shared/datatable/Datatable'
import { connect } from 'react-redux'
import { fetchStatsVenteJournaliere } from '../../../redux/actions/statistiques-action'
import { cellFormat, roundTwoDec } from '../../shared/datatable/cell-format'
import VenteJournaliereSearchForm from './VenteJournaliereSearchForm'
import moment from 'moment'
import { Grid } from 'semantic-ui-react'
import ExportPdf from '../../shared/exportcsv/export-pdf'
import './StatistiqueVenteJournaliere.css'
const _ = require('lodash');

class StatistiqueVenteJournaliere extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cols: [
        {
          text: 'Produit',
              key: 'libelleProduit',
        },
/*         {
          text: 'Colis',
          key: 'nbColis',
        }, */
        {
            _text: 'Poids-net',
            text: 'Quantité',
          key: 'poidsNet',
        },

        {
          text: 'Montant Unitaire HT',
          key: 'prixUnitaireHT',
          format: cellFormat.MONEY,
        },
        {
          text: 'Montant Total HT',
          key: 'totalLigneHT',
          format: cellFormat.MONEY,
        },
      ],
    }
  }

  componentDidMount() {
    this.props
      .fetchStatsVenteJournaliere(this.props.statistiques.query)
      .then(this.onSubmitSearch(this.props.statistiques?.query?.params))
  }

  /**
   * Action on page click
   * @param {*} pageInfo
   */
  onColumnClick = (col) => {
    console.log('tri impossible')
  }

  /* Specifique au searchForm */
  onSubmitSearch = (formValues) => {
    // console.log('search')
    // console.log(formValues)
    const query = this.props.statistiques.query
    // console.log(query)
    if (formValues.fromDate) {
      query.params.fromDate = moment(formValues.fromDate, 'DD/MM/YYYY')
        .hour(0)
        .minutes(0)
        .second(0)
        .toDate()
      query.params.toDate = moment(formValues.fromDate, 'DD/MM/YYYY')
        .hour(23)
        .minutes(59)
        .second(59)
        .toDate()
    }

    this.props.fetchStatsVenteJournaliere(query)
  }
  renderTotalLine = (blLignes) => {
    return (
      <tr className="total-row">
        <td colSpan={2}></td>
        <td>Total</td>
        <td>
          {blLignes
            ? roundTwoDec(_.sumBy(blLignes, (ligne) => ligne?.totalLigneHT))
            : 0.0}{' '}
          €
        </td>
      </tr>
    )
  }
  renderResult = () => {
    const { statistiques } = this.props

    return statistiques?.data?.map((bl, index) => (
      <div key={index} className="paddingBottomTable">
        <Grid columns={2} relaxed="very">
          <Grid.Column>
            <div className="paddingBottomGrid">{bl.recepteur.nom}</div>
          </Grid.Column>
          <Grid.Column>
            <div className="paddingBottomGrid">BL numéro : {bl.numero}</div>
          </Grid.Column>
        </Grid>

        <DataTables
          columns={this.state.cols}
          rows={bl.bonDeLivraisonLignes}
          onColumnClick={this.onColumnClick}
          actionRow={this.getActionRow}
          query={this.props.statistiques.query}
          total={this.renderTotalLine(bl.bonDeLivraisonLignes)}
        />
      </div>
    ))
  }

  getDataForExport = () => {
    const { data } = this.props.statistiques
    const result = _.flatMap(
      data,
      ({ numero, recepteur, bonDeLivraisonLignes }) => {
        return _.map(bonDeLivraisonLignes, (bll, index) => {
          return {
            // On affiche le code récepteur que pour la première ligne
            numero,
            nomRecepteur: index === 0 ? recepteur.nom : '',
            ...bll,
          }
        })
      }
    )

    // Ajout d'une ligne pour le total (auto-bindé sur les keys des colonnes)
    result.push({
      prixUnitaireHT: 'Total',
      totalLigneHT: result
        ? roundTwoDec(
            _.sumBy(result, (ligne) =>
              isNaN(parseFloat(ligne?.totalLigneHT)) // l'espace des milliers posait problème pour la conversion en float
                ? 0
                : parseFloat(ligne?.totalLigneHT)
            )
          )
        : '0.0',
    })

    return result
  }
  async generateFileName() {
    const { query } = this.props.statistiques
    let filename = 'Vente_Journalière_du_'
    filename += `${
      query.params.fromDate
        ? '_' + moment(query.params.fromDate).format('DD/MM/YYYY')
        : ''
    }`
    return filename
  }
  getHeaderActions = () => {
    return (
      <ExportPdf
        filenameGeneratorFunc={async () => await this.generateFileName()}
        csvData={this.getDataForExport}
        showHeader={false}
        //customTotalVenteJournaliere={true} // verrue
        headers={[
          {
            text: 'Client',
            key: 'nomRecepteur',
          },
          {
            text: 'Numero BL',
            key: 'numero',
          },

          ...this.state.cols,
        ]}
        orientation="portrait"
      />
    )
  }

  render() {
    const { statistiques } = this.props
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title="Statistiques ventes journalières"
            actions={this.getHeaderActions()}
          />

          <div className="ui divider" />

          <VenteJournaliereSearchForm
            onSubmit={this.onSubmitSearch}
            initialValues={statistiques?.query?.params}
          />

          <div className="ui divider" />

          {this.renderResult()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  // console.log('mapStateToProps')
  // console.log(state)
  return {
    statistiques: {
      count: state.statistiques.venteJournaliereCount,
      data: state.statistiques.venteJournaliere,
      query: state.statistiques.venteJournaliereQuery,
    },
  }
}

export default connect(mapStateToProps, { fetchStatsVenteJournaliere })(
  StatistiqueVenteJournaliere
)
