import React, { Component, Fragment } from 'react'
import { history } from '../../utils/history'
import ProduitForm from './ProduitForm'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { Button } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { handleErrorResponse } from '../../utils/handle-error-response'

class ProduitCreate extends Component {
  constructor(props) {
    super(props)
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button icon="save" type="submit" content="Enregistrer" color="teal" />
        <Button
          icon="reply"
          type="submit"
          content="Retour à la liste"
          color="black"
          onClick={() => {
            history.push('/settings/produits')
          }}
        />
      </Fragment>
    )
  }
  onSubmit = (formValues) => {
    ipcRenderer
      .invoke(Channels.PRODUIT_CREATE, formValues)
      .then((response) => {
        if (response.hasError) {
          handleErrorResponse(response.errors)
        } else {
          history.push('/settings/produits')
          toast.success('Le produit a bien été créé')
        }
      })
      .catch((error) => {
        handleErrorResponse(error.toString())
      })
  }

  render() {
    return (
      <ProduitForm
        onSubmit={this.onSubmit}
        headerActions={this.getHeaderActions()}
        formTitle={'Créer un produit'}
      />
    )
  }
}

export default ProduitCreate
