// Types
export const MODE_VISION = 'MODE_VISION'
export const TITLE_PAGE = 'TITLE_PAGE'
export const BTN_PAGE = 'BTN_PAGE'
export const SHOW_HIDE_MODAL_EDIT = 'SHOW_HIDE_MODAL_EDIT'
export const SHOW_HIDE_MODAL_DELETE = 'SHOW_HIDE_MODAL_DELETE'
export const SHOW_HIDE_MODAL_CANCEL = 'SHOW_HIDE_MODAL_CANCEL'
export const LOAD_FORM_VALUES = 'LOAD_FORM_VALUES'
export const ACTIVATE_HOME_RECAP = 'ACTIVATE_HOME_RECAP'

export const actionTitlePage = (titlePage) => {
  return { type: TITLE_PAGE, payload: titlePage }
}

export const actionBtnPage = (btnPage) => {
  return { type: BTN_PAGE, payload: btnPage }
}

export const actionShowHideModalEdit = (active) => {
  return { type: SHOW_HIDE_MODAL_EDIT, payload: active }
}

export const actionShowHideModalDelete = (active) => {
  return { type: SHOW_HIDE_MODAL_DELETE, payload: active }
}

export const actionShowHideModalCancel = (active) => {
  return { type: SHOW_HIDE_MODAL_CANCEL, payload: active }
}

export const actionLoadFormValues = (loadedValues) => {
  return { type: LOAD_FORM_VALUES, payload: loadedValues }
}

export const activateHomeRecap = () => ({ type: ACTIVATE_HOME_RECAP })
