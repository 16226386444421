import React from 'react'

import {
  Button,
  Container,
  Form as SemanticForm,
  Modal,
  Radio,
} from 'semantic-ui-react'
import { Field, Form as FinalForm } from 'react-final-form'
import { ipcRenderer } from '../../../../api/api'
import { Channels } from '../../../../api/channels'
import DataTables from '../../../shared/datatable/Datatable'
import Pagination from '../../../shared/datatable/Pagination'

class ModalSelectContact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contactId: null,
      contacts: {},
      query: {
        params: {
          isFournisseur: this.props.isFournisseur,
          isClient: this.props.isClient,
          isActive: true,
        },
        skip: 0,
        take: 10,
        order: { code: 'ASC' },
      },
      cols: [
        {
          text: 'Code',
          key: 'code',
        },
        {
          text: 'Nom',
          key: 'nom',
        },
        {
          text: 'Ville',
          key: 'ville',
        },
      ],
    }
  }

  componentDidMount() {
    this.selectAllDatas()
  }

  selectAllDatas() {
    let query = {
      params: {
        isFournisseur: this.props.isFournisseur,
        isClient: this.props.isClient,
        isActive: true,
        isGerant: false,
      },
      skip: 0,
      take: 10,
      order: { code: 'ASC' },
    }

    ipcRenderer.invoke(Channels.CONTACT_FIND_ALL, query).then((data) => {
      this.setState({ contacts: data, query: query })
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (prevState.query !== this.state.query && !!this.state.query) ||
      (prevState.contactId !== this.state.contactId && !!this.state.contactId)
    ) {
      ipcRenderer
        .invoke(Channels.CONTACT_FIND_ALL, this.state.query)
        .then((data) => {
          this.setState({ contacts: data })
        })
    }
  }
  onPageClick = (pageInfo) => {
    const query = { ...this.state.query }
    query.skip = (pageInfo.currentPage - 1) * query.take
    this.setState({ query })
  }
  /**
   * The actions to show in the right column
   * @param {*} row
   * @returns
   */
  getActionRow = (row) => {
    return (
      <Radio
        label={''}
        name={`selectContactIdEmetteur`}
        value={row.id}
        checked={this.state.contactId === row.id}
        key={row.id}
        onChange={(e, { value }) => {
          this.setState({ contactId: value })
        }}
      />
    )
  }

  onClose = () => {
    if (!this.state.contactId) {
      this.props.onCancel()
    } else {
      this.props.onClose(this.state.contactId)
    }
  }
  onCancel = () => {
    this.props.onCancel()
  }
  onOpen = () => {
    this.props.onOpen()
  }

  onFormSubmit = (formValues) => {
    const query = { ...this.state.query }
    query.params = { ...query.params, ...formValues }
    this.setState({ query })
  }

  render() {
    return (
      <Modal
        onClose={() => this.onClose()}
        onOpen={() => this.onOpen()}
        open={this.props.open}
        size="large"
      >
        <Modal.Header>Sélectionnez un contact</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Container fluid>
              <FinalForm
                onSubmit={this.onFormSubmit}
                render={({ handleSubmit, form }) => {
                  return (
                    <SemanticForm onSubmit={handleSubmit}>
                      <SemanticForm.Group className="inline">
                        <Field name="code">
                          {({ input }) => (
                            <SemanticForm.Input
                              {...input}
                              type="text"
                              placeholder="Filtrer par code"
                              autoComplete="off" 
                            />
                          )}
                        </Field>
                        <Field name="nom">
                          {({ input }) => (
                            <SemanticForm.Input
                              {...input}
                              type="text"
                              placeholder="Filtrer par nom"
                              autoComplete="off" 
                            />
                          )}
                        </Field>
                        <Button type="submit">
                          <i className="icon search"></i>Rechercher
                        </Button>
                        <Button
                          type="button"
                          onClick={() => {
                            // Reset the form
                            form.reset()
                            this.selectAllDatas()
                          }}
                        >
                          <i className="icon eraser"></i>Effacer
                        </Button>
                      </SemanticForm.Group>
                    </SemanticForm>
                  )
                }}
              />
            </Container>
            <Container>
              <DataTables
                columns={this.state.cols}
                rows={this.state.contacts.data}
                onColumnClick={this.onColumnClick}
                actionRow={this.getActionRow}
                query={this.state.query}
              />
              <Pagination
                onPageClick={this.onPageClick}
                itemPerPage={this.state.query.take}
                pageNeighbours={1}
                totalItems={this.state.contacts.count}
              />
            </Container>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => this.onCancel()}>
            Annuler
          </Button>
          <Button
            content="Valider"
            labelPosition="right"
            icon="checkmark"
            onClick={() => this.onClose()}
            positive
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ModalSelectContact
