import React from 'react'
import { Button, Form as SemanticForm, Icon, Table } from 'semantic-ui-react'
import { Field } from 'react-final-form'
const _ = require('lodash');

class BonDeLivraisonFormTableFooter extends React.Component {
  render() {
    const { fields, view, emballages, totalTVA_2_1_visible, totalTVA_5_5_visible, totalTVA_10_visible, totalTVA_20_visible} = this.props
    return (
      //aurore: mémo : ici prise en compte de toutes les colonnes
      <Table.Footer>
        <Table.Row> 
          <Table.HeaderCell colSpan="14" rowSpan="1"> 
            <Button
              floated="left"
              type="button"
              disabled={view}
              icon
              labelPosition="left"
              primary
              size="small" 
              onClick={
                () => 
                  fields.push({
                    emballageId: emballages?.find(
                      (emballage) => emballage.code === 'R'
                      )?.id,
                      nbColis: 1,
                      tare: 0,
                      poidsBrut: 1,
                      montantRemiseIndicative: 0,
                  }) // valeur par défaut car souvent ça
              }
            >
            <Icon name="shopping basket" /> Ajouter une ligne
            </Button>
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
        {/* Ajoute 10 cellules vides pour occuper les premières colonnes */}
        {[...Array(10)].map((_, index) => (
          <Table.HeaderCell key={index} style={{ visibility: 'hidden' }} />
        ))}
          <Table.HeaderCell colSpan="2">Montant remise</Table.HeaderCell>
          {/* extension sur les colonnes 13 et 14*/}
          <Table.HeaderCell colSpan="2"> 
            <Field name={'totalMontantRemiseIndicative'}>
                {({ input, meta }) => {
                  return (
                    <SemanticForm.Input
                      {...input}
                      type="text"
                      readOnly={true}
                      className="in-array"
                      error={meta.error && meta.touched ? meta.error : null}
                    />
                  )
                }}
              </Field>
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
        {[...Array(10)].map((_, index) => (
          <Table.HeaderCell key={index} style={{ visibility: 'hidden' }} />
        ))}
          <Table.HeaderCell colSpan="2">Total HT</Table.HeaderCell>
          <Table.HeaderCell colSpan="2">
            <Field name={'totalPrixHT'}>
                {({ input, meta }) => {
                  return (
                    <SemanticForm.Input
                      {...input}
                      type="text"
                      readOnly={true}
                      className="in-array"
                      error={meta.error && meta.touched ? meta.error : null}
                    />
                  )
                }}
              </Field> 
          </Table.HeaderCell>
        </Table.Row> 
        <Table.Row hidden= {!totalTVA_2_1_visible}>
        {[...Array(10)].map((_, index) => (
          <Table.HeaderCell key={index} style={{ visibility: 'hidden' }} />
        ))}
          <Table.HeaderCell colSpan="2">Total TVA 2.1 %</Table.HeaderCell>
          <Table.HeaderCell colSpan="2">
          <Field name={'totalTVA_2_1'}>
              {({ input, meta }) => {
                return (
                  <SemanticForm.Input
                    {...input}
                    type="text"
                    readOnly={true}
                    className="in-array"
                    error={meta.error && meta.touched ? meta.error : null}
                  />
                )
              }}
            </Field>
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row hidden= {!totalTVA_5_5_visible}>
        {[...Array(10)].map((_, index) => (
          <Table.HeaderCell key={index} style={{ visibility: 'hidden' }} />
        ))}
          <Table.HeaderCell colSpan="2">Total TVA 5.5 %</Table.HeaderCell>
          <Table.HeaderCell colSpan="2">
          <Field name={'totalTVA_5_5'}>
              {({ input, meta }) => {
                return (
                  <SemanticForm.Input
                    {...input}
                    type="text"
                    readOnly={true}
                    className="in-array"
                    error={meta.error && meta.touched ? meta.error : null}
                  />
                )
              }}
            </Field>
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row hidden= {!totalTVA_10_visible}>
        {[...Array(10)].map((_, index) => (
          <Table.HeaderCell key={index} style={{ visibility: 'hidden' }} />
        ))}
          <Table.HeaderCell colSpan="2">Total TVA 10 %</Table.HeaderCell>
          <Table.HeaderCell colSpan="2">
          <Field name={'totalTVA_10'}>
              {({ input, meta }) => {
                return (
                  <SemanticForm.Input
                    {...input}
                    type="text"
                    readOnly={true}
                    className="in-array"
                    error={meta.error && meta.touched ? meta.error : null}
                  />
                )
              }}
            </Field>
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row hidden= {!totalTVA_20_visible}>
        {[...Array(10)].map((_, index) => (
          <Table.HeaderCell key={index} style={{ visibility: 'hidden' }} />
        ))}
          <Table.HeaderCell colSpan="2">Total TVA 20 %</Table.HeaderCell>
          <Table.HeaderCell colSpan="2">
          <Field name={'totalTVA_20'}>
              {({ input, meta }) => {
                return (
                  <SemanticForm.Input
                    {...input}
                    type="text"
                    readOnly={true}
                    className="in-array"
                    error={meta.error && meta.touched ? meta.error : null}
                  />
                )
              }}
            </Field>
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
        {[...Array(10)].map((_, index) => (
          <Table.HeaderCell key={index} style={{ visibility: 'hidden' }} />
        ))}
          <Table.HeaderCell colSpan="2">Total TVA</Table.HeaderCell>
          <Table.HeaderCell colSpan="2">
          <Field name={'totalTVA'}>
              {({ input, meta }) => {
                return (
                  <SemanticForm.Input
                    {...input}
                    type="text"
                    readOnly={true}
                    className="in-array"
                    error={meta.error && meta.touched ? meta.error : null}
                  />
                )
              }}
            </Field>
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
        {[...Array(10)].map((_, index) => (
          <Table.HeaderCell key={index} style={{ visibility: 'hidden' }} />
        ))}
          <Table.HeaderCell colSpan="2">Total TTC</Table.HeaderCell>
          <Table.HeaderCell colSpan="2">
            <Field name={'totalTTC'}>
              {({ input, meta }) => {
                return (
                  <SemanticForm.Input
                    {...input}
                    type="text"
                    readOnly={true}
                    className="in-array"
                    error={meta.error && meta.touched ? meta.error : null}
                  />
                )
              }}
            </Field>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    )
  }
}

export default BonDeLivraisonFormTableFooter
