import React, { Component, Fragment } from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import { ipcRenderer } from '../../api/api'
import {
  composeValidators,
  minLength,
  isRequired,
} from '../../utils/validators'
import { Channels } from '../../api/channels'
import './VarieteForm.css'

class VarieteForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      familles: [],
    }
    ipcRenderer
      .invoke(Channels.FAMILLE_FIND_ALL, { order: { libelle: 'ASC' } })
      .then(({ data }) => {
        this.setState({
          familles: data,
        })
      })
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    /*if (prevProps !== this.props && !!this.props) {
      const { initialValues } = this.props
      if (initialValues && initialValues.famille) {
        this.setState({
          familles: [initialValues.famille],
        })
      }
    }*/
  }

  /*handleSearchChange = (e) => {
    if (e.target.value && e.target.value.length > 1) {
      const query = {
        params: {
          libelle: e.target.value,
        },
      }
      ipcRenderer.invoke(Channels.FAMILLE_FIND_ALL, query).then(({ data }) => {
        this.setState({
          familles: data,
        })
      })
    }
  }*/

  render() {
    return (
      <FinalForm
        onSubmit={this.props.onSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <div className="ui segment">
                  <SubHeader
                    title={this.props.formTitle}
                    actions={this.props.headerActions}
                  />
                  <div className="ui divider"></div>
                  <SemanticForm.Group widths="equal">
                    <Field
                      name="code"
                      validate={composeValidators(isRequired, minLength(3))}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Code"
                          readOnly={this.props.view}
                          type="text"
                          placeholder="ex: Ac12"
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                    <Field
                      name="libelle"
                      validate={composeValidators(isRequired, minLength(3))}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Libellé"
                          type="text"
                          placeholder="ex: Ac12"
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                  </SemanticForm.Group>
                  <SemanticForm.Group widths="equal">
                    <Field name="familleId">
                      {({ input, meta }) => (
                        <SemanticForm.Field>
                          <SemanticForm.Dropdown
                            {...input}
                            placeholder="Sélectionnez une famille"
                            search
                            fluid
                            selection
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.familles.map((famille) => {
                              return {
                                key: famille.id,
                                value: famille.id,
                                text: famille.code + ' - ' + famille.libelle,
                              }
                            })}
                            label="Famille"
                            onChange={(event, data) => {
                              return input.onChange(data.value)
                            }}
                            //onSearchChange={this.handleSearchChange}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )}
                    </Field>
                    <Field name="isBio" type="checkbox">
                      {({ input, meta }) => (
                        <div className="field chk-position">
                          <SemanticForm.Checkbox
                            {...input}
                            toggle
                            label="Label bio ?"
                            onChange={(event, { checked }) => {
                              return input.onChange(checked)
                            }}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </div>
                      )}
                    </Field>
                  </SemanticForm.Group>
                </div>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default VarieteForm
