import React, { Component, Fragment } from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import {
  composeValidators,
  minLength,
  isRequired,
  maxLength,
} from '../../utils/validators'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'

class ParcelleForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contacts: [],
      varietes: [],
      varietesSelected: [],
    }
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_ALL, { params: { isGerant: false,}, order: { nom: 'ASC' } })
      .then(({ data }) => {
        this.setState({
          contacts: data,
        })
      })
    ipcRenderer.invoke(Channels.VARIETE_FIND_ALL, {}).then(({ data }) => {
      this.setState({
        ...this.state,
        varietes: data,
      })
    })
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (prevProps !== this.props && this.props) {
      if (this.props?.initialValues?.varietes) {
        const { varietes } = this.props.initialValues
        //console.log(varietes)

        ipcRenderer.invoke(Channels.VARIETE_FIND_ALL, {}).then(({ data }) => {
          //console.log(data)
          this.setState({
            varietesSelected: varietes.map((v) => v.id),
            varietes: data,
          })
        })
      }
    }
  }

  handleSearchChangeVarietes = (e) => {
    if (e.target.value && e.target.value.length > 2) {
      const query = {
        params: {
          libelle: e.target.value,
        },
      }

      ipcRenderer.invoke(Channels.VARIETE_FIND_ALL, query).then(({ data }) => {
        const { varietes, varietesSelected } = this.state
        this.setState({
          varietes: [
            ...varietes.filter((v) => varietesSelected.indexOf(v.id) >= 0),
            ...data.filter((v) => varietesSelected.indexOf(v.id) < 0),
          ],
        })
      })
    }
  }

  onSubmit = (formValues) => {
    formValues.varietes = formValues.varietes.map((item) => {
      if (typeof item === 'number') {
        return { id: item }
      } else {
        return item
      }
    })
    //console.log(formValues)
    if (formValues.varietes) this.props.onSubmit(formValues)
  }

  render() {
    /*console.log(this.state.varietesSelected)
    console.log(this.state.varietes)*/
    return (
      <FinalForm
        onSubmit={this.onSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <div className="ui segment">
                  <SubHeader
                    title={this.props.formTitle}
                    actions={this.props.headerActions}
                  />
                  <div className="ui divider"></div>
                  <SemanticForm.Group widths="equal">
                    <Field
                      name="code"
                      validate={composeValidators(
                        isRequired,
                        minLength(3),
                        maxLength(30)
                      )}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Code"
                          readOnly={this.props.view}
                          type="text"
                          placeholder="Code de la parcelle"
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                    <Field
                      name="libelle"
                      validate={composeValidators(isRequired, minLength(3))}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Libellé"
                          type="text"
                          placeholder="Libellé de la parcelle"
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                  </SemanticForm.Group>
                  <SemanticForm.Group widths="equal">
                    <Field
                      name="contactId"
                      validate={composeValidators(isRequired)}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Field>
                          <SemanticForm.Dropdown
                            {...input}
                            placeholder="Sélectionnez un contact"
                            search
                            fluid
                            selection
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.contacts.map((contact) => {
                              return {
                                key: contact.id,
                                value: contact.id,
                                text: contact?.code + ' - ' + contact?.nom,
                              }
                            })}
                            label="Contact"
                            onChange={(event, data) => {
                              return input.onChange(data.value)
                            }}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )}
                    </Field>
                    <Field
                      name="varietes"
                      validate={composeValidators(isRequired)}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Field>
                          <SemanticForm.Dropdown
                            {...input}
                            placeholder="Sélectionnez des varietes"
                            search
                            multiple
                            fluid
                            selection
                            value={this.state.varietesSelected}
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.varietes?.map((variete) => {
                              return {
                                key: variete.id,
                                value: variete.id,
                                text: variete.libelle,
                              }
                            })}
                            label="Variétés à associer à cette parcelle"
                            onChange={(event, data) => {
                              this.setState({
                                varietesSelected: data.value,
                              })
                              return input.onChange(data.value)
                            }}
                            onSearchChange={this.handleSearchChangeVarietes}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )}
                    </Field>
                  </SemanticForm.Group>
                </div>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default ParcelleForm
