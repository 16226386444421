import React, { Component } from 'react'
import { Button, Form as SemanticForm } from 'semantic-ui-react'
import { Field, Form as FinalForm } from 'react-final-form'
import DatePicker from 'react-datepicker'
import { ipcRenderer } from '../../../api/api'
import { Channels } from '../../../api/channels'
import { useForm } from 'react-final-form'
import moment from 'moment'

class FournisseurSearchForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contacts: [],
      varietes: [],
      familles: [],
      produits: [],
    }
  }

  componentDidMount() {
    this.getVarietesInState()
    this.getFamillesInState()
    this.getProduitsInState()
    this.getContactsInState()
  }

  getFamillesInState = () => {
    ipcRenderer.invoke(Channels.FAMILLE_FIND_ALL, {}).then(({ data }) => {
      this.setState({
        familles: data,
      })
    })
  }
  getVarietesInState = () => {
    ipcRenderer.invoke(Channels.VARIETE_FIND_ALL, {}).then(({ data }) => {
      this.setState({
        varietes: data,
      })
    })
  }
  getProduitsInState = () => {
    ipcRenderer.invoke(Channels.PRODUIT_FIND_ALL, {}).then(({ data }) => {
      this.setState({
        produits: data,
      })
    })
  }

  getContactsInState = () => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_ALL, { params: { isGerant: false, isFournisseur: true } })
      .then(({ data }) => {
        this.setState({
          contacts: data,
        })
      })
  }

  handleSubmit = (formValues) => {
    if (formValues.fromDate) {
      formValues.fromDate = moment(formValues.fromDate, 'DD/MM/YYYY')
        .hour(0)
        .minutes(0)
        .second(0)
        .toDate()
    }
    if (formValues.toDate) {
      formValues.toDate = moment(formValues.toDate, 'DD/MM/YYYY')
        .hour(23)
        .minutes(59)
        .second(59)
        .toDate()
    }
    this.props.onSubmit(formValues)
  }
  render() {
    return (
      <FinalForm
        onSubmit={this.handleSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit, form }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <SemanticForm.Group>
                  <Field name="fromDate">
                    {({ input, meta }) => (
                      <SemanticForm.Field>
                        <label>Date début</label>
                        <DatePicker
                          {...input.value}
                          locale="fr"
                          showYearDropdown
                          selected={input.value ? input.value : null}
                          dateFormat="dd/MM/yyyy"
                          onSelect={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(
                              date.setHours(
                                (-1 * date.getTimezoneOffset()) / 60
                              )
                            )

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { toDate } = form.getState().values
                            let toDateUpdated = new Date(toDate)
                            toDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`toDate`, toDateUpdated)

                            input.onChange(dateFmt)
                          }}
                          onChange={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(
                              date.setHours(
                                (-1 * date.getTimezoneOffset()) / 60
                              )
                            )

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { toDate } = form.getState().values
                            let toDateUpdated = new Date(toDate)
                            toDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`toDate`, toDateUpdated)

                            input.onChange(dateFmt)
                          }}
                        />
                      </SemanticForm.Field>
                    )}
                  </Field>
                  <Field name="toDate">
                    {({ input, meta }) => (
                      <SemanticForm.Field>
                        <label>Date de fin</label>
                        <DatePicker
                          {...input.value}
                          locale="fr"
                          showYearDropdown
                          selected={input.value ? input.value : null}
                          dateFormat="dd/MM/yyyy"
                          onSelect={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(
                              date.setHours(
                                (-1 * date.getTimezoneOffset()) / 60
                              )
                            )

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { fromDate } = form.getState().values
                            let fromDateUpdated = new Date(fromDate)
                            fromDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`fromDate`, fromDateUpdated)

                            input.onChange(dateFmt)
                          }}
                          onChange={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(
                              date.setHours(
                                (-1 * date.getTimezoneOffset()) / 60
                              )
                            )

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { fromDate } = form.getState().values
                            let fromDateUpdated = new Date(fromDate)
                            fromDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`fromDate`, fromDateUpdated)

                            input.onChange(dateFmt)
                          }}
                        />
                      </SemanticForm.Field>
                    )}
                  </Field>
                  <Field name="codeFamille">
                    {({ input, meta }) => {
                      const form = useForm()
                      return (
                        <SemanticForm.Field>
                          <label>{'Famille'}</label>
                          <SemanticForm.Dropdown
                            placeholder="Toutes les familles"
                            {...input}
                            search
                            fluid
                            selection
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.familles?.map((famille) => {
                              return {
                                key: famille.id,
                                value: famille.code,
                                text: famille.libelle,
                              }
                            })}
                            onChange={(event, data) => {
                              input.onChange(data.value)
                            }}
                            onSearchChange={this.handleSearchChange}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )
                    }}
                  </Field>
                  <Field name="codeVariete">
                    {({ input, meta }) => {
                      const form = useForm()
                      return (
                        <SemanticForm.Field>
                          <label>{'Variété'}</label>
                          <SemanticForm.Dropdown
                            placeholder="Toutes les variétés"
                            {...input}
                            search
                            fluid
                            selection
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.varietes?.map((variete) => {
                              return {
                                key: variete.id,
                                value: variete.code,
                                text: variete.libelle,
                              }
                            })}
                            onChange={(event, data) => {
                              input.onChange(data.value)
                            }}
                            onSearchChange={this.handleSearchChange}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )
                    }}
                  </Field>
                  <Field name="codeProduit">
                    {({ input, meta }) => {
                      const form = useForm()
                      return (
                        <SemanticForm.Field>
                          <label>{'Produit'}</label>
                          <SemanticForm.Dropdown
                            placeholder="Toutes les produits"
                            {...input}
                            search
                            fluid
                            selection
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.produits?.map((produit) => {
                              return {
                                key: produit.id,
                                value: produit.code,
                                text: produit.libelle,
                              }
                            })}
                            onChange={(event, data) => {
                              input.onChange(data.value)
                            }}
                            onSearchChange={this.handleSearchChange}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )
                    }}
                  </Field>
                  <Field name="contactId">
                    {({ input, meta }) => {
                      const form = useForm()
                      return (
                        <SemanticForm.Field>
                          <label>Fournisseur</label>
                          <SemanticForm.Dropdown
                            placeholder="Tous"
                            {...input}
                            search
                            fluid
                            selection
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.contacts.map((contact) => {
                              return {
                                key: contact.id,
                                value: contact.id,
                                text: contact.code + ' - ' + contact.nom,
                              }
                            })}
                            onChange={(event, data) => {
                              input.onChange(data.value)
                            }}
                            onSearchChange={this.handleSearchChange}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )
                    }}
                  </Field>
                  <div className="field searchFormBtns">
                    <Button type="submit">
                      <i className="icon search"></i>Rechercher
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        // Reset the form
                        form.reset()
                        this.props.onResetSearch()
                      }}
                    >
                      <i className="icon eraser"></i>Effacer
                    </Button>
                  </div>
                </SemanticForm.Group>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default FournisseurSearchForm
