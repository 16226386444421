import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { factureTransporteur, produit } from './types'

export const fetchFactureTransporteur = (id) => {
  return async (dispatch) => {
    try {
      const payload = await ipcRenderer.invoke(
        Channels.FACTURE_TRANSPORTEUR_FIND_BY_ID,
        id
      )
      //console.log(payload)
      if (payload.hasError) {
        throw new Error(payload.error?.toString())
      } else {
        dispatch({
          type: factureTransporteur.FETCH_FACTURE_TRANSPORTEUR,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération d'une facture transporteur")
    }
  }
}

export const fetchFacturesTransporteur = (query) => {
  return async (dispatch) => {
    try {
      const response = await ipcRenderer.invoke(
        Channels.FACTURE_TRANSPORTEUR_FIND_ALL,
        query
      )
      const payload = { response, query }
      if (response.hasError) {
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: factureTransporteur.FETCH_FACTURES_TRANSPORTEUR,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des factures transporteur")
    }
  }
}
