import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchSolde } from '../../redux/actions/solde-action'
import { roundTwoDec } from '../shared/datatable/cell-format'
import SoldeSearchForm from './SoldeSearchForm'
import './Solde.css'

class Solde extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { query } = this.props.solde
    console.log(this.props.solde)
    this.props.fetchSolde(query)
  }

  onSubmitSearch = (formValues) => {
    const query = this.props.solde.query
    query.params = formValues
    this.props.fetchSolde(query)
  }
  onResetSearch = () => {
    const query = {
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
    }
    //console.log(query)
    this.props.fetchSolde(query)
  }

  render() {
    const { data, query } = this.props.solde

    return (
      <div className="ui content soldes">
        <div className="ui segment">
          <div className="dt-top-container">
            <div className="content">
              <SoldeSearchForm
                initialValues={query?.params}
                onSubmit={this.onSubmitSearch}
                onResetSearch={this.onResetSearch}
              />
            </div>
          </div>
        </div>
        <div className="ui segment">
          <h3>CLIENTS</h3>
          <div className="ui divider" />
          <table className="ui table solde">
            <tbody>
              <tr>
                <td className="libelle">
                  Factures en attente de paiement (Montant HT)
                </td>
               {/* <td>
                   {data?.soldeClient?.factureEnAttente
                    ? data?.soldeClient.factureEnAttente?.toFixed(2)
                    : '0.00'}{' '} 
                  €
                </td>*/}
                <td>{data?.soldeClient?.factureEnAttente
                    ? roundTwoDec(data.soldeClient.factureEnAttente) : '0.00'+ ' €'}</td>
              </tr>
              <tr>
                <td className="libelle">
                  Bons de livraisons non facturés (Montant HT)
                </td>
                {/* <td>
                  {data?.soldeClient?.blNonFacture
                    ? data?.soldeClient.blNonFacture?.toFixed(2)
                    : '0.00'}{' '}
                  €
                </td> */}
                <td>{data?.soldeClient?.blNonFacture
                    ?roundTwoDec(data.soldeClient.blNonFacture) : '0.00'+ ' €'}</td>
              </tr>
            </tbody>
          </table>
          {/*<h3>FOURNISSEURS</h3>*/}
          {/*<div className="ui divider" />*/}
          {/*<table className="ui table solde">*/}
          {/*  <tbody>*/}
          {/*    <tr>*/}
          {/*      <td className="libelle">*/}
          {/*        Factures en attente de paiement (Montant HT)*/}
          {/*      </td>*/}
          {/*      <td>*/}
          {/*        {data?.soldeFournisseur?.factureEnAttente*/}
          {/*          ? data?.soldeFournisseur.factureEnAttente?.toFixed(2)*/}
          {/*          : '0.00'}{' '}*/}
          {/*        €*/}
          {/*      </td>*/}
          {/*    </tr>*/}
          {/*    <tr>*/}
          {/*      <td className="libelle">*/}
          {/*        Bons de livraisons non facturés (Montant HT)*/}
          {/*      </td>*/}
          {/*      <td>*/}
          {/*        {data?.soldeFournisseur?.blNonFacture*/}
          {/*          ? data?.soldeFournisseur.blNonFacture?.toFixed(2)*/}
          {/*          : '0.00'}{' '}*/}
          {/*        €*/}
          {/*      </td>*/}
          {/*    </tr>*/}
          {/*  </tbody>*/}
          {/*</table>*/}
        {/*  <h3>OPM-AGM</h3>*/}
        {/*  <div className="ui divider" />*/}
        {/*  <table className="ui table solde">*/}
        {/*    <tbody>*/}
        {/*      <tr>*/}
        {/*        <td className="libelle">*/}
        {/*          Factures en attente de paiement (Montant HT)*/}
        {/*        </td>*/}
        {/*        <td>*/}
        {/*          {data?.soldeOPMAGM?.factureEnAttente*/}
        {/*            ? data?.soldeOPMAGM.factureEnAttente?.toFixed(2)*/}
        {/*            : '0.00'}{' '}*/}
        {/*          €*/}
        {/*        </td>*/}
        {/*      </tr>*/}
        {/*      <tr>*/}
        {/*        <td className="libelle">*/}
        {/*          Bons de livraisons non facturés (Montant HT)*/}
        {/*        </td>*/}
        {/*        <td>*/}
        {/*          {data?.soldeOPMAGM?.blNonFacture*/}
        {/*            ? data?.soldeOPMAGM.blNonFacture?.toFixed(2)*/}
        {/*            : '0.00'}{' '}*/}
        {/*          €*/}
        {/*        </td>*/}
        {/*      </tr>*/}
        {/*    </tbody>*/}
        {/*  </table>*/}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  // console.log(state.solde)
  return {
    solde: {
      data: state.solde.data,
      query: state.solde.query,
    },
  }
}

export default connect(mapStateToProps, { fetchSolde })(Solde)
