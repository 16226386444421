import React, { Component } from 'react'
import { Divider, Grid, Header, Icon, Segment, Table } from 'semantic-ui-react'
import { VisionMode } from '../../../utils/theme'

const _ = require('lodash');

class ColisSearchForm extends Component {
  renderEmballageStat = (key, value) => {
    return (
      <Table.Row negative={value < 0} key={key}>
        <Table.Cell>{key}</Table.Cell>
        <Table.Cell>{value}</Table.Cell>
      </Table.Row>
    )
  }

  renderTableResult = (obj, formater) => {
    const data = formater ? formater(obj) : obj
    let total = 0
    Object.entries(data ? data : {}).map(([key, value]) => {
      total += value
    })
    return (
      <table
        className={`ui celled striped table ${
          this.props.theme === VisionMode.AMELIORE ? 'inverted' : ''
        }`}
      >
        <thead>
          <tr>
            <th>Code Emballage</th>
            <th>Nombre de colis</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data ? data : {}).map(([key, value]) => {
            return this.renderEmballageStat(key, value)
          })}
        </tbody>
        <thead>
          <tr>
            <th>Total</th>
            <th>{total}</th>
          </tr>
        </thead>
      </table>
    )
  }

  renderBalance = (result) => {
    return _.mergeWith(result.ACHAT, result.VENTE, (achat, vente) => {
      return (achat ? achat : 0) - (vente ? vente : 0)
    })
  }

  render() {
    const { result } = this.props
    if (!result) {
      return <div>Sélectionnez des paramètres</div>
    } else {
      return (
        <div>
          {/* <Segment placeholder> */}
          <Grid columns={2} stackable textAlign="center">
            {/* <Divider vertical>ET</Divider> */}

            <Grid.Row verticalAlign="top">
              <Grid.Column>
                <Header icon>
                  <Icon name="sign-in">
                    <h3>Colis</h3>
                  </Icon>
                </Header>
                <div className="ui content">
                  {this.renderTableResult(result?.ACHAT)}
                </div>
              </Grid.Column>

              {/* <Grid.Column>
                  <Header icon>
                    <Icon name="sign-out" />
                    Ventes
                  </Header>
                  <div>{this.renderTableResult(result?.VENTE)}</div>
                </Grid.Column> */}
            </Grid.Row>
          </Grid>
          {/* </Segment> */}
          {/* <Segment placeholder>
            <Grid columns={1} stackable textAlign="center">
              <Grid.Row verticalAlign="top">
                <Grid.Column>
                  <Header icon>
                    <Icon name="balance scale" />
                    Balance
                  </Header>
                  <div>
                    {this.renderTableResult(result, this.renderBalance)}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment> */}
        </div>
      )
    }
  }
}

export default ColisSearchForm
