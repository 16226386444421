import React, { Component } from 'react'
import { ipcRenderer } from '../../../api/api'
//import fs from 'fs' 

class ExportXml extends Component {
 
  openFileDialog = async (xml) => {
     //CJ import * as FileSaver from 'file-saver' ???
  /*  const { dialog } = require('electron')

    // console.log(xml?.filename)
    // console.log(xml?.content)
    await dialog
      .showSaveDialog({
        filters: [{ name: 'xml', extensions: ['xml'] }],
        title: 'Veuillez choisir un emplacement et un libellé de fichier',
        defaultPath: xml?.filename,
      })
      .then((result) => {
        const { filePath } = result
        if (filePath === undefined) {
          alert('Donner un nom au fichier')
        } else {
          // Save du fichier à l'emplacement, avec son contenu
          fs.writeFileSync(filePath, xml?.content, 'ascii')
        }
      })
      .catch((err) => {
        alert(err)
      })*/
  }
  render() {
    const { channel, data } = this.props

    return (
      <button
        className="ui button blue"
        type="button"
        onClick={async () => {
          const xml = await ipcRenderer.invoke(channel, data)
        }}
      >
        <i className="icon file archive" />
        Exporter
      </button>
    )
  }
}

export default ExportXml
