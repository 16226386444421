import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { bonDeLivraison } from './types'

export const fetchBonDeLivraison = (id) => {
  console.log(' fetch action')
  return async (dispatch) => {
    try {
      const payload = await ipcRenderer.invoke(
        Channels.BON_DE_LIVRAISON_FIND_BY_ID,
        id
        )
        console.log(payload)
      if (payload.hasError) {
        throw new Error(payload.error?.toString())
      } else {
        dispatch({
          type: bonDeLivraison.FETCH_BON_DE_LIVRAISON,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération d'un bon de livraison")
    }
  }
}

export const fetchBonsDeLivraison = (query) => {
  return async (dispatch) => {
    try {
      const response = await ipcRenderer.invoke(
        Channels.BON_DE_LIVRAISON_FIND_ALL,
        query
        )
        const payload = { response, query }
      if (response.hasError) {
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: bonDeLivraison.FETCH_BONS_DE_LIVRAISON,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des bons de livraison")
    }
  }
}
