import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { solde } from './types'
import { toast } from 'react-toastify'

export const fetchSolde = (query) => {
  return async (dispatch) => {
    try {
      //console.log(query)
      const response = await ipcRenderer.invoke(Channels.SOLDE, query)

      const payload = { response, query }
      if (response.hasError) {
        throw new Error(payload.error?.toString())
      } else {
        dispatch({
          type: solde.FETCH_SOLDE,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération d'un solde")
    }
  }
}
