import React, { Component } from 'react'
import { Button, Form as SemanticForm } from 'semantic-ui-react'
import { Field, Form as FinalForm } from 'react-final-form'
import DatePicker, { registerLocale } from 'react-datepicker'

class FactureTransporteurSearchForm extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <FinalForm
        onSubmit={this.props.onSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit, form }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <SemanticForm.Group className="inline">
                  <Field name="numero">
                    {({ input, meta }) => (
                      <SemanticForm.Field>
                        <label>Numéro Facture</label>
                        <SemanticForm.Input
                          {...input}
                          type="text"
                          placeholder="Filtrer par numéro"
                        />
                      </SemanticForm.Field>
                    )}
                  </Field>
                  <Field name="societeTransport">
                    {({ input, meta }) => (
                      <SemanticForm.Field>
                        <label>Société Transport</label>
                        <SemanticForm.Input
                          {...input}
                          type="text"
                          placeholder="Filtrer par société"
                        />
                      </SemanticForm.Field>
                    )}
                  </Field>
                  <Field name="fromDate">
                    {({ input, meta }) => (
                      <SemanticForm.Field>
                        <label>Date début</label>
                        <DatePicker
                          {...input.value}
                          locale="fr"
                          showYearDropdown
                          selected={input.value ? input.value : null}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            if (!date) return ''

                            const dateFmt = new Date(date)

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { toDate } = form.getState().values
                            let toDateUpdated = new Date(toDate)
                            toDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`toDate`, toDateUpdated)

                            input.onChange(dateFmt)
                          }}
                          onSelect={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(date)

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { toDate } = form.getState().values
                            let toDateUpdated = new Date(toDate)
                            toDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`toDate`, toDateUpdated)

                            input.onChange(dateFmt)
                          }}
                        />
                      </SemanticForm.Field>
                    )}
                  </Field>
                  <Field name="toDate">
                    {({ input, meta }) => (
                      <SemanticForm.Field>
                        <label>Date de fin</label>
                        <DatePicker
                          {...input.value}
                          locale="fr"
                          showYearDropdown
                          selected={input.value ? input.value : null}
                          dateFormat="dd/MM/yyyy"
                          onSelect={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(
                              date.setHours(
                                (-1 * date.getTimezoneOffset()) / 60
                              )
                            )

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { fromDate } = form.getState().values
                            let fromDateUpdated = new Date(fromDate)
                            fromDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`fromDate`, fromDateUpdated)

                            input.onChange(dateFmt)
                          }}
                          onChange={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(
                              date.setHours(
                                (-1 * date.getTimezoneOffset()) / 60
                              )
                            )

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { fromDate } = form.getState().values
                            let fromDateUpdated = new Date(fromDate)
                            fromDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`fromDate`, fromDateUpdated)

                            input.onChange(dateFmt)
                          }}
                        />
                      </SemanticForm.Field>
                    )}
                  </Field>
                  <Button type="submit">
                    <i className="icon search"></i>Rechercher
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      // Reset the form
                      form.reset()
                      this.props.onResetSearch()
                    }}
                  >
                    <i className="icon eraser"></i>Effacer
                  </Button>
                </SemanticForm.Group>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default FactureTransporteurSearchForm
