import React, { Component, Fragment } from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react'
import SubHeader from '../shared/subHeader/SubHeader'
import {
  composeValidators,
  minLength,
  isRequired,
  maxLength,
  length,
} from '../../utils/validators'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'

class EmballageForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emballages: [],
    }
  }

  render() {
    return (
      <FinalForm
        onSubmit={this.props.onSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <div className="ui segment">
                  <SubHeader
                    title={this.props.formTitle}
                    actions={this.props.headerActions}
                  />
                  <div className="ui divider"></div>

                  <SemanticForm.Group widths="equal">
                    <Field
                      name="code"
                      validate={composeValidators(isRequired, minLength(1))}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Code"
                          type="text"
                          placeholder="ex: Ac12"
                          autoComplete="off" 
                          readOnly={this.props.view}
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                    <Field
                      name="libelle"
                      validate={composeValidators(isRequired, minLength(3))}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Libellé"
                          type="text"
                          placeholder="ex: ABCDE"
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                  </SemanticForm.Group>
                </div>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default EmballageForm
