import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import { VisionMode } from '../../utils/theme'

class ContactInfoView extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { contactInfos } = this.props

    if (contactInfos == undefined || contactInfos.length == 0) {
      return null
    }

    const contactInfosBloc = contactInfos.map((contactInfo, i) => (
      <div className="dt-top-container" key={i}>
        <h4> Contact Info {i + 1}</h4>
        <div className="content">
          <Table
            definition
            className={`${
              this.props.theme.visionMode === VisionMode.AMELIORE
                ? 'inverted'
                : ''
            }`}
          >
            <Table.Body>
              <Table.Row>
                <Table.Cell>Intitulé</Table.Cell>
                <Table.Cell>{contactInfo?.intitule}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Civilité</Table.Cell>
                <Table.Cell>{contactInfo?.civilite}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Nom</Table.Cell>
                <Table.Cell>{contactInfo?.nom}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Prénom</Table.Cell>
                <Table.Cell>{contactInfo?.prenom}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Profession</Table.Cell>
                <Table.Cell>{contactInfo?.profession}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Email</Table.Cell>
                <Table.Cell>{contactInfo?.email}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Tél. Fixe</Table.Cell>
                <Table.Cell>{contactInfo?.telFixe}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Tél. Portable</Table.Cell>
                <Table.Cell>{contactInfo?.telPortable}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Fax</Table.Cell>
                <Table.Cell>{contactInfo?.fax}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </div>
    ))

    return <div>{contactInfosBloc}</div>
  }
}

export default connect(null)(ContactInfoView)
