import React, { useState } from 'react'
import {Icon} from 'semantic-ui-react';
import './Login.css';
import logo from '../../OCCIFACT-logo.jpg'

const Login = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const [type, setType] = useState('password');
  const [icon, setIcon] = useState("eye slash");

  const handleToggle = () => {
    if (type==='password'){
      setIcon("eye");
      setType('text');
    } else {
      setIcon("eye slash"); 
      setType('password');
    }
  }

  const onButtonLogInClick = () => {

    // Set initial error values to empty
    setEmailError('')
    setPasswordError('')
    
    // Check if the user has entered both fields correctly
    if ('' === email) {
        setEmailError('Veuillez entrer votre email')
        return
    }
    
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
        setEmailError('Veuillez entrer un email valide')
        return
    }
    
    if ('' === password) {
        setPasswordError('Veuillez entrer un mot de passe')
        return
    }
    
    if (password.length < 7) {
        setPasswordError('Le mot de passe doit avoir 8 caractères minimum')
        return
    }
    
    // Check if email has an account associated with it
  checkAccountExists((accountExists) => {
    // If yes, log in
    if (accountExists) logIn()
    // Else, error
    else  {
        setPasswordError('Utilisateur ou mot de passe invalide')
    }
  })
  }

  const onButtonForgotClick = () => {
    if (passwordError != 'Procédure de réinitialisation en cours'){
    setPasswordError('Procédure de réinitialisation en cours')
    forgot()
    }
  }

  // Call the server API to check if the given email ID already exists
const checkAccountExists = (callback) => {
  const API  = (process.env.NODE_ENV === 'development') ? (process.env.REACT_APP_API_DEV || 'http://localhost:3001/') : (process.env.REACT_APP_API_PROD || 'http://localhost:3001/')

    fetch(API + 'check-account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((r) => r.json())
      .then((r) => {
        callback(r?.userExists)
      })
  }
  
  // Log in a user using email and password
  const logIn = () => {
    const API  = (process.env.NODE_ENV === 'development') ? (process.env.REACT_APP_API_DEV || 'http://localhost:3001/') : (process.env.REACT_APP_API_PROD || 'http://localhost:3001/')

    fetch(API +'auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })
      .then((r) => r.json())
      .then((r) => {
        if ('success' === r.message) {
          localStorage.setItem('user', JSON.stringify({ email, token: r.token }))
          props.log(true, r.societeId)// RAF auth et recup de l'idsociete lors de l'authentification
        } else {
            setPasswordError('Mauvais email ou mot de passe') 
        }
      })
  }

  const forgot = () => {
    const API  = (process.env.NODE_ENV === 'development') ? (process.env.REACT_APP_API_DEV || 'http://localhost:3001/') : (process.env.REACT_APP_API_PROD || 'http://localhost:3001/')

    fetch(API + 'forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email}),
    })
      .then((r) => r.json())
      .then((r) => {
        // localStorage.removeItem('user')
        // props.log(false, 0)
        setPasswordError(r.message) 
      })
  }

  return (
<div className={'login-container'}>
  <div className='login-content'>
    <div className='login-logo'>
    <img src={logo} alt="logo-OCCIFACT" class="logo-image" />
    </div>
    <h1>Bienvenue !</h1>
    <br />
    <div className={'login-inputContainer'}>
      <input
        value={email}
        placeholder="Adresse email"
        onChange={(ev) => setEmail(ev.target.value)}
        className={'inputBox'}
      />
      <label className={`errorLabel ${emailError ? 'show' : ''}`}>{emailError}</label>
    <br />
      <div className="login-inputWrapper">
        <input
          type={type}
          name="password"
          value={password}
          placeholder="Mot de passe"
          onChange={(ev) => setPassword(ev.target.value)}
          className={'loginInputBox'}
          autoComplete="current-password"
        />
        <span className="eyeIcon" onClick={handleToggle}>
          <Icon name={icon} />
        </span>
        <label className={`errorLabel ${passwordError ? 'show' : ''}`}>{passwordError}</label>
      </div>
    </div>
    <div className="login-forgotPassword">
    <a href="#" onClick={(e) => { e.preventDefault(); onButtonForgotClick(); }}>Mot de passe oublié ?</a>
    </div>
    <input className={'login-inputButton'} type="button" onClick={onButtonLogInClick} value={'Connexion'} />
  </div>
</div>
  )
}

export default Login