import React, { Component } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { connect } from 'react-redux'
import { Checkbox } from 'semantic-ui-react'
import { actionVisionMode } from '../../../redux/actions/theme'
import { VisionMode } from '../../../utils/theme'
import { history } from '../../../utils/history'
import Menu from './Menu'
import './Header.css'

class Header extends Component {
  constructor(props) {
    super(props)
  }

  /**
   *
   * @param {*} event
   */
  handleTheme = (event) => {
    this.props.actionVisionMode(
      event.target.checked ? VisionMode.AMELIORE : VisionMode.CLASSIQUE
    )
  }

  onButtonClick = () => {
    this.logOut()
  }
    
  logOut = () => {
      localStorage.setItem('loggedIn', JSON.stringify(false)) 
      localStorage.setItem('societeId', JSON.stringify( -1 ))
      localStorage.removeItem('user')
      this.props.log(false, -1)
  //    history.push('/home')
  }

  render() {
    return (
      <header>
        <span
          onClick={() => {
            document.getElementById('sideMenu').classList.toggle('visible')
            document.getElementById('closebtn').classList.toggle('visible')
          }}
          className="btnToggleMenu"
        >
          <i className="bars icon"></i>
        </span>
        <div className="title">{this.props.autres.titlePage}</div>

        {/* Changement de mode de vision*/}
{/*         <div className="vision">
          <span>
            {this.props.theme.visionMode === VisionMode.AMELIORE
              ? 'Thème amélioré'
              : 'Thème classique'}
          </span>

          <Checkbox
            toggle
            onChange={(_, data) => {
              this.props.actionVisionMode(
                data.checked ? VisionMode.AMELIORE : VisionMode.CLASSIQUE
              )
            }}
          />
        </div> */}
            <Menu logout = {this.logOut}/>
      </header>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    autres: state.autres,
    theme: state.theme,
  }
}

export default connect(mapStateToProps, {
  actionVisionMode,
})(Header)
