import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { fetchAvoir } from '../../redux/actions/avoir-action'
import SubHeader from '../shared/subHeader/SubHeader'
import { Table } from 'semantic-ui-react'
import { VisionMode } from '../../utils/theme'
import { history } from '../../utils/history'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import AvoirForm from './AvoirForm'
import EmailModal from '../shared/modal/EmailModal'
import { toast } from 'react-toastify'
import { handleErrorResponse } from '../../utils/handle-error-response'
const _ = require('lodash');

class AvoirView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showModalMail: false,
      defaultEmail: '',
    }
  }

  componentDidMount() {
    this.props.fetchAvoir(this.props.match.params.id)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.avoir !== prevProps.avoir) {
      this.getRecepteurEmail(this.props.avoir?.facture.recepteurId)
    }
  }
  modalActionsMail() {
    return (
      <Fragment>
        <button className="ui button teal left labeled icon" type="submit">
          <i className="mail icon"></i>
          Envoyer
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalMail: false,
            })
            history.push(`/avoirs`)
            toast.success(
              `L'avoir ${this.props.avoir.numero} a bien été créé mais pas de email envoyé`
            )
          }}
        >
          Fermer
        </button>
      </Fragment>
    )
  }

  getRecepteurEmail = (id) => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_BY_ID, id)
      .then((response) => {
        this.setState({
          defaultEmail: response.email,
        })
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  emailSubmit = (email, objet, message) => {
    ipcRenderer
      .invoke(Channels.EMAIL_SEND, {
        email,
        objet,
        message,
        typeDocument: 'Avoir',
        idDocument: this.props.avoir.id,
        numero: this.props.avoir.numero,
      })
      .then((response) => {
        if (response.hasError) {
          handleErrorResponse(response.error)
        } else {
          history.push(`/avoirs`)
          toast.success(
            `L'avoir ${this.props.avoir.numero} a bien été créé et l'email a bien été envoyé`
          )
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button
          icon="print"
          type="button"
          content="Imprimer"
          color="green"
          onClick={() => {
            const { id } = this.props.match.params
            ipcRenderer
              .invoke(Channels.AVOIR_PRINT, id)
              .then((response) => {
                const pdfPath = response.filePath;
                if (pdfPath) {
                  window.open(pdfPath); 
                  history.push(`/avoirs`);
                  toast.success(`L'avoir est prêt à être imprimé.`);
                } else {
                  toast.error("Le chemin du PDF est manquant ou invalide.");
                }
              })
              .catch((error) => {
                console.error(error)
                toast.error("Erreur lors de l'impression de l'avoir.");
              })
          }}
        />
        <Button
          icon="mail"
          type="button"
          content="Email"
          color="blue"
          onClick={() =>
            this.setState({
              showModalMail: true,
            })
          }
        />
        <Button
          icon="reply"
          type="button"
          content="Retour"
          color="teal"
          onClick={() => {
            history.push('/avoirs')
          }}
        />
      </Fragment>
    )
  }

  render() {
    const { avoir, theme } = this.props

    return avoir ? (
      <>
        <AvoirForm
          headerActions={this.getHeaderActions()}
          formTitle={`Avoir : ${this.props.avoir?.numero}`}
          initialValues={avoir}
          onSubmit={() => {
            console.log('no submit for view')
          }}
          view={true}
          isEdit={true}
        />
        <EmailModal
          title="Envoi de l'avoir par mail"
          visible={this.state.showModalMail}
          actions={this.modalActionsMail()}
          id={avoir?.id}
          numero={avoir?.numero}
          email={this.state.defaultEmail}
          typeDocument="Avoir"
          onSubmit={this.emailSubmit}
        />
      </>
    ) : (
      <div>Chargement</div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    avoir: state.avoirs.avoir,
    theme: state.theme,
  }
}

export default connect(mapStateToProps, {
  fetchAvoir,
})(AvoirView)
