export abstract class CustomError extends Error {
  hasError: boolean = true

  protected constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, CustomError.prototype)
  }

  abstract serializeErrors(): ErrorFormated
  public static formatError(err: any) {
    if (err instanceof CustomError) {
      return err.serializeErrors()
    } else {
      return {
        hasError: true,
        type: 'Unknow',
        errors: [
          {
            message: err.message ? err.message : err,
          },
        ],
      }
    }
  }
}

export interface ErrorFormated {
  hasError: boolean
  type: string
  errors: { message: string; field?: string; value?: any }[]
}
