import React from 'react'
import { Card, Feed } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

class TraiteRecapFeed extends React.Component {
  constructor(props) {
    super(props)
  }

  renderDays = (diffDays) => {
    switch (true) {
      case diffDays > 0:
        return `Dépassée de ${parseInt(diffDays)} jours`
      case diffDays === 0:
        return "Aujourd'hui"
      case diffDays < 0:
        return `${parseInt(diffDays) * -1} jours restant`
    }
  }

  getLengthFeed = (length) => {
    if (length < 4) {
      return length
    }
    return 4
  }

  renderTraitesList() {
    const data = this.props.traites
    let nbTraites = data.length

    if (data && data.length > 0) {
      const traitesToShow = []
      let itemsLength = this.getLengthFeed(data.length)
      for (let i = 0; i < itemsLength; i++) {
        nbTraites--
        const dateEcheance = data[i].dateEcheance
        const currentDate = new Date()

        const diffDays = currentDate.getTime() - dateEcheance.getTime()

        // To calculate the no. of days between two dates
        let numberOfDays = diffDays / (1000 * 3600 * 24)

        traitesToShow.push(
          <Feed.Event key={i}>
            <Feed.Label>
              <i
                className={`warning ${
                  diffDays <= 3 ? 'red' : 'orange'
                } sign icon`}
              />
            </Feed.Label>
            <Feed.Content>
              <Feed.Date content={this.renderDays(numberOfDays)} />
              <Feed.Summary>
                {`${data[i].numeroTraite} - ${dateEcheance.toLocaleDateString(
                  'fr-FR'
                )} `}
              </Feed.Summary>
            </Feed.Content>
          </Feed.Event>
        )
      }
      return (
        <Feed>
          {traitesToShow}
          <NavLink to="/traites">et {nbTraites} de plus...</NavLink>
        </Feed>
      )
    } else {
      return <div>Pas de traite à venir</div>
    }
  }

  render() {
    const data = this.props.traites
    if (data && data.length > 0) {
      return this.renderTraitesList()
    }
    return <div>Pas de traite à venir</div>
  }
}

export default TraiteRecapFeed
