import React, { Component } from 'react'
import { Container } from 'semantic-ui-react'
import AccueilRecap from './AccueilRecap'
import { activateHomeRecap } from '../../redux/actions/autres'
import { connect } from 'react-redux'

class Accueil extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    setTimeout(this.props.activateHomeRecap, 2000)
  }

  render() {
    return (
      <Container fluid>
        {this.props.recapReadyToLoad && <AccueilRecap />}
      </Container>
    )
  }
}

export default connect(
  ({ autres }) => ({ recapReadyToLoad: autres.recapReadyToLoad }),
  { activateHomeRecap }
)(Accueil)
