import { combineReducers } from 'redux'
import themeReducer from './theme-reducer'
import modeReducers from './autres'
import familleReducer from './famille-reducer'
import varieteReducer from './variete-reducer'
import produitReducer from './produit-reducer'
import emballageReducer from './emballage-reducer'
import mentionReducer from './mention-reducer'
import parcelleReducer from './parcelle-reducer'
import contactReducer from './contact-reducer'
import traiteReducer from './traite-reducer'
import avoirReducer from './avoir-reducer'
import bonDeLivraisonReducer from './bon-de-livraison-reducer'
import facturesReducer from './facture-reducer'
import statistiquesReducer from './statistiques-reducer'
import factureTransporteurReducer from './facture-transporteur-reducer'
import soldeReducer from './solde-reducer'
import prodouaneReducer from './prodouane-reducer'
import societeReducer from './societe-reducer'
import compteComptableReducer from './compte-comptable-reducer'

// Utilisation de combineReducers pour avoir accès aux states correspondant dans les components. Ex: state.produits
const allReducers = combineReducers({
  autres: modeReducers,
  theme: themeReducer,
  familles: familleReducer,
  varietes: varieteReducer,
  produits: produitReducer,
  societes: societeReducer,
  emballages: emballageReducer,
  mentions: mentionReducer,
  parcelles: parcelleReducer,
  contacts: contactReducer,
  comptesComptables: compteComptableReducer,
  traites: traiteReducer,
  avoirs: avoirReducer,
  bonsDeLivraison: bonDeLivraisonReducer,
  factures: facturesReducer,
  statistiques: statistiquesReducer,
  facturesTransporteur: factureTransporteurReducer,
  solde: soldeReducer,
  prodouanes: prodouaneReducer,
})

export default allReducers
