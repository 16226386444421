import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { statistiques, variete } from './types'

export const fetchStatsAchatsVentes = (query) => {
  return async (dispatch) => {
    try {
      const response = await ipcRenderer.invoke(
        Channels.STATS_ACHATS_VENTES,
        query
      )
      const payload = { response, query }
      if (response.hasError) {
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: statistiques.ACHAT_VENTE,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des statistiques d'achats / ventes")
    }
  }
}

export const fetchStatsParcelles = (query) => {
  return async (dispatch) => {
    try {
      const response = await ipcRenderer.invoke(Channels.STATS_PARCELLE, query)
      const payload = { response, query }
      if (response.hasError) {
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: statistiques.PARCELLES,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des statistiques des parcelles")
    }
  }
}

export const fetchStatsClients = (query) => {
  return async (dispatch) => {
    try {
      const response = await ipcRenderer.invoke(Channels.STATS_CLIENT, query)
/*       const response2 = await ipcRenderer.invoke(Channels.STATS_PARCELLE, query) */
      const payload = { response, query }
      if (response.hasError) {
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: statistiques.CLIENT,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des statistiques clients")
    }
  }
}

export const fetchStatsFournisseurs = (query) => {
  return async (dispatch) => {
    try {
      const response = await ipcRenderer?.invoke(
        Channels.STATS_FOURNISSEUR,
        query
      )
      const payload = { response, query }

      //console.log(payload.response)

      if (response.hasError) {
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: statistiques.FOURNISSEUR,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des statistiques fournisseurs")
    }
  }
}

export const fetchStatsVenteJournaliere = (query) => {
  return async (dispatch) => {
    try {
      const response = await ipcRenderer.invoke(
        Channels.BON_DE_LIVRAISON_FIND_ALL,
        query
      )

      const payload = { response, query }

      // console.log(payload.response)

      if (response.hasError) {
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: statistiques.VENTE_JOURNALIERE,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des statistiques des ventes journalières")
    }
  }
}
