import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import ProdouaneSearchForm from './ProdouaneSearchForm'
import SubHeader from '../shared/subHeader/SubHeader'
import DataTables from '../shared/datatable/Datatable'
import { fetchProdouanes } from '../../redux/actions/prodouane-action'
import { history } from '../../utils/history'
import Pagination from '../shared/datatable/Pagination'
import moment from 'moment'
import ExportXml from '../shared/exportXML/export-xml'
import { Channels } from '../../api/channels'

class Prodouane extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cols: [
        {
          text: 'Période',
          key: 'referencePeriodAsMonthYear',
        },
        {
          text: 'Nombre de Bon de livraison',
          key: 'nbBls',
        },
      ],
    }
  }

  componentDidMount() {
    const { query } = this.props.prodouanes
    this.props.fetchProdouanes(query)
  }

  onSubmitSearch = (formValues) => {
    const query = this.props.prodouanes.query
    query.params = formValues
    query.skip = 0
    query.take = 10
    this.props.fetchProdouanes(query)
  }
  onResetSearch = () => {
    const query = {
      skip: 0,
      take: 10,
      params: {
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
      },
      order: { id: 'ASC' },
    }

    this.props.fetchProdouanes(query)
  }

  getActionRow = (row) => {
    return (
      <Fragment>
        <ExportXml channel={Channels.PRODOUANE_EXPORT} data={row.id} />
        <button
          className="ui button olive"
          type="button"
          onClick={() => {
            history.push(`/prodouane/edit/${row.id}`)
          }}
        >
          <i className="icon pencil" />
          Editer
        </button>
      </Fragment>
    )
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <button
          className="ui button teal"
          onClick={() => {
            history.push(`/prodouane/create`)
          }}
          type="button"
        >
          <i className="icon plus" />
          Ajouter
        </button>
      </Fragment>
    )
  }

  renderTableBL = () => {
    const { data, query, count } = this.props.prodouanes
    return (
      <div className="content">
        <DataTables
          columns={this.state.cols}
          rows={data}
          onColumnClick={this.onColumnClick}
          actionRow={this.getActionRow}
          query={query}
        />
        <Pagination
          onPageClick={this.onPageClick}
          itemPerPage={query.take}
          pageNeighbours={1}
          totalItems={count}
        />
      </div>
    )
  }

  render() {
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title="Export prodouane enregistré(s)"
            actions={this.getHeaderActions()}
          />

          <div className="ui divider" />

          <div className="ui content">
            <ProdouaneSearchForm
              onSubmit={this.onSubmitSearch}
              initialValues={this.props.prodouanes.query.params}
              onResetSearch={this.onResetSearch}
            />

            {this.renderTableBL()}
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    prodouanes: {
      count: state.prodouanes.count,
      data: state.prodouanes.ids
        ? state.prodouanes.ids.map((id) => {
            state.prodouanes.byIds[id].nbBls = state.prodouanes.byIds[id]
              .bonDeLivraisons
              ? state.prodouanes.byIds[id].bonDeLivraisons.length
              : 0
            state.prodouanes.byIds[id].referencePeriodAsMonthYear = moment(
              state.prodouanes.byIds[id].referencePeriod
            ).format('MM/YYYY')
            return state.prodouanes.byIds[id]
          })
        : [],
      query: state.prodouanes.query,
    },
  }
}

export default connect(mapStateToProps, {
  fetchProdouanes,
})(Prodouane)
