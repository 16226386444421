import React, { Component, Fragment } from 'react'
import SubHeader from '../shared/subHeader/SubHeader'
import { history } from '../../utils/history'
import { connect } from 'react-redux'
import DataTables from '../shared/datatable/Datatable'
import Pagination from '../shared/datatable/Pagination'
import AvoirSearchForm from './AvoirSearchForm'
import { fetchAvoirs } from '../../redux/actions/avoir-action'
import { cellFormat } from '../shared/datatable/cell-format'

class Avoir extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cols: [
        {
          text: 'Numéro Avoir',
          key: 'numero',
        },
        {
          text: 'Numéro Facture',
          key: 'facture.numero',
        },
        {
          text: 'Client',
          key: 'facture.recepteurAsString',
        },
        {
          text: 'Prix en baisse',
          key: 'isPrixEnBaisse',
        },
        {
          text: 'Retour marchandise',
          key: 'isRetourMarchandise',
        },
        {
          text: 'Perte marchandise',
          key: 'isPerteMarchandise',
        },
        {
          text: 'Comptabilisé',
          key: 'isComptabilise',
        },
        {
          text: 'Quantité retournée',
          key: 'quantiteRetournee',
        },
        {
          text: 'Montant',
          key: 'montant',
          format: cellFormat.MONEY,
        },
      ],
    }
  }
  componentDidMount() {
    this.props.fetchAvoirs(this.props.avoirs.query)
  }
  /**
   * The actions to show in the right column
   * @param {*} row
   * @returns
   */
  getActionRow = (row) => {
    if (!row.id) {
      return null
    }
    const  isEdit  = !row.isComptabilise
    return (
      <Fragment>
        <button
          className="ui button blue custom-btn"
          type="button"
          onClick={() => {
            history.push(`/avoirs/view/${row.id}`)
          }}
        >
          <i className="icon eye" />
          Consulter
        </button>
        {isEdit == true ?(
        <button
          className="ui button olive custom-btn"
          type="button"
          onClick={() => {
            history.push(`/avoirs/edit/${row.id}`)
          }}
        >
          <i className="icon pencil" />
          Editer
        </button>):''}
      </Fragment>
    )
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <button
          className="ui button teal"
          onClick={() => {
            history.push('/avoirs/create')
          }}
          type="button"
        >
          <i className="icon plus" />
          Ajouter
        </button>
      </Fragment>
    )
  }

  /**
   * Action on column click
   * @param {*} col
   */
  onColumnClick = (col) => {
    let query = this.props.avoirs.query
    // Si on tri déjà par ce champs
    if (query.order[col]) {
      // Si le tri est déjà en ASC, on switch en DESC
      if (query.order[col] === 'ASC') {
        query.order[col] = 'DESC'
        // Sinon ASC
      } else {
        query.order[col] = 'ASC'
      }
    } else {
      query.order = { [col]: 'ASC' }
    }
    this.props.fetchAvoirs(query)
  }

  /**
   * Action on page click
   * @param {*} pageInfo
   */
  onPageClick = (pageInfo) => {
    let query = this.props.avoirs.query
    query.skip = (pageInfo.currentPage - 1) * query.take
    this.props.fetchAvoirs(query)
  }

  onSubmitSearch = (formValues) => {
    const query = this.props.avoirs.query
    query.params = formValues
    this.props.fetchAvoirs(query)
  }
  onResetSearch = () => {
    const query = this.props.avoirs.query
    query.params = {}
    this.props.fetchAvoirs(query)
  }

  render() {
    return (
      <div className="ui content">
        <div className="ui segment">
          <SubHeader
            title={'Liste des avoirs'}
            actions={this.getHeaderActions()}
          />
          <div className="ui divider" />
          <div className="dt-top-container">
            <div className="content">
              <AvoirSearchForm
                onSubmit={this.onSubmitSearch}
                initialValues={this.props.avoirs.query.params}
                onResetSearch={this.onResetSearch}
                type={'VENTE'}
              />
            </div>
          </div>
          <div className="content">
            <DataTables
              columns={this.state.cols}
              rows={this.props.avoirs.data}
              onColumnClick={this.onColumnClick}
              actionRow={this.getActionRow}
              query={this.props.avoirs.query}
            />
            <Pagination
              onPageClick={this.onPageClick}
              itemPerPage={this.props.avoirs.query.take}
              pageNeighbours={1}
              totalItems={this.props.avoirs.count}
            />
            </div>
            <div className="ui divider" />
            <SubHeader
                title={''}
                    actions={this.getHeaderActions()}
                    repetitionEnbas={this.props.avoirs.count>6?true:false}
            />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    avoirs: {
      count: state.avoirs.count,
      data: state.avoirs.ids
        ? state.avoirs.ids.map((id) => {
            return state.avoirs.byIds[id]
          })
        : [],
      query: state.avoirs.query,
    },
  }
}

export default connect(mapStateToProps, { fetchAvoirs })(Avoir)
