import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form as SemanticForm } from 'semantic-ui-react'
import { Field, Form as FinalForm, useForm } from 'react-final-form'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import DatePicker, { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import moment from 'moment/moment'

registerLocale('fr', fr)
class TraiteSearchForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      contacts: [],
    }
  }

  componentDidMount() {
    this.getContactsInState()
  }

  getContactsInState = () => {
    ipcRenderer.invoke(Channels.CONTACT_FIND_ALL,  {params: { isGerant: false,}}).then(({ data }) => {
      this.setState({
        contacts: data,
      })
    })
  }

  handleSubmit = (formValues) => {
    if (formValues.fromDate) {
      formValues.fromDate = moment(formValues.fromDate, 'DD/MM/YYYY')
        .hour(0)
        .minutes(0)
        .second(0)
        .toDate()
    }
    if (formValues.toDate) {
      formValues.toDate = moment(formValues.toDate, 'DD/MM/YYYY')
        .hour(0)
        .minutes(1)
        .second(0)
        .toDate()
    }
    this.props.onSubmit(formValues)
  }
  render() {
    return (
      <FinalForm
        onSubmit={this.handleSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit, form }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <SemanticForm.Group className="inline">
                  <Field name="descriptionCourte">
                    {({ input, meta }) => (
                      <SemanticForm.Field>
                        <label>Description courte</label>
                        <SemanticForm.Input
                          {...input}
                          type="text"
                          placeholder="ex: ABCDE"
                          autoComplete="off" 
                        />
                      </SemanticForm.Field>
                    )}
                  </Field>
                  {/*<Field name="categorie">*/}
                  {/*  {({ input, meta }) => (*/}
                  {/*    <SemanticForm.Field>*/}
                  {/*      <label>Catégorie</label>*/}
                  {/*      <SemanticForm.Input*/}
                  {/*        {...input}*/}
                  {/*        type="text"*/}
                  {/*        placeholder="ex: Transport"*/}
                  {/*      />*/}
                  {/*    </SemanticForm.Field>*/}
                  {/*  )}*/}
                  {/*</Field>*/}
                  <Field name="contactId">
                    {({ input, meta }) => {
                      const form = useForm()
                      return (
                        <SemanticForm.Field>
                          <label>Contact</label>
                          <SemanticForm.Dropdown
                            placeholder="-- Tous les contacts --"
                            {...input}
                            search
                            fluid
                            selection
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.contacts?.map((contact) => {
                              return {
                                key: contact.id,
                                value: contact.id,
                                text: `${contact.code} - ${contact.nom}`,
                              }
                            })}
                            onChange={(event, data) => {
                              const contactOption = this.state.contacts.find(
                                (item) => {
                                  return item.id === data.value
                                }
                              )
                              if (contactOption) {
                                form.change(`contactId`, contactOption.id)
                              }
                              input.onChange(data.value)
                            }}
                            //onSearchChange={this.handleSearchChange}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )
                    }}
                  </Field>
                  <Field name="isPayee" type="checkbox">
                    {({ input, meta }) => (
                      <SemanticForm.Checkbox
                        {...input}
                        toggle
                        className="d-flex-center"
                        label="Payée"
                        onChange={(event, { checked }) => {
                          return input.onChange(checked)
                        }}
                        error={meta.error && meta.touched ? meta.error : null}
                      />
                    )}
                  </Field>
                  <div>
                    <Button type="submit">
                      <i className="icon search"></i>Rechercher
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        // Reset the form
                        form.reset()
                        this.props.onResetSearch()
                      }}
                    >
                      <i className="icon eraser"></i>Effacer
                    </Button>
                  </div>
                </SemanticForm.Group>
                <SemanticForm.Group>
                  <Field name="fromDate">
                    {({ input, meta }) => (
                      <SemanticForm.Field>
                        <label>Date début</label>
                        <DatePicker
                          {...input.value}
                          locale="fr"
                          showYearDropdown
                          selected={input.value ? input.value : null}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(
                              date.setHours(
                                (-1 * date.getTimezoneOffset()) / 60
                              )
                            )

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { toDate } = form.getState().values
                            let toDateUpdated = new Date(toDate)
                            toDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`toDate`, toDateUpdated)

                            input.onChange(dateFmt)
                          }}
                          onSelect={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(
                              date.setHours(
                                (-1 * date.getTimezoneOffset()) / 60
                              )
                            )

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { toDate } = form.getState().values
                            let toDateUpdated = new Date(toDate)
                            toDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`toDate`, toDateUpdated)

                            input.onChange(dateFmt)
                          }}
                        />
                      </SemanticForm.Field>
                    )}
                  </Field>
                  <Field name="toDate">
                    {({ input, meta }) => (
                      <SemanticForm.Field>
                        <label>Date de fin</label>
                        <DatePicker
                          {...input.value}
                          locale="fr"
                          showYearDropdown
                          selected={input.value ? input.value : null}
                          dateFormat="dd/MM/yyyy"
                          onSelect={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(
                              date.setHours(
                                (-1 * date.getTimezoneOffset()) / 60
                              )
                            )

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { fromDate } = form.getState().values
                            let fromDateUpdated = new Date(fromDate)
                            fromDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`fromDate`, fromDateUpdated)

                            input.onChange(dateFmt)
                          }}
                          onChange={(date) => {
                            if (!date) return ''
                            const dateFmt = new Date(
                              date.setHours(
                                (-1 * date.getTimezoneOffset()) / 60
                              )
                            )

                            // Adapte l'année de l'autre date pour rester dans l'année courante
                            const { fromDate } = form.getState().values
                            let fromDateUpdated = new Date(fromDate)
                            fromDateUpdated.setFullYear(dateFmt.getFullYear())
                            form.change(`fromDate`, fromDateUpdated)

                            input.onChange(dateFmt)
                          }}
                        />
                      </SemanticForm.Field>
                    )}
                  </Field>
                </SemanticForm.Group>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default connect(null, null)(TraiteSearchForm)
