import React, { Component } from 'react'
import { toast, ToastContainer, Flip } from 'react-toastify'
import './Notification.css'
toast.configure()

class Notification extends Component {
  notif = (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false} // progress bar
      newestOnTop={false} // apparition vers le bas
      closeOnClick
      rtl={false} // de gauche à droite
      pauseOnFocusLoss={false} // pause la notif au changement de fenêtre
      draggable // peut être fermé à la main
      pauseOnHover
      className="toast-container"
      transition={Flip}
    />
  )

  render() {
    return this.notif
  }
}

export default Notification
