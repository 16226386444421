import React, { Component, Fragment } from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form as SemanticForm, Grid, Message } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import SubHeader from '../shared/subHeader/SubHeader'
import {
  composeValidators,
  minLength,
  maxLength,
  isRequired,
  mustBeNumber,
} from '../../utils/validators'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import ModeReglement from '../../api/enum/mode-reglement'
import moment from 'moment'
import { toast } from 'react-toastify'
import './TraiteForm.css'

class TraiteForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // dateValid: new Map(),
      // initialValuesSet: false,
      contacts: [],
      edit: false,
    }

    this.state.edit = props.edit ?? false
  }

  componentDidMount = () => {
    // const dateValid = this.state.dateValid

    // dateValid.set('dateEcheance', "Date d'échéance")
    // dateValid.set('datePaiement', 'Date de paiement')
    // dateValid.set('modeReglement', 'Mode de règlement')

    const annee = new Date(Date.now()).getFullYear()
    const numero = 'TR-' + annee.toString() + '-' + (annee + 1).toString()
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_ALL, { params: { isGerant: false,}, order: { nom: 'ASC' } })
      .then(({ data }) => {
        this.setState({
          contacts: data,
          numeroTraite: numero,
        })
      })
  }

  // En édition
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { dateValid, initialValuesSet } = this.state

  //   if (this.props !== prevProps || !initialValuesSet) {
  //     // Suppression message d'erreur
  //     if (initialValuesSet && this.props.initialValues?.dateEcheance != null)
  //       dateValid.delete('dateEcheance')
  //     if (initialValuesSet && this.props.initialValues?.datePaiement != null)
  //       dateValid.delete('datePaiement')
  //     if (initialValuesSet && this.props.initialValues?.modeReglement != null)
  //       dateValid.delete('modeReglement')

  //     this.setState({
  //       initialValuesSet: true,
  //     })
  //   }
  // }

  // checkIfDateIsValid = (date, field, message) => {
  //   // Zone
  //   const dateValid = this.state.dateValid

  //   // Si date invalide ou nulle
  //   if (
  //     !moment(date).isValid() ||
  //     date.getFullYear() != new Date().getFullYear()
  //   ) {
  //     dateValid.set(field, message)
  //   } else {
  //     dateValid.delete(field)
  //   }

  //   this.setState({
  //     dateValid: dateValid,
  //   })
  // }

  // checkIfNull = (data, field, message) => {
  //   // Zone
  //   const dateValid = this.state.dateValid

  //   // Si date invalide ou nulle
  //   if (data == null || data == '') {
  //     dateValid.set(field, message)
  //   } else {
  //     dateValid.delete(field)
  //   }

  //   this.setState({
  //     dateValid: dateValid,
  //   })
  // }

  render() {
    const modeReglementOptions = []
    for (const mode in ModeReglement) {
      modeReglementOptions.push({
        key: ModeReglement[mode],
        value: ModeReglement[mode],
        text: ModeReglement[mode],
      })
    }

    const traite = this.props.initialValues
    return (
      <FinalForm
        onSubmit={this.props.onSubmit}
        initialValues={traite}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, form }) => {
          return (
            <SemanticForm onSubmit={handleSubmit} size="massive">
              <div className="ui content">
                <div className="ui segment">
                  <SubHeader
                    title={this.props.formTitle}
                    actions={this.props.headerActions}
                  />
                  {/* <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Message
                          hidden={this.state.dateValid.size <= 0}
                          icon="warning sign"
                          header="Certains éléments sont manquants ou semblent incorrects"
                          list={[...this.state.dateValid.values()]}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid> */}
                  <div className="ui divider"></div>
                  <SemanticForm.Group>
                    <Field name="isPayee" type="checkbox">
                      {({ input, meta }) => (
                        <div className="field chk-position">
                          <SemanticForm.Checkbox
                            {...input}
                            toggle
                            onChange={(event, { checked }) => {
                              // this.setState({
                              //   isPayee: !this.state.isPayee,
                              // })
                              return input.onChange(checked)
                            }}
                            label="Payée ?"
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </div>
                      )}
                    </Field>
                  </SemanticForm.Group>
                  <SemanticForm.Group widths="equal">
                    <Field
                      name="numeroTraite"
                      validate={composeValidators(
                        isRequired,
                        minLength(3),
                        maxLength(30)
                      )}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Numéro traite"
                          type="text"
                          placeholder="ex: TR-AAAA-XXXX"
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                    {/*<Field*/}
                    {/*  name="categorie"*/}
                    {/*  validate={composeValidators(*/}
                    {/*    isRequired,*/}
                    {/*    minLength(3),*/}
                    {/*    maxLength(255)*/}
                    {/*  )}*/}
                    {/*>*/}
                    {/*  {({ input, meta }) => (*/}
                    {/*    <SemanticForm.Input*/}
                    {/*      {...input}*/}
                    {/*      label="Catégorie"*/}
                    {/*      type="text"*/}
                    {/*      placeholder="ex: Transport, Emballage, URSSAF, ..."*/}
                    {/*      error={meta.error && meta.touched ? meta.error : null}*/}
                    {/*    />*/}
                    {/*  )}*/}
                    {/*</Field>*/}
                  </SemanticForm.Group>
                  <SemanticForm.Group widths="equal">
                    <Field
                      name="contactId"
                      validate={composeValidators(isRequired)}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Field>
                          <SemanticForm.Dropdown
                            {...input}
                            placeholder="Sélectionnez un contact"
                            search
                            fluid
                            selection
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.contacts?.map((contact) => {
                              return {
                                key: contact.id,
                                value: contact.id,
                                text: contact?.code + ' - ' + contact?.nom,
                              }
                            })}
                            label="Société débitrice"
                            onChange={(event, data) => {
                              return input.onChange(data.value)
                            }}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )}
                    </Field>
                    {/* <Field
                      name="factureId"
                      validate={composeValidators(isRequired)}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Field
                          className={
                            this.state.factures.length > 0 ? '' : 'hidden'
                          }
                        >
                          <SemanticForm.Dropdown
                            {...input}
                            placeholder="Sélectionnez une facture"
                            search
                            fluid
                            selection
                            onBlur={(e) => e.preventDefault()}
                            options={this.state.factures?.map((facture) => {
                              return {
                                key: facture.id,
                                value: facture.id,
                                text:
                                  facture?.numero +
                                  ' - ' +
                                  moment(facture?.date)
                                    .local()
                                    .format('DD/MM/YYYY'),
                              }
                            })}
                            label="Facture"
                            onChange={async (event, data) => {
                              await ipcRenderer
                                .invoke(Channels.FACTURE_FIND_BY_ID, data.value)
                                .then((data) => {
                                  form.change('numeroFacture', data.numero)
                                  form.change(
                                    'intituleFacture',
                                    data.numero + ' ' + data.recepteurAsString
                                  )
                                  this.setState({
                                    facture: data,
                                  })
                                })
                              return input.onChange(data.value)
                            }}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )}
                    </Field> */}

                    <Field name="numeroFacture">
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Numéro de facture"
                          type="text"
                          readOnly={this.state.view}
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>

                    {/* <Field name="intituleFacture">
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Intitulé de facture"
                          type="text"
                          readOnly={true}
                          className={
                            this.state.facture != null || this.state.edit
                              ? ''
                              : 'hidden'
                          }
                          onChange={(data) => {
                            input.onChange(data)
                          }}
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field> */}
                  </SemanticForm.Group>

                  <SemanticForm.Group widths="equal">
                    <Field
                      name="modeReglement"
                      validate={composeValidators(isRequired)}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Select
                          {...input}
                          fluid
                          label="Mode de réglement"
                          onChange={(value, data) => {
                            // this.checkIfNull(
                            //   data?.value,
                            //   input.name,
                            //   'Mode de règlement'
                            // )
                            return input.onChange(data.value)
                          }}
                          disabled={this.state.view}
                          options={modeReglementOptions}
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>

                    <Field name="numeroCheque">
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Numéro (chèque, virement, ...)"
                          placeholder="ex: 1234567"
                          autoComplete="off" 
                          type="text"
                        />
                      )}
                    </Field>
                    <Field
                      name="montant"
                      validate={composeValidators(mustBeNumber, isRequired)}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.Input
                          {...input}
                          label="Montant"
                          type="number"
                          placeholder={'Montant de la traite '}
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                  </SemanticForm.Group>
                  <SemanticForm.Group widths="equal">
                    <Field name="dateEcheance">
                      {({ input, meta }) => (
                        <SemanticForm.Field>
                          <label>Date d'échéance de paiement</label>
                          <DatePicker
                            {...input.value}
                            locale="fr"
                            selected={
                              (input.value && new Date(input.value)) || null
                            }
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            onSelect={(date) => {
                              // this.checkIfDateIsValid(
                              //   date,
                              //   input.name,
                              //   "Date d'échéance"
                              // )
                              input.onChange(date)
                            }}
                            onChange={(date) => {
                              // this.checkIfDateIsValid(
                              //   date,
                              //   input.name,
                              //   "Date d'échéance"
                              // )
                              input.onChange(date)
                            }}
                            error={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        </SemanticForm.Field>
                      )}
                    </Field>

                    <Field name="datePaiement">
                      {({ input, meta }) => (
                        <SemanticForm.Field>
                          <label>Date de paiement</label>
                          <DatePicker
                            {...input.value}
                            locale="fr"
                            selected={
                              (input.value && new Date(input.value)) || null
                            }
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            onSelect={(date) => {
                              // this.checkIfDateIsValid(
                              //   date,
                              //   input.name,
                              //   'Date de paiement'
                              // )
                              input.onChange(date)
                            }}
                            onChange={(date) => {
                              // this.checkIfDateIsValid(
                              //   date,
                              //   input.name,
                              //   'Date de paiement'
                              // )
                              input.onChange(date)
                            }}
                          />
                        </SemanticForm.Field>
                      )}
                    </Field>
                    <Field
                      name="commentaire"
                      validate={composeValidators(minLength(3), maxLength(255))}
                    >
                      {({ input, meta }) => (
                        <SemanticForm.TextArea
                          {...input}
                          label="Commentaire"
                          type="text"
                          placeholder="ex: ABCDE"
                          autoComplete="off" 
                          error={meta.error && meta.touched ? meta.error : null}
                        />
                      )}
                    </Field>
                  </SemanticForm.Group>
                </div>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default TraiteForm
