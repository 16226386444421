import React, { Fragment } from 'react'
import { history } from '../../utils/history'
import { Button } from 'semantic-ui-react'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import FactureTransporteurForm from './FactureTransporteurForm'
import moment from 'moment/moment'
import { toast } from 'react-toastify'

class FactureTransporteurCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultEmetteur: null,
      bonsDeLivraison: [],
    }
  }
  getHeaderActions = () => {
    return (
      <Fragment>
        <Button icon="save" type="submit" content="Enregistrer" color="teal" />
        <Button
          icon="reply"
          type="button"
          content="Retour à la liste"
          color="black"
          onClick={() => history.push(`/factures-transporteur/`)}
        />
      </Fragment>
    )
  }

  onSubmit = (formValues) => {
    if (!formValues?.date || !moment(formValues?.date).isValid()) {
      toast.error('La date est obligatoire.')
      return
    }
    if (
      !formValues?.bonsDeLivraison ||
      formValues.bonsDeLivraison.length == 0
    ) {
      toast.error('Veuillez sélectionner au moins un bon de livraison.')
      return
    }
    ipcRenderer
      .invoke(Channels.FACTURE_TRANSPORTEUR_CREATE, formValues)
      .then((response) => {
        history.push(`/factures-transporteur`)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  render() {
    return (
      <FactureTransporteurForm
        formTitle="Création d'une facture transporteur"
        headerActions={this.getHeaderActions()}
        onSubmit={this.onSubmit}
      />
    )
  }
}

export default FactureTransporteurCreate
