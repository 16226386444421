import React, { Component, Fragment } from 'react'
import { Button, Form as SemanticForm } from 'semantic-ui-react'
import { Field, Form as FinalForm } from 'react-final-form'

class EmballageSearchForm extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <FinalForm
        onSubmit={this.props.onSubmit}
        initialValues={this.props.initialValues}
        render={({ handleSubmit, form }) => {
          return (
            <SemanticForm onSubmit={handleSubmit}>
              <div className="ui content">
                <SemanticForm.Group className="inline">
                  <Field name="code">
                    {({ input, meta }) => (
                      <SemanticForm.Input
                        {...input}
                        label="Code"
                        type="text"
                        placeholder="ex: Ac12"
                        autoComplete="off" 
                      />
                    )}
                  </Field>
                  <Button type="submit">
                    <i className="icon search"></i>Rechercher
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      // Reset the form
                      form.reset()
                      this.props.onResetSearch()
                    }}
                  >
                    <i className="icon eraser"></i>Effacer
                  </Button>
                </SemanticForm.Group>
              </div>
            </SemanticForm>
          )
        }}
      />
    )
  }
}

export default EmballageSearchForm
