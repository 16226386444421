import React, { Component } from 'react'
import TableCell from './TableCell'
import { connect } from 'react-redux'
import { Fragment } from 'react'
import { VisionMode } from '../../../utils/theme'
import './Datatable.css'
const _ = require('lodash');

class DataTables extends Component {
  constructor(props) {
    super(props)
  }

  renderActionRowHeader = () => {
    if (!this.props.actionRow && typeof this.props.actionRow != 'function') {
      return null
    }
    return <th>Actions</th>
  }
  renderActionRow = (row) => {
    if (!this.props.actionRow && typeof this.props.actionRow != 'function') {
      return null
    }
    return <td rowtype="action">{this.props.actionRow(row)}</td>
  }

  renderOrder = (orderDirection) => {
    switch (orderDirection) {
      case 'ASC':
        return <i className="vertically flipped sort amount up icon" />
      case 'DESC':
        return <i className="sort amount down icon" />
      default:
        return <Fragment />
    }
  }

  renderTableHead = () => {
    return (
      <tr>
        {this.props.columns?.map((col, index) => {
          return (
            <th key={index} onClick={() => this.props.onColumnClick(col.key)}>
              {col.text}
              {this.renderOrder(this.props?.query?.order?.[col?.key])}
            </th>
          )
        })}
        {this.renderActionRowHeader()}
      </tr>
    )
  }
  renderTableBody = () => {
    if (this.props.rows) {
      
      return this.props.rows?.map((row, index) => {
        return (
          <tr key={index}>
            {this.props.columns?.map((col, index) => {
              return (
                <TableCell
                  key={index}
                  content={_.get(row, col.key)}
                  format={col.format}
                />
              )
            })}
            {this.renderActionRow(row)}
          </tr>
        )
      })
    }
  }

  render() {
    return (
      <div className="ui content">
        <table
          className={`ui celled striped table ${
            this.props.theme.visionMode === VisionMode.AMELIORE
              ? 'inverted'
              : ''
          }`}
        >
          <thead>{this.renderTableHead()}</thead>
          <tbody>
            {this.renderTableBody()}
            {this.props.total ? this.props.total : null}
          </tbody>
        </table>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  }
}

export default connect(mapStateToProps, null)(DataTables)
