import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { contact } from './types'

// Récupération d'un client ou du paramètre de la société si id = -1
export const fetchContact = (id) => {
  return async (dispatch) => {
    try {
      
      var payload
      // Si -1 on cherche le gérant, sinon le contact par id
      if (id == -1) {
        payload = await ipcRenderer.invoke(Channels.CONTACT_GERANT, {}) 
      } else {
        payload = await ipcRenderer.invoke(Channels.CONTACT_FIND_BY_ID, id)
      }
      if (payload.hasError) {
        throw new Error(payload.error?.toString())
      } else {
        dispatch({
          type: contact.FETCH_CONTACT,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error(`Erreur lors de la récupération ${id == -1 ? "du paramétrage société" : "d'un client"}`)
    }
  }
}

// Récupération de la liste des clients (isGerant = false)
export const fetchContacts = (query) => {
  query.params.isGerant = false;
  return async (dispatch) => {
    try {
      const response = await ipcRenderer.invoke(
        Channels.CONTACT_FIND_ALL,
        query
      )
      const payload = { response, query }
      if (response.hasError) {
        throw new Error(response.error?.toString())
      } else {
        dispatch({
          type: contact.FETCH_CONTACTS,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération des clients")
    }
  }
}
