import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { VisionMode } from '../../../utils/theme'

class CustomModal extends React.Component {
  render() {
    const mode =
      this.props.theme.visionMode === VisionMode.AMELIORE
        ? 'ameliore'
        : 'classique'

    return ReactDOM.createPortal(
      <div
        id={this.props.id}
        className={`ui dimmer pulse modals${
          this.props.visible ? ' active visible' : ''
        }`}
      >
        <div
          className={`ui medium modal center${
            this.props.visible ? ' active visible' : ''
          }`}
          onClick={(event) => event.stopPropagation()}
        >
          <div className="header">{this.props.title}</div>
          <div className="content">{this.props.content}</div>
          <div className={`actions ${mode}`}>{this.props.actions}</div>
        </div>
      </div>,
      document.querySelector('#modal')
    )
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  }
}

export default connect(mapStateToProps)(CustomModal)
