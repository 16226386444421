import React, { Component, Fragment } from 'react'
import { history } from '../../utils/history'
import { connect } from 'react-redux'
import AvoirForm from './AvoirForm'
import { Button } from 'semantic-ui-react'
import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { avoir } from '../../redux/actions/types'
import EmailModal from '../shared/modal/EmailModal'
import { toast } from 'react-toastify'
import { handleErrorResponse } from '../../utils/handle-error-response'

class AvoirCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      btnSubmit: null,
      showModalMail: false,
      avoirId: 0,
      numAvoir: '',
      defaultEmail: '',
    }
  }

  modalActionsMail() {
    return (
      <Fragment>
        <button className="ui button teal left labeled icon" type="submit">
          <i className="mail icon"></i>
          Envoyer
        </button>
        <button
          type="button"
          className="ui button black"
          onClick={() => {
            this.setState({
              showModalMail: false,
            })
            history.push(`/avoirs`)
            toast.success(
              `L'avoir ${this.state.numAvoir} a bien été créé mais pas de email envoyé`
            )
          }}
        >
          Fermer
        </button>
      </Fragment>
    )
  }

  emailSubmit = (email, objet, message) => {
    ipcRenderer
      .invoke(Channels.EMAIL_SEND, {
        email,
        objet,
        message,
        typeDocument: 'Avoir',
        idDocument: this.state.avoirId,
        numero: this.state.numAvoir,
      })
      .then((response) => {
        if (response.error) {
          handleErrorResponse(response.error)
        } else {
          history.push(`/avoirs`)
          toast.success(
            `L'avoir ${this.state.numAvoir} a bien été créé et l'email a bien été envoyé`
          )
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  getHeaderActions = () => {
    return (
      <Fragment>
        <Button
          icon="save"
          type="submit"
          content="Enregistrer"
          onClick={() => {
            this.setState({
              btnSubmit: 'enregistrer',
            })
          }}
          color="teal"
        />
        <Button
          icon="print"
          type="submit"
          content="Imprimer"
          color="green"
          onClick={() => {
            this.setState({
              btnSubmit: 'imprimer',
            })
          }}
        />
        <Button
          icon="mail"
          type="submit"
          content="Email"
          color="blue"
          onClick={() =>
            this.setState({
              btnSubmit: 'email',
            })
          }
        />
        <Button
          icon="reply"
          type="button"
          content="Retour à la liste"
          color="black"
          onClick={() => history.push(`/avoirs`)}
        />
      </Fragment>
    )
  }

  onSubmit = (formValues) => {
    formValues = { ...formValues, quantiteRetournee: 0 }
    let lignes = []
    if (!formValues.bonsDeLivraisons) {
      toast.error('Impossible de créer un avoir sans ligne')
      return
    }
    formValues.bonsDeLivraisons?.map((bl) => {
      bl.bonDeLivraisonLignes?.map((ligneBl) => {
        ligneBl.bonDeLivraisonId = undefined
        ligneBl.id = undefined
        lignes.push(ligneBl)
        formValues.quantiteRetournee += parseFloat(ligneBl.poidsNet ?? 0)
      })
    })

    formValues.montant =
      formValues.montant != null
        ? parseFloat(
            formValues.montant
          )
        : null

    formValues.montantTVA =
      (formValues.montantTVA != null)
        ? parseFloat(formValues.montantTVA)
        : null

     formValues.totalTTC =
     formValues.totalTTC != null
        ? parseFloat(formValues.totalTTC)
        : null 
    formValues = { ...formValues, avoirLignes: lignes }
    delete formValues.bonsDeLivraisons
    delete formValues.totalHTApresRemise
    delete formValues.totalHTAvantRemise
    delete formValues.montantRemise

    ipcRenderer
      .invoke(Channels.AVOIR_CREATE, formValues)
      .then((response) => {
        if (response.hasError) {
          handleErrorResponse(response.errors)
        } else {
          if (this.state.btnSubmit == 'imprimer') {
            ipcRenderer
              .invoke(Channels.AVOIR_PRINT, response.id)
              .then((response) => {
                const pdfPath = response.filePath;
               if (pdfPath) {
                 window.open(pdfPath);
                 history.push(`/avoirs/${this.props.avoir.type}`);
                 toast.success(`L'avoir ${response.numero} a bien été créé`);
               } else {
                 toast.error("Le chemin du PDF est manquant ou invalide.");
               }
             })
               .catch((error) => {
                 console.error(error)
                 toast.error("Erreur lors de l'impression de l'avoir.");
               })

          } else if (this.state.btnSubmit == 'email') {
            ipcRenderer
              .invoke(Channels.FACTURE_FIND_BY_ID, formValues.factureId)
              .then((facture) => {
                this.getRecepteurEmail(facture.recepteurId)
              })
              .catch((error) => {
                console.error(error)
              })
            this.setState(
              {
                avoirId: response.id,
                numAvoir: response.numero,
              },
              () =>
                this.setState({
                  showModalMail: true,
                })
            )
          } else {
            history.push('/avoirs')
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  getRecepteurEmail = (id) => {
    ipcRenderer
      .invoke(Channels.CONTACT_FIND_BY_ID, id)
      .then((response) => {
        this.setState({
          defaultEmail: response.email,
        })
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  render() {
    const { avoirId, numAvoir, defaultEmail } = this.state

    return (
      <>
        <AvoirForm
          onSubmit={this.onSubmit}
          headerActions={this.getHeaderActions()}
          formTitle="Créer un avoir"
          initialValues={{
            isPrixEnBaisse: false,
            isRetourMarchandise: false,
            isPerteMarchandise: false,
            isComptabilise: false,
            tauxDeTVA: 0,
            totalCotisation: '0.00',
            totalHTApresRemise: 0,
            totalHTAvantRemise: 0,
            totalTTC: 0,
            date: new Date(),
          }}
          creation={true}
          view={false}
        />
        <EmailModal
          title="Envoi de l'avoir par mail"
          visible={this.state.showModalMail}
          actions={this.modalActionsMail()}
          id={avoirId}
          numero={numAvoir}
          email={defaultEmail}
          typeDocument="Avoir"
          onSubmit={this.emailSubmit}
        />
      </>
    )
  }
}

export default connect(null, null)(AvoirCreate)
