export const composeValidators =
  (...validators) =>
  (value) => {
    return validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    )
  }

/*
 * ####################################################
 * ################# GENERAL ##########################
 * ####################################################
 */
/**
 * Check if the field is not undefined
 * @param value
 * @returns {undefined|string}
 */
export const isRequired = (value) =>
  value || value >= 0 ? undefined : 'Ce champs est requis'

/*
 * ####################################################
 * ################## STRING ##########################
 * ####################################################
 */
/**
 * Check if the string length equal to the length param
 * @param min
 * @returns {function(*=): undefined|string}
 */
export const length = (length) => (value) =>
  !value || (typeof value === 'string' && value.length === length)
    ? undefined
    : `Ce champs doit comporter ${length} caractères`

/**
 * Check if the string length is upper than the min param
 * @param min
 * @returns {function(*=): undefined|string}
 */
export const minLength = (min) => (value) =>
  !value || (typeof value === 'string' && value.length >= min)
    ? undefined
    : `Ce champs doit comporter au moins ${min} caractères`

/**
 * Check if the string length is less than the max param
 * @param max
 * @returns {function(*=): undefined|string}
 */
export const maxLength = (max) => (value) =>
  !value || (typeof value === 'string' && value.length <= max)
    ? undefined
    : `Ce champs doit comporter moins de ${max} caractères`

/*
 * ####################################################
 * ################## NUMBER ##########################
 * ####################################################
 */
/**
 * Check if the value is a number
 * @param value
 * @returns {string|undefined}
 */
export const mustBeNumber = (value) =>
  value && isNaN(value) ? 'La valeur doit être un nombre' : undefined

/**
 * Check if the value is upper than the min param
 * @param min
 * @returns {function(*=): undefined|string}
 */
export const minValue = (min) => (value) =>
  isNaN(value) || value >= min ? undefined : `Doit être supérieur à ${min}`

/**
 * Check if the value is less than the max param
 * @param max
 * @returns {function(*=): undefined|string}
 */
export const maxValue = (max) => (value) =>
  isNaN(value) || value <= max ? undefined : `Doit être supérieur à ${max}`
