import React, { Component } from 'react'
import './Footer.css'
//CJ import { remote } from 'electron'

class Footer extends Component {
  render() {
    return (
      <footer>
        Inforsud Technologies SAS - Copyright &copy; {new Date().getFullYear()}{' '}
        - Tous droits réservés{/* - Version  {remote?.app?.getVersion()} */} 
      </footer>
    )
  }
}

export default Footer
