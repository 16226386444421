import { ipcRenderer } from '../../api/api'
import { Channels } from '../../api/channels'
import { toast } from 'react-toastify'
import { traite } from './types'

export const fetchTraite = (id) => {
  return async (dispatch) => {
    try {
      const payload = await ipcRenderer.invoke(Channels.TRAITE_FIND_BY_ID, id)
      if (payload.hasError) {
        throw new Error(payload.error.toString())
      } else {
        dispatch({
          type: traite.FETCH_TRAITE,
          payload: payload,
        })
      }
    } catch (ex) {
      console.log(ex)
      toast.error("Erreur lors de la récupération d'une traite")
    }
  }
}

export const fetchTraites = (query) => {
  return async (dispatch) => {
    try {
      const response = await ipcRenderer.invoke(Channels.TRAITE_FIND_ALL, query)
      const payload = { response, query }
      if (response.hasError) {
        console.log(response.errors)
        throw new Error()
      } else {
        dispatch({
          type: traite.FETCH_TRAITES,
          payload: payload,
        })
      }
    } catch (ex) {
      toast.error('Erreur lors de la récupération des traites.')
    }
  }
}
